import { z } from 'zod';


export const LivestormApiResponseSchema = z.object({
  data: z.array(
    z.object({
      type: z.literal('events'),
      attributes: z.object({
        title: z.string()
      })
    })
  ),
  meta: z.object({
    current_page: z.number().gte(0),
    previous_page: z.string().nullable(),
    next_page: z.string().nullable(),
    record_count: z.number().gte(0),
    page_count: z.number().gte(0),
    items_per_page: z.number().gte(0)
  })
});

export type LivestormApiResponseType = z.infer<typeof LivestormApiResponseSchema>;

export const GetUpcomingLivestormEventsResponseSchema = z.object({
  upcomingLivestormEvents: z.object({
    eventTitle: z.string()
  }).array()
});

export type GetUpcomingLiveStormEventsResponseType = z.infer<typeof GetUpcomingLivestormEventsResponseSchema>;
