
type FieldWithoutImageProps = {
  wrapperClassName: string;
  selector: JSX.Element;
};

export function FieldWithoutImage(props: FieldWithoutImageProps): JSX.Element {
  return (
    <div className={props.wrapperClassName}>
      {props.selector}
    </div>
  );
}