import { z } from 'zod';

export const MarketIndustryEnum = z.enum(
  [
    'Aerospace',
    'Agriculture Equipment',
    'Alternative Energy',
    'Automotive',
    'Construction Equipment and Vehicles',
    'Electronics',
    'Oil and Gas',
    'Other',
    'Student',
    'Truck and Trailer',
  ],
  { errorMap: () => ({ message: 'Please select a market industry' }) }
)

export const AreaOfInterestEnum = z.enum(
  [
    'Assembly',
    'Automation',
    'Coatings',
    'Locking',
    'New Fasteners',
    'New Metals',
    'Other',
    'Sealing',
  ],
  { errorMap: () => ({ message: 'Please select an area of interest' }) }
)

export const JobFocusEnum = z.enum(
  [
    'Aircraft',
    'Attachments',
    'BIW',
    'Brakes',
    'Cabin',
    'Carport',
    'Chassis',
    'Combine',
    'Computer',
    'Downhole',
    'Dozers',
    'Engine',
    'Entertainment',
    'Excavators',
    'Exterior',
    'Fixed Tilt',
    'Interior',
    'IP and Infotainment',
    'Landing/Brakes',
    'Medical',
    'Other',
    'Phone',
    'Power Electronics',
    'Powertrain',
    'Refinery',
    'Residential',
    'Satelite',
    'Seats',
    'Seats and Steering',
    'Skateboard',
    'Skid Loaders',
    'Space Travel',
    'Storage',
    'Tablet/Laptop',
    'Topside',
    'Tower',
    'Trackers',
    'Tractor',
    'Transmission',
    'Turbine',
  ],
  { errorMap: () => ({ message: 'Please select an industry focus' }) }
)

export const UserBasicFormSchema = z.object({
  email: z.string().trim().email(),
  firstName: z.string().trim()
    .min(1, {
      message: 'First name cannot be blank'
    })
    .max(200, {
      message: 'First name must be 200 characters or shorter'
    }),
  lastName: z.string().trim()
    .min(1, {
      message: 'Last name cannot be blank'
    })
    .max(200, {
      message: 'Last name must be 200 characters or shorter'
    }),
  // Could add better phone number validation. EG, https://github.com/google/libphonenumber
  phoneNumber: z.string().trim()
    .min(1, {
      message: 'Phone number cannot be blank'
    })
    .max(25, {
      message: 'Phone number must be 25 characters or shorter'
    }).regex(new RegExp('^[-+\(\)0-9 ]+$'), {
      message: 'Phone number may only contain numbers, spaces, and the following characters: +-()'
    }),
  companyName: z.string().trim()
    .min(1, {
      message: 'Company or university name cannot be blank'
    })
    .max(200, {
      message: 'Company or university name must be 200 characters or shorter'
    })
});

export const UserDemographicsFormSchema = z.object({
  jobTitle: z.string().trim()
    .min(1, {
      message: 'First name cannot be blank'
    })
    .max(200, {
      message: 'First name must be 200 characters or shorter'
    }),
  marketIndustry: MarketIndustryEnum,
  jobFocus: JobFocusEnum,
  areaOfInterest: AreaOfInterestEnum,
});

export type UserDemographicsFormType = z.infer<typeof UserDemographicsFormSchema>;

export type UserBasicFormType = z.infer<typeof UserBasicFormSchema>;

export const CountryEnum = z.enum(
  [
    "United States of America",
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Côte d'Ivoire",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia (Czech Republic)",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini (formerly Swaziland)",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ],
  { errorMap: () => ({ message: 'Please select a country' }) }
)

export const StateEnum = z.enum(
  [
    "AK",
    "AL",
    "AR",
    "AS",
    "AZ",
    "CA",
    "CO",
    "CT",
    "DC",
    "DE",
    "FL",
    "FM",
    "GA",
    "GU",
    "HI",
    "IA",
    "ID",
    "IL",
    "IN",
    "KS",
    "KY",
    "LA",
    "MA",
    "MD",
    "ME",
    "MH",
    "MI",
    "MN",
    "MO",
    "MP",
    "MS",
    "MT",
    "NC",
    "ND",
    "NE",
    "NH",
    "NJ",
    "NM",
    "NV",
    "NY",
    "OH",
    "OK",
    "OR",
    "PA",
    "PR",
    "PW",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VA",
    "VI",
    "VT",
    "WA",
    "WI",
    "WV",
    "WY",
  ],
  { errorMap: () => ({ message: 'Please select a state' }) }
)

export const UserBillingAddressUsaSchema = z.object({
  country: z.enum([CountryEnum.Enum["United States of America"]]),
  streetAddress: z.string().trim()
    .min(1, {
      message: 'Street address cannot be blank'
    })
    .max(500, {
      message: 'Street address may not be longer than 500 characters'
    }),
  city: z.string().trim()
    .min(1, {
      message: 'City cannot be blank'
    })
    .max(200, {
      message: 'City may not be longer than 200 characters'
    }),
  state: StateEnum,
  postalCode: z.string().trim()
    .length(5, {
      message: 'Zip code must be 5 digits long'
    })
    .regex(new RegExp('^\\d+$'), 'Zip code may contain only numbers'),
})


export const UserBillingAddressNonUsaSchema = z.object({
  country: CountryEnum,
  streetAddress: z.string().trim()
    .min(1, {
      message: 'Street address cannot be blank'
    })
    .max(500, {
      message: 'Street address may not be longer than 500 characters'
    }),
  postalCode: z.string().trim()
    .max(25, {
      message: 'Postal code may not be longer than 25 digits'
    })
    .regex(new RegExp('^\\d*$'), 'Postal code may contain only numbers')
    .optional(),
})

export const UserTermsAcceptanceSchema = z.object({
  termsOfUseAcceptance: z.preprocess((arg) => {
    if (typeof arg == "string" || arg instanceof Date) return new Date(arg);
  }, z.date())
});

export const UserBillingAddressBaseSchema = z.object({
  country: CountryEnum,
  // termsOfUseAcceptance: z.date({ errorMap: () => ({ message: 'Please check the box to accept the terms of use'}) })
  //   .refine(
  //     (date: Date) => date <= new Date,
  //     'Please check the box to accept the terms of use'
  //   )
  // termsOfUseAcceptance: z.preprocess((arg) => {
  //   if (typeof arg == "string" || arg instanceof Date) return new Date(arg);
  // }, z.date())
}).merge(UserTermsAcceptanceSchema);

export const UserBillingAddressUsaFormSchema = UserBillingAddressBaseSchema.merge(UserBillingAddressUsaSchema)
export const UserBillingAddressNonUsaFormSchema = UserBillingAddressBaseSchema.merge(UserBillingAddressNonUsaSchema)
export const UserBillingAddressSchema = z.union([
  UserBillingAddressUsaFormSchema,
  UserBillingAddressNonUsaFormSchema,
  UserTermsAcceptanceSchema
])

export type UserBillingAddressUsaFormType = z.infer<typeof UserBillingAddressUsaFormSchema>
export type UserBillingAddressNonUsaFormType = z.infer<typeof UserBillingAddressNonUsaFormSchema>
export type UserNonBillingFormType = z.infer<typeof UserTermsAcceptanceSchema>

export const UserInfoSchema = UserBasicFormSchema
  .and(UserDemographicsFormSchema)
  .and(UserBillingAddressSchema);

export type UserInfoSchemaType = z.infer<typeof UserInfoSchema>;
