import generalStyles from "../styles/style.module.scss"

const LoadingScreen: React.ReactFragment = <>
  <div className={generalStyles.loadingScreenText}>Taking you to Finding Fasteners!</div>
  <div className={generalStyles.loadingScreenWrapper}>
    {/* <div className="LoadingScreenText">Taking you to Finding Fasteners!</div> */}
    <div className={generalStyles.loadingScreen}></div>
  </div>
</>;
export default LoadingScreen;
