import styles from "../../styles/_findIt.module.scss"

type FieldWithImageProps = {
  wrapperClassName: string;
  imgSrc?: string;
  selector: JSX.Element;
};

export function FieldWithImage(props: FieldWithImageProps): JSX.Element {
  return (
    <div className={props.wrapperClassName}>
      {props.selector}
      <img className={styles.findingItMobileImage} src={props.imgSrc} />
    </div>
  );
}
