import { UnitSystem } from '../FindIt/findItTypes';
import { Form } from 'react-bootstrap';
import Switch from 'react-switch';
import styles from "../../styles/_findIt.module.scss"


type UnitSystemToggleProps = {
  currentUnitSystem: UnitSystem;
  setUnitSystem: (newUnitSystem: UnitSystem) => void;
  disableToggle?: true
};
export function UnitSystemToggle(props: UnitSystemToggleProps) {
  const handleToggleCurrentUnitSystem = () => {
    const newUnitSystem = (props.currentUnitSystem === UnitSystem.Imperial)
      ? UnitSystem.Metric
      : UnitSystem.Imperial;
    props.setUnitSystem(newUnitSystem);
  };

  return (
    <>
      <h2 className={`${styles.smallH2} ${styles.blueH2} ${styles.unitSystemLabel}`}>Unit System</h2>
      <Form.Text>Imperial</Form.Text>
      <Switch
        disabled={props.disableToggle}
        checked={props.currentUnitSystem === UnitSystem.Metric}
        onChange={handleToggleCurrentUnitSystem}
        checkedIcon={false}
        uncheckedIcon={false}
        onColor={'#327C9B'}
        offColor={'#327C9B'} />
      <Form.Text>Metric</Form.Text>
      { props.disableToggle && 
        // Note: This can removed when it is no longer needed for demo purposes
        <p
          style={{
            gridColumn: "1/5",
            fontSize: "0.9rem",
            color: "#052c45",
            fontStyle: "italic"
          }}
        >
          Imperial option coming soon
        </p>
      }
   </>
  );
}
