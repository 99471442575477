import React from "react";
import PropTypes from "prop-types";
import BaseSelect from "react-select";
import generalStyles from "../../styles/style.module.scss"

const noop = () => {
  // no operation (do nothing real quick)
};

class FixRequiredSelect extends React.Component {
  state = {
    // @ts-ignore
    value: this.props.value
  };

  selectRef = null;
  // @ts-ignore
  setSelectRef = ref => {
    this.selectRef = ref;
  };

  // @ts-ignore
  onChange = (value, actionMeta) => {
    // @ts-ignore
    this.props.onChange(value, actionMeta);
    this.setState({ value });
  };

  getValue = () => {
    // @ts-ignore
    if (this.props.value !== undefined) return this.props.value;
    return this.state.value || "";
  };

  render() {
    // @ts-ignore
    const { SelectComponent, required, ...props } = this.props;
    // @ts-ignore
    const { isDisabled } = this.props;
    const enableRequired = !isDisabled;

    return (
      <div>
        <SelectComponent
          {...props}
          ref={this.setSelectRef}
          onChange={this.onChange}
        />
        {enableRequired && (
          <input
            tabIndex={-1}
            autoComplete="off"
            value={this.getValue()}
            onChange={noop}
            // @ts-ignore
            onFocus={() => this.selectRef.focus()}
            required={required}
          />
        )}
      </div>
    );
  }
}

// @ts-ignore
FixRequiredSelect.defaultProps = {
  onChange: noop
};

// @ts-ignore
FixRequiredSelect.protoTypes = {
  // react-select component class (e.g. Select, Creatable, Async)
  selectComponent: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool
};

type SelectProps =
  & React.ComponentProps<typeof BaseSelect>
  & { required?: boolean }

const FixRequiredSelectWrapper = (props: SelectProps) => (
  <div
    className={generalStyles.reactSelect}
  >
    <FixRequiredSelect
      {...props}
      // @ts-ignore
      SelectComponent={BaseSelect}
      options={props.options}
    />
  </div>
);

export type ReactSelectOption<ValueType, LabelType> = {
  value: ValueType,
  label: LabelType,
  tooltipText?: string
}

export type ReactSelectOptions<ValueType, LabelType> = Array<ReactSelectOption<ValueType, LabelType>>

export default FixRequiredSelectWrapper;
