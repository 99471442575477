import { SvgLine } from "../utilities/SvgLine"; // todo: move SvgLine component to its own file at `[project root]/utilities/SvgLine`

export type RivetDiagramProps = {
  rivetHighlightedFeatures: {
    diameter?: boolean,
    gripRange?: boolean,
    head?: boolean
  }
}

type SvgPathProps = {
  display?: boolean;
  fillColor: string;
  pathString: string;
  style?: React.CSSProperties;
};

const SvgPath = (props: SvgPathProps) => {
  const defaultStyle: React.CSSProperties = {
    fill: "none",
    stroke: "#3c649b",
    strokeWidth: 2,
    strokeLinecap: "round",
    strokeLinejoin: "round",
  };
  return (
    <path
      style={props.style ? { ...defaultStyle, ...props.style } : defaultStyle}
      fillOpacity={1}
      strokeOpacity={1}
      // fillOpacity={props.display ? 1 : undefined}
      // strokeOpacity={props.display ? 1 : undefined}
      d={props.pathString}
    />
  );
};

export const RivetDiagram = (props: RivetDiagramProps) => {

  const indicators = {
    diameter: <>
      <SvgLine
        display={props.rivetHighlightedFeatures.diameter}
        coordinates={{
          x1: 20.0157,
          x2: 20.0157,
          y1: 55,
          y2: 88,
        }}
      />
      <SvgLine
        display={props.rivetHighlightedFeatures.diameter}
        coordinates={{
          x1: 20.0157,
          x2: 30.016,
          y1: 88,
          y2: 88,
        }}
      />
      <SvgLine
        display={props.rivetHighlightedFeatures.diameter}
        coordinates={{
          x1: 20,
          x2: 30,
          y1: 55,
          y2: 55,
        }}
      />
    </>,
    gripRange: <>
      <SvgLine
        display={props.rivetHighlightedFeatures.gripRange}
        coordinates={{
          x1: 120,
          x2: 55,
          y1: 45,
          y2: 45,
        }}
      />
      <SvgLine
        display={props.rivetHighlightedFeatures.gripRange}
        coordinates={{
          x1: 55,
          x2: 55,
          y1: 45,
          y2: 54,
        }}
      />
      <SvgLine
        display={props.rivetHighlightedFeatures.gripRange}
        coordinates={{
          x1: 120,
          x2: 120,
          y1: 45,
          y2: 54,
        }}
      />
    </>
  }

  const indicatorList = Object.entries(indicators).map(([indicatorName, svgObj]) => {
    return svgObj
  })

  const st_1: React.CSSProperties = {
    fill: "url(#SVGID_00000046333722953570387230000011155759707905367191_)",
    stroke: "#000000",
    strokeWidth: 1.5,
    strokeMiterlimit: 10,
  };

  const st_1_highlight: React.CSSProperties = {
    fill: "#052c45",
    stroke: "#000000",
    strokeWidth: 1.5,
    strokeMiterlimit: 10,
  };

  

  const standardStrokeStyle: React.CSSProperties = {
    stroke: "#000000",
    strokeWidth: "1.5",
    strokeMiterlimit: "10",
  }

  const roundStrokeStyle: React.CSSProperties = {
    ...standardStrokeStyle,
    strokeLinecap: "round",
    strokeLinejoin: "round",
  }

  const st0: React.CSSProperties = {
    fill: "url(#SVGID_1_)",
    ...standardStrokeStyle
  }

  const st8: React.CSSProperties = {
    ...roundStrokeStyle,
    fill: "none",
    strokeWidth: "1.8289",
  }

  const st10: React.CSSProperties = {
    ...roundStrokeStyle,
    fill: "none",
  }


  return <>
    <svg
      version="1.1" 
      id="RivetIllustration" 
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px" 
      viewBox="0 0 396 144"
    >
      <linearGradient id="SVGID_1_" x1="52.365" x2="88.882" y1="85.109" y2="85.109" gradientTransform="rotate(-90 71.583 67.867)" gradientUnits="userSpaceOnUse">
        <stop stop-color="#fff" offset="0"/>
        <stop stop-color="#FBFBFB" offset="0"/>
        <stop stop-color="#BBBDBF" offset="0"/>
        <stop stop-color="#909295" offset="0"/>
        <stop stop-color="#77787B" offset="0"/>
        <stop stop-color="#6D6E71" offset="0"/>
        <stop stop-color="#97999B" offset=".0444"/>
        <stop stop-color="#DEDFE1" offset=".1191"/>
        <stop stop-color="#fff" offset=".153"/>
        <stop stop-color="#E4E5E6" offset=".173"/>
        <stop stop-color="#A6A8AB" offset=".2294"/>
        <stop stop-color="#7D7F81" offset=".2762"/>
        <stop stop-color="#636466" offset=".3109"/>
        <stop stop-color="#58595B" offset=".3298"/>
        <stop stop-color="#6D6E71" offset=".51"/>
        <stop stop-color="#858789" offset=".539"/>
        <stop stop-color="#B6B8BA" offset=".6011"/>
        <stop stop-color="#DBDDDE" offset=".6536"/>
        <stop stop-color="#F3F3F4" offset=".694"/>
        <stop stop-color="#fff" offset=".7173"/>
        <stop stop-color="#6D6E71" offset="1"/>
      </linearGradient>
      <radialGradient id="SVGID_00000080199254882532718480000002909629027488536218_" cx="66.679" cy="130.69" r="24.072" gradientTransform="rotate(-90 71.583 67.867)" gradientUnits="userSpaceOnUse">
        <stop stop-color="#fff" offset="0"/>
        <stop stop-color="#6D6E71" offset="1"/>
      </radialGradient>
      <linearGradient id="SVGID_00000046333722953570387230000011155759707905367191_" x1="34.693" x2="100.17" y1="124.67" y2="124.67" gradientTransform="rotate(-90 71.583 67.867)" gradientUnits="userSpaceOnUse">
        <stop stop-color="#6D6E71" offset=".0722"/>
        <stop stop-color="#97999B" offset=".1092"/>
        <stop stop-color="#DEDFE1" offset=".1714"/>
        <stop stop-color="#fff" offset=".1997"/>
        <stop stop-color="#FBFBFB" offset=".2013"/>
        <stop stop-color="#BBBDBF" offset=".2476"/>
        <stop stop-color="#909295" offset=".2859"/>
        <stop stop-color="#77787B" offset=".3143"/>
        <stop stop-color="#6D6E71" offset=".3298"/>
        <stop stop-color="#6D6E71" offset=".6024"/>
        <stop stop-color="#858789" offset=".6185"/>
        <stop stop-color="#B6B8BA" offset=".6529"/>
        <stop stop-color="#DBDDDE" offset=".682"/>
        <stop stop-color="#F3F3F4" offset=".7044"/>
        <stop stop-color="#fff" offset=".7173"/>
        <stop stop-color="#6D6E71" offset=".9818"/>
      </linearGradient>
      <linearGradient id="SVGID_00000159447776063640982040000015777019724883560107_" x1="48.343" x2="86.46" y1="137.59" y2="137.59" gradientTransform="translate(-139.45 3.7154)" gradientUnits="userSpaceOnUse">
        <stop stop-color="#414042" offset="0"/>
        <stop stop-color="#F1F2F2" offset=".5062"/>
        <stop stop-color="#414042" offset="1"/>
      </linearGradient>
      <linearGradient id="SVGID_00000137841553970616038480000004045467692225490576_" x1="54.323" x2="82.174" y1="39.484" y2="39.484" gradientTransform="rotate(-90 71.583 67.867)" gradientUnits="userSpaceOnUse">
        <stop stop-color="#fff" offset="0"/>
        <stop stop-color="#FCFDFD" offset="0"/>
        <stop stop-color="#CDCFD1" offset="0"/>
        <stop stop-color="#B1B3B6" offset="0"/>
        <stop stop-color="#A7A9AC" offset="0"/>
        <stop stop-color="#A1A3A5" offset=".0316"/>
        <stop stop-color="#7B7C7F" offset=".2452"/>
        <stop stop-color="#626366" offset=".4069"/>
        <stop stop-color="#58595B" offset=".4967"/>
        <stop stop-color="#5D5E60" offset=".57"/>
        <stop stop-color="#6B6C6E" offset=".6667"/>
        <stop stop-color="#7F8184" offset=".7765"/>
        <stop stop-color="#9C9EA1" offset=".895"/>
        <stop stop-color="#BCBEC0" offset="1"/>
      </linearGradient>
      <linearGradient id="SVGID_00000096051288335226769760000003520350869615936166_" x1="53.021" x2="83.32" y1="44.925" y2="44.925" gradientTransform="rotate(-90 71.583 67.867)" gradientUnits="userSpaceOnUse">
        <stop stop-color="#fff" offset="0"/>
        <stop stop-color="#FBFBFB" offset="0"/>
        <stop stop-color="#BBBDBF" offset="0"/>
        <stop stop-color="#909295" offset="0"/>
        <stop stop-color="#77787B" offset="0"/>
        <stop stop-color="#6D6E71" offset="0"/>
        <stop stop-color="#97999B" offset=".0444"/>
        <stop stop-color="#DEDFE1" offset=".1191"/>
        <stop stop-color="#fff" offset=".153"/>
        <stop stop-color="#E4E5E6" offset=".1782"/>
        <stop stop-color="#A6A8AB" offset=".2492"/>
        <stop stop-color="#7D7F81" offset=".308"/>
        <stop stop-color="#636466" offset=".3518"/>
        <stop stop-color="#58595B" offset=".3755"/>
        <stop stop-color="#6D6E71" offset=".5762"/>
        <stop stop-color="#858789" offset=".6111"/>
        <stop stop-color="#B6B8BA" offset=".6855"/>
        <stop stop-color="#DBDDDE" offset=".7485"/>
        <stop stop-color="#F3F3F4" offset=".7968"/>
        <stop stop-color="#fff" offset=".8248"/>
        <stop stop-color="#6D6E71" offset="1"/>
      </linearGradient>
      <linearGradient id="SVGID_00000125585653573827720440000011256977867705053878_" x1="52.963" x2="82.981" y1="49.209" y2="49.209" gradientTransform="rotate(-90 71.583 67.867)" gradientUnits="userSpaceOnUse">
        <stop stop-color="#fff" offset="0"/>
        <stop stop-color="#FBFBFB" offset="0"/>
        <stop stop-color="#BBBDBF" offset="0"/>
        <stop stop-color="#909295" offset="0"/>
        <stop stop-color="#77787B" offset="0"/>
        <stop stop-color="#6D6E71" offset="0"/>
        <stop stop-color="#97999B" offset=".0596"/>
        <stop stop-color="#DEDFE1" offset=".16"/>
        <stop stop-color="#fff" offset=".2055"/>
        <stop stop-color="#E4E5E6" offset=".2247"/>
        <stop stop-color="#A6A8AB" offset=".2785"/>
        <stop stop-color="#7D7F81" offset=".3231"/>
        <stop stop-color="#636466" offset=".3562"/>
        <stop stop-color="#58595B" offset=".3742"/>
        <stop stop-color="#6D6E71" offset=".5831"/>
        <stop stop-color="#858789" offset=".6141"/>
        <stop stop-color="#B6B8BA" offset=".6802"/>
        <stop stop-color="#DBDDDE" offset=".7361"/>
        <stop stop-color="#F3F3F4" offset=".7791"/>
        <stop stop-color="#fff" offset=".804"/>
        <stop stop-color="#6D6E71" offset="1"/>
      </linearGradient>
      <linearGradient id="SVGID_00000179612037711014569850000007677457808872634770_" x1="59.148" x2="75.655" y1="244.91" y2="244.91" gradientTransform="rotate(-90 71.583 67.867)" gradientUnits="userSpaceOnUse">
        <stop stop-color="#fff" offset="0"/>
        <stop stop-color="#FBFBFB" offset="0"/>
        <stop stop-color="#BBBDBF" offset="0"/>
        <stop stop-color="#909295" offset="0"/>
        <stop stop-color="#77787B" offset="0"/>
        <stop stop-color="#6D6E71" offset="0"/>
        <stop stop-color="#97999B" offset=".0559"/>
        <stop stop-color="#DEDFE1" offset=".15"/>
        <stop stop-color="#fff" offset=".1927"/>
        <stop stop-color="#CFD0D2" offset=".2329"/>
        <stop stop-color="#939597" offset=".2931"/>
        <stop stop-color="#696B6D" offset=".3431"/>
        <stop stop-color="#4D4D4F" offset=".3802"/>
        <stop stop-color="#414042" offset=".4004"/>
        <stop stop-color="#58595B" offset=".5306"/>
        <stop stop-color="#858789" offset=".5858"/>
        <stop stop-color="#B6B7BA" offset=".6524"/>
        <stop stop-color="#DBDDDE" offset=".7089"/>
        <stop stop-color="#F3F3F4" offset=".7523"/>
        <stop stop-color="#fff" offset=".7773"/>
        <stop stop-color="#6D6E71" offset="1"/>
      </linearGradient>
      <linearGradient id="SVGID_00000072996294218251874960000007836614699414907565_" x1="65.472" x2="74.334" y1="341.92" y2="343.38" gradientTransform="rotate(-90 71.583 67.867)" gradientUnits="userSpaceOnUse">
        <stop stop-color="#fff" offset="0"/>
        <stop stop-color="#FBFBFB" offset=".00039274"/>
        <stop stop-color="#BBBDBF" offset=".0116"/>
        <stop stop-color="#909295" offset=".0209"/>
        <stop stop-color="#77787B" offset=".0278"/>
        <stop stop-color="#6D6E71" offset=".0315"/>
        <stop stop-color="#97999B" offset=".0783"/>
        <stop stop-color="#DEDFE1" offset=".157"/>
        <stop stop-color="#fff" offset=".1927"/>
        <stop stop-color="#D0D1D3" offset=".2607"/>
        <stop stop-color="#A3A6A8" offset=".3387"/>
        <stop stop-color="#8A8C8E" offset=".3971"/>
        <stop stop-color="#808285" offset=".4289"/>
        <stop stop-color="#6D6E71" offset=".5901"/>
        <stop stop-color="#858789" offset=".6086"/>
        <stop stop-color="#B6B8BA" offset=".6479"/>
        <stop stop-color="#DBDDDE" offset=".6812"/>
        <stop stop-color="#F3F3F4" offset=".7068"/>
        <stop stop-color="#fff" offset=".7216"/>
        <stop stop-color="#F0F0F1" offset=".7551"/>
        <stop stop-color="#D1D2D4" offset=".8188"/>
        <stop stop-color="#9FA2A4" offset=".9056"/>
        <stop stop-color="#6D6E71" offset=".9924"/>
      </linearGradient>
      <path style={st0} d="m97.295 56.12c-1.49 0-8.94 0.76-12.15-0.6-3.45-1.46-26.89 0.4-26.89 0.4l-2.79 3.79c-2.28 12.27 0 23.21 0 23.21l2.89 3.77s23.61 0.91 26.69-0.07c4.28-1.36 11.95-0.23 11.95-0.23s14.09 1.14 18.16 0.19c3.79-0.89 8.05 0.16 8.05 0.16s-1.52-15.29 0-29.65c0 0-4.12 0.79-8.21-0.49-3.64-1.12-17.7-0.48-17.7-0.48z" fill="url(#SVGID_1_)"/>
      <g stroke="#000" stroke-miterlimit="10" stroke-width="1.5">
        <path
          style={props.rivetHighlightedFeatures.head ? st_1_highlight : st_1 }
         d="m141.31 91.11s-3.67 16.59-10.83 13.17c-7.17-3.42-7.82-25.74-7.82-33.56s0.98-26.55 7.49-30.79c6.52-4.24 10.03 12.9 10.03 12.9" fill="url(#SVGID_00000046333722953570387230000011155759707905367191_)"/> 
        <path
        style={props.rivetHighlightedFeatures.head ? st_1_highlight : st_1 }
        d="m133.95 104.29c-7.17-3.42-7.82-25.74-7.82-33.56s0.98-26.55 7.49-30.79l0.29-0.16c-1.12-0.67-2.37-0.75-3.77 0.16-6.52 4.24-7.49 22.97-7.49 30.79s0.65 30.14 7.82 33.56c1.34 0.64 2.56 0.58 3.66 0.06z" fill="url(#SVGID_00000046333722953570387230000011155759707905367191_)"/>
        <ellipse style={props.rivetHighlightedFeatures.head ? st_1_highlight : st_1 } transform="rotate(-90)" cx="-72.05" cy="141.31" rx="19.06" ry="5.13" fill="url(#SVGID_00000159447776063640982040000015777019724883560107_)"/>
        <path 
        d="m46.705 85.13c-4.05-2.54-7.2-7.81-7.2-13.82 0-5.74 2.66-11.2 7.37-14.03" fill="url(#SVGID_00000137841553970616038480000004045467692225490576_)"/>
        <path d="m53.205 86.43h-5.65s-3.48-3.34-3.48-15.04 3.69-15.25 3.69-15.25h5.27c0.01-0.01-5.7 13.36 0.17 30.29z" fill="url(#SVGID_00000096051288335226769760000003520350869615936166_)" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="m53.045 56.13 2.41 3.58c-2.28 9.77 0 23.21 0 23.21l-2.25 3.5c0 0.01-6.22-15.19-0.16-30.29z" fill="url(#SVGID_00000125585653573827720440000011256977867705053878_)" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <path style={st8} d="m97.505 56.97s-2.93 4.63-2.93 14.47c0 9.34 2.56 14.46 2.56 14.46"/>
      <path d="m355.92 70.97c0.8 0.51 0.8 1.67 0 2.18-3.56 2.24-11.61 7.15-13.67 7.15h-196.69c-2.67 0-4.83-2.16-4.83-4.83v-6.85c0-2.67 2.16-4.83 4.83-4.83h196.68c2.06 0 10.12 4.94 13.68 7.18z" fill="url(#SVGID_00000179612037711014569850000007677457808872634770_)" stroke="#000" stroke-miterlimit="10" stroke-width="1.5"/>
      <path style={st10} d="m342.24 63.79-4.76 1.96c-1.1 0.45-1.39 1.88-0.55 2.73l5 5.02c0.13 0.13 0.14 0.35 0.03 0.49l-3.37 4.18c-0.15 0.19-0.08 0.47 0.14 0.57l3.51 1.56"/>
      <path d="m342.37 63.79c-0.08-0.02-3.08 1.13-5.19 1.94-0.95 0.37-1.21 1.59-0.49 2.31l5.48 5.5 13.5-0.68c0.89-0.13 1.11-1.31 0.33-1.75 0 0.01-9.15-6.17-13.63-7.32z" fill="url(#SVGID_00000072996294218251874960000007836614699414907565_)" stroke="#000" stroke-miterlimit="10" stroke-width="1.5"/>
      <path style={props.rivetHighlightedFeatures.head ? st_1_highlight : st_1 } d="m144.16 80.35-1.5 1.88c-0.58 0.73-1.74 0.58-2.1-0.29-0.77999-1.88-1.8-5.19-1.85-9.68-0.05-4.8 0.91-8.02 1.68-9.81 0.35999-0.85 1.51-0.99 2.09-0.27l1.6 1.99s-2.78 2.18-2.78 7.88 2.86 8.3 2.86 8.3z"/>
      {indicatorList}
    </svg>
  </>
  
}