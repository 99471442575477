import { SvgLine } from "../utilities/SvgLine";

export type ThreadedDiagramProps = {
  highlightedFeatures: {
    diameter?: boolean;
    pitch?: boolean;
    length?: boolean;
    head?: boolean;
    material?: boolean;
    drive?: boolean;
  };
};

export const ThreadedDiagram = (props: ThreadedDiagramProps) => {
  
  const indicators = {
    diameter: <>
      <SvgLine
        display={props.highlightedFeatures.diameter}
        coordinates={{
          x1: 20.0157,
          x2: 43.0157,
          y1: 132,
          y2: 190,
        }}
      />
      <SvgLine
        display={props.highlightedFeatures.diameter}
        coordinates={{
          x1: 20.0157,
          x2: 32.016,
          y1: 132,
          y2: 126,
        }}
      />
      <SvgLine
        display={props.highlightedFeatures.diameter}
        coordinates={{
          x1: 43.0157,
          x2: 56.016,
          y1: 190,
          y2: 184,
        }}
      />
      </>,
    pitch: <>
      <SvgLine
        display={props.highlightedFeatures.pitch}
        coordinates={{
          x1: 71.016,
          x2: 155.02,
          y1: 95,
          y2: 64,
        }}
      />
      <SvgLine
        display={props.highlightedFeatures.pitch}
        coordinates={{
          x1: 71.016,
          x2: 73.5,
          y1: 95,
          y2: 102,
        }}
      />
      <SvgLine
        display={props.highlightedFeatures.pitch}
        coordinates={{
          x1: 157.5,
          x2: 155.02,
          y1: 71,
          y2: 64,
        }}
      />
      </>,
    length: <>
      <SvgLine
        display={props.highlightedFeatures.length}
        coordinates={{
          x1: 210.02,
          x2: 66.016,
          y1: 154.4,
          y2: 200.4,
        }}
      />
      <SvgLine
        display={props.highlightedFeatures.length}
        coordinates={{
          x1: 65.016,
          x2: 60.9,
          y1: 200.4,
          y2: 190.4,
        }}
      />
      <SvgLine
        display={props.highlightedFeatures.length}
        coordinates={{
          x1: 210.02,
          x2: 206.5,
          y1: 154,
          y2: 144.4,
        }}
      />
      </>,
  }

  const indicatorList = Object.entries(indicators).map(
    ([indicatorName, svgObj]) => {
      return svgObj;
    }
  );

  const st_1: React.CSSProperties = {
    fill: "url(#SVGID_1_)",
    stroke: "#000000",
    strokeWidth: 1.5,
    strokeMiterlimit: 10,
  };

  const st_1_highlight: React.CSSProperties = {
    fill: "#052c45",
    stroke: "#000000",
    strokeWidth: 1.5,
    strokeMiterlimit: 10,
  };

  const st2: React.CSSProperties = {
    fill: "url(#SVGID_2_)",
    stroke: "#000000",
    strokeWidth: 1.5,
    strokeMiterlimit: 10,
  };

  const st2_highlight: React.CSSProperties = {
    fill: "#052c45",
    stroke: "#000000",
    strokeWidth: 1.5,
    strokeMiterlimit: 10,
  };

  return (
    <>
      <svg
        version="1.1"
        id="ThreadedIllustration"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 300 225"
        // xlinkHref="data:image/png;base64"
      >
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1="85.1624"
          y1="52.9802"
          x2="125.5764"
          y2="100.7423"
          gradientTransform="matrix(-0.9164 -0.4003 -0.4003 0.9164 331.2718 69.1978)"
        >
          <stop offset="0" style={{ stopColor: "#7C7E80" }} />
          <stop offset="0.4308" style={{ stopColor: "#F1F2F2" }} />
          <stop offset="1" style={{ stopColor: "#6D6E71" }} />
        </linearGradient>
        <linearGradient
          id="SVGID_1_highlight"
          gradientUnits="userSpaceOnUse"
          x1="85.1624"
          y1="52.9802"
          x2="125.5764"
          y2="100.7423"
          gradientTransform="matrix(-0.9164 -0.4003 -0.4003 0.9164 331.2718 69.1978)"
        >
          <stop offset="0" style={{ stopColor: "#245696" }} />
          <stop offset="0.4308" style={{ stopColor: "#F1F2F2" }} />
          <stop offset="1" style={{ stopColor: "#3c649b" }} />
        </linearGradient>
        <path
          // Head underside
          style={props.highlightedFeatures.head ? st_1_highlight : st_1}
          d="M171.97,38.14c-26.85,10.91-37.25,47.75-23.22,82.29s47.17,53.7,74.03,42.79s37.25-47.75,23.22-82.29
        S198.82,27.23,171.97,38.14z"
        />
        <path
          // Head rim
          style={props.highlightedFeatures.head ? st2_highlight : st2}
          d="M170.97,38.19c25.47-5.65,54.56,13.02,67.46,44.77c13.72,33.77,4.09,69.73-21.44,81.52c2.33-0.5,4.62-1.2,6.88-2.12
        c26.85-10.91,37.25-47.75,23.22-82.29s-47.17-53.7-74.03-42.79C172.35,37.57,171.66,37.87,170.97,38.19z"
        />
        <linearGradient
          id="SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1="189.8437"
          y1="106.7025"
          x2="138.4076"
          y2="160.7628"
          gradientTransform="matrix(-0.9164 -0.4003 -0.4003 0.9164 331.2718 69.1978)"
        >
          <stop offset="0" style={{ stopColor: "#7C7E80" }} />
          <stop offset="0.4888" style={{ stopColor: "#F1F2F2" }} />
          <stop offset="1" style={{ stopColor: "#6D6E71" }} />
        </linearGradient>
        <linearGradient
          id="SVGID_2_highlight"
          gradientUnits="userSpaceOnUse"
          x1="189.8437"
          y1="106.7025"
          x2="138.4076"
          y2="160.7628"
          gradientTransform="matrix(-0.9164 -0.4003 -0.4003 0.9164 331.2718 69.1978)"
        >
          <stop offset="0" style={{ stopColor: "#245696" }} />
          <stop offset="0.4888" style={{ stopColor: "#F1F2F2" }} />
          <stop offset="1" style={{ stopColor: "#3c649b" }} />
        </linearGradient>
        <path
          style={st2}
          d="
        M42.8,157.84c8.43,21.8,23.55,20.65,23.55,20.65c21.4,4.24,132.79-40.71,132.79-40.71s3.66-0.76,7.7-9.89
        c4.04-9.14,7.87-26.67,5.18-33.27c-2.57-6.29-17.83-17.5-26.62-21.79c-6.64-3.24-11.86-1.05-11.86-1.05
        C166.91,74.8,57.8,109.2,45.71,125.72C45.71,125.72,34.37,136.04,42.8,157.84z"
        />
        <path
          style={st2}
          d="M192.23,140.5c0,0,3.66-0.76,7.7-9.89c4.04-9.14,7.87-26.67,5.18-33.27c-2.57-6.29-17.83-17.5-26.62-21.79
        c-6.64-3.24-13.8-0.61-13.8-0.61"
        />
        <path
          style={st2}
          d="M184.53,143.47c0,0,3.78-1.44,7.82-10.58c4.04-9.14,7.87-26.67,5.18-33.27c-2.57-6.29-17.83-17.5-26.62-21.79
        c-6.64-3.24-11.92-0.88-11.92-0.88"
        />
        <path
          style={st2}
          d="M177.82,146.1c0,0,4.42-1.35,8.46-10.49c4.04-9.14,7.87-26.67,5.18-33.27c-2.57-6.29-17.83-17.5-26.62-21.79
        c-6.64-3.24-12.61-1.3-12.61-1.3"
        />
        <path
          style={st2}
          d="M169.71,149.11c0,0,4.93-2.56,8.96-11.7c4.04-9.14,7.87-26.67,5.18-33.27c-2.57-6.29-17.83-17.5-26.62-21.79
        c-6.64-3.24-11.9-0.69-11.9-0.69"
        />
        <path
          style={st2}
          d="M163.1,151.53c0,0,4.78-2.34,8.82-11.47s7.87-26.67,5.18-33.27c-2.57-6.29-17.83-17.5-26.62-21.79
        c-6.64-3.24-11.86-1.05-11.86-1.05"
        />
        <path
          style={st2}
          d="M158.28,153.49c0,0,3.13-1.76,7.17-10.9c4.04-9.14,7.87-26.67,5.18-33.27c-2.57-6.29-17.83-17.5-26.62-21.79
        c-6.64-3.24-11.99-1.22-11.99-1.22"
        />
        <path
          style={st2}
          d="M151.42,155.88c0,0,3.08-1.45,7.12-10.58c4.04-9.14,7.87-26.67,5.18-33.27c-2.57-6.29-17.83-17.5-26.62-21.79
        c-6.64-3.24-12.14-1.38-12.14-1.38"
        />
        <path
          style={st2}
          d="M144.48,158.46c0,0,2.82-1.2,6.86-10.33c4.04-9.14,7.87-26.67,5.18-33.27c-2.57-6.29-17.83-17.5-26.62-21.79
        c-6.64-3.24-12.74-1.36-12.74-1.36"
        />
        <path
          style={st2}
          d="M136.87,161.09c0,0,3.23-1.01,7.27-10.15c4.04-9.14,7.87-26.67,5.18-33.27c-2.57-6.29-17.83-17.5-26.62-21.79
        c-6.64-3.24-12.94-1.49-12.94-1.49"
        />
        <path
          style={st2}
          d="M129.67,163.49c0,0,3.66-0.76,7.7-9.89s7.87-26.67,5.18-33.27c-2.57-6.29-17.83-17.5-26.62-21.79
        c-6.64-3.24-12.98-1.51-12.98-1.51"
        />
        <path
          style={st2}
          d="M124.74,165.3c0,0,2.8-2.78,6.84-11.91c4.04-9.14,6.49-23.65,3.8-30.25c-2.57-6.29-17.83-17.5-26.62-21.79
        c-6.64-3.24-13.07-1.54-13.07-1.54"
        />
        <path
          style={st2}
          d="M118.12,167.31c0,0,2.33-1.7,6.37-10.84c4.04-9.14,6.82-24.08,4.12-30.68c-2.57-6.29-17.83-17.5-26.62-21.79
        c-6.64-3.24-13.03-1.47-13.03-1.47"
        />
        <path
          style={st2}
          d="M111.66,169.26c0,0,2.96-2.05,7-11.19c4.04-9.14,5.88-23.03,3.18-29.63c-2.57-6.29-17.83-17.5-26.62-21.79
        c-6.64-3.24-12.99-1.34-12.99-1.34"
        />
        <path
          style={st2}
          d="M105.67,171.07c0,0,2.75-1.56,6.79-10.7c4.04-9.14,5.61-22.79,2.91-29.4c-2.57-6.29-17.83-17.5-26.62-21.79
        c-6.64-3.24-12.62-1.3-12.62-1.3"
        />
        <path
          style={st2}
          d="M99.68,172.88c0,0,2.04-1.68,6.07-10.82c4.04-9.14,6-22.01,3.3-28.61c-2.57-6.29-17.83-17.5-26.62-21.79
        c-6.64-3.24-12.12-1.08-12.12-1.08"
        />
        <path
          style={st2}
          d="M93.21,174.48c0,0,2.4-0.76,6.44-9.89c4.04-9.14,5.78-22.06,3.09-28.66c-2.57-6.29-17.83-17.5-26.62-21.79
        c-6.64-3.24-11.84-0.69-11.84-0.69"
        />
        <path
          style={st2}
          d="M85.34,176.63c0,0,2.58-0.29,6.62-9.43c4.04-9.14,6.85-22.07,4.16-28.68c-2.57-6.29-17.83-17.5-26.62-21.79
        c-6.64-3.24-11.04-0.25-11.04-0.25"
        />
        <path
          style={st2}
          d="M77.34,177.75c0,0,4.47-1.97,8.51-11.11c4.04-9.14,5.91-18.76,3.21-25.36c-2.57-6.29-16.73-16.98-25.51-21.27
        c-6.64-3.24-7.78-2.04-7.78-2.04"
        />
        <path
          style={st2}
          d="M67.81,178.73c0,0,6.49-1.6,10.53-10.74c4.04-9.14,6.22-17.27,3.53-23.87c-2.57-6.29-13.29-15.05-22.08-19.34
        c-6.64-3.24-9.33-3.46-9.33-3.46"
        />
        <path
          style={st2}
          d="M55.22,174.81c0,0,10.41,3.43,14.45-5.71c4.04-9.14,5.49-14.7,2.8-21.3c-2.57-6.29-12.05-13.62-20.83-17.91
        c-6.64-3.24-9.95,2.16-9.95,2.16"
        />
        <radialGradient
          id="SVGID_00000091727930622110276670000013429511373777291920_"
          cx="218.3344"
          cy="186.3557"
          r="23.1306"
          gradientTransform="matrix(-0.9164 -0.4003 -0.4003 0.9164 331.2718 69.1978)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.105" style={{ stopColor: "#F1F2F2" }} />
          <stop offset="0.2151" style={{ stopColor: "#ECEDEE" }} />
          <stop offset="0.3615" style={{ stopColor: "#DEDFE0" }} />
          <stop offset="0.528" style={{ stopColor: "#C7C9CB" }} />
          <stop offset="0.7096" style={{ stopColor: "#A7AAAC" }} />
          <stop offset="0.9012" style={{ stopColor: "#828487" }} />
          <stop offset="1" style={{ stopColor: "#6D6E71" }} />
        </radialGradient>
        <path
          style={{
            fill: "url(#SVGID_00000091727930622110276670000013429511373777291920_)",
            stroke: "#000000",
            strokeWidth: 1.5,
            strokeMiterlimit: 10,
          }}
          d="M55.71,175.08c0,0,10.41,3.43,14.45-5.71c4.04-9.14,5.49-14.7,2.8-21.3c-2.57-6.29-12.05-13.62-20.83-17.91
        c-6.64-3.24-9.95,2.16-9.95,2.16l-0.13,0.31c-2.09,5.26-3.31,13.7,1.25,25.49c3.42,8.85,7.95,13.92,12.12,16.82L55.71,175.08z"
        />
        <linearGradient
          id="SVGID_00000009563129679256321800000009018316974358306180_"
          gradientUnits="userSpaceOnUse"
          x1="41.2024"
          y1="77.0049"
          x2="151.7099"
          y2="77.0049"
          gradientTransform="matrix(-0.9164 -0.4003 -0.4003 0.9164 331.2718 69.1978)"
        >
          <stop offset="0.1179" style={{ stopColor: "#7C7E80" }} />
          <stop offset="0.1567" style={{ stopColor: "#909295" }} />
          <stop offset="0.2364" style={{ stopColor: "#B9BABD" }} />
          <stop offset="0.3061" style={{ stopColor: "#D7D9DA" }} />
          <stop offset="0.3622" style={{ stopColor: "#EAEBEC" }} />
          <stop offset="0.3978" style={{ stopColor: "#F1F2F2" }} />
          <stop offset="0.5365" style={{ stopColor: "#EFF0F0" }} />
          <stop offset="0.6124" style={{ stopColor: "#E8E9EA" }} />
          <stop offset="0.673" style={{ stopColor: "#DDDEDF" }} />
          <stop offset="0.7256" style={{ stopColor: "#CDCED0" }} />
          <stop offset="0.7731" style={{ stopColor: "#B8BABC" }} />
          <stop offset="0.8167" style={{ stopColor: "#9FA1A3" }} />
          <stop offset="0.8568" style={{ stopColor: "#848588" }} />
          <stop offset="0.8845" style={{ stopColor: "#6D6E71" }} />
        </linearGradient>
        {indicatorList}
      </svg>
    </>
  );
};

type SvgPathProps = {
  display?: boolean;
  fillColor: string;
  pathString: string;
  style?: React.CSSProperties;
};

const SvgPath = (props: SvgPathProps) => {
  const defaultStyle: React.CSSProperties = {
    fill: "none",
    stroke: "#3c649b",
    strokeWidth: 2,
    strokeLinecap: "round",
    strokeLinejoin: "round",
  };
  return (
    <path
      style={props.style ? { ...defaultStyle, ...props.style } : defaultStyle}
      fillOpacity={1}
      strokeOpacity={1}
      // fillOpacity={props.display ? 1 : undefined}
      // strokeOpacity={props.display ? 1 : undefined}
      d={props.pathString}
    />
  );
};
