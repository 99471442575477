import utilityStyles from "../../styles/_utilityStyles.module.scss"

export type RequiredFieldIndicatorProps = {
  cssClassName?: string
}

export function RequiredFieldIndicator({ cssClassName }: RequiredFieldIndicatorProps) {
  const defaultClassName = utilityStyles.requiredFieldIndicator;
  return <>
    <span
      className={ (cssClassName === undefined) ? defaultClassName : cssClassName }
    >
      *
    </span>
  </>
}