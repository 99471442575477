import { Diameter, DimensionedQuantity, isDiameter, isDimensionedQuantity, isIncrementRange,  SelectedRivetParameters, SelectedThreadedParameters } from '../FindIt/findItTypes';
import { SelectedPartParameters } from "./FastenerRecommender";
import Select, { ReactSelectOption, ReactSelectOptions } from '../utilities/FixRequiredSelect';
import { OptionTypeBase } from 'react-select';
import { useEffect, useState, FocusEventHandler, } from 'react';
import { ReactSelectOptionWithTooltip } from '../utilities/ReactSelectOptionWithTooltip';



type FieldNames =
  | keyof SelectedThreadedParameters
  | keyof SelectedRivetParameters

type BasicFieldSelectorProps = {
  selectionOptions: ReactSelectOptions<any, string>;
  defaultValue?: ReactSelectOption<any, string>;
  required: boolean;
  fieldName: FieldNames;
  // fieldTitle: string;
  parentField?: {
    fieldName: FieldNames,
    fieldTitle: string
  }
  onFocus?: FocusEventHandler,
  onBlur?: FocusEventHandler,
  // wrapperClassName: string;
  // childClassName: string;
  // placeholder?: string,
  // isDisabled?: boolean,
  selectedPartParameters: SelectedPartParameters;
  setSelectedPartParameters: (newPartParameters: SelectedPartParameters) => void;
};

export function BasicFieldSelector(props: BasicFieldSelectorProps) {

  // console.log("BasicFieldSelector default value:", props.defaultValue)


  const [selection, setSelection] = useState<OptionTypeBase | null>(
    (props.defaultValue !== undefined) ? props.defaultValue : null
  )

  // console.log("selection:", selection)

  // console.log(`Basic field selector defaultValue for ${props.fieldName}`, props.defaultValue)

  const handleSelectionChange = (newSelection: OptionTypeBase | null) => {
    setSelection(newSelection);
    const newSelectedPartParameters: SelectedPartParameters = {
      ...props.selectedPartParameters,
      // fastenerName: props.selectedPartParameters.fastenerName,
      [props.fieldName]: newSelection?.value
    };
    props.setSelectedPartParameters(newSelectedPartParameters);
  };

  const parentFieldName = props.parentField?.fieldName

  useEffect(() => {
    // @ts-ignore
    const currentSelection: any = props.selectedPartParameters[props.fieldName];
    const allowedSelections = props.selectionOptions.map(option => option.value)

    let selectionIsValid: boolean
    if (currentSelection === undefined) {
      selectionIsValid = false;
    } else if (typeof currentSelection === 'string') {
      selectionIsValid = (allowedSelections.indexOf(currentSelection) !== -1);
    } else if (isDimensionedQuantity(currentSelection)) {
      const allowedSelectionValues = (allowedSelections as DimensionedQuantity<any>[])
        .map(selection => selection.value);
      selectionIsValid = (allowedSelectionValues.indexOf(currentSelection.value) !== -1)
    } else if (isDiameter(currentSelection)) {
      const allowedSelectionValues = (allowedSelections as Diameter<any>[])
        .map(selection => selection.distance.value);
      selectionIsValid = (allowedSelectionValues.indexOf(currentSelection.distance.value) !== -1)
    } else if (isIncrementRange(currentSelection)) {
      // console.log("found increment range")
      selectionIsValid = true;
    } else {
      console.error(`Unrecognized selection type: ${typeof currentSelection} ${JSON.stringify(currentSelection)}`)
      selectionIsValid = false
    }

    if (!selectionIsValid) {
      console.log(`Setting ${props.fieldName} to null due to invalid selection: ${JSON.stringify(currentSelection)}. `
        + `Expected something like ${JSON.stringify(props.selectionOptions[0])}`)
      handleSelectionChange(null);
    }
  },
    parentFieldName
      // @ts-ignore
      ? [props.selectedPartParameters[parentFieldName]]
      : []
  )

  // let placeholder: string | undefined = undefined;
  const placeholder = undefined;  // Removed placeholder text per Heather's request. Leaving this in case we want it back.
  let isDisabled: boolean | undefined = undefined;
  if (
    props.parentField?.fieldName
    // @ts-ignore
    && (props.selectedPartParameters[props.parentField.fieldName] === undefined)
  ) {
    // placeholder = `Pick ${props.parentField.fieldTitle} first`;
    isDisabled = true;
  }

  return <Select
    options={props.selectionOptions}
    // @ts-ignore This works fine. Typescript finds no errors when in same file as custom Option definition
    components={{ Option: ReactSelectOptionWithTooltip }}
    onChange={handleSelectionChange}
    defaultValue={props.defaultValue !== undefined ? props.defaultValue : selection}
    // defaultValue={defaultValue}
    // value={defaultValue}
    value={selection}
    required={props.required}
    isClearable={true}
    placeholder={placeholder}
    isDisabled={isDisabled}
    // @ts-ignore todo: figure out focusEvent type compatibility issues
    onFocus={props.onFocus}
    // @ts-ignore todo: figure out focusEvent type compatibility issues
    onBlur={props.onBlur}
  />;
}
