import {
  DimensionedQuantityRange,
  DimensionedQuantity,
  UnitsDistance,
  Distance,
  UnitsPitch,
  UnitsTorque,
  UnitsTensileStrength,
  UnitsPressure,
  UnitsForce
} from "../FindIt/findItTypes";

export function getPitchDiameter(
  nominalDiameter: Distance<UnitsDistance.Millimeter>,
  threadPitch: DimensionedQuantity<UnitsPitch.MillimetersPerThread>
  ): Distance<UnitsDistance.Millimeter> {
    // Todo: ask Guilherme what formula this is
    // Block_mm_Dia-2*(3/8*(Block_mm_Pitch/2*(TAN(60*PI()/180))));
    const pitchDiameter = (
    nominalDiameter.value -
    2 * ( 3 / 8 * ( threadPitch.value / 2 *
    Math.tan(
      60 * Math.PI / 180
      )
      ))
      )
      return {
        unit: UnitsDistance.Millimeter,
    value: pitchDiameter,
  };
}

export function getMinorDiameter(
  nominalDiameter: Distance<UnitsDistance.Millimeter>,
  threadPitch: DimensionedQuantity<UnitsPitch.MillimetersPerThread>
  ): Distance<UnitsDistance.Millimeter> {
    // Todo: ask Guilherme what formula this is
    const minorDiameter =
    nominalDiameter.value -
    2 * ((5 / 8) * ((threadPitch.value / 1.765) * Math.tan(Math.PI / 3)));
    return {
      unit: UnitsDistance.Millimeter,
      value: minorDiameter,
    };
  }
  
  export function getYieldFactor(
    pitchDiameter: Distance<UnitsDistance>,
    threadPitch: DimensionedQuantity<UnitsPitch.MillimetersPerThread>,
    minimumDiameter: Distance<UnitsDistance>,
    threadFrictionCoefficient: number
    ): number {
      // Todo: ask Guilherme what formula this is
      // (1/SQRT(1+3*POWER((3/2*(Block_mm_Pitch_Dia/Block_mm_AREA_IF_RED))*(Block_mm_Pitch/PI()/Block_mm_Pitch_Dia+1.155*Thread_Coeff_MIN_),2)))
      return (
        1 /
        Math.sqrt(
      1 +
      3 *
      Math.pow(
        (3 / 2) *
        (pitchDiameter.value / minimumDiameter.value) *
        (threadPitch.value / Math.PI / pitchDiameter.value +
        1.155 * threadFrictionCoefficient),
        2
        )
        )
        );
        // return (1/Math.sqrt(1+3*Math.pow((3/2*(pitchDiameter.value/Block_mm_AREA_IF_RED))*(Block_mm_Pitch/PI()/Block_mm_Pitch_Dia+1.155*Thread_Coeff_MIN_),2)))
      }
      
      /*
      Next steps:
      * build a UI
      * Test calculateTorque function     @ Justin
      * Tests for reduced shank     @ Justin
      * Wrapper to convert to/from imperial units
      */
     
     export function getAreaOfThreadedContact(
       pitchDiameter: Distance<UnitsDistance>,
       minorDiameter: Distance<UnitsDistance>
       ): number {
         return (
           (Math.PI / 4) * Math.pow((pitchDiameter.value + minorDiameter.value) / 2, 2)
           );
          }
          
          export function getAreaOfReducedShankContact(
            shankDiameter?: Distance<UnitsDistance>
            ): number | undefined {
              if (shankDiameter === undefined) {
                return undefined;
              }
              return (Math.PI * Math.pow(shankDiameter.value, 2)) / 4;
            }
            
            // =IF(clamp_load_units_force="Lbf",clamp_load_max/((PI()*(Outside_Bearing_diameter__mm^2-Passing_hole__mm^2))/4),clamp_load_max*1000/((PI()*(Outside_Bearing_diameter__mm^2-Passing_hole__mm^2))/4))
            
            // export function maxContactSurfacePresure(
              
              // )

export type CalculateTorqueProps<UDistance, UPitch, UTensileStrength> = {
  targetTorqueOverYieldRatio: number; // raw number, NOT percentage!
  nominalDiameter: Distance<UDistance>;
  threadPitch: DimensionedQuantity<UPitch>;
  regionOfContactUnderNutOrBoltHead: {
    outerDiameter: Distance<UDistance>;  // Outer diameter of bolt head or nut
    innerDiameter: Distance<UDistance>;  // AKA Holes_C_Sink, AKA Passing hole. Inner diameter of area under bolt head or nut. Usually diameter of hole into which bolt is being inserted
  }
  enagementLength: Distance<UDistance>;  // Length of region where male threads are engaged with female threads
  reducedShankDiameter?: Distance<UDistance>;
  tensileStrengthRange: DimensionedQuantityRange<UTensileStrength>,
  yieldOverTensileStrengthRatioRange: DimensionedQuantityRange<number>;
  threadFrictionCoefficientRange: DimensionedQuantityRange<number>;
  headFrictionCoefficientRange: DimensionedQuantityRange<number>; // AKA bearing surface coefficient
};

export type CalculatedTorqueValues = {
  targetTorque: DimensionedQuantity<UnitsTorque>,
  minClampLoadOnYield: DimensionedQuantity<UnitsForce>,
  minTorqueOnYield: DimensionedQuantity<UnitsTorque>,
  yieldFactorRange: DimensionedQuantityRange<number>,
  minUltimateClampLoad: DimensionedQuantity<UnitsForce>,
  minUltimateTorque: DimensionedQuantity<UnitsTorque>,
  clampLoads: Record<
    | "min"
    | "max"
    ,
    DimensionedQuantity<UnitsForce>
  > & { dispersion: number },
  threadEngagementRatio: number, //raw number, NOT percentage!
  nutFactorVariabilities: ReturnType<typeof getNutFactorVariabilities>,
  maxContactSurfacePressure: DimensionedQuantity<UnitsPressure>
}

export function calculateTorque(
  props: CalculateTorqueProps<
    UnitsDistance.Millimeter, 
    UnitsPitch.MillimetersPerThread, 
    UnitsTensileStrength.MegaPascal
  >
): {
  targetTorque: DimensionedQuantity<UnitsTorque.NewtonMeter>,
  minClampLoadOnYield: DimensionedQuantity<UnitsForce.Kilonewton>,
  minTorqueOnYield: DimensionedQuantity<UnitsTorque.NewtonMeter>,
  yieldFactorRange: DimensionedQuantityRange<number>,
  minUltimateClampLoad: DimensionedQuantity<UnitsForce.Kilonewton>,
  minUltimateTorque: DimensionedQuantity<UnitsTorque.NewtonMeter>,
  clampLoads: Record<
    | "min"
    | "max"
    ,
    DimensionedQuantity<UnitsForce.Kilonewton>
  > & { dispersion: number },
  threadEngagementRatio: number, //raw number, NOT percentage!
  nutFactorVariabilities: ReturnType<typeof getNutFactorVariabilities>,
  maxContactSurfacePressure: DimensionedQuantity<UnitsPressure.MegaPascal>
} {
  // This torque calculator is based on a spreadsheet created by Guilherme Oliveira, of PCLTork.
  // The spreadsheet and source documents ISO 16047 and VDI 2230-1 are in the Google Drive at the following path:
  // Finding Fasteners / Website Data / Torque Calculator Reference docs
  // https://drive.google.com/drive/folders/11CwzS4BbyqeJ4X5bB2-jKszWqo9C3zSH

  // Pitch diameter is a standard measure, basically the distance between the midpoint between peak and trough of
  //  thread on one side to the same on the other side. Google it. :)
  const pitchDiameter = getPitchDiameter(props.nominalDiameter, props.threadPitch);

  // Minor diameter is a standard measure: the distance between the troughs on either side of a threaded shaft. Google it. :)
  const minorDiameter = getMinorDiameter(props.nominalDiameter, props.threadPitch);

  // minimumDiameter is in the spreadsheet as "AREA IF RED". This is probably "area if reduced"
  const minimumDiameter =
    props.reducedShankDiameter !== undefined
      ? getMinimumDistance(minorDiameter, props.reducedShankDiameter)
      : minorDiameter;

  // todo: figure out actual units on yield factor
  // Yield factor probably is well-defined, but not easily googled. Todo: ask Guillherme
  // Oddly, the value named minValue is larger than the value named maxValue. Why? todo: ask Guilherme
  const yieldFactorRange: DimensionedQuantityRange<number> = {
    unit: 1,
    minValue: getYieldFactor(
      pitchDiameter,
      props.threadPitch,
      minimumDiameter,
      props.threadFrictionCoefficientRange.maxValue
    ),
    maxValue: getYieldFactor(
      pitchDiameter,
      props.threadPitch,
      minimumDiameter,
      props.threadFrictionCoefficientRange.minValue
    ),
  };

  // areaOfThreadedContact is PROBABLY the area of contact between the threaded shaft and the nut or hole, but should confirm with Guilherme
  // Labeled "block_mm_area" in spreadsheet
  // todo: ask Guilherme
  const areaOfThreadedContact = getAreaOfThreadedContact(
    pitchDiameter,
    minorDiameter
  );

  // areaOfReducedShankContact is PROBABLY the area of contact between the unthreaded shank and the hole, if shank diameter is reduced, but should confirm with Guilherme
  // Labeled Red Area in spreadsheet
  // todo: ask Guilherme
  const areaOfReducedShankContact = getAreaOfReducedShankContact(
    props.reducedShankDiameter
  );

  const crossSectionArea: number =
    areaOfReducedShankContact === undefined // Equivalent to checking whether props.reducedShankDiameter is undefined
      ? areaOfThreadedContact
      : areaOfReducedShankContact;

  // Corresponds to spreadsheet results section 1, rows 35-49
  const clampLoadsAtYieldPoint = getClampLoadsAtYieldPoint(
    props.tensileStrengthRange,
    props.yieldOverTensileStrengthRatioRange,
    yieldFactorRange,
    crossSectionArea
  );

  // ultimateClampLoads is the set of ultimate clamp loads on the plastic zone in kN
  // Corresponds to spreadsheet results section 2, rows 52 -57
  const ultimateClampLoadsForPlasticZone = getUltimateClampLoads(
    props.tensileStrengthRange,
    yieldFactorRange,
    crossSectionArea
  );

  // Friction diameter is, we think, the area under the nut that makes contact with the material surface.
  //  This is calculated by subtracting the diameter of the hole underneath the nut from the outer diameter of the nut.
  //  There's also some other stuff going on in this calculation that we don't understand.
  // todo: ask Guilherme exactly what this is
  const frictionDiameter = getFrictionDiameter(
    props.regionOfContactUnderNutOrBoltHead.outerDiameter,
    props.regionOfContactUnderNutOrBoltHead.innerDiameter
  );

  // console.log("frictionDiameter", {frictionDiameter})

  const nutFactorVariabilities = getNutFactorVariabilities({
    threadPitch: props.threadPitch,
    pitchDiameter: pitchDiameter,
    threadFrictionCoefficientRange: props.threadFrictionCoefficientRange,
    frictionDiameter: frictionDiameter,
    headFrictionCoefficientRange: props.headFrictionCoefficientRange,
    nominalDiameter: props.nominalDiameter,
    targetTorqueOverYieldRatio: props.targetTorqueOverYieldRatio,
    outerDiameter: props.regionOfContactUnderNutOrBoltHead.outerDiameter,
    innerDiameter: props.regionOfContactUnderNutOrBoltHead.innerDiameter,
  });

  // Theoretical torques on yield point. Corresponds to spreadsheet rows 60-85
  const theoreticalTorquesAtYieldPoint = getTheoreticalTorquesAtYieldPoint({
    clampLoadsAtYieldPoint: clampLoadsAtYieldPoint,
    ultimateClampLoadsForPlasticZone: ultimateClampLoadsForPlasticZone,
    nutFactorVariabilities: nutFactorVariabilities,
    nominalDiameter: props.nominalDiameter,
    reducedShankDiameter: props.reducedShankDiameter,
  });


  // Clamp loads considering target torque (spreadsheet lines 100-108)
  const clampLoadsConsideringTargetTorque = getClampLoadRangeConsideringTargetTorque({
    targetTorqueOverYieldRatio: props.targetTorqueOverYieldRatio,
    theoreticalTorquesAtYieldPoint: theoreticalTorquesAtYieldPoint,
    nutFactorVariabilities: nutFactorVariabilities,
    nominalDiameter: props.nominalDiameter,
  });

  const engagementRatio = (props.enagementLength.value / props.nominalDiameter.value) * 100;

  const targetTorque = (engagementRatio < 1)
    ? props.targetTorqueOverYieldRatio * theoreticalTorquesAtYieldPoint.minRp02.minimum * engagementRatio
    : props.targetTorqueOverYieldRatio * theoreticalTorquesAtYieldPoint.minRp02.minimum;
  
  const maxContactSurfacePressure = getMaxContactSurfacePressure({
    maxClampLoad: clampLoadsConsideringTargetTorque.max,
    regionOfContactUnderNutOrBoltHead: props.regionOfContactUnderNutOrBoltHead
  });

  const result: ReturnType<typeof calculateTorque> = {
    targetTorque: {
      value: targetTorque,
      unit: UnitsTorque.NewtonMeter
    },
    yieldFactorRange: yieldFactorRange,
    minClampLoadOnYield: {
      value: Math.min(...Object.values(clampLoadsAtYieldPoint)),  // multiplied by 1000 to convert from kN to N
      unit: UnitsForce.Kilonewton
    },
    minUltimateClampLoad: {
      value: Math.min(...Object.values(ultimateClampLoadsForPlasticZone)),  // multiplied by 1000 to convert kN to N
      unit: UnitsForce.Kilonewton
    },
    minTorqueOnYield: {
      value: theoreticalTorquesAtYieldPoint.minRp02.minimum,
      unit: UnitsTorque.NewtonMeter
    },
    minUltimateTorque: {
      value: theoreticalTorquesAtYieldPoint.plasticZone.minimum,
      unit: UnitsTorque.NewtonMeter
    },
    threadEngagementRatio: engagementRatio,
    clampLoads: clampLoadsConsideringTargetTorque,
    nutFactorVariabilities: nutFactorVariabilities,
    maxContactSurfacePressure: maxContactSurfacePressure
  }

   const calculatedValues = {
    pitchDiameter: pitchDiameter,
    minorDiameter: minorDiameter,
    minimumDiameter: minimumDiameter,
    yieldFactorRange: yieldFactorRange,
    areaOfThreadedContact: areaOfThreadedContact,
    areaOfReducedShankContact: areaOfReducedShankContact,
    crossSectionArea: crossSectionArea,
    clampLoadsAtYieldPoint: clampLoadsAtYieldPoint,
    ultimateClampLoadsForPlasticZone: ultimateClampLoadsForPlasticZone,
    frictionDiameter: frictionDiameter,
    nutFactorVariabilities: nutFactorVariabilities,
    theoreticalTorquesAtYieldPoint: theoreticalTorquesAtYieldPoint,
    clampLoadsConsideringTargetTorque: clampLoadsConsideringTargetTorque,
    engagementRatio: engagementRatio,
    targetTorque: targetTorque,
    result: result
  }
  
  // console.log("calculateTorque inputs");
  // console.log(JSON.stringify(props, null, 2));
  
  // console.log("calculateTorque calculatedValues")
  // console.log(JSON.stringify(calculatedValues, null, 2));

  return result;
}

export function getMaxContactSurfacePressure(args: 
  { maxClampLoad: DimensionedQuantity<UnitsForce> }
  & Pick<
    CalculateTorqueProps<
      UnitsDistance.Millimeter, 
      UnitsPitch.MillimetersPerThread, 
      UnitsTensileStrength.MegaPascal
    >,
    'regionOfContactUnderNutOrBoltHead'
  >
): DimensionedQuantity<UnitsPressure.MegaPascal> {
  /*
    =IF(
      clamp_load_units_force="Lbf",
      clamp_load_max/((PI()*(Outside_Bearing_diameter__mm^2-Passing_hole__mm^2))/4),

      >>>  clamp_load_max * 1000 / (( PI() * ( Outside_Bearing_diameter__mm^2 - Passing_hole__mm^2))/4)
    )
  */
  const rawResult =
    (args.maxClampLoad.value * 1000) 
    / (
      (
        Math.PI * (
          Math.pow(args.regionOfContactUnderNutOrBoltHead.outerDiameter.value, 2) - Math.pow(args.regionOfContactUnderNutOrBoltHead.innerDiameter.value, 2)
        )
      ) / 4
    )
    // (args.maxClampLoad.value * 1000)
    // / 
    //   ( 
    //     Math.PI * Math.pow(args.regionOfContactUnderNutOrBoltHead.outerDiameter.value, 2) - Math.pow(args.regionOfContactUnderNutOrBoltHead.innerDiameter.value, 2)
    //   ) / 4
    
  
  return {
    unit: UnitsPressure.MegaPascal,
    value: rawResult
  };
}

export function getClampLoadConsideringTargetTorque(args: {
  targetTorqueOverYieldRatio: number,
  minTheoreticalTorqueAtYieldPointMinRp02: number,
  nutFactorVariability: number,
  nominalDiameter: Distance<UnitsDistance.Millimeter>
}) {
  // Target_Torque_as_a___of_Yield*TheoreticalTorque_MinRp02_Minimum/(Mg_MAX__Mk_MAX*Block_mm_Dia)
  return (
    args.targetTorqueOverYieldRatio * args.minTheoreticalTorqueAtYieldPointMinRp02
    /
    ( args.nutFactorVariability * args.nominalDiameter.value )
  )
}

export function getClampLoadRangeConsideringTargetTorque(args: {
  targetTorqueOverYieldRatio: number,
  theoreticalTorquesAtYieldPoint: ReturnType<typeof getTheoreticalTorquesAtYieldPoint>,
  nutFactorVariabilities: ReturnType<typeof getNutFactorVariabilities>,
  nominalDiameter: Distance<UnitsDistance.Millimeter>
}): Record<
  | "min"
  | "max"
  ,
  DimensionedQuantity<UnitsForce.Kilonewton>
> & {
  dispersion: number
} {
  const rawValues = {
    k1: getClampLoadConsideringTargetTorque({
      targetTorqueOverYieldRatio: args.targetTorqueOverYieldRatio,
      minTheoreticalTorqueAtYieldPointMinRp02: args.theoreticalTorquesAtYieldPoint.minRp02.minimum,
      nutFactorVariability: args.nutFactorVariabilities.rawValues.threadCoeffMaxHeadCoeffMax,
      nominalDiameter: args.nominalDiameter,
    }),
    k2: getClampLoadConsideringTargetTorque({
      targetTorqueOverYieldRatio: args.targetTorqueOverYieldRatio,
      minTheoreticalTorqueAtYieldPointMinRp02: args.theoreticalTorquesAtYieldPoint.minRp02.minimum,
      nutFactorVariability: args.nutFactorVariabilities.rawValues.threadCoeffMaxHeadCoeffMin,
      nominalDiameter: args.nominalDiameter,
    }),
    k3: getClampLoadConsideringTargetTorque({
      targetTorqueOverYieldRatio: args.targetTorqueOverYieldRatio,
      minTheoreticalTorqueAtYieldPointMinRp02: args.theoreticalTorquesAtYieldPoint.minRp02.minimum,
      nutFactorVariability: args.nutFactorVariabilities.rawValues.threadCoeffMinHeadCoeffMax,
      nominalDiameter: args.nominalDiameter,
    }),
    k4: getClampLoadConsideringTargetTorque({
      targetTorqueOverYieldRatio: args.targetTorqueOverYieldRatio,
      minTheoreticalTorqueAtYieldPointMinRp02: args.theoreticalTorquesAtYieldPoint.minRp02.minimum,
      nutFactorVariability: args.nutFactorVariabilities.rawValues.threadCoeffMinHeadCoeffMin,
      nominalDiameter: args.nominalDiameter,
    }),
  };

  const clampLoadRange: Record<
    | "min"
    | "max"
    ,
    DimensionedQuantity<UnitsForce.Kilonewton>
  > = {
    min: {
      unit: UnitsForce.Kilonewton,
      value: Math.min(...Object.values(rawValues))
    },
    max: {
      unit: UnitsForce.Kilonewton,
      value: Math.max(...Object.values(rawValues))
    },
  }

  const dispersionFactor = ((clampLoadRange.max.value / clampLoadRange.min.value) - 1 ) * 100;

  const dispersion = dispersionFactor;

  return {
    ...clampLoadRange,
    dispersion: dispersion
  }
}

export function getTheoreticalTorqueAtYieldPoint(
  clampLoad: number,
  nutFactorVariability: number,
  nominalDiameter: Distance<UnitsDistance.Millimeter>,
): number {
  // Although this spreadsheet formula uses the variable for the reduced shank diameter, DIAM_IF_RED, when reduced_shank
  // is undefined, it will actually have the same value as the nominal diameter, so we can just use the nominal diameter.
  // From a mechanical perspective, we want to use the diameter of the *threads* for this calculation, so the fact that
  // there's a different diameter for the *shank* doesn't get factored in here.

  // ClampLoad_MinRP02_LowStrOverLowYield*Mg_MAX__Mk_MAX*Block_mm_Dia
  /*
    =IF(
      Reduced_Shank="YES",
      IF(
        Unit_System="METRIC",
        ClampLoad_MinRP02_LowStrOverLowYield*Mg_MIN__Mk_MIN*Block_mm_Dia,
        ClampLoad_MinRP02_LowStrOverLowYield*'PCLTork-eTool(3)_modified.xlsx'!Mg_MIN__Mk_MIN*Block_ft_Dia
      ), 
      IF(
        Unit_System="METRIC",
        ClampLoad_MinRP02_LowStrOverLowYield*Mg_MIN__Mk_MIN*Block_mm_DIAM_IF_RED,
        ClampLoad_MinRP02_LowStrOverLowYield*'PCLTork-eTool(3)_modified.xlsx'!Mg_MIN__Mk_MIN*Block_ft_DIAM_IF_RED
      )
    )
  */

  return (
    clampLoad * nutFactorVariability * nominalDiameter.value
  )
}

export function getTheoreticalTorquesAtYieldPoint(args: {
  clampLoadsAtYieldPoint: ReturnType<typeof getClampLoadsAtYieldPoint>,
  ultimateClampLoadsForPlasticZone:  ReturnType<typeof getUltimateClampLoads>,
  nutFactorVariabilities: ReturnType<typeof getNutFactorVariabilities>,
  nominalDiameter: Distance<UnitsDistance.Millimeter>,
  reducedShankDiameter?: Distance<UnitsDistance.Millimeter>
}) {
  const minRp02RawValues = {
    highStrengthThreadCoeffMaxHeadCoeffMax: getTheoreticalTorqueAtYieldPoint(
      args.clampLoadsAtYieldPoint.highStrengthOverHighYieldAtMinYieldTensileRatio,
      args.nutFactorVariabilities.rawValues.threadCoeffMaxHeadCoeffMax,
      args.nominalDiameter
    ),
    highStrengthThreadCoeffMaxHeadCoeffMin: getTheoreticalTorqueAtYieldPoint(
      args.clampLoadsAtYieldPoint.highStrengthOverHighYieldAtMinYieldTensileRatio,
      args.nutFactorVariabilities.rawValues.threadCoeffMaxHeadCoeffMin,
      args.nominalDiameter
    ),
    highStrengthThreadCoeffMinHeadCoeffMax: getTheoreticalTorqueAtYieldPoint(
      args.clampLoadsAtYieldPoint.highStrengthOverHighYieldAtMinYieldTensileRatio,
      args.nutFactorVariabilities.rawValues.threadCoeffMinHeadCoeffMax,
      args.nominalDiameter
    ),
    highStrengthThreadCoeffMinHeadCoeffMin: getTheoreticalTorqueAtYieldPoint(
      args.clampLoadsAtYieldPoint.highStrengthOverHighYieldAtMinYieldTensileRatio,
      args.nutFactorVariabilities.rawValues.threadCoeffMinHeadCoeffMin,
      args.nominalDiameter
    ),
    lowStrengthThreadCoeffMaxHeadCoeffMax: getTheoreticalTorqueAtYieldPoint(
      args.clampLoadsAtYieldPoint.lowStrengthOverLowYieldAtMinYieldTensileRatio,
      args.nutFactorVariabilities.rawValues.threadCoeffMaxHeadCoeffMax,
      args.nominalDiameter
    ),
    lowStrengthThreadCoeffMaxHeadCoeffMin: getTheoreticalTorqueAtYieldPoint(
      args.clampLoadsAtYieldPoint.lowStrengthOverLowYieldAtMinYieldTensileRatio,
      args.nutFactorVariabilities.rawValues.threadCoeffMaxHeadCoeffMin,
      args.nominalDiameter
    ),
    lowStrengthThreadCoeffMinHeadCoeffMax: getTheoreticalTorqueAtYieldPoint(
      args.clampLoadsAtYieldPoint.lowStrengthOverLowYieldAtMinYieldTensileRatio,
      args.nutFactorVariabilities.rawValues.threadCoeffMinHeadCoeffMax,
      args.nominalDiameter
    ),
    lowStrengthThreadCoeffMinHeadCoeffMin: getTheoreticalTorqueAtYieldPoint(
      args.clampLoadsAtYieldPoint.lowStrengthOverLowYieldAtMinYieldTensileRatio,
      args.nutFactorVariabilities.rawValues.threadCoeffMinHeadCoeffMin,
      args.nominalDiameter
    ),
  }

  const plasticZoneRawValues = {
    highStrengthThreadCoeffMaxHeadCoeffMax: getTheoreticalTorqueAtYieldPoint(
      args.ultimateClampLoadsForPlasticZone.highStrengthOverHighYield,
      args.nutFactorVariabilities.rawValues.threadCoeffMaxHeadCoeffMax,
      args.nominalDiameter
    ),
    highStrengthThreadCoeffMaxHeadCoeffMin: getTheoreticalTorqueAtYieldPoint(
      args.ultimateClampLoadsForPlasticZone.highStrengthOverHighYield,
      args.nutFactorVariabilities.rawValues.threadCoeffMaxHeadCoeffMin,
      args.nominalDiameter
    ),
    highStrengthThreadCoeffMinHeadCoeffMax: getTheoreticalTorqueAtYieldPoint(
      args.ultimateClampLoadsForPlasticZone.highStrengthOverHighYield,
      args.nutFactorVariabilities.rawValues.threadCoeffMinHeadCoeffMax,
      args.nominalDiameter
    ),
    highStrengthThreadCoeffMinHeadCoeffMin: getTheoreticalTorqueAtYieldPoint(
      args.ultimateClampLoadsForPlasticZone.highStrengthOverHighYield,
      args.nutFactorVariabilities.rawValues.threadCoeffMinHeadCoeffMin,
      args.nominalDiameter
    ),
    lowStrengthThreadCoeffMaxHeadCoeffMax: getTheoreticalTorqueAtYieldPoint(
      args.ultimateClampLoadsForPlasticZone.lowStrengthOverLowYield,
      args.nutFactorVariabilities.rawValues.threadCoeffMaxHeadCoeffMax,
      args.nominalDiameter
    ),
    lowStrengthThreadCoeffMaxHeadCoeffMin: getTheoreticalTorqueAtYieldPoint(
      args.ultimateClampLoadsForPlasticZone.lowStrengthOverLowYield,
      args.nutFactorVariabilities.rawValues.threadCoeffMaxHeadCoeffMin,
      args.nominalDiameter
    ),
    lowStrengthThreadCoeffMinHeadCoeffMax: getTheoreticalTorqueAtYieldPoint(
      args.ultimateClampLoadsForPlasticZone.lowStrengthOverLowYield,
      args.nutFactorVariabilities.rawValues.threadCoeffMinHeadCoeffMax,
      args.nominalDiameter
    ),
    lowStrengthThreadCoeffMinHeadCoeffMin: getTheoreticalTorqueAtYieldPoint(
      args.ultimateClampLoadsForPlasticZone.lowStrengthOverLowYield,
      args.nutFactorVariabilities.rawValues.threadCoeffMinHeadCoeffMin,
      args.nominalDiameter
    ),
  }

  return {
    minRp02: {
      rawValues: minRp02RawValues,
      minimum: Math.min(...Object.values(minRp02RawValues))
    },
    plasticZone: {
      rawValues: plasticZoneRawValues,
      minimum: Math.min(...Object.values(plasticZoneRawValues))
    }
  }
}

export function getFrictionDiameter(
  outerDiameter: Distance<UnitsDistance.Millimeter>,
  innerDiameter: Distance<UnitsDistance.Millimeter>
): number {
  // Spreadsheet cell O20.
  // todo: Ask Guilherme where this comes from
  // 2/3*(POWER(Block_mm_OD, 3)-POWER(Block_mm_ID, 3))/(POWER(Block_mm_OD, 2)-POWER(Block_mm_ID, 2))
  return (
    2/3 * (
      Math.pow(outerDiameter.value, 3) - Math.pow(innerDiameter.value, 3)
      ) / (
        Math.pow(outerDiameter.value, 2) - Math.pow(innerDiameter.value, 2)
      )
  )
}

export function getNutFactorVariability(args: {
  threadPitch: DimensionedQuantity<UnitsPitch.MillimetersPerThread>,
  pitchDiameter: Distance<UnitsDistance>,
  threadFrictionCoefficient: number,
  frictionDiameter: number,
  headFrictionCoefficient: number; // AKA bearing surface coefficient
  nominalDiameter: Distance<UnitsDistance.Millimeter>
}): number {
  // ((0.158*Block_mm_Pitch+0.58*Block_mm_Pitch_Dia*Thread_Coeff_MAX_+0.5*Block_mm_Friction_D*Bearing_Surface_Coeff_MAX_)/Block_mm_Dia)
  return (
    (
      0.158 * args.threadPitch.value
      +
      0.58 *args.pitchDiameter.value * args.threadFrictionCoefficient
      +
      0.5 * args.frictionDiameter * args.headFrictionCoefficient
    )
    /
    args.nominalDiameter.value
  )
}

export function getNutFactorVariabilities(args: {
  threadPitch: DimensionedQuantity<UnitsPitch.MillimetersPerThread>,
  pitchDiameter: Distance<UnitsDistance>,
  threadFrictionCoefficientRange: DimensionedQuantityRange<number>;
  frictionDiameter: number,
  headFrictionCoefficientRange: DimensionedQuantityRange<number>; // AKA bearing surface coefficient
  nominalDiameter: Distance<UnitsDistance.Millimeter>,
  targetTorqueOverYieldRatio: number,
  outerDiameter: Distance<UnitsDistance.Millimeter>,
  innerDiameter: Distance<UnitsDistance.Millimeter>,
}): {
  rawValues: {
    threadCoeffMaxHeadCoeffMax: number;
    threadCoeffMaxHeadCoeffMin: number;
    threadCoeffMinHeadCoeffMax: number;
    threadCoeffMinHeadCoeffMin: number;
  };
  averageµtot: number;
  mean: number;
  min: number;
  max: number;
} {
  // mg == thread coeff
  // mk == head friction coeff, or bearing surface coeff
  const rawKValues = {
    threadCoeffMaxHeadCoeffMax: getNutFactorVariability({
      threadPitch: args.threadPitch,
      pitchDiameter: args.pitchDiameter,
      threadFrictionCoefficient: args.threadFrictionCoefficientRange.maxValue,
      frictionDiameter: args.frictionDiameter,
      headFrictionCoefficient: args.headFrictionCoefficientRange.maxValue,
      nominalDiameter: args.nominalDiameter,
    }),
    threadCoeffMaxHeadCoeffMin: getNutFactorVariability({
      threadPitch: args.threadPitch,
      pitchDiameter: args.pitchDiameter,
      threadFrictionCoefficient: args.threadFrictionCoefficientRange.maxValue,
      frictionDiameter: args.frictionDiameter,
      headFrictionCoefficient: args.headFrictionCoefficientRange.minValue,
      nominalDiameter: args.nominalDiameter,
    }),
    threadCoeffMinHeadCoeffMax: getNutFactorVariability({
      threadPitch: args.threadPitch,
      pitchDiameter: args.pitchDiameter,
      threadFrictionCoefficient: args.threadFrictionCoefficientRange.minValue,
      frictionDiameter: args.frictionDiameter,
      headFrictionCoefficient: args.headFrictionCoefficientRange.maxValue,
      nominalDiameter: args.nominalDiameter,
    }),
    threadCoeffMinHeadCoeffMin: getNutFactorVariability({
      threadPitch: args.threadPitch,
      pitchDiameter: args.pitchDiameter,
      threadFrictionCoefficient: args.threadFrictionCoefficientRange.minValue,
      frictionDiameter: args.frictionDiameter,
      headFrictionCoefficient: args.headFrictionCoefficientRange.minValue,
      nominalDiameter: args.nominalDiameter,
    }),
  };

  const calculatedKValues = {
    mean: Object.values(rawKValues).reduce((acc, cur) => acc + cur) / Object.values(rawKValues).length,
    min: Math.min(...Object.values(rawKValues)),
    max: Math.max(...Object.values(rawKValues))
  }

  const averageµtot = getAverageµtot({
    targetTorqueOverYieldRatio: args.targetTorqueOverYieldRatio,
    threadPitch: args.threadPitch,
    pitchDiameter: args.pitchDiameter,
    outerDiameter: args.outerDiameter,
    innerDiameter: args.innerDiameter,
    nominalDiameter: args.nominalDiameter,
    nutFactorVariabilityMinK: calculatedKValues.min,
    nutFactorVariabilityMaxK: calculatedKValues.max,
  })

  return {
    ...calculatedKValues,
    rawValues: rawKValues,
    averageµtot: averageµtot
  }
}

export function getClampLoads(args: {
  targetTorqueOverYieldRatio: number,
  nominalDiameter: Distance<UnitsDistance.Millimeter>,
  nutFactorVariabilityMinK: number,
  nutFactorVariabilityMaxK: number,
}): {
  min: number,
  max: number
} {
  // for minimum: (TARGET_TORQUE___XPercent__of_Yield/(nominal_diameter_numeric*Nut_Factor_Variability_Maximum__K))
  // for maximum: (TARGET_TORQUE___XPercent__of_Yield/(nominal_diameter_numeric*Nut_Factor_Variability_Minimum__K))
  return {
    min:  (
      args.targetTorqueOverYieldRatio
      /
      ( args.nominalDiameter.value * args.nutFactorVariabilityMaxK )
    ),
    max: (
      args.targetTorqueOverYieldRatio
      /
      ( args.nominalDiameter.value * args.nutFactorVariabilityMinK )
    )
  }
}

export function getAverageµtot(args: {
  targetTorqueOverYieldRatio: number,
  threadPitch: DimensionedQuantity<UnitsPitch.MillimetersPerThread>,
  pitchDiameter: Distance<UnitsDistance>,
  outerDiameter: Distance<UnitsDistance.Millimeter>,
  innerDiameter: Distance<UnitsDistance.Millimeter>,
  nominalDiameter: Distance<UnitsDistance.Millimeter>,
  nutFactorVariabilityMinK: number,
  nutFactorVariabilityMaxK: number,
}): number {
  // ((TARGET_TORQUE___XPercent__of_Yield/Clamp_load_min)-(Pitch_TPI_/(2*PI())))/(0.577*Minimum_Pitch_Diameter__d2_+0.5*((Outside_Bearing_diameter__mm+Passing_hole__mm)/2))
  const { min: clampLoadMin, max } = getClampLoads({
    targetTorqueOverYieldRatio: args.targetTorqueOverYieldRatio,
    nominalDiameter: args.nominalDiameter,
    nutFactorVariabilityMinK: args.nutFactorVariabilityMinK,
    nutFactorVariabilityMaxK: args.nutFactorVariabilityMaxK,
  })
  return (
    (
      (args.targetTorqueOverYieldRatio / clampLoadMin)
      -
      (args.threadPitch.value / (2 * Math.PI))
    )
    /
    (
      0.577 * args.pitchDiameter.value + 0.5
      *
      (
        ( args.outerDiameter.value + args.innerDiameter.value ) / 2)
    )
  )
}

export function getUltimateClampLoad(
  tensileStrength: number,
  yieldFactor: number,
  crossSectionArea: number
) {
  return tensileStrength * yieldFactor * crossSectionArea;
}

export function getUltimateClampLoads(
  tensileStrengthRange: DimensionedQuantityRange<UnitsTensileStrength.MegaPascal>,
  yieldFactorRange: DimensionedQuantityRange<number>,
  crossSectionArea: number
): {
  highStrengthOverHighYield: number;
  highStrengthOverLowYield: number;
  lowStrengthOverHighYield: number;
  lowStrengthOverLowYield: number;
} {
  return {
    highStrengthOverHighYield: getUltimateClampLoad(
      tensileStrengthRange.maxValue,
      yieldFactorRange.maxValue,
      crossSectionArea
      / 1000
    ),
    highStrengthOverLowYield: getUltimateClampLoad(
      tensileStrengthRange.maxValue,
      yieldFactorRange.minValue,
      crossSectionArea
      / 1000
    ),
    lowStrengthOverHighYield: getUltimateClampLoad(
      tensileStrengthRange.minValue,
      yieldFactorRange.maxValue,
      crossSectionArea
      / 1000
    ),
    lowStrengthOverLowYield: getUltimateClampLoad(
      tensileStrengthRange.minValue,
      yieldFactorRange.minValue,
      crossSectionArea / 1000
    ),
  };
}

export function getClampLoad(args: {
  tensileStrength: number,
  yieldOverTensileStrengthRatio: number,
  yieldFactor: number,
  crossSectionArea: number
}): number {
  
  return (
    (args.tensileStrength *
      args.yieldOverTensileStrengthRatio *
      args.yieldFactor *
      args.crossSectionArea) /
    1000
  );
}

export function getClampLoadsAtYieldPoint(
  tensileStrengthRange: DimensionedQuantityRange<UnitsTensileStrength.MegaPascal>,
  yieldOverTensileStrengthRatioRange: DimensionedQuantityRange<number>,
  yieldFactorRange: DimensionedQuantityRange<number>,
  crossSectionArea: number
): {
  highStrengthOverHighYieldAtMinYieldTensileRatio: number;
  highStrengthOverLowYieldAtMinYieldTensileRatio: number;
  lowStrengthOverHighYieldAtMinYieldTensileRatio: number;
  lowStrengthOverLowYieldAtMinYieldTensileRatio: number;
  highStrengthOverHighYieldAtMaxYieldTensileRatio: number;
  highStrengthOverLowYieldAtMaxYieldTensileRatio: number;
  lowStrengthOverHighYieldAtMaxYieldTensileRatio: number;
  lowStrengthOverLowYieldAtMaxYieldTensileRatio: number;
} {
  // YieldOverTensile == Rp02
  return {
    highStrengthOverHighYieldAtMinYieldTensileRatio: getClampLoad({
      tensileStrength: tensileStrengthRange.maxValue,
      yieldFactor: yieldFactorRange.maxValue,
      yieldOverTensileStrengthRatio: yieldOverTensileStrengthRatioRange.minValue,
      crossSectionArea: crossSectionArea
    }),
    highStrengthOverLowYieldAtMinYieldTensileRatio: getClampLoad({
      tensileStrength: tensileStrengthRange.maxValue,
      yieldFactor: yieldFactorRange.minValue,
      yieldOverTensileStrengthRatio: yieldOverTensileStrengthRatioRange.minValue,
      crossSectionArea: crossSectionArea
    }),
    lowStrengthOverHighYieldAtMinYieldTensileRatio: getClampLoad({
      tensileStrength: tensileStrengthRange.minValue,
      yieldFactor: yieldFactorRange.maxValue,
      yieldOverTensileStrengthRatio: yieldOverTensileStrengthRatioRange.minValue,
      crossSectionArea: crossSectionArea
    }),
    lowStrengthOverLowYieldAtMinYieldTensileRatio: getClampLoad({
      tensileStrength: tensileStrengthRange.minValue,
      yieldFactor: yieldFactorRange.minValue,
      yieldOverTensileStrengthRatio: yieldOverTensileStrengthRatioRange.minValue,
      crossSectionArea: crossSectionArea
    }),
    highStrengthOverHighYieldAtMaxYieldTensileRatio: getClampLoad({
      tensileStrength: tensileStrengthRange.maxValue,
      yieldFactor: yieldFactorRange.maxValue,
      yieldOverTensileStrengthRatio: yieldOverTensileStrengthRatioRange.maxValue,
      crossSectionArea: crossSectionArea
    }),
    highStrengthOverLowYieldAtMaxYieldTensileRatio: getClampLoad({
      tensileStrength: tensileStrengthRange.maxValue,
      yieldFactor: yieldFactorRange.minValue,
      yieldOverTensileStrengthRatio: yieldOverTensileStrengthRatioRange.maxValue,
      crossSectionArea: crossSectionArea
    }),
    lowStrengthOverHighYieldAtMaxYieldTensileRatio: getClampLoad({
      tensileStrength: tensileStrengthRange.minValue,
      yieldFactor: yieldFactorRange.maxValue,
      yieldOverTensileStrengthRatio: yieldOverTensileStrengthRatioRange.maxValue,
      crossSectionArea: crossSectionArea
    }),
    lowStrengthOverLowYieldAtMaxYieldTensileRatio: getClampLoad({
      tensileStrength: tensileStrengthRange.minValue,
      yieldFactor: yieldFactorRange.minValue,
      yieldOverTensileStrengthRatio: yieldOverTensileStrengthRatioRange.maxValue,
      crossSectionArea: crossSectionArea
    }),
  };
}

export function getMinimumDistance(
  ...values: DimensionedQuantity<UnitsDistance>[]
): DimensionedQuantity<UnitsDistance> {
  return {
    unit: values[0].unit,
    value: Math.min(...values.map((a) => a.value)),
  };
}




