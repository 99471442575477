import { Button } from "../../Button";
import BOLT_image from "../../images/FindIt_BOLT.png";
import CAP_SCREW_image from "../../images/FindIt_CAP_SCREW.png";
import THREAD_FORMING_image from "../../images/FindIt_THREAD_FORMING.png";
import BULBEX_image from "../../images/FindIt_BULBEX.png";
import NAIL_RIVET_image from "../../images/FindIt_NAIL_RIVET.png";
import NS_KTR_image from "../../images/FindIt_NS_KTR.png";
import SOLID_image from "../../images/FindIt_SOLID.png";
import infoIcon from "../../images/info-hover-01.svg";
import Half_Hex_Rivet_Nut_image from "../../images/Half_Hex_Rivet_Nut.svg";
import {
  FastenerNames,
  isDimensionedQuantity,
  isDimensionedQuantityRange,
  isSelectedRivetNutParameters,
  isSelectedRivetParameters,
  isSelectedThreadedParameters,
} from "../FindIt/findItTypes";
import { RecommenderState } from "./FastenerRecommender";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { FindingItSolutionContact } from "./FindingItSolutionContact";
import { FfTooltip } from "../globalComponents/FfTooltip";

import styles from "../../styles/_findIt.module.scss"
import utilityStyles from "../../styles/_utilityStyles.module.scss"

const fastenerImages: Record<FastenerNames, string> = {
  [FastenerNames.Bolt]: BOLT_image,
  [FastenerNames.MachineScrew]: CAP_SCREW_image,
  [FastenerNames.TFormingScrewMetal]: THREAD_FORMING_image,
  [FastenerNames.TFormingScrewPlastic]: THREAD_FORMING_image,
  [FastenerNames.OpenEnd]: NAIL_RIVET_image,
  [FastenerNames.ClosedEnd]: NAIL_RIVET_image,
  [FastenerNames.Crimped]: NAIL_RIVET_image,
  [FastenerNames.Rolled]: NAIL_RIVET_image,
  [FastenerNames.Bulbex]: BULBEX_image,
  [FastenerNames.NSKTR]: NS_KTR_image,
  [FastenerNames.MonoboltInterlock]: NAIL_RIVET_image,
  [FastenerNames.Bulbing]: NAIL_RIVET_image,
  [FastenerNames.StructuralKTR]: NS_KTR_image,
  [FastenerNames.Solid]: SOLID_image,
  [FastenerNames.SemiTubular]: NAIL_RIVET_image,
  [FastenerNames.Tubular]: NAIL_RIVET_image,
  [FastenerNames.SelfPierce]: NAIL_RIVET_image,
  [FastenerNames.OpenEndRivetNut]: Half_Hex_Rivet_Nut_image,
  [FastenerNames.ClosedEndRivetNut]: Half_Hex_Rivet_Nut_image,
};

// type LearningItLink = {
//   [key in FastenerNames]?: {
//     path: string;
//     text: string;
//   };
// };

type LearningItLink = Record<
  FastenerNames,
  {
    path: string;
    text: string;
  }
>

const LearningItLinks: LearningItLink = {
  [FastenerNames.Bolt]: {
    path: "/LearnIt/Bolts",
    text: "Bolts",
  },
  [FastenerNames.MachineScrew]: {
    path: "/LearnIt/Screws",
    text: "Screws",
  },
  [FastenerNames.TFormingScrewMetal]: {
    path: "/LearnIt/ThreadFormers",
    text: "Thread Forming Screws",
  },
  [FastenerNames.TFormingScrewPlastic]: {
    path: "/LearnIt/ThreadFormers",
    text: "Thread Forming Screws",
  },
  [FastenerNames.OpenEnd]: {
    path: "/LearnIt/Bolts",
    text: "Bolts",
  },
  [FastenerNames.OpenEnd]: {
    path: "/LearnIt/Rivets",
    text: "Rivets",
  },
  [FastenerNames.ClosedEnd]: {
    path: "/LearnIt/Rivets",
    text: "Rivets",
  },
  [FastenerNames.Crimped]: {
    path: "/LearnIt/Rivets",
    text: "Rivets",
  },
  [FastenerNames.Rolled]: {
    path: "/LearnIt/Rivets",
    text: "Rivets",
  },
  [FastenerNames.Bulbex]: {
    path: "/LearnIt/Rivets",
    text: "Rivets",
  },
  [FastenerNames.NSKTR]: {
    path: "/LearnIt/Rivets",
    text: "Rivets",
  },
  [FastenerNames.MonoboltInterlock]: {
    path: "/LearnIt/Rivets",
    text: "Rivets",
  },
  [FastenerNames.Bulbing]: {
    path: "/LearnIt/Rivets",
    text: "Rivets",
  },
  [FastenerNames.StructuralKTR]: {
    path: "/LearnIt/Rivets",
    text: "Rivets",
  },
  [FastenerNames.Solid]: {
    path: "/LearnIt/Rivets",
    text: "Rivets",
  },
  [FastenerNames.SemiTubular]: {
    path: "/LearnIt/Rivets",
    text: "Rivets",
  },
  [FastenerNames.Tubular]: {
    path: "/LearnIt/Rivets",
    text: "Rivets",
  },
  [FastenerNames.SelfPierce]: {
    path: "/LearnIt/Rivets",
    text: "Rivets",
  },
  [FastenerNames.OpenEndRivetNut]: {
    path: "/LearnIt/Rivnuts",
    text: "Rivet Nuts"
  },
  [FastenerNames.ClosedEndRivetNut]: {
    path: "/LearnIt/Rivnuts",
    text: "Rivet Nuts"
  }
};

export type FindingItSolutionProps = {
  recommenderState: RecommenderState;
  solutionRef: React.MutableRefObject<HTMLDivElement | null>;
};

export function FindingItSolution(props: FindingItSolutionProps) {
  const navigate = useNavigate();

  useEffect(() => {
    props.solutionRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const selectedPartParameters = props.recommenderState.selectedPartParameters;

  if (selectedPartParameters.fastenerName === undefined) {
    throw "Undefined fastener name in FindingItSolution";
  }


  const fields = (() => {
    if (isSelectedThreadedParameters(selectedPartParameters)) {
      return (
        <>
          <dt>Diameter : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.diameter
              ? selectedPartParameters.diameter.text
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Pitch : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.pitch
              ? `${selectedPartParameters.pitch.value} ${selectedPartParameters.pitch.unit}`
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Length : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.length
              ? `${selectedPartParameters.length.value} ${selectedPartParameters.length.unit}`
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Head Style : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.headType
              ? selectedPartParameters.headType
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Drive Type : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.driveType
              ? selectedPartParameters.driveType
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Material : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.material
              ? selectedPartParameters.material
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Finish : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.finish
              ? selectedPartParameters.finish
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Tensile Strength : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.tensileStrength
              ? `${selectedPartParameters.tensileStrength.value} ${selectedPartParameters.tensileStrength.unit}`
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Shear Strength : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.shearStrength
              ? `${selectedPartParameters.shearStrength.value} ${selectedPartParameters.shearStrength.unit}`
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Torque : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            <Button
              onClick={() => { navigate("/TorqueCalculator") }}
              className={styles.viewSourceButton}
              backgroundColor={{ background: "#3c649b" }}
              border={{ border: "solid 6px #0a6b7c" }}
            >
              See Recommendations
            </Button>
          </dd>
        </>
      );
    }

    if (isSelectedRivetParameters(selectedPartParameters)) {
      return (
        <>
          <dt>Diameter : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.diameter
              ? selectedPartParameters.diameter.text
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Grip Range : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.gripRange && selectedPartParameters.gripRange.min !== undefined && selectedPartParameters.gripRange.max !== undefined
              ? `${selectedPartParameters.gripRange.min.value.toFixed(
                1
              )} - ${selectedPartParameters.gripRange.max.value.toFixed(1)} ${selectedPartParameters.gripRange.max.unit
              }`
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Head Style : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.headType
              ? selectedPartParameters.headType
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Body Material : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.bodyMaterial
              ? selectedPartParameters.bodyMaterial
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Body Finish : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.bodyFinish
              ? selectedPartParameters.bodyFinish
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Stem Material : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.stemMaterial
              ? selectedPartParameters.stemMaterial
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Tensile Strength : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.tensileStrength
              ? `${selectedPartParameters.tensileStrength.value} ${selectedPartParameters.tensileStrength.unit}`
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Shear Strength : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.shearStrength
              ? `${selectedPartParameters.shearStrength.value} ${selectedPartParameters.shearStrength.unit}`
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
        </>
      );
    }

    if (isSelectedRivetNutParameters(selectedPartParameters)) {
      return (
        <>
          <dt>Diameter : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.diameter
              ? selectedPartParameters.diameter.text
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Pitch : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.pitch
              ? `${selectedPartParameters.pitch.value} ${selectedPartParameters.pitch.unit}`
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Grip Range : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.gripRange
              ? `${selectedPartParameters.gripRange.minValue} - ${selectedPartParameters.gripRange.maxValue} ${selectedPartParameters.gripRange.unit}`
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Length : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {(selectedPartParameters.installedLength && selectedPartParameters.uninstalledLength)
              ? `${selectedPartParameters.installedLength.value} ${selectedPartParameters.installedLength.unit} `
              + `installed, ${selectedPartParameters.uninstalledLength.value} `
              + `${selectedPartParameters.uninstalledLength.unit} uninstalled`
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Material : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.material
              ? selectedPartParameters.material
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Finish : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.finish
              ? selectedPartParameters.finish
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Head Style : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.headStyle
              ? selectedPartParameters.headStyle
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Body Type : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {selectedPartParameters.bodyType
              ? selectedPartParameters.bodyType
              : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Misc. Features : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {(selectedPartParameters.miscFeatures && Object.keys(selectedPartParameters.miscFeatures).length > 0)
              ? Object.keys(selectedPartParameters.miscFeatures).join(", ")
              : "none"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Tensile Strength : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {isDimensionedQuantity(selectedPartParameters.tensileStrength)
              ? `${selectedPartParameters.tensileStrength.value} ${selectedPartParameters.tensileStrength.unit}`
              : isDimensionedQuantityRange(selectedPartParameters.tensileStrength)
                ? `${selectedPartParameters.tensileStrength.minValue} - ${selectedPartParameters.tensileStrength.maxValue} ${selectedPartParameters.tensileStrength.unit}`
                : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Pull Out Strength : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {isDimensionedQuantity(selectedPartParameters.pullOutStrength)
              ? `${selectedPartParameters.pullOutStrength.value} ${selectedPartParameters.pullOutStrength.unit}`
              : isDimensionedQuantityRange(selectedPartParameters.pullOutStrength)
                ? `${selectedPartParameters.pullOutStrength.minValue} - ${selectedPartParameters.pullOutStrength.maxValue} ${selectedPartParameters.pullOutStrength.unit}`
                : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Max Torque, Unsupported : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {isDimensionedQuantity(selectedPartParameters.maxTorqueUnsupported)
              ? `${selectedPartParameters.maxTorqueUnsupported.value} ${selectedPartParameters.maxTorqueUnsupported.unit}`
              : isDimensionedQuantityRange(selectedPartParameters.maxTorqueUnsupported)
                ? `${selectedPartParameters.maxTorqueUnsupported.minValue} - ${selectedPartParameters.maxTorqueUnsupported.maxValue} ${selectedPartParameters.maxTorqueUnsupported.unit}`
                : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Max Torque, Supported : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            {isDimensionedQuantity(selectedPartParameters.maxTorqueSupported)
              ? `${selectedPartParameters.maxTorqueSupported.value} ${selectedPartParameters.maxTorqueSupported.unit}`
              : isDimensionedQuantityRange(selectedPartParameters.maxTorqueSupported)
                ? `${selectedPartParameters.maxTorqueSupported.minValue} - ${selectedPartParameters.maxTorqueSupported.maxValue} ${selectedPartParameters.maxTorqueSupported.unit}`
                : "unknown"}
          </dd>
          <br className={styles.findItSolutionBreak} />
          <dt>Torque : </dt>
          <br className={styles.findItSolutionBreak} />
          <dd>
            <Button
              onClick={() => { navigate("/TorqueCalculator") }}
              className={styles.viewSourceButton}
              backgroundColor={{ background: "#3c649b" }}
              border={{ border: "solid 6px #0a6b7c" }}
            >
              See Recommendations
            </Button>
          </dd>
        </>
      );
    }

    return <p>Content missing</p>;
  })();

  const header = (
    <div>
      <FfTooltip
        tooltipDecorator="icon-pro-tip"
        link={{
          targetLocation: 'local',
          path: LearningItLinks[selectedPartParameters.fastenerName]?.path
        }}
        tooltipContent={<>
          Click here to acquire more information about {selectedPartParameters.fastenerName} by taking a course in Learningit.
        </>}
      >
        <h2 className={styles.findingItSummaryHeader}>
          <span className={styles.tealH2}>Fastener Summary:</span>{" "}
          {selectedPartParameters.fastenerName}
          {" "}
          <img src={infoIcon} className={utilityStyles.infoIcon} />
        </h2>
      </FfTooltip>
    </div>
  )

  return (
    <>
      <div ref={props.solutionRef} className={styles.findItResultsCard}>
        <div className={styles.findItResults}>
          {header}
          <div className={styles.findItResultsDetails}>
            <dl className={styles.firdText}>{fields}</dl>
            <img
              className={styles.findItResultImage}
              src={fastenerImages[selectedPartParameters.fastenerName]}
            />
          </div>
          <FindingItSolutionContact recommenderState={props.recommenderState} />
        </div>
      </div>
    </>
  );
}