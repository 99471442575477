import { useAuth0 } from "@auth0/auth0-react";
import { Card } from "react-bootstrap";
import { plans } from '../ChoosePlan'
import generalStyles from "../../styles/style.module.scss"

export type CompanyInfo = {
  industry: string | null,
  industryFocus: string | null,
  companyName: string | null,
  companyAddress: string | null,
  companyWebsite: string | null,
}

export type BrandedSignupProps = {
  companyInfo: CompanyInfo
}

export const canooCompanyInfo: CompanyInfo = {
  industry: "Automotive",
  industryFocus: null,
  companyName: "Canoo",
  companyAddress: "19951 Mariner Ave Torrance, CA, 90503",
  companyWebsite: "https://www.canoo.com/",
}

export function BrandedSignup(props: BrandedSignupProps) {

  const { loginWithRedirect } = useAuth0();

  const companyInfo = props.companyInfo;
  const advancedPlan = plans[ plans.length - 1 ];
  localStorage.setItem('signUpPlan', JSON.stringify(advancedPlan));
  localStorage.setItem('signUpCompany', JSON.stringify(props.companyInfo));

  loginWithRedirect({
    screen_hint: "signup",
    redirectUri: process.env.REACT_APP_AUTH0_SIGNUP_CALLBACK,
    appState: {
      plan: advancedPlan
    }
  })

  return <div className={generalStyles.baseContainer}>
    <div className={generalStyles.verify}>
      <Card.Body>
        Redirecting to {companyInfo.companyName} account creation page...
      </Card.Body>
    </div>
  </div>
}