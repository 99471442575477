import env from '../../env';
import { ContactUsProps } from "../ContactUs";
import { SaveSolutionProps, RequestPrintProps, RequestQuoteProps, RequestSampleProps, TalkToExpertProps } from './contactRequestTypes';

export type SubmitAuthedContactRequestProps =
  | SaveSolutionProps
  | RequestPrintProps
  | RequestQuoteProps
  | RequestSampleProps
  | TalkToExpertProps
  | ContactUsProps

async function fetchWithTimeout(resource: string, options: RequestInit & { timeout?: number } = {}) {
  // Default to 8 second timeout
  const { timeout = 8000 } = options;
  
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal  
  });
  clearTimeout(id);
  return response;
}

export async function submitAuthedContactRequest(props: SubmitAuthedContactRequestProps, accessToken: string): Promise<boolean> {
  const contactRequestUrl = `${env.REACT_APP_CF_WORKERS_URL}/authedContactRequest`;

  console.log("props", props)
  const resp = await fetchWithTimeout(contactRequestUrl, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(props),
  }).then(async (response) => {
    if (!response) {
      return false
    }
    console.log("response:", response);
    console.log("response body", await response.json());
    return await response;
  }).catch((error) => {
    console.error(error);
  });

  if (resp && resp.status === 200) {
    return true;
  } else {
    return false;
  }
}
