import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from "@auth0/auth0-react";
import env from '../env';

const httpLink = new HttpLink({
  uri: env.REACT_APP_GRAPHQL_URL || 'https://data.findingfasteners.com/v1/graphql',
  fetch,
});

const authLink = setContext( async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const { getAccessTokenSilently } = useAuth0();
  // const token = await getAccessTokenSilently();
  // const token = "potato";
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // authorization: token ? `Bearer ${token}` : "",
      // 'x-hasura-admin-secret': env.REACT_APP_HASURA_SECRET_KEY,
    }
  }
});

export const client = new ApolloClient({
  // link: httpLink,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});