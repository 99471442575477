import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import env from "../env";

export interface Auth0ProviderProps {
  children: React.ReactNode
}

const Auth0ProviderWithHistory = ({ children }: Auth0ProviderProps) => {
  const domain = env.REACT_APP_AUTH0_DOMAIN;
  const clientId = env.REACT_APP_AUTH0_CLIENTID;
  const audience = env.REACT_APP_AUTH0_AUDIENCE;

  const navigate = useNavigate();

  const onRedirectCallback = (appState: any) => {
    localStorage.setItem('auth0State', JSON.stringify(appState));
    navigate(
      appState && appState.returnTo ? appState.returnTo : window.location.pathname
    );
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      audience={audience}
      onRedirectCallback={onRedirectCallback}
      cacheLocation='localstorage'
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;