type SvgLineProps = {
  display?: boolean;
  coordinates: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
  style?: React.CSSProperties;
};

export const SvgLine = (props: SvgLineProps) => {
  const defaultStyle: React.CSSProperties = {
    fill: "none",
    stroke: "#052c45",
    strokeWidth: 2,
    strokeLinecap: "round",
    strokeLinejoin: "round",
  };
  return (
    <line
      style={props.style ? { ...defaultStyle, ...props.style } : defaultStyle}
      // fillOpacity={1}    // Force display on, regardless of props
      // strokeOpacity={1}  // Force display on, regardless of props
      fillOpacity={props.display ? 1 : 0}
      strokeOpacity={props.display ? 1 : 0}
      x1={props.coordinates.x1}
      y1={props.coordinates.y1}
      x2={props.coordinates.x2}
      y2={props.coordinates.y2} />
  );
};