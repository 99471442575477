import {
  FastenerNames,
  isPossibleRivetNutSelectionOptions,
  isPossibleRivetSelectionOptions,
  isPossibleThreadedSelectionOptions,
  isSelectedRivetNutParameters,
  isSelectedRivetParameters,
  isSelectedThreadedParameters,
  PossibleFastenerSelectionOptions,
  PossibleRivetNutSelectionOptions,
  PossibleRivetSelectionOptions,
  SelectedRivetNutParameters,
  SelectedRivetParameters,
  SelectedThreadedParameters,
  UnitSystem,
} from "../FindIt/findItTypes";
import {
  RecommenderState,
  SelectedPartParameters,
} from "./FastenerRecommender";

import { Form } from "react-bootstrap";

import Select, { ReactSelectOptions } from "../utilities/FixRequiredSelect";
import { OptionTypeBase } from "react-select";
import { UnitSystemToggle } from "./UnitSystemToggle";
import { getPossibleFastenerSelections } from "./getPossibleFastenerSelections";
import threadedDiagramDiameter from "../../images/diagram_threaded_findit-diameter.svg";
import threadedDiagramLength from "../../images/diagram_threaded_findit-length.svg";
import threadedDiagramPitch from "../../images/diagram_threaded_findit-pitch.svg";
import threadedPitch from "../../images/tool-tip-thread-pitch.png";
import threadedDiameter from "../../images/tool-tip-diameter.png";
import rivetDiameter from "../../images/tool-tip-diameter-rivet.png";
import rivetHeadStyle from "../../images/tool-tip-head-style-rivet.png";
import gripRange from "../../images/tool-tip-grip-range-rivet.png";
import threadedHeadStyle from "../../images/tool-tip-head-style.png";
import threadedDriveType from "../../images/tool-tip-drive-type.png";
import threadedLength from "../../images/tool-tip-length.png";
import threadedDiagramHeadDrive from "../../images/diagram_threaded_findit-drive-head.svg";
import rivetDiagramDiameter from "../../images/diagram_rivet_findit-diameter.svg";
import rivetDiagramGrip from "../../images/diagram_rivet_findit-grip-range.svg";
import rivetDiagramHead from "../../images/diagram_rivet_findit-head.svg";
import rivetDiagramStem from "../../images/diagram_rivet_findit-stem.svg";
import rivetDiagramBody from "../../images/diagram_rivet_findit-body.svg";
import rivetNutDiagram from "../../images/Half_Hex_Rivet_Nut.svg";
import { FieldSelector } from "./FieldSelector";
import { RangeFieldSelector } from "./RangeFieldSelector";
import { MaterialAndFinishFieldSelector } from "./MaterialAndFinishFieldSelector";
import { FESBranded } from "../globalComponents/FESBrandedFeatures";

import tensileIcon from "../../images/FindIt_tensile-1.svg";
import shearIcon from "../../images/FindIt_shear.svg";
import { FindingItSolution } from "./FindingItSolution";
import { useState, useRef } from "react";
import { Button } from "../../Button";
import { FieldWithImage } from "./FieldWithImageProps";
import { FieldWithoutImage } from "./FieldWithoutImageProps";
import { Helmet } from "react-helmet";
import { RivetNutDiameterAndPitchFieldSelector } from "./RivetNutDiameterAndPitchFieldSelector";
import { RivetNutGripRangeAndLengthFieldSelector } from "./RivetNutGripRangeAndLengthsFieldSelector";
import { FieldSelectorMultipleChoice } from "./FieldSelectorMultipleSelection";
import {
  ThreadedDiagram,
  ThreadedDiagramProps,
} from "../FindIt/ThreadedDiagram";
import { RivetDiagram, RivetDiagramProps } from "../FindIt/RivetDiagram";

import {
  IncrementRange,
  isIncrementRange,
  DimensionedQuantity,
  Distance
} from "../FindIt/findItTypes";

import styles from "../../styles/_findIt.module.scss"


const threadedFieldRequiredStatuses: Record<
  keyof SelectedThreadedParameters,
  boolean
> = {
  fastenerName: true,
  diameter: true,
  length: true,
  pitch: true,
  material: true,
  finish: false,
  tensileStrength: false,
  shearStrength: false,
  headType: false,
  driveType: false,
};

const rivetFieldRequiredStatuses: Record<
  keyof SelectedRivetParameters,
  boolean
> = {
  fastenerName: true,
  diameter: true,
  gripRange: true,
  bodyMaterial: true,
  bodyFinish: false,
  tensileStrength: false,
  shearStrength: false,
  headType: false,
  stemMaterial: false,
};

const rivetNutFieldRequiredStatuses: Record<
  keyof SelectedRivetNutParameters,
  boolean
> = {
  fastenerName: true,
  diameter: true,
  gripRange: true,
  pitch: true,
  installedLength: false,
  uninstalledLength: false,
  swagingForce: true,
  headStyle: true,
  miscFeatures: false,
  bodyType: true,
  material: true,
  finish: false,
  tensileStrength: false,
  pullOutStrength: false,
  shearStrength: false,
  maxTorqueUnsupported: false,
  maxTorqueSupported: false,
};

type FastenerSelectorProps = {
  recommenderState: RecommenderState;
  setSelectedPartParameters: (
    newPartParameters: SelectedPartParameters
  ) => void;
  fastenerNameOptions: ReactSelectOptions<string, FastenerNames>;
};

function FastenerSelector(props: FastenerSelectorProps) {
  const handleFastenerSelectionChange = (
    newFastener: OptionTypeBase | null
  ) => {
    // console.log(newFastener)
    const newSelectedPartParameters: SelectedPartParameters = {
      fastenerName: newFastener?.value,
    };
    props.setSelectedPartParameters(newSelectedPartParameters);
  };

  const currentSelection = props.recommenderState.selectedPartParameters.fastenerName;

  const defaultValue = props.fastenerNameOptions.find(
    (currentOption) => currentOption.value === currentSelection
  );

  return (
    <>
      <div className={styles.findItFieldType}>
        <h2 className={`${styles.smallH2} ${styles.blueH2}`}>Fastener Type</h2>
        <Select
          options={props.fastenerNameOptions}
          onChange={handleFastenerSelectionChange}
          required={defaultValue === undefined}
          isClearable={false}
          defaultValue={defaultValue}
        />
      </div>
    </>
  );
}

type FindingItTooltipContentProps = {
  title: string;
  text: string;
  imgSrc?: string;
  // setFeatureAsNotHighlighted?: Function;
};

export function FindingItTooltipContent({
  title,
  text,
  imgSrc,

  // setFeatureAsNotHighlighted
}: FindingItTooltipContentProps) {
  return (
    <>
      <h2 className={styles.findingItTooltipTitle}>{title}</h2>
      <p className={styles.findingItTooltipText}>{text}</p>
      <img className={styles.findingItTooltipImage} src={imgSrc} alt="" />
    </>
  );
}

type ThreadedFieldsProps = {
  recommenderState: RecommenderState;
  setSelectedPartParameters: (
    newPartParameters: SelectedPartParameters
  ) => void;
  possibleFastenerSelections: PossibleFastenerSelectionOptions;
};

function ThreadedFields(props: ThreadedFieldsProps): JSX.Element {
  const threadPitchTooltipText =
    `Thread pitch is measured differently in different measurement systems. ` +
    `In the metric system, pitch is the distance between threads. ` +
    `However, in the imperial system, pitch is the number of threads per inch.`;

  const diameterTooltipText = `The distance of the outer thread on one side to the the outer distance of the other side.`;

  const headStyleTooltipText = `The type of head on top of a fastener.`;

  const driveTypeTooltipText = `Shaped cavities on a fastener head that allows torque to be applied.`;

  const lengthTooltipText = `Length excludes the head.`;

  const tensileTooltipText = `The maximum tension applied load the fastener  can support prior to fracture. `;

  const shearTooltipText = `The maximum tension applied load the fastener  can support prior to fracture. `;


  if (
    props.recommenderState.selectedPartParameters.fastenerName === undefined
  ) {
    // console.log(
    //   "fastenerName is not defined; showing non-interactive preview of ThreadedFields"
    // );
  } else if (
    !isSelectedThreadedParameters(props.recommenderState.selectedPartParameters)
  ) {
    throw "ThreadedFields: selected parameters are not threaded";
  }

  const [highlightedFeatures, setHighlightedFeatures] = useState<
    ThreadedDiagramProps["highlightedFeatures"]
  >({});

  const setFeatureAsHighlighted = (
    featureName: keyof ThreadedDiagramProps["highlightedFeatures"]
  ) => {
    // Assume only current feature should be highlighted.
    // This fixes a bug that occurs when multiple feature highlights are turned on or off during the same render cycle,
    //  such as when a mouseLeaveEvent and MouseEnter event happen simultaneously.
    // If we use the highlightedFeatures obj, any changes to that obj made in a previous step in the same cycle will be
    //  ignored because the value has not yet been updated.
    const allFeaturesNotHighlighted: Record<keyof ThreadedDiagramProps["highlightedFeatures"], false> = {
      diameter: false,
      pitch: false,
      length: false,
      head: false,
      material: false,
      drive: false
    }
    setHighlightedFeatures({
      ...allFeaturesNotHighlighted,
      [featureName]: true,
    });
  };

  const setFeatureAsNotHighlighted = (
    featureName: keyof ThreadedDiagramProps["highlightedFeatures"]
  ) => {
    setHighlightedFeatures({
      ...highlightedFeatures,
      [featureName]: false,
    });
  };

  return (
    <div className={styles.findItQuestions}>
      <div className={styles.findItDiagram}>
        <ThreadedDiagram highlightedFeatures={highlightedFeatures} />
      </div>

      {/* Diameter Field */}

      <FieldWithImage
        imgSrc={threadedDiagramDiameter}
        wrapperClassName={styles.findItFieldThreadedDiameter}
        selector={
          <FieldSelector
            selectionOptions={
              isPossibleThreadedSelectionOptions(
                props.possibleFastenerSelections
              )
                ? props.possibleFastenerSelections.diameter
                : []
            }
            defaultValue={getDefaultSelection(props.recommenderState.selectedPartParameters, props.possibleFastenerSelections, 'diameter')}
            required={threadedFieldRequiredStatuses["diameter"]}
            fieldName="diameter"
            fieldTitle="Diameter"
            childClassName=""
            parentField={{
              fieldName: "fastenerName",
              fieldTitle: "Fastener",
            }}
            tooltip={{
              content: (
                <FindingItTooltipContent
                  title="Diameter"
                  text={diameterTooltipText}
                  imgSrc={threadedDiameter}
                />
              ),
            }}
            highlight={{
              setFeatureAsHighlighted: () => {
                setFeatureAsHighlighted("diameter");
              },
              setFeatureAsNotHighlighted: () => {
                setFeatureAsNotHighlighted("diameter");
              },
            }}
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />

      {/* Pitch field */}

      <FieldWithImage
        imgSrc={threadedDiagramPitch}
        wrapperClassName={styles.findItFieldThreadPitch}
        selector={
          <FieldSelector
            selectionOptions={
              isPossibleThreadedSelectionOptions(
                props.possibleFastenerSelections
              )
                ? props.possibleFastenerSelections.pitch
                : []
            }
            required={threadedFieldRequiredStatuses["pitch"]}
            fieldName={"pitch"}
            fieldTitle="Thread Pitch"
            childClassName=""
            parentField={{
              fieldName: "diameter",
              fieldTitle: "Diameter",
            }}
            tooltip={{
              content: (
                <FindingItTooltipContent
                  title="Thread Pitch"
                  text={threadPitchTooltipText}
                  imgSrc={threadedPitch}
                />
              ),
            }}
            highlight={{
              setFeatureAsHighlighted: () => {
                setFeatureAsHighlighted("pitch");
              },
              setFeatureAsNotHighlighted: () => {
                setFeatureAsNotHighlighted("pitch");
              },
            }}
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />

      {/* Head style field */}

      <FieldWithoutImage
        wrapperClassName={styles.findItFieldThreadedHeadStyle}
        selector={
          <FieldSelector
            selectionOptions={
              isPossibleThreadedSelectionOptions(
                props.possibleFastenerSelections
              )
                ? props.possibleFastenerSelections.headType
                : []
            }
            required={threadedFieldRequiredStatuses["headType"]}
            fieldName={"headType"}
            fieldTitle="Head Style"
            childClassName=""
            parentField={{
              fieldName: "diameter",
              fieldTitle: "Diameter",
            }}
            tooltip={{
              content: (
                <FindingItTooltipContent
                  title="Head Style"
                  text={headStyleTooltipText}
                  imgSrc={threadedHeadStyle}
                />
              ),
              position: "left",
            }}
            highlight={{
              setFeatureAsHighlighted: () => {
                setFeatureAsHighlighted("head");
              },
              setFeatureAsNotHighlighted: () => {
                setFeatureAsNotHighlighted("head");
              },
            }}
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />

      {/* Drive type field */}

      <FieldWithImage
        imgSrc={threadedDiagramHeadDrive}
        wrapperClassName={styles.findItFieldDriveType}
        selector={
          <FieldSelector
            selectionOptions={
              isPossibleThreadedSelectionOptions(
                props.possibleFastenerSelections
              )
                ? props.possibleFastenerSelections.driveType
                : []
            }
            required={threadedFieldRequiredStatuses["driveType"]}
            fieldName="driveType"
            fieldTitle="Drive Type"
            childClassName=""
            parentField={{
              fieldName: "headType",
              fieldTitle: "Head Style",
            }}
            tooltip={{
              content: (
                <FindingItTooltipContent
                  title="Drive Type"
                  text={driveTypeTooltipText}
                  imgSrc={threadedDriveType}
                />
              ),
              position: "left",
            }}
            // highlight={{
            //   setFeatureAsHighlighted: () => {setFeatureAsHighlighted("drive")},
            //   setFeatureAsHighNotlighted: () => {setFeatureAsNotHighlighted("drive")},
            // }}
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />

      {/* Length field */}

      <FieldWithImage
        imgSrc={threadedDiagramLength}
        wrapperClassName={styles.findItFieldThreadedLength}
        selector={
          <FieldSelector
            selectionOptions={
              isPossibleThreadedSelectionOptions(
                props.possibleFastenerSelections
              )
                ? props.possibleFastenerSelections.length
                : []
            }
            required={threadedFieldRequiredStatuses["length"]}
            fieldName="length"
            fieldTitle="Length"
            childClassName=""
            parentField={{
              fieldName: "diameter",
              fieldTitle: "Diameter",
            }}
            tooltip={{
              content: (
                <FindingItTooltipContent
                  title="Length"
                  text={lengthTooltipText}
                  imgSrc={threadedLength}
                />
              ),
            }}
            highlight={{
              setFeatureAsHighlighted: () => {
                setFeatureAsHighlighted("length");
              },
              setFeatureAsNotHighlighted: () => {
                setFeatureAsNotHighlighted("length");
              },
            }}
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />

      {/* Material & finish fields */}

      <FieldWithoutImage
        wrapperClassName={styles.findItFieldThreadedMaterial}
        selector={
          <MaterialAndFinishFieldSelector
            material={{
              selectionOptions: isPossibleThreadedSelectionOptions(
                props.possibleFastenerSelections
              )
                ? props.possibleFastenerSelections.material
                : [],
              required: threadedFieldRequiredStatuses["material"],
              fieldName: "material",
              fieldTitle: "Material",
              parentField: {
                fieldName: "diameter",
                fieldTitle: "Diameter",
              },
            }}
            finish={{
              selectionOptions: isPossibleThreadedSelectionOptions(
                props.possibleFastenerSelections
              )
                ? props.possibleFastenerSelections.finish
                : [],
              required: threadedFieldRequiredStatuses["finish"],
              fieldName: "finish",
              fieldTitle: "Finish",
              parentField: {
                fieldName: "material",
                fieldTitle: "Material",
              },
            }}
            fieldGroupTitle="Material & Finish"
            childClassName=""
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />

      {/* Tensile strength field */}

      <FieldWithImage
        imgSrc={tensileIcon}
        wrapperClassName={styles.findItFieldThreadedTensileStrength}
        selector={
          <FieldSelector
            selectionOptions={
              isPossibleThreadedSelectionOptions(
                props.possibleFastenerSelections
              )
                ? props.possibleFastenerSelections.tensileStrength
                : []
            }
            required={threadedFieldRequiredStatuses["tensileStrength"]}
            fieldName="tensileStrength"
            fieldTitle="Tensile Strength"
            childClassName=""
            // icon={{
            //   src: tensileIcon,
            //   altText:
            //     "Tensile strength is the ability to resist forces pulling parallel to the fastener's axis, away from the center",
            //   position: "center",
            // }}
            parentField={{
              fieldName: "material",
              fieldTitle: "Material",
            }}

            tooltip={{
              content: (
                <FindingItTooltipContent
                  title="Tensile Strength"
                  text={tensileTooltipText}
                  imgSrc={tensileIcon}
                />
              ),
            }}
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />

      {/* Shear strength field */}

      <FieldWithImage
        imgSrc={shearIcon}
        wrapperClassName={styles.findItFieldThreadedShearStrength}
        selector={
          <FieldSelector
            selectionOptions={
              isPossibleThreadedSelectionOptions(
                props.possibleFastenerSelections
              )
                ? props.possibleFastenerSelections.shearStrength
                : []
            }
            required={threadedFieldRequiredStatuses["shearStrength"]}
            fieldName="shearStrength"
            fieldTitle="Shear Strength"
            childClassName=""
            // icon={{
            //   src: shearIcon,
            //   altText:
            //     "Shear strength is the ability to resist forces pulling perpendicular to the fastener's axis, away from the center",
            //   position: "center",
            // }}
            parentField={{
              fieldName: "material",
              fieldTitle: "Material",
            }}
            tooltip={{
              content: (
                <FindingItTooltipContent
                  title="Shear Strength"
                  text={shearTooltipText}
                  imgSrc={shearIcon}
                />
              ),
              position: "left",
            }}
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />
    </div>
  );
}

type RivetFieldsProps = {
  recommenderState: RecommenderState;
  setSelectedPartParameters: (
    newPartParameters: SelectedPartParameters
  ) => void;
  possibleFastenerSelections: PossibleRivetSelectionOptions;
};

function RivetFields(props: RivetFieldsProps): JSX.Element {
  if (
    props.recommenderState.selectedPartParameters.fastenerName === undefined
  ) {
    throw "RivetFields: fastenerName is not defined";
  } else if (
    !isSelectedRivetParameters(props.recommenderState.selectedPartParameters)
  ) {
    throw "RivetFields: selected parameters are not Rivet";
  }

  const [rivetHighlightedFeatures, setRivetHighlightedFeatures] = useState<
    RivetDiagramProps["rivetHighlightedFeatures"]
  >({});

  const setRivetFeatureAsHighlighted = (
    featureName: keyof RivetDiagramProps["rivetHighlightedFeatures"]
  ) => {
    // console.log("Showing " + featureName);
    setRivetHighlightedFeatures({
      ...rivetHighlightedFeatures,
      [featureName]: true,
    });
  };

  const setRivetFeatureAsNotHighlighted = (
    featureName: keyof RivetDiagramProps["rivetHighlightedFeatures"]
  ) => {
    // console.log("Hiding " + featureName);
    setRivetHighlightedFeatures({
      ...rivetHighlightedFeatures,
      [featureName]: false,
    });
  };

  const rivetDiameterTooltipText = `Measurement of the rivet body and measured in 1/32” increments, and the larger the diameter results in higher tensile and shear strength. `;

  const gripRangeTooltipText = `The total width of rivet’s body that will expand when placed in the hole and squeezed. Grip range increases in 1/16” increments, with a maximum grip of ½” 
The thickness of material a rivet can effectively fasten, and increases in 1/16” increments up ½”
 `;

  const rivetHeadStyleTooltipText = `Head style of a rivet when installed either protrudes or is flat with the material.  It is important to know the head style when movement of the application is involved such as wind  resistance or drag.`;

  const tensileTooltipText = `The maximum tension applied load the fastener  can support prior to fracture. `;

  const shearTooltipText = `The maximum tension applied load the fastener  can support prior to fracture. `;

  return (
    <div className={styles.findItQuestions}>
      <Helmet>
        <title>Smart Fastener Finder</title>
        <meta
          name="description"
          content="A fastener finding tool that prevents users from selecting fasteners no one makes and teaches them along the way. "
        />
      </Helmet>
      <div className={styles.findItRivetDiagram}>
        <RivetDiagram rivetHighlightedFeatures={rivetHighlightedFeatures} />
      </div>
      {/* Diameter Field */}
      <FieldWithImage
        imgSrc={rivetDiagramDiameter}
        wrapperClassName={styles.findItFieldRivetDiameter}
        selector={
          <FieldSelector
            selectionOptions={props.possibleFastenerSelections.diameter}
            defaultValue={getDefaultSelection(props.recommenderState.selectedPartParameters, props.possibleFastenerSelections, 'diameter')}
            required={rivetFieldRequiredStatuses["diameter"]}
            fieldName="diameter"
            fieldTitle="Diameter"
            childClassName=""
            parentField={{
              fieldName: "fastenerName",
              fieldTitle: "Fastener",
            }}
            tooltip={{
              content: (
                <FindingItTooltipContent
                  title="Diameter"
                  text={rivetDiameterTooltipText}
                  imgSrc={rivetDiameter}
                />
              ),
            }}
            highlight={{
              setFeatureAsHighlighted: () => { setRivetFeatureAsHighlighted("diameter") },
              setFeatureAsNotHighlighted: () => { setRivetFeatureAsNotHighlighted("diameter") },
            }}
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />
      {/* Grip Range field */}
      <FieldWithImage
        wrapperClassName={styles.findItFieldRivetGripRange}
        imgSrc={rivetDiagramGrip}
        selector={
          <RangeFieldSelector
            selectionOptions={props.possibleFastenerSelections.gripRange}
            defaultValue={getIncrementRangeDefaultSelection(props.recommenderState.selectedPartParameters, props.possibleFastenerSelections, 'gripRange')}
            required={rivetFieldRequiredStatuses["gripRange"]}
            fieldName={"gripRange"}
            fieldTitle="Grip Range"
            childClassName={styles.findItRangeFieldGrid}
            parentField={{
              fieldName: "diameter",
              fieldTitle: "Diameter",
            }}
            tooltip={{
              content: (
                <FindingItTooltipContent
                  title="Grip Range"
                  text={gripRangeTooltipText}
                  imgSrc={gripRange}
                />
              ),
            }}
            highlight={{
              setFeatureAsHighlighted: () => { setRivetFeatureAsHighlighted("gripRange") },
              setFeatureAsNotHighlighted: () => { setRivetFeatureAsNotHighlighted("gripRange") },
            }}
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />
      {/* Head style field */}
      <FieldWithImage
        imgSrc={rivetDiagramHead}
        wrapperClassName={styles.findItFieldRivetHeadStyle}
        selector={
          <FieldSelector
            selectionOptions={props.possibleFastenerSelections.headStyle}
            required={rivetFieldRequiredStatuses["headType"]}
            fieldName={"headType"}
            fieldTitle="Head Style"
            childClassName=""
            parentField={{
              fieldName: "diameter",
              fieldTitle: "Diameter",
            }}
            tooltip={{
              content: (
                <FindingItTooltipContent
                  title="Head Style"
                  text={rivetHeadStyleTooltipText}
                  imgSrc={rivetHeadStyle}
                />
              ),
              position: "left",
            }}
            highlight={{
              setFeatureAsHighlighted: () => { setRivetFeatureAsHighlighted("head") },
              setFeatureAsNotHighlighted: () => { setRivetFeatureAsNotHighlighted("head") },
            }}

            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />

      {/* Body material & finish fields */}
      <FieldWithImage
        imgSrc={rivetDiagramBody}
        wrapperClassName={styles.findItFieldRivetBodyMaterial}
        selector={
          <MaterialAndFinishFieldSelector
            material={{
              selectionOptions: props.possibleFastenerSelections.bodyMaterial,
              required: rivetFieldRequiredStatuses["bodyMaterial"],
              fieldName: "bodyMaterial",
              fieldTitle: "Body Material",
              parentField: {
                fieldName: "diameter",
                fieldTitle: "Diameter",
              },
            }}
            finish={{
              selectionOptions: props.possibleFastenerSelections.bodyFinish,
              required: rivetFieldRequiredStatuses["bodyFinish"],
              fieldName: "bodyFinish",
              fieldTitle: "Body Finish",
              parentField: {
                fieldName: "bodyMaterial",
                fieldTitle: "Body Material",
              },
            }}
            fieldGroupTitle="Body Material & Finish"
            childClassName=""
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />
      {/* Stem material field */}
      <FieldWithImage
        imgSrc={rivetDiagramStem}
        wrapperClassName={styles.findItFieldRivetStemMaterial}
        selector={
          <FieldSelector
            selectionOptions={props.possibleFastenerSelections.stemMaterial}
            required={rivetFieldRequiredStatuses["stemMaterial"]}
            fieldName={"stemMaterial"}
            fieldTitle="Stem Material"
            childClassName=""
            parentField={{
              fieldName: "bodyMaterial",
              fieldTitle: "Body Material",
            }}
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />

      {/* Tensile strength field */}
      <FieldWithImage
        imgSrc={tensileIcon}
        wrapperClassName={styles.findItFieldRivetTensileStrength}
        selector={
          <FieldSelector
            selectionOptions={props.possibleFastenerSelections.tensileStrength}
            required={rivetFieldRequiredStatuses["tensileStrength"]}
            fieldName="tensileStrength"
            fieldTitle="Tensile Strength"
            childClassName=""
            parentField={{
              fieldName: "bodyMaterial",
              fieldTitle: "Body Material",
            }}
            tooltip={{
              content: (
                <FindingItTooltipContent
                  title="Tensile Strength"
                  text={tensileTooltipText}
                  imgSrc={tensileIcon}
                />
              ),
            }}
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />

      {/* Shear strength field */}

      <FieldWithImage
        imgSrc={shearIcon}
        wrapperClassName={styles.findItFieldRivetShearStrength}
        selector={
          <FieldSelector
            selectionOptions={props.possibleFastenerSelections.shearStrength}
            required={rivetFieldRequiredStatuses["shearStrength"]}
            fieldName="shearStrength"
            fieldTitle="Shear Strength"
            childClassName=""
            parentField={{
              fieldName: "stemMaterial",
              fieldTitle: "Stem Material",
            }}
            tooltip={{
              content: (
                <FindingItTooltipContent
                  title="Shear Strength"
                  text={shearTooltipText}
                  imgSrc={shearIcon}
                />
              ),
              position: "left",
            }}
            // icon={{
            //   src: shearIcon,
            //   altText:
            //     "Shear strength is the ability to resist forces pulling perpendicular to the fastener's axis, away from the center",
            //   position: "right",
            // }}
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />
    </div>
  );
}

function getIncrementRangeDefaultSelection(
  selectedPartParameters: SelectedPartParameters,
  possibleFastenerSelections: PossibleFastenerSelectionOptions,
  fieldName: keyof SelectedPartParameters | keyof SelectedRivetParameters | keyof SelectedThreadedParameters
) {
  const fieldNameAsKey = fieldName as keyof SelectedPartParameters
  const selectedValue = selectedPartParameters[fieldNameAsKey] as unknown as IncrementRange<Distance<any>>;
  // console.log("selectedValue", selectedValue)

  if (!selectedValue || !isIncrementRange(selectedValue)) {
    return undefined
  }

  const selectionOptions = possibleFastenerSelections[fieldNameAsKey] as unknown as ReactSelectOptions<DimensionedQuantity<any>, string>;

  // console.log("incrementRange selectionOptions:", selectionOptions)
  // console.log("incrementRange selected value:", selectedValue)

  const min = selectionOptions.find(
    (currentOption) => (currentOption.value.value === selectedValue.min.value)
  )

  const max = selectionOptions.find(
    (currentOption) => (currentOption.value.value === selectedValue.max.value)
  )

  return {
    min: min,
    max: max
  }
}

function getDefaultSelection(
  selectedPartParameters: SelectedPartParameters,
  possibleFastenerSelections: PossibleFastenerSelectionOptions,
  fieldName: keyof SelectedPartParameters | keyof SelectedRivetParameters | keyof SelectedThreadedParameters
) {
  const fieldNameAsKey = fieldName as keyof SelectedPartParameters
  const selectedValue = selectedPartParameters[fieldNameAsKey]
  // console.log("selectedValue", selectedValue)

  if (!selectedValue) {
    return undefined
  }

  const defaultSelection = possibleFastenerSelections[fieldNameAsKey].find(
    (currentOption) => (currentOption.value === selectedValue)
  )

  // console.log("default selection:", defaultSelection)
  return defaultSelection;
}

type RivetNutFieldsProps = {
  recommenderState: RecommenderState;
  setSelectedPartParameters: (
    newPartParameters: SelectedPartParameters
  ) => void;
  possibleFastenerSelections: PossibleRivetNutSelectionOptions;
};

function RivetNutFields(props: RivetNutFieldsProps): JSX.Element {
  const threadPitchTooltipText =
    `Thread pitch is measured differently in different measurement systems. ` +
    `In the metric system, pitch is the distance between threads. ` +
    `However, in the imperial system, pitch is the number of threads per inch.`;

  if (
    props.recommenderState.selectedPartParameters.fastenerName === undefined
  ) {
    throw "RivetNutFields: fastenerName is not defined";
  } else if (
    !isSelectedRivetNutParameters(props.recommenderState.selectedPartParameters)
  ) {
    throw "RivetNutFields: selected parameters are not RivetNut";
  }

  // console.log("selectedPartParameters", props.recommenderState.selectedPartParameters)
  // console.log("possibleFastenerSelections", props.possibleFastenerSelections)


  return <>
    <div className={styles.findItQuestions}>
      <img src={rivetNutDiagram} className={`${styles.findItDiagram} ${styles.findItRivetNutDiagram}`} />

      {/* Diameter Field */}

      <FieldWithImage
        imgSrc={rivetNutDiagram}
        wrapperClassName={styles.findItFieldRivetNutDiameterPitch}
        selector={
          <RivetNutDiameterAndPitchFieldSelector
            selectionOptions={
              isPossibleRivetNutSelectionOptions(props.possibleFastenerSelections)
                ? props.possibleFastenerSelections.diameterAndPitch
                : []
            }
            required={rivetNutFieldRequiredStatuses["diameter"] || rivetNutFieldRequiredStatuses["pitch"]}
            fieldName="diameter"
            fieldTitle="Diameter & Pitch"
            childClassName=""
            parentField={{
              fieldName: "fastenerName",
              fieldTitle: "Fastener",
            }}
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />

      {/* Pitch field */}

      <FieldWithImage
        imgSrc={rivetNutDiagram}
        wrapperClassName={styles.findItFieldThreadPitch}
        selector={
          <RivetNutGripRangeAndLengthFieldSelector
            selectionOptions={
              isPossibleRivetNutSelectionOptions(props.possibleFastenerSelections)
                ? props.possibleFastenerSelections.gripRangeAndLengths
                : []
            }
            required={
              rivetNutFieldRequiredStatuses["gripRange"]
              || rivetNutFieldRequiredStatuses["uninstalledLength"]
              || rivetNutFieldRequiredStatuses["installedLength"]
            }
            fieldName={"gripRange"}
            fieldTitle="Length & Grip Range"
            childClassName=""
            parentField={{
              fieldName: "diameter",
              fieldTitle: "Diameter",
            }}
            tooltipText={threadPitchTooltipText}
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />

      {/* Head style field */}

      <FieldWithoutImage
        wrapperClassName={styles.findItFieldRivetNutHeadStyle}
        selector={
          <FieldSelector
            selectionOptions={isPossibleRivetNutSelectionOptions(props.possibleFastenerSelections) ? props.possibleFastenerSelections.headStyle : []}
            required={rivetNutFieldRequiredStatuses["headStyle"]}
            fieldName={"headStyle"}
            fieldTitle="Head Style"
            childClassName=""
            parentField={{
              fieldName: "diameter",
              fieldTitle: "Diameter",
            }}
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />

      {/* Body type field */}

      <FieldWithImage
        imgSrc={rivetNutDiagram}
        wrapperClassName={styles.findItFieldBodyType}
        selector={
          <FieldSelector
            selectionOptions={isPossibleRivetNutSelectionOptions(props.possibleFastenerSelections) ? props.possibleFastenerSelections.bodyType : []}
            required={rivetNutFieldRequiredStatuses["bodyType"]}
            fieldName="bodyType"
            fieldTitle="Body Type"
            childClassName=""
            parentField={{
              fieldName: "diameter",
              fieldTitle: "Diameter",
            }}
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />

      {/* Misc Features field */}

      <FieldWithImage
        imgSrc={rivetNutDiagram}
        wrapperClassName={styles.findItFieldRivetNutMiscFeatures}
        selector={
          <FieldSelectorMultipleChoice
            selectionOptions={
              isPossibleRivetNutSelectionOptions(props.possibleFastenerSelections)
                ? props.possibleFastenerSelections.miscFeatures
                : []
            }
            required={rivetNutFieldRequiredStatuses["miscFeatures"]}
            fieldName="miscFeatures"
            fieldTitle="Misc. Features"
            childClassName=""
            parentField={{
              fieldName: "diameter",
              fieldTitle: "Diameter",
            }}
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />

      {/* Material & finish fields */}

      <FieldWithoutImage
        wrapperClassName={styles.findItFieldRivetNutMaterial}
        selector={
          <MaterialAndFinishFieldSelector
            material={{
              selectionOptions:
                isPossibleRivetNutSelectionOptions(props.possibleFastenerSelections)
                  ? props.possibleFastenerSelections.material
                  : [],
              required: rivetNutFieldRequiredStatuses["material"],
              fieldName: "material",
              fieldTitle: "Material",
              parentField: {
                fieldName: "diameter",
                fieldTitle: "Diameter",
              },
            }}
            finish={{
              selectionOptions:
                isPossibleRivetNutSelectionOptions(props.possibleFastenerSelections)
                  ? props.possibleFastenerSelections.finish
                  : [],
              required: rivetNutFieldRequiredStatuses["finish"],
              fieldName: "finish",
              fieldTitle: "Finish",
              parentField: {
                fieldName: "material",
                fieldTitle: "Material",
              },
            }}
            fieldGroupTitle="Material & Finish"
            childClassName=""
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />

      {/* Tensile strength field */}

      <FieldWithImage
        imgSrc={tensileIcon}
        wrapperClassName={styles.findItFieldRivetNutTensileStrength}
        selector={
          <FieldSelector
            selectionOptions={isPossibleRivetNutSelectionOptions(props.possibleFastenerSelections) ? props.possibleFastenerSelections.tensileStrength : []}
            required={rivetNutFieldRequiredStatuses["tensileStrength"]}
            fieldName="tensileStrength"
            fieldTitle="Tensile Strength"
            childClassName=""
            parentField={{
              fieldName: "material",
              fieldTitle: "Material",
            }}
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />

      {/* Pull Out strength field */}

      <FieldWithImage
        imgSrc={shearIcon}
        wrapperClassName={styles.findItFieldRivetNutPullOutStrength}
        selector={
          <FieldSelector
            selectionOptions={isPossibleRivetNutSelectionOptions(props.possibleFastenerSelections) ? props.possibleFastenerSelections.pullOutStrength : []}
            required={rivetNutFieldRequiredStatuses["pullOutStrength"]}
            fieldName="pullOutStrength"
            fieldTitle="Pull Out Strength"
            childClassName=""
            parentField={{
              fieldName: "material",
              fieldTitle: "Material",
            }}
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />

      {/* Max torque, unsupported field */}

      <FieldWithImage
        imgSrc={tensileIcon}
        wrapperClassName={styles.findItFieldRivetNutMaxTorqueUnsupported}
        selector={
          <FieldSelector
            selectionOptions={isPossibleRivetNutSelectionOptions(props.possibleFastenerSelections) ? props.possibleFastenerSelections.maxTorqueUnsupported : []}
            // required={rivetNutFieldRequiredStatuses["tensileStrength"]}
            required={false}
            fieldName="maxTorqueUnsupported"
            fieldTitle="Max Torque, Unsupported"
            childClassName=""
            parentField={{
              fieldName: "material",
              fieldTitle: "Material",
            }}
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />

      {/* Max torque, supported field */}

      <FieldWithImage
        imgSrc={tensileIcon}
        wrapperClassName={styles.findItFieldRivetNutMaxTorqueSupported}
        selector={
          <FieldSelector
            selectionOptions={isPossibleRivetNutSelectionOptions(props.possibleFastenerSelections) ? props.possibleFastenerSelections.maxTorqueSupported : []}
            // required={rivetNutFieldRequiredStatuses["tensileStrength"]}
            required={false}
            fieldName="maxTorqueSupported"
            fieldTitle="Max Torque, Supported"
            childClassName=""
            parentField={{
              fieldName: "material",
              fieldTitle: "Material",
            }}
            selectedPartParameters={
              props.recommenderState.selectedPartParameters
            }
            setSelectedPartParameters={props.setSelectedPartParameters}
          />
        }
      />

    </div>
  </>
}

type FindingItProps = {
  recommenderState: RecommenderState;
  setUnitSystem: (newUnitSystem: UnitSystem) => void;
  setSelectedPartParameters: (
    newPartParameters: SelectedPartParameters
  ) => void;
};

export function FindingIt(props: FindingItProps) {
  const solutionRef = useRef<HTMLDivElement | null>(null);

  const currentUnitSystem = props.recommenderState.unitSystem;
  const selectedFastener =
    props.recommenderState.selectedPartParameters.fastenerName;

  const possibleFastenerSelections: PossibleFastenerSelectionOptions =
    getPossibleFastenerSelections(
      props.recommenderState.selectedPartParameters,
      currentUnitSystem
    );

  const [showSolution, setShowSolution] = useState<boolean>(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // console.log("Form submitted!")
    // console.log("selected part parameters:", props.recommenderState.selectedPartParameters)
    setShowSolution(true);
    solutionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // console.log("possible fastener selections", possibleFastenerSelections)
  // console.log("recommender state", props.recommenderState)

  const typedFastenerFields: JSX.Element = (() => {
    switch (selectedFastener) {
      case undefined:
        // No fastener has been selected
        return (
          <>
            <p>Please select a fastener.</p>
            {/* ThreadedFields will display but won't allow interaction until a fastener has been selected */}
            <ThreadedFields
              recommenderState={props.recommenderState}
              setSelectedPartParameters={props.setSelectedPartParameters}
              possibleFastenerSelections={possibleFastenerSelections}
            />
          </>
        );
      case FastenerNames.Bolt:
      case FastenerNames.MachineScrew:
      case FastenerNames.TFormingScrewMetal:
      case FastenerNames.TFormingScrewPlastic:
        // Threaded fastener selected
        if (!isPossibleThreadedSelectionOptions(possibleFastenerSelections)) {
          throw new Error(
            `Selected fastener type (${selectedFastener}) does not match possibleFastenerSelections ${JSON.stringify(
              possibleFastenerSelections,
              null,
              2
            )}`
          );
        }
        return (
          <ThreadedFields
            recommenderState={props.recommenderState}
            setSelectedPartParameters={props.setSelectedPartParameters}
            possibleFastenerSelections={possibleFastenerSelections}
          />
        );
      case FastenerNames.OpenEnd:
      case FastenerNames.ClosedEnd:
      case FastenerNames.Crimped:
      case FastenerNames.Rolled:
      case FastenerNames.Bulbex:
      case FastenerNames.NSKTR:
      case FastenerNames.MonoboltInterlock:
      case FastenerNames.Bulbing:
      case FastenerNames.StructuralKTR:
      case FastenerNames.Solid:
      case FastenerNames.SemiTubular:
      case FastenerNames.Tubular:
      case FastenerNames.SelfPierce:
        // Rivet fastener selected
        if (!isPossibleRivetSelectionOptions(possibleFastenerSelections)) {
          throw `Selected fastener type (${selectedFastener}) does not match possibleFastenerSelections ${JSON.stringify(
            possibleFastenerSelections,
            null,
            2
          )}`;
        }
        return (
          <RivetFields
            recommenderState={props.recommenderState}
            setSelectedPartParameters={props.setSelectedPartParameters}
            possibleFastenerSelections={possibleFastenerSelections}
          />
        );
      case FastenerNames.OpenEndRivetNut:
      case FastenerNames.ClosedEndRivetNut:
        // Rivet Nut fastener selected
        if (!isPossibleRivetNutSelectionOptions(possibleFastenerSelections)) {
          throw new Error(`Selected fastener type (${selectedFastener}) does not match possibleFastenerSelections ${JSON.stringify(
            possibleFastenerSelections,
            null,
            2
          )}`)
        }
        return <RivetNutFields
          recommenderState={props.recommenderState}
          setSelectedPartParameters={props.setSelectedPartParameters}
          possibleFastenerSelections={possibleFastenerSelections}
        />
      default:
        console.error("selected fastener not recognized");
        return <>Please select a different fastener.</>;
    }
  })();

  return (
    <>
      <div className={styles.container} id={styles.findItContainer}>
        <Helmet>
          <title>Smart Fastener Finder</title>
          <meta name="description" content="A fastener finding tool that prevents users from selecting fasteners no one makes and teaches them along the way. " />
        </Helmet>
        <div className={`${styles.featureHeader} ${styles.findingItHeader}`}>
          <FESBranded
            tagClass="fes-tag"
            name="FindingIt"
            wrapperClass={styles.fesBrandedHeader}
            tradeMarkClass={styles.fesBrandedTrademark}
          />
        </div>
        <div className={styles.findIt}>
          <Form onSubmit={handleSubmit}>
            <div className={styles.questionGroup}>
              <h2 className={`${styles.findItH2} ${styles.blueH2}`}>
                Select your application requirements below:
              </h2>
              <div className={styles.findItTopQuestions}>
                <FastenerSelector
                  recommenderState={props.recommenderState}
                  setSelectedPartParameters={props.setSelectedPartParameters}
                  fastenerNameOptions={possibleFastenerSelections.fastenerName}
                />
                <div className={styles.unitSystemSelect}>
                  <UnitSystemToggle
                    currentUnitSystem={currentUnitSystem}
                    setUnitSystem={props.setUnitSystem}
                  />
                </div>
              </div>

              {typedFastenerFields}
            </div>
            <div className={styles.findItFooter}>
              <Button
                className={styles.findItSubmit}
                backgroundColor={{ background: "#3c649b" }}
                border={{ border: "solid 6px #0a6b7c" }}
                type="submit"
              >
                Show Solution
              </Button>
            </div>
          </Form>
        </div>
        <br />
        {showSolution && (
          <FindingItSolution
            solutionRef={solutionRef}
            recommenderState={props.recommenderState}
          // searchInputs={searchInputs}
          // joinItState={props.location?.state?.joinItState}
          // selections={activeSelections as ThreadedSolution | RivetSolution}
          />
        )}
      </div>
    </>
  );
}
