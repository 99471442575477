import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "../styles/carousel-style-override.scss"
import dots from "../images/dots.png";
import { Carousel } from "react-responsive-carousel";
import photo1 from "../images/slide1.png";
import photo2 from "../images/slide2.png";
import photo3 from "../images/slide3.png";
import photo4 from "../images/slide4.png";
import photo5 from "../images/slide5.png";
import photo6 from "../images/slide6.png";
import blackboard from "../images/blackboard.jpg";
import head from "../images/Head.png";
import book from "../images/Book.png";
import magnifyingGlass from "../images/magGlass.png";
import puzzle from "../images/Puzzle.png";
import Review1 from "../images/Review1.png";
import Review2 from "../images/Review2.png";
import Review3 from "../images/Review3.png";
import Review4 from "../images/Review4.png";
import pencil from "../images/pencil.svg";
import mail from "../images/mail.png";
import video from "../images/video.png";
import EVClients from "../images/EVClients.png";
import Vendors from "../images/Vendors.png";
import Universities from "../images/Universities.png";
import { LivestormEventList } from "./LivestormEventList";
import styles from "../styles/_home.module.scss"
import generalStyles from "../styles/style.module.scss"

export function Home2() {
  return (
    <div className={styles.wrapper}>
      <div className={generalStyles.liveStorm}>
        <LivestormEventList/>
      </div>
      <div className={styles.row2H}>
        <div className={styles.getStartedText}>
          <h1 className={styles.getStartedWhite}>
            <span className={styles.getStartedBlue}>OEM expert for fasteners, clips, torque, and the assembly needs</span>
            {" "}of product designers. Solve your mechanical joining solutions in minutes while{" "}
            <span className={styles.getStartedBlue}>avoiding common launch mistakes.</span>
          </h1>
        </div>
      </div>

      <div className={styles.row3Ah}>
        <div className={styles.whiteFadeOverlay} />
      </div>
      <div className={styles.row3Bh}>
        <span>
          Design the joint around proven solutions vs. finding a fastener to fit
          in your hole.
        </span>
        <br />
        <div className={styles.dots}>
        <img src={dots} alt="dots"  />
        </div>
        <span>
          {" "}
          Bridge the gap between manufacturing, design, and assembly to launch
          new programs with no drama.
        </span>
         <br />
        <div className={styles.dots}>
        <img src={dots} alt="dots"  />
        </div>
        <span>
          Educate design and manufacturing team to close the knowledge gap, but
          also learn about new joining methods to innovate your product.
        </span>
      </div>
      <div className={styles.row4H}>
        <a className={styles.getStartedButton} href="/Welcome">
          <img
            id="hs-cta-img-1bdf90e4-8151-404e-b096-bc177944665b"
            src="https://no-cache.hubspot.com/cta/default/21326007/1bdf90e4-8151-404e-b096-bc177944665b.png"
            alt="Get Started"
            className={styles.getStartedButton}
          />
          <span className={styles.buttonSpacer}></span>
          <a
            className={styles.demobutton}
            href="https://cta-redirect.hubspot.com/cta/redirect/21326007/2b947804-d849-4aab-a11b-6b36bffa39c8"
          >
            <img
              id="hs-cta-img-2b947804-d849-4aab-a11b-6b36bffa39c8"
              src="https://no-cache.hubspot.com/cta/default/21326007/2b947804-d849-4aab-a11b-6b36bffa39c8.png"
              alt="Schedule a Demo"
              className={styles.demobutton}
            />
          </a>
        </a>
      </div>
      <div className={styles.row6Ah}>
        <h2 className={styles.seoText1}>Designing inside or outside of the box</h2>
        <div className={styles.seoText2}>
          <ul className={styles.block2ul}>
            In the box
            <li className={styles.seoText2}>
              Designers lack knowledge of fasteners, clips, and torquing
              solutions{" "}
            </li>
            <li className={styles.seoText2}>
              Lack of knowledge leads to one-offs, recalls, and launch mistakes{" "}
            </li>
            <li className={styles.seoText2}>
              We take proven solutions vetted by mfg. and assembly and created a
              standard search tool{" "}
            </li>
            <li className={styles.seoText2}>
              Common path with fewer parts and mistakes{" "}
            </li>
            <li className={styles.seoText2}>
              Gate Keep Program to prevent rogue designs{" "}
            </li>
          </ul>
          <ul className={styles.block2ul}>
            Out of the box
            <li className={styles.seoText2}>
              Master tool online for critical joining solutions{" "}
            </li>
            <li className={styles.seoText2}>
              Decision makers, fasteners, and design managers can use the master
              tool to look for new options when needed{" "}
            </li>
          </ul>
        </div>
        <a href="/Welcome">
          <img
            id="hs-cta-img-1bdf90e4-8151-404e-b096-bc177944665b"
            src="https://no-cache.hubspot.com/cta/default/21326007/1bdf90e4-8151-404e-b096-bc177944665b.png"
            alt="Get Started"
            className={styles.getStartedButtonSeo}
          />
        </a>
      </div>

      <div className={styles.row6Bh}>
        <Carousel
          className={styles.slideShowContainer}
          autoPlay={true}
          infiniteLoop={true}
          interval={3000}
          transitionTime={10}
          showStatus={false}
        >
          <div>
            <img src={photo1} alt="car" className={styles.slideShowPic} />
          </div>
          <div>
            <img src={photo2} alt="FF" className={styles.slideShowPic} />
          </div>
          <div>
            <img src={photo3} alt="car" className={styles.slideShowPic}/>
          </div>
          <div>
            <img src={photo4} alt="car" className={styles.slideShowPic} />
          </div>
          <div>
            <img src={photo5} alt="car" className={styles.slideShowPic} />
          </div>
          <div>
            <img src={photo6} alt="car" className={styles.slideShowPic} />
          </div>
        </Carousel>
      </div>

      <div className={styles.row7H}>
        <h2 className={styles.row7HText}>Partnerships</h2>
        <a
          className={styles.whyDemoButton}
          href="https://cta-redirect.hubspot.com/cta/redirect/21326007/2b947804-d849-4aab-a11b-6b36bffa39c8"
        >
          <img
            id="hs-cta-img-2b947804-d849-4aab-a11b-6b36bffa39c8"
            src="https://no-cache.hubspot.com/cta/default/21326007/2b947804-d849-4aab-a11b-6b36bffa39c8.png"
            alt="Schedule a Demo"
          />
        </a>
      </div>

      <div className={styles.row8H}>
        <div className={`${styles.why} ${styles.row8A}`}>
          <h2 className={styles.partenershipTitle}>EV Clients</h2>
          <img
            src={EVClients}
            alt="manufacturing"
            className={styles.partnershipImage}
          />
          <p className={styles.whyText}>
            Cars, Planes, ATV’s, Trucks, and Trains, we support EV clients as a
            company.
          </p>
        </div>

        <div className={`${styles.why} ${styles.row8B}`}>
          <h2 className={styles.partenershipTitle}>Vendors</h2>
          <img
            src={Vendors}
            alt="manufacturing"
            className={styles.partnershipImage}
          />
          <p className={styles.whyText}>
            OEM Experts – Internal and External Brand Experts that are crucial
            to Innovation.
          </p>
        </div>
        <div className={`${styles.why} ${styles.row8C}`}>
          <h2 className={styles.partenershipTitle}>Universities</h2>
          <img
            src={Universities}
            alt="manufacturing"
            className={styles.partnershipImage}
          />
          <p className={styles.whyText}>
            We partner with colleges to better educate the designers of the
            future.
          </p>
        </div>
        {/* <div className="why row-8D">
          <p className={styles.whyText}>
            Money saved per vehicle cost reduced with upfront commonization
          </p>
        </div> */}
      </div>

      <div className={`${styles.featureInfoCard} ${styles.joiningIt}`}>
        <div className={`${styles.featureInfoCardHeader} ${styles.joiningItImageContainer}`}>
          <img src={puzzle} alt="puzzle piece" className={styles.cardImage} />
          <div className={styles.cardText}>Qualifying the Application</div>
        </div>
        <div className={styles.cardText2}>
          <ul>
            <li>
          JoiningIt™- O.E.M. expert qualifying tool for designers to search for proven joining solutions.
          </li>
          <br />
          <li>
          Qualifying application questions expert tells the designer to weld, glue, or fasten the joint.
          </li>
          </ul>
        </div>
        <div className={styles.cardRow}>
          <a href="/WelcomeJoinIt">
            <img
              id="hs-cta-img-1bdf90e4-8151-404e-b096-bc177944665b"
              src="https://no-cache.hubspot.com/cta/default/21326007/1bdf90e4-8151-404e-b096-bc177944665b.png"
              alt="Get Started"
              className={styles.cardButton1}
            />
          </a>
          <img src={video} className={styles.linkIcon} />
          <a
            className={styles.courseLink}
            href="https://drive.google.com/file/d/1MXzgTxDjOElQT4PJHvlOahrrNnYaDkE-/view?usp=sharing"
          >
            Watch Demo Video
          </a>
          <img src={video} className={styles.linkIcon} />
        </div>
      </div>

      <div className={`${styles.featureInfoCard} ${styles.findingIt}`}>
        <div className={`${styles.featureInfoCardHeader} ${styles.findingItImageContainer}`}>
          <img
            src={magnifyingGlass}
            alt="magnifying glass"
            className={styles.cardImage}
          />
          <div className={styles.cardText}>Qualifying the Product</div>
        </div>
        <div className={styles.cardText2}>
          <ul>
            <li>
              FindingIt™- JoiningIt qualifies the application, and this finds
              the best solution for the joining method.
            </li>
            <br />
            <li>
              From seals to coatings search current solutions first and out of
              the box solutions 2nd.
            </li>
          </ul>
        </div>
        <div className={styles.cardRow}>
          <a href="/WelcomeFindIt">
            <img
              id="hs-cta-img-1bdf90e4-8151-404e-b096-bc177944665b"
              src="https://no-cache.hubspot.com/cta/default/21326007/1bdf90e4-8151-404e-b096-bc177944665b.png"
              alt="Get Started"
              className={styles.cardButton2}
            />
          </a>
          <img src={video} className={styles.linkIcon} />
          <a
            className={styles.courseLink}
            href="https://drive.google.com/file/d/1Qu10VBB1xdQZ-gwkXUNbtdsaORHJMANZ/view?usp=sharing"
          >
            Watch Demo Video
          </a>
          <img src={video} className={styles.linkIcon} />
        </div>
      </div>

      <div className={`${styles.featureInfoCardRow2 } ${styles.learningIt}`}>
        <div className={`${styles.featureInfoCardHeader} ${styles.learningItImageContainer}`}>
          <img src={book} alt="open book" className={styles.cardImage} />
          <div className={styles.cardText}>Closing the Gap</div>
        </div>
        <div className={`${styles.cardText2} ${styles.learningItCardText}`}>
          <ul>
            <li>
              Our purpose is to provide learning on the site in two ways: our
              learning platform and interactive fastening pro tips built into
              the qualifying tools.
            </li>
            <br />
            <li>
              LearningIt™ offers classes, videos, and handouts on every major
              fastener subtype in regards to seals, vibration prevention, and
              calculating torque.
            </li>
          </ul>
        </div>
        <a
          href="https://findingfasteners.coassemble.com/unlock/8kl92Il"
          className={styles.courseLink2}
          target="_blank"
        >
          Fastener Master Knowledge Quiz
        </a>
        <div className={styles.cardRow}>
          <a href="/WelcomeLearnIt">
            <img
              id="hs-cta-img-1bdf90e4-8151-404e-b096-bc177944665b"
              src="https://no-cache.hubspot.com/cta/default/21326007/1bdf90e4-8151-404e-b096-bc177944665b.png"
              alt="Get Started"
              className={styles.cardButton3}
            />
          </a>
          <img src={pencil} className={styles.linkIcon} />
          <a
            className={styles.courseLink}
            href="https://findingfasteners.coassemble.com/unlock/BdFAruu"
            target="_blank"
          >
            Take a Mini Course
          </a>
          <img src={pencil} className={styles.linkIcon} />
        </div>
      </div>
      <div className={`${styles.featureInfoCardRow2} ${styles.designingIt}`}>
        <div className={`${styles.featureInfoCardHeader} ${styles.designingItImageContainer}`}>
          <img src={head} alt="head with light bulb" className={styles.cardImage} />
          <div className={styles.cardText}>Experts are a Click Away</div>
        </div>
        <div className={`${styles.cardText2} ${styles.designingItCardText}`}>
          <ul>
            <li>
          DesigningIt™ - Our web tools help solve 80% of common solutions
          factoring all joining options in the world or your system.
          </li>
          <br />
          <li>
          For the 20%, we have a network of internal and external OEM experts to
          help innovate and solve.
          </li>
          <br />
          <li>
          If you have a start-up with an unproven design, our experts will
          complete a full print, process, and assembly review to look for
          potential launch mistakes.
          </li>
          </ul>
        </div>
        <div className={styles.cardRow}>
          <a href="/WelcomeDesignIt">
            <img
              id="hs-cta-img-1bdf90e4-8151-404e-b096-bc177944665b"
              src="https://no-cache.hubspot.com/cta/default/21326007/1bdf90e4-8151-404e-b096-bc177944665b.png"
              alt="Get Started"
              className={styles.cardButton3}
            />
          </a>
          <img src={mail} className={styles.linkIcon} />
          <a className={styles.courseLinkDesignIt} href="/ContactUs">
            Email One of Our Experts
          </a>
          <img src={mail} className={styles.linkIcon} />
        </div>
      </div>

      <div className={styles.row11H}>
        <div className={styles.review1}>
          <div>
            <img className={styles.reviewImage} src={Review1} alt="puzzle"></img>
          </div>
        </div>
        <div className={styles.review2}>
          <div>
            <img className={styles.reviewImage} src={Review2} alt="puzzle"></img>
          </div>
        </div>
      </div>
      <div className={styles.row12H}>
        <div className={styles.review3}>
          <div>
            <img className={styles.reviewImage} src={Review3} alt="puzzle"></img>
          </div>
        </div>
        <div className={styles.review4}>
          <div>
            <img className={styles.reviewImage} src={Review4} alt="puzzle"></img>
          </div>
        </div>
      </div>

      <div className={styles.row13Ah}>
        <h2 className={styles.skillsText1}>
          Grow your resume, role, or design skills.
        </h2>
        <div className={styles.skillsText2}>
          We offer weekly updates on fastened joints, assembly, and electric
          vehicle design. Our blog, articles, and informational videos aim to
          keep you informed on the latest technologies.
        </div>
        <div className={styles.skillsButtonWrapper}>
          <a className={styles.getStartedButton} href="/Welcome">
            <img
              id="hs-cta-img-1bdf90e4-8151-404e-b096-bc177944665b"
              src="https://no-cache.hubspot.com/cta/default/21326007/1bdf90e4-8151-404e-b096-bc177944665b.png"
              alt="Get Started"
              className={styles.getStartedButton}
            />
            <span className={styles.buttonSpacer2}></span>
            <a
              className={styles.demobutton}
              href="https://cta-redirect.hubspot.com/cta/redirect/21326007/2b947804-d849-4aab-a11b-6b36bffa39c8"
            >
              <img
                id="hs-cta-img-2b947804-d849-4aab-a11b-6b36bffa39c8"
                src="https://no-cache.hubspot.com/cta/default/21326007/2b947804-d849-4aab-a11b-6b36bffa39c8.png"
                alt="Schedule a Demo"
                className={styles.demobutton}
              />
            </a>
          </a>
        </div>
      </div>
      <div className={styles.row13Bh}>
        <div className={styles.blackBoardContainer}>
          <img src={blackboard} alt="blackboard" className={styles.blackBoardPic}/>
        </div>
      </div>
    </div>
  );
}

export default Home2;
