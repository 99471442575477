// import { Route } from "react-router-dom";
// import { withAuthenticationRequired } from "@auth0/auth0-react";

// const ProtectedRoute = ({ component, ...args }: any) => {
//   const ProtectedComponent = withAuthenticationRequired(component);
//   return <ProtectedComponent {...args} />;
// };

// export default ProtectedRoute;

import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { ComponentType } from "react";
import Loading from "../components/Loading";
// import { PageLoader } from "./page-loader";

export const AuthenticationGuard = ({ component }: { component: ComponentType<object> }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <Loading />
    ),
  });

  return <Component />;
};

export default AuthenticationGuard;