
import ReactMarkdown from "react-markdown";
import generalStyles from "../styles/style.module.scss"

export default function PrivacyPolicy() {
const text = `PRIVACY POLICY


FOR


FINDING FASTENERS

This Privacy Policy describes the information collected by Finding Fasteners, LLC, a Texas limited liability company, ("we", "us", "Finding Fasteners"), how that information may be used, with whom it may be shared, and your choices about such uses and disclosures. We encourage you to read this Privacy Policy carefully when using our website or services or transacting business with us. By using our website, you are accepting the practices described in this Privacy Policy.

If you have any questions about our privacy practices, please refer to the end of this Privacy Policy for information on how to contact us.

We reserve the right to modify this Privacy Policy at any time, and without prior notice, by posting an amended Privacy Policy that is always accessible by clicking on the "Privacy Policy" link on this site's home page. Your continued use of this site indicates your acceptance of the amended Privacy Policy.

You should check the Privacy Policy through this link periodically for modifications by clicking on the link provided near the top of the Privacy Policy for a listing of material modifications and their effective dates.

Regarding Protected Information (defined below), if any modifications are materially less restrictive on our use or disclosure of the Protected Information previously disclosed by you, we will obtain your consent before implementing such revisions with respect to such information.

The information we collect
--------------------------

In General. We may collect personal information that can identify you such as your name and email address and other information that does not identify you. When you provide personal information through our website, the information may be sent to servers located in the United States.

Information you provide. We may collect and store any personal information you enter on our website or provide to us in some other manner. This includes identifying information, such as your name, address, email address, and telephone number, and, if you transact business with us, financial information such as your payment method (valid credit card number, type, expiration date or other financial information).

Use of cookies and other technologies to collect information. We use various technologies to collect information from our website or your mobile device and about your activities on our website.

Information collected automatically. We automatically collect information from your browser when you use our website or mobile application. This information includes usage data, search strings, search results, and usage activity.

Information collected automatically. We collect your phone number and email address specifically for the purpose of communicating with you. We do not share or sell your phone number or email address with any third party.

Cookies and Use of Cookie Data. When you use our website or mobile application, we may assign your device one or more cookies, to facilitate access to our website or mobile application and to personalize your experience. Through the use of a cookie, we also may automatically collect information about your activity on our website, such as your search history and results, and the time and date of your visits. Most browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies. If you choose to decline cookies, please note that you may not be able to sign in or use some of the interactive features offered on our website. Other cookies may reflect de-identified demographic or other data linked to the registration data you have submitted to us, e.g., your email address, in hashed, non-human readable form. No personally identifiable information is contained in these cookies. 

3rd Party Cookies for Serving Behavioral Ads; Flash Cookies. We reserve the right to serve our ads and third party ads on our site that are targeted to your interests ("3rd Party Behavioral Ads"). 3rd Party Behavioral Ads are served by 3rd party advertising services that use 3rd party cookies (cookies passed by others, not by us) to collect Non-Personal Information about your use of our website or mobile application and other websites. This information is your "behavioral data". 3rd Party Behavioral Ads do not use your Personal Information. Instead, they associate your behavioral data on visited sites with your computer, so that the ads your computer sees on this site are more likely to be relevant to your interests. These advertising services may also transfer this information to third parties where required to do so by law, or where such third parties process the information on the service's behalf. We will provide notice and the opportunity to opt-out of receiving 3rd Party Behavioral Ads; however, the 3rd party advertising services that serve ads on our site may use Flash cookies which may make it more difficult to opt-out effectively. Flash cookies are not the same as "browser cookies". Flash cookies consist of an Adobe Flash Media Player local shared object (LSO) that is used for tracking purposes. The Adobe Flash Media Player is software that enables users to view content on their computers. Flash cookies are also accompanied by a browser cookie. If you delete the browser cookie, the Flash cookie will automatically create (or re-spawn) a replacement for the browser cookie. For information on how to delete a Flash cookie, visit http://kb2.adobe.com/cps/526/52697ee8.html or search the Web for "How to delete flash cookie".

3rd Party Cookies for Crediting Purchases To Affiliates; Flash Cookies. We reserve the right to use 3rd party cookies, including Flash cookies, for purposes of crediting any purchase you may make on this site to one of our affiliate marketing partners that may have referred you to us. These cookies will only be used for purposes of crediting sales to the referring affiliate and not for other purposes such as serving behavioral ads. Flash cookies are not the same as "browser cookies". Flash cookies consist of an Adobe Flash Media Player local shared object (LSO) that is used for tracking purposes. The Adobe Flash Media Player is software that enables users to view content on their computers. Flash cookies are also accompanied by a browser cookie. If you delete the browser cookie, the Flash cookie may automatically create (or re-spawn) a replacement for the browser cookie. However, recent announcements indicate that IE 8 and IE 9 browsers have been updated to also delete Flash cookies with an their "delete browsing history" feature. For more information, Google "How to delete flash cookie".

Participation in Google's Adsense Network. We reserve the right to participate in Google's AdSense network for purposes of serving 3rd Party Behavioral Ads. Google uses DoubleClick's DART cookie in its AdSense network. You may opt out of the use of the DART cookie. For information regarding how to opt out, go to http://www.google.com/privacy_ads.html.

How we use the information we collect
-------------------------------------

In General. We may use information that we collect about you to:

-   deliver the products and services that you have requested.

-   manage your account and provide you with customer support.

-   perform research and analysis about your use of, or interest in, our product and services.

-   communicate with you by email, postal mail, telephone and/or mobile devices about products or services that may be of interest to you.

-   enforce or exercise any rights in our terms and conditions.

-   manage our business.

-   provide our advertisements to you when you visit other sites.

-   perform functions as otherwise described to you at the time of collection.

Financial information. We may use financial information or payment method information to process payment for any purchases made on our website or mobile application, enroll you in a discount, rebate, affiliate program, and other programs in which you elect to participate, to protect against or identify possible fraudulent transactions, and otherwise as needed to manage our business. Our payment processing is Payment Card Industry Data Security Standard (PCI DSS) compliant.

With whom we share your information
-----------------------------------

Personal information. We do not share your personal information with others except as indicated below or when we inform you and give you an opportunity to opt out of having your personal information shared. We may share personal information with:

Service providers: We may share your personal information, which may include your name and contact information (including email address), with third parties that perform certain services on our behalf. These services may include fulfilling orders, providing customer service and marketing assistance, performing business and sales analysis, user screenings, supporting our website application functionality, surveys and other features offered through our website. We may also share your name, contact information and credit card information with our authorized service providers who process credit card payments. These service providers may have access to personal information needed to perform their functions but are not permitted to share or use such information for any other purposes.

Business partners: When you make purchases on our website, we may share personal information with the businesses with which we partner to offer you the applicable products or services.

Other Situations. We also may disclose your information, including personal information:

In response to a subpoena or similar investigative demand, a court order, or a request for cooperation from a law enforcement or other government agency; to establish or exercise our legal rights; to defend against legal claims; or as otherwise required by law. In such cases, we may raise or waive any legal objection or right available to us.

When we believe disclosure is appropriate in connection with efforts to investigate, prevent, or take other action regarding illegal activity, suspected fraud or other wrongdoing; to protect and defend the rights, property or safety of our company, our users, our employees, or others; to comply with applicable law or cooperate with law enforcement; or to enforce our Terms of Use or other agreements or policies.

In connection with a substantial corporate transaction, such as the sale of our business, a divestiture, merger, consolidation, or asset sale, or in the unlikely event of bankruptcy.

Anonymized and non-personally-identifiable information. We may share non-personally-identifiable information we collect under any of the above circumstances. We may also share it with third parties to develop and deliver targeted advertising on our website or mobile application and on websites of third parties, and to analyze and report on advertising you see. We may combine non-personally-identifiable information we collect with additional non-personally-identifiable information collected from other sources. We also may share anonymized, non-personally-identifiable information with third parties, including advisors, advertisers and investors, for the purpose of conducting general business and other analysis.

Disclosures

Disclosure to our Affiliates and Resellers; Your California Privacy Rights. We reserve the right to disclose your Protected Information to any of our (i) affiliates that referred you to our site for purposes of sales by us, and (ii) resellers that may re-sell our products or services to you. We may also disclose your Protected Information to any marketing partners that may participate in the offer or related fulfillment. Understand that you originated with these affiliates and resellers, and that the information you may provide to them is subject to their privacy policies also. California Civil Code Section 1798.83 permits California residents to request certain information regarding disclosure of their Personal Information to third parties for purposes of direct marketing. You may request further information about our compliance with California Civil Code Section 1798.83 at the following email address: privacy@findingfasteners.com. Please note that we are only required to respond to one request per registrant each year, and we are not required to respond to requests made by means other than through this the address provided above.

Disclosure to Third Parties For Direct Marketing Without Restriction; Your California Privacy Rights. We reserve the right to share your Protected Information with third parties for direct marketing purposes without restriction. California Civil Code Section 1798.83 permits California residents to request certain information regarding disclosure of their Personal Information to third parties for purposes of direct marketing. You may request further information about our compliance with California Civil Code Section 1798.83 at the following email address: privacy@findingfasteners.com. Please note that we are only required to respond to one request per registrant each year, and we are not required to respond to requests made by means other than through this the address provided above.

Third-party websites
--------------------

There may be places on our website where you may click on a link to access other websites that do not operate under this Privacy Policy. For example, if you click on an advertisement on our website or mobile application, you may be taken to a website that we do not control. These third-party websites may independently solicit and collect information, including personal information, from you and, in some instances, provide us with information about your activities on those websites. We recommend that you consult the privacy statements of all third-party websites you visit by clicking on the "privacy" link typically located at the bottom of the webpage you are visiting.

How you can access your information
-----------------------------------

If you have an online account with us, you have the ability to review and update your personal information online by logging into your account and clicking on Login. More information about how to contact us is provided below. If you have an online account with us, you also may terminate your account at any time by visiting the "Cancellation" page for your account. If you delete or disable your account, your personal and non- personal data will be deleted. In addition, if certain information has already been provided to third parties as described in this Privacy Policy, retention of that information will be subject to those third parties' policies.

Your choices about collection and use of your information
---------------------------------------------------------

You can choose not to provide us with certain information, but that may result in you being unable to use certain features of our website because such information may be required in order for you to register an account; purchase products or services; ask a question; or initiate other transactions on our website.

At any time you can choose to no longer receive commercial or promotional emails from us by submitting this request to privacy@findingfasteners.com. You may be given the opportunity, in any commercial email that we send to you, to opt out of receiving such messages in the future. We request a reasonable time to process an opt-out request. We may send you other types of transactional and relationship email communications, such as service announcements, administrative notices, and surveys, without offering you the opportunity to opt out of receiving them. Please note that changing information in your account, or otherwise opting out of receipt of promotional email communications, will only affect future activities or communications from us. If we have already provided your information to a third party (such as a service provider) before you have changed your preferences or updated your information, you may have to change your preferences directly with that third party.

You can also control information collected by cookies. See Cookies above for information about declining or deleting cookies.

How we protect your personal information
----------------------------------------

We take appropriate security measures (including physical, electronic and procedural measures) to help safeguard your personal information from unauthorized access and disclosure. We want you to feel confident using our website to transact business. However, no system can be completely secure. Therefore, although we take steps to secure your information, we do not promise, and you should not expect, that your personal information, searches, or other communications will always remain secure. Users should also take care with how they handle and disclose their personal information and should avoid sending personal information through insecure email. Please refer to the [Federal Trade Commission's website](http://www.ftc.gov/bcp/menus/consumer/data.shtm) for information about how to protect yourself against identity theft.

Visiting our Website from Outside the United States 
----------------------------------------------------

This Privacy Policy is intended to cover collection of information on our website or mobile application from residents of the United States. If you are visiting using our website from outside the United States, please be aware that your information may be transferred to, stored, and processed in the United States where our servers are located and our central database is operated. By using our services, you understand that your information may be transferred to our facilities and those third parties with whom we share it as described in this Privacy Policy.

General Data Protection for European Union Users

Finding Fasteners, LLC, will serve as the Data Controller and Data Processor. The contact information for Finding Fasteners, LLC is located in the Contact Section at the bottom of this Privacy Policy. Finding Fasteners, LLC's Data Protection Officer can be reached at the address in the Contact Section.

If we make any changes to this Privacy Policy, you will be notified via email of the changes. The personal data that we store and how we store it will always be included in our Privacy Policy. If your personal data is incomplete or incorrect, we will change it for you upon request. If you wish to have your private data deleted, we will delete it for you upon request. If you want to have some personal data blocked, we will do so upon request (just understand that Finding Fasteners may not operate without personal information). We will make your personal data available to you upon request. If you do not want your personal data used for marketing purposes, you may object by contacting the Data Privacy Officer in the Contact section at the bottom of this Privacy Policy. If we decide to take any action that subjects you to legal consequence, we will survey you prior to taking action.

Please note that our data does not leave the United States. 

The personal information we collect is necessary to utilize our services.

Disclosure to Successors
------------------------

If we are acquired by or merged with a third party entity, or if we sell Finding Fasteners, we reserve the right to transfer your Protected Information as part of such merger, acquisition, sale, or other change in control. In the unlikely event of our bankruptcy, insolvency, reorganization, receivership, or assignment for the benefit of creditors, or the application of laws or equitable principles affecting creditors' rights generally, we reserve the right to transfer your Protected Information to protect our rights or as required by law.
----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

No Rights of Third Parties
--------------------------

This Privacy Policy does not create rights enforceable by third parties or require disclosure of any personal information relating to users of our website or mobile application.

Changes to this Privacy Policy
------------------------------

We will occasionally update this Privacy Policy. When we post changes to this Privacy Policy, we will revise the "last updated" date at the top of this Privacy Policy. We recommend that you check our website or mobile application from time to time to inform yourself of any changes in this Privacy Policy or any of our other policies.

Contact

If you have any questions regarding this Privacy Policy, please contact the owner and operator of our website:

Finding Fasteners, LLC

Attn: Data Protection Officer

privacy@findingfasteners.com` 

return (<>
    <div className={generalStyles.terms}>
        <h1>Privacy Policy</h1>
        Here's what you need to know about using our product.
        <hr></hr>
        <ReactMarkdown>{text}
</ReactMarkdown>
    </div>
  </>);
}