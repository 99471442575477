import { useAuth0 } from "@auth0/auth0-react";
import { Card } from "react-bootstrap";
import { plans } from '../ChoosePlan'
import generalStyles from "../../styles/style.module.scss"

export type CompanyInfo = {
  industry: string | null,
  industryFocus: string | null,
  companyName: string | null,
  companyAddress: string | null,
  companyWebsite: string | null,
}

const rivianCompanyInfo: CompanyInfo = {
  industry: "Automotive",
  industryFocus: null,
  companyName: "Rivian",
  companyAddress: "15770 Laguna Canyon Rd #100, Irvine, CA 92618",
  companyWebsite: "https://rivian.com/",
}

export function SignupRivian() {

  const { loginWithRedirect } = useAuth0();

  const advancedPlan = plans[ plans.length - 1 ];
  localStorage.setItem('signUpPlan', JSON.stringify(advancedPlan));
  localStorage.setItem('signUpCompany', JSON.stringify(rivianCompanyInfo));

  loginWithRedirect({
    screen_hint: "signup",
    redirectUri: process.env.REACT_APP_AUTH0_SIGNUP_CALLBACK,
    appState: {
      plan: advancedPlan
    }
  })

  return <div className={generalStyles.baseContainer}>
    <div className={generalStyles.verify}>
      <Card.Body>
        Redirecting to {rivianCompanyInfo.companyName} account creation page...
      </Card.Body>
    </div>
  </div>
}