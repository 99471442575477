import { useEffect, useState } from "react";
import { useAuth0, User } from "@auth0/auth0-react";
import { Plan } from "../planTypes";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Button } from "../../Button";
import { useForm, SubmitHandler } from 'react-hook-form'
import { zodResolver } from "@hookform/resolvers/zod";
import logo from '../../images/__FF_Logo_Horiz_Reverse.png'
import AccountCreationSuccess from "./AccountCreationSuccess";
import { AreaOfInterestEnum, JobFocusEnum, MarketIndustryEnum, UserBasicFormSchema, UserBasicFormType, UserDemographicsFormSchema, UserDemographicsFormType, UserInfoSchemaType } from "./UserInfoSchema";
import styles from "../../styles/_accountCreation.module.scss"
import generalStyles from "../../styles/style.module.scss"

const formUuids: Record<"Student" | "Standard" | "Premium" | "Advanced", string> = {
  Student: 'f51121d6-2f39-4c9c-a7db-2bd479866d03',
  Standard: '82646c9e-fa78-418c-b64a-668607f14591',
  Premium: '08ad5286-516c-4483-a61b-55bdb9015b2f',
  Advanced: '08ad5286-516c-4483-a61b-55bdb9015b2f'
}

export const ChoosePlanModal = () => {
  const redirectDelay = 5000;
  const navigate = useNavigate();

  setTimeout(() => {
    navigate({
      pathname: '/ChoosePlan',
    });
  }, redirectDelay);

  return (
    <Modal show>
      <Modal.Header>Pick a Plan</Modal.Header>
      <Modal.Body>
        You must choose a plan for your account
        <br />
        <br />
        You will now be redirected to pick a plan.
      </Modal.Body>
    </Modal>
  );
};

function VerifyEmail() {
  const { getAccessTokenSilently, isLoading } = useAuth0();

  const updateToken = () => {
    getAccessTokenSilently({ ignoreCache: true });
    // todo: display "loading..." while refreshing token. If error, tell user to try again, or whatever.
  };

  return (
    <div className={styles.verify}>
      <p>You must verify your email to continue.</p>
      <p>
        {
          'Please check your email for a message from <support@findingfasteners.com>, and click the verification link.'
        }
      </p>
      <p>Once you have done so, click the button to proceed:</p>
      <Button onClick={updateToken}>Next</Button>
    </div>
  );
}



const UserBasicInfoForm = ({ auth0User, showNextForm, mergeNewUserInputData }: {
  auth0User: User | undefined
  showNextForm: () => void,
  mergeNewUserInputData: (newData: Partial<UserInfoSchemaType>) => void
}) => {
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<UserBasicFormType>({
    resolver: zodResolver(UserBasicFormSchema),
    mode: 'onTouched'
  });



  useEffect(() => {
    // Load user email from Auth0
    const userEmail = auth0User?.email
      ? auth0User.email
      : ""
    setValue('email', userEmail)
  }, [])

  const onSubmit: SubmitHandler<UserBasicFormType> = async (data) => {
    mergeNewUserInputData(data);
    showNextForm();
  };

  return <>
    <form
      className={styles.accountCreationForm}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={styles.fieldContainer}>
        <label>Email Address<span className={styles.requiredAsterisk}>*</span></label>
        <input
          type='text'
          {...register('email')}
          disabled={true}
        />
        {errors.email && <>
          <p className={styles.accountCreationFormFieldErrorMessage}>{errors.email.message}</p>
        </>}
      </div>
      <div className={styles.fieldContainer}>
        <label>First Name<span className={styles.requiredAsterisk}>*</span></label>
        <input
          type='text'
          {...register('firstName')}
          disabled={isSubmitting}
        />
        {errors.firstName && <>
          <p className={styles.accountCreationFormFieldErrorMessage}>{errors.firstName.message}</p>
        </>}
      </div>
      <div className={styles.fieldContainer}>
        <label>Last Name<span className={styles.requiredAsterisk}>*</span></label>
        <input
          type='text'
          {...register('lastName')}
          disabled={isSubmitting}
        />
        {errors.lastName && <>
          <p className={styles.accountCreationFormFieldErrorMessage}>{errors.lastName.message}</p>
        </>}
      </div>
      <div className={styles.fieldContainer}>
        <label>Company or University Name<span className={styles.requiredAsterisk}>*</span></label>
        <input
          type='text'
          {...register('companyName')}
          disabled={isSubmitting}
        />
        {errors.companyName && <>
          <p className={styles.accountCreationFormFieldErrorMessage}>{errors.companyName.message}</p>
        </>}
      </div>
      <div className={styles.fieldContainer}>
        <label>Phone Number<span className={styles.requiredAsterisk}>*</span></label>
        <input
          type='text'
          {...register('phoneNumber')}
          disabled={isSubmitting}
        />
        {errors.phoneNumber && <>
          <p className={styles.accountCreationFormFieldErrorMessage}>{errors.phoneNumber.message}</p>
        </>}
      </div>
      <Button
        type="submit"
        disabled={isSubmitting}
      >
        Next
      </Button>
    </form>
  </>
}

const UserDemographicsForm = ({ showNextForm, mergeNewUserInputData, auth0User }: {
  showNextForm: () => void
  mergeNewUserInputData: (newData: Partial<UserInfoSchemaType>) => void,
  auth0User: User | undefined
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UserDemographicsFormType>({
    resolver: zodResolver(UserDemographicsFormSchema),
    mode: 'onTouched'
  });

  const onSubmit: SubmitHandler<UserDemographicsFormType> = async (data) => {
    mergeNewUserInputData(data)
    showNextForm();
  };

  return <>
    <form
      className={styles.accountCreationForm}
      onSubmit={handleSubmit(onSubmit)}
    >
      <input
        className={styles.hiddenEmailField}
        type='email'
        defaultValue={auth0User?.email}
      />
      <div className={styles.fieldContainer}>
        <label>Job Title<span className={styles.requiredAsterisk}>*</span></label>
        <input
          type='text'
          {...register('jobTitle')}
          disabled={isSubmitting}
        />
        {errors.jobTitle && <>
          <p className={styles.accountCreationFormFieldErrorMessage}>{errors.jobTitle.message}</p>
        </>}
      </div>
      <div className={styles.fieldContainer}>
        <label>Market Industry<span className={styles.requiredAsterisk}>*</span></label>
        <select
          {...register('marketIndustry')}
          disabled={isSubmitting}
        >
          <option>Please Select...</option>
          {MarketIndustryEnum.options.map((industry) => (<option>{industry}</option>))}
        </select>
        {errors.marketIndustry && <>
          <p className={styles.accountCreationFormFieldErrorMessage}>{errors.marketIndustry.message}</p>
        </>}
      </div>
      <div className={styles.fieldContainer}>
        <label>Job Focus<span className={styles.requiredAsterisk}>*</span></label>
        <select
          {...register('jobFocus')}
          disabled={isSubmitting}
        >
          <option>Please Select...</option>
          {JobFocusEnum.options.map((jobFocus) => (<option>{jobFocus}</option>))}
        </select>
        {errors.jobFocus && <>
          <p className={styles.accountCreationFormFieldErrorMessage}>{errors.jobFocus.message}</p>
        </>}
      </div>
      <div className={styles.fieldContainer}>
        <label>Area of Interest<span className={styles.requiredAsterisk}>*</span></label>
        <select
          {...register('areaOfInterest')}
          disabled={isSubmitting}
        >
          <option>Please Select...</option>
          {AreaOfInterestEnum.options.map((area) => (<option>{area}</option>))}
        </select>
        {errors.areaOfInterest && <>
          <p className={styles.accountCreationFormFieldErrorMessage}>{errors.areaOfInterest.message}</p>
        </>}
      </div>
      <Button
        type="submit"
        disabled={isSubmitting}
      >
        Next
      </Button>
    </form>
  </>
}

const Account = () => {
  const { user: auth0User, isLoading, loginWithRedirect } = useAuth0();
  if (!auth0User && !isLoading) {
    loginWithRedirect({ redirectUri: 'http://localhost:3000/Account1' });
  }

  const plan = localStorage.getItem('signUpPlan');
  const planObj: Plan | undefined = (plan) ? JSON.parse(plan) : undefined;

  console.log("planObj", planObj)

  const [userInputData, setUserInputData] = useState<Partial<UserInfoSchemaType>>({});
  console.log("userInputData", userInputData)

  const mergeNewUserInputData = (newData: Partial<UserInfoSchemaType>) => {
    setUserInputData({
      ...userInputData,
      ...newData
    })
  }

  const [formComponentIdx, setFormComponentIdx] = useState<number>(0)
  const showNextForm = () => {
    setFormComponentIdx(formComponentIdx + 1)
  }

  const formComponents = [
    <UserBasicInfoForm
      auth0User={auth0User}
      showNextForm={showNextForm}
      mergeNewUserInputData={mergeNewUserInputData}
    />,
    <UserDemographicsForm
      auth0User={auth0User}
      showNextForm={showNextForm}
      mergeNewUserInputData={mergeNewUserInputData}
    />,
    <AccountCreationSuccess
      combinedUserInputData={userInputData}
      collectStripePaymentInfo={planObj?.name !== "Advanced"}
      planObj={planObj}
    />
  ]


  return (
    <div className={styles.accountCreationPageContainer}>
      <img className={generalStyles.logo} src={logo} />
      {!planObj && <ChoosePlanModal />}
      {!auth0User?.email_verified && <VerifyEmail />}
      {auth0User?.email_verified && formComponents[formComponentIdx]}
    </div>
  );

}


export default Account
