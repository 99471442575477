import { submitAuthedContactRequest } from "./FindIt/SubmitAuthedContactRequestProps";
import { ContactRequestType } from "./FindIt/ContactRequestType";
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap"
import Loading from "./Loading"
import env from '../env'
import styles from "../styles/style.module.scss"

export type ContactUsProps = {
  _requestType: ContactRequestType.ContactUs,
  comments: string
}

type ContactUsModalProps = {
  // isShowing: boolean
  hideModal: () => void;
  joinItState?: any,
  findItState?: any,
  selectedProduct?: any
}

export type SubmitAdvancedPlanContactRequestProps = {
  comments: string,
  teamMemberCount: number,
  email: string
}

export async function submitAdvancedPlanContactRequest(props: SubmitAdvancedPlanContactRequestProps) {

  const contactRequestUrl = `${env.REACT_APP_CF_WORKERS_URL}/advancedPlanContactRequest`;

  if (typeof props.teamMemberCount !== 'number') {
    console.error("teamMemberCount:", props.teamMemberCount)
    throw "Team member count is not number"
  }
  const resp = await fetch(contactRequestUrl, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(props)
  }).then(async (response) => {
    console.log("response:", response)
    console.log("response body", await response.json());
    return await response;
  }).catch((error) => {
    console.error(error);
  });

  if (resp && resp.status === 200) {
    return true;
  } else {
    return false
  }
}

export type SubmitUnauthedContactRequestProps = {
  comments: string,
  email: string
}

export async function submitUnauthedContactRequest(props: SubmitUnauthedContactRequestProps) {

  const contactRequestUrl = `${env.REACT_APP_CF_WORKERS_URL}/unauthedContactRequest`;

  const resp = await fetch(contactRequestUrl, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(props)
  }).then(async (response) => {
    console.log("response:", response)
    console.log("response body", await response.json());
    return await response;
  }).catch((error) => {
    console.error(error);
  });

  if (resp && resp.status === 200) {
    return true;
  } else {
    return false
  }
}

export function ContactUsModal(props: ContactUsModalProps) {
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const [comments, setComments] = useState<string>("");

  const [requestStatus, setRequestStatus] = useState<"loading" | undefined | boolean>()

  const [userEmail, setUserEmail] = useState<string | undefined>(
    user?.email
      ? user?.email
      : undefined
  )

  const handleSubmit = async () => {
    let result: boolean;
    switch (isAuthenticated) {
      case true:
        const accessToken = await getAccessTokenSilently();
        setRequestStatus("loading")
        result = await submitAuthedContactRequest({
          _requestType: ContactRequestType.ContactUs,
          comments: comments,
        }, accessToken)
        break;
      case false:
        const email = userEmail === undefined ? "" : userEmail;
        setRequestStatus("loading")
        result = await submitUnauthedContactRequest({
          comments: comments,
          email: email
        })
        break;
    }
    setRequestStatus(result);
  }

  return <>
    <div>
      <h3>Send us a message</h3>
      <Form>
        {"sending from: "}
        {user?.email
          ? <>
            {user.email}
          </>
          : <>
            <Form.Control
              as="input"
              placeholder="me@company.com"
              value={userEmail}
              onChange={e => setUserEmail(e.target.value)}
              required
            />
          </>
        }
        <br />
        <Form.Control
          as="textarea"
          placeholder="Enter any supporting information pertaining to your request"
          value={comments}
          onChange={e => setComments(e.target.value)}
        />
        <br />
      </Form>
      <Modal.Footer>
        {requestStatus === false && <>
          Error submitting request
        </>}
        {!requestStatus && <>
          <Button variant="primary" disabled={!requestStatus === undefined} onClick={handleSubmit}>
            Submit
          </Button>
        </>}
        {requestStatus === "loading" &&
          <Loading />
        }
        {requestStatus === true && <>
          <h5>Request submitted!</h5>
        </>}
      </Modal.Footer>
    </div>
  </>
}

const Contact = () => (
  <>
    <div className={styles.baseContainer}>
      <div className={styles.contactHeader}>
        <h1>Contact Us</h1>
      </div>
      <div className={styles.contactContainer}>
        <h3>Drop us a line. We'd love to hear from you!</h3>
        <div className={styles.contactInfo}>


          <div className={styles.contactInfoHeading}>Email:</div>
          <div>contact@findingengineeredsolutions.com</div>
          <div className={styles.contactInfoHeading}>Phone:</div>
          <div>(281) 297-8813</div>
          <div className={styles.contactInfoHeading}>Address:</div>
          <div>
            <div>P.O. Box 8195</div>
            <div>Spring, TX 77380</div>
          </div>
        </div>
        <ContactUsModal
          hideModal={() => { return; }}
        />
      </div>
    </div>
  </>
)

export default Contact