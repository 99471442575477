import { Button } from '../../Button';
import Select, { ReactSelectOption, ReactSelectOptions } from '../utilities/FixRequiredSelect';

import { ContactRequestType } from "../FindIt/ContactRequestType";
import { useState } from "react";
import { OptionTypeBase } from "react-select";
import { ReactSelectOptionWithTooltip } from "../utilities/ReactSelectOptionWithTooltip";
import { RequiredFieldIndicator } from "../utilities/RequiredFieldIndicator";
import { RecommenderState } from './FastenerRecommender';
import { submitAuthedContactRequest } from '../FindIt/SubmitAuthedContactRequestProps';
import { isRequestPrintProps, isRequestQuoteProps, isSaveSolutionProps, isTalkToExpertProps, isRequestSampleProps } from "../FindIt/contactRequestTypes";
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../Loading';

import styles from "../../styles/_findIt.module.scss"


export type FindingItSolutionContactProps = {
  recommenderState: RecommenderState,
}

export function FindingItSolutionContact(props: FindingItSolutionContactProps) {
  const selectOptions: ReactSelectOptions<ContactRequestType, string> = [
    {
      label: "Email Solution",
      tooltipText: "Send yourself an email with this solution.",
      value: ContactRequestType.SaveSolution
    },
    {
      label: "Request a Print",
      tooltipText: "Request a print of this fastener from our team of experts.",
      value: ContactRequestType.RequestForPrint
    },
    {
      label: "Request a Quote",
      tooltipText: "Request a quote for this fastener.",
      value: ContactRequestType.RequestForQuote
    },
    {
      label: "Request a Sample",
      tooltipText: "Request a sample of this fastener. Our team will reach out to arrange delivery.",
      value: ContactRequestType.RequestForSample
    },
    {
      label: "Talk to an Expert",
      tooltipText: "Discuss this solution with our team of experts.",
      value: ContactRequestType.TalkToExpert
    },
  ]

  const { getAccessTokenSilently } = useAuth0();

  const [contactType, setContactType] = useState<ReactSelectOption<ContactRequestType, string> | null>(selectOptions[0])
  const handleContactTypeChange = (newContactType: OptionTypeBase | null) => {
    // console.log("changing contact type to ", newContactType)
    setContactType(newContactType as ReactSelectOption<ContactRequestType, string>)
  }

  const [submitState, setSubmitState] = useState<'presubmit' | 'loading' | 'success' | 'error'>('presubmit');
  const disableSubmission = (submitState == 'loading' || submitState == 'success')

  const [solutionName, setSolutionName] = useState<string | undefined>();
  const [projectName, setProjectName] = useState<string | undefined>();
  const [annualVolume, setAnnualVolume] = useState<number | undefined>();
  const [quantity, setQuantity] = useState<number | undefined>();
  const [comments, setComments] = useState<string | undefined>("");
  const [notes, setNotes] = useState<string | undefined>("");



  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitState('loading')

    const accessToken = await getAccessTokenSilently();

    const requestProps = {
      _requestType: contactType?.value,
      findItState: props.recommenderState.selectedPartParameters,
      joinItState: props.recommenderState.jointParameters,
      solutionName: solutionName,
      notes: notes,
      projectName: projectName,
      comments: comments,
      quantity: quantity,
      annualVolume: annualVolume
    };

    if (
      isSaveSolutionProps(requestProps)
      || isRequestPrintProps(requestProps)
      || isRequestQuoteProps(requestProps)
      || isRequestSampleProps(requestProps)
      || isTalkToExpertProps(requestProps)
    ) {
      const result = await submitAuthedContactRequest(requestProps, accessToken);
      if (result) {
        setSubmitState('success')
      } else {
        setSubmitState('error')
      }
    } else {
      setSubmitState('error')
      throw "Did not recognize contact request type:" + JSON.stringify(requestProps, null, 2);
    }
  }



  return (<>
    <div className={styles.findItSolutionContact}>
      <form onSubmit={handleSubmit} >
        <div className={styles.findItSolutionContactFields}>
          <h2 className={`${styles.smallH2} ${styles.blueH2}`}>Finalize and Save Your Solution</h2>
          <p className={styles.solutionContactEmailText}>
            Here’s where you can request a quote, sample, expert, or save the solutions for later. 
          </p>
          <Select
            isDisabled={ disableSubmission }
            options={selectOptions}
            defaultValue={selectOptions[0]}
            value={contactType}
            onChange={handleContactTypeChange}
            // @ts-ignore This works fine. Typescript finds no errors when in same file as custom Option definition
            components={{ Option: ReactSelectOptionWithTooltip }}
          />


          <label>Name Your Solution <RequiredFieldIndicator /></label>
          <input
            type="text"
            required
            disabled={ disableSubmission }
            placeholder="EG, “chassis default bolt”"
            value={solutionName}
            onChange={(e) => { setSolutionName(e.target.value) }}
          />

          {/* Display Project Name field for appropriate contact types */}
          {contactType && [
            ContactRequestType.RequestForPrint,
            ContactRequestType.RequestForQuote,
            ContactRequestType.RequestForSample,
            ContactRequestType.TalkToExpert
          ].includes(contactType.value) &&
            <>
              <label>Project Name <RequiredFieldIndicator /></label>
              <input
                type="text"
                required
                disabled={ disableSubmission }
                placeholder="EG, “2011 Curiosity Mars Rover — Model A”"
                value={projectName}
                onChange={(e) => { setProjectName(e.target.value) }}
              />
            </>
          }

          {(contactType?.value === ContactRequestType.RequestForQuote) && <>
            <label>Annual Volume <RequiredFieldIndicator /></label>
            <input
              type="text"
              required
              disabled={ disableSubmission }
              inputMode="numeric"
              min={1}
              placeholder="EG, 10,000"
              value={annualVolume}
              onChange={(e) => { setAnnualVolume(Number(e.target.value)) }}
            />
          </>}

          {(contactType?.value === ContactRequestType.RequestForSample) && <>
            <label>Quantity <RequiredFieldIndicator /></label>
            <input
              type="text"
              required
              disabled={ disableSubmission }
              inputMode="numeric"
              min={1}
              placeholder="EG, 10"
              value={quantity}
              onChange={(e) => { setQuantity(Number(e.target.value)) }}
            />
          </>}

          {contactType && [
            ContactRequestType.RequestForPrint,
            ContactRequestType.RequestForQuote,
            ContactRequestType.RequestForSample,
            ContactRequestType.TalkToExpert
          ].includes(contactType.value) &&
          <>
            <label>Comments & Questions</label>
            <textarea
              placeholder="Enter any comments or questions you may have for experts reviewing this fastener request."
              value={comments}
              disabled={ disableSubmission }
              onChange={(e) => { setComments(e.target.value) }}
            />
          </>
          }

        <label>Notes</label>
        <textarea
          placeholder="Enter any notes you want to save for personal use."
          value={notes}
          disabled={ disableSubmission }
          onChange={(e) => { setNotes(e.target.value) }}
        />
        </div>
        { !disableSubmission &&
          <Button
            type="submit"
            disabled={ disableSubmission }
            backgroundColor={{background: '#3c649b'}}
            border={{border: 'solid 6px #0a6b7c'}}
          >
            Submit
          </Button>
        }
        { submitState === 'loading' &&
          <Loading />
        }
        { submitState === 'error' &&
          <p>
            An error has occurred. Try again in 60 seconds; if this error persists, please contact us.
          </p>
        }
        { submitState === 'success' &&
          <p>
            Your request has been received. Check your email for a summary of your request.
          </p>
        }
      </form>
    </div>
  </>)
}