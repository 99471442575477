import { ContactRequestType } from './ContactRequestType';


export type SaveSolutionProps = {
  _requestType: ContactRequestType.SaveSolution;
  solutionName: string;
  notes: string;
  findItState: any;
  joinItState?: any;
  selectedProduct?: any;
};

export function isSaveSolutionProps(a: any): a is SaveSolutionProps {
  return (
    a.hasOwnProperty('_requestType') && a._requestType === ContactRequestType.SaveSolution
    && a.hasOwnProperty('solutionName') && typeof a.solutionName === 'string'
    && a.hasOwnProperty('notes') && typeof a.notes === 'string'
    && a.hasOwnProperty('findItState') && typeof a.findItState === 'object'
  );
}

export type RequestPrintProps = {
  _requestType: ContactRequestType.RequestForPrint;
  solutionName: string;
  notes: string;
  projectName: string;
  comments: string;
  findItState: any;
  joinItState?: any;
};

export function isRequestPrintProps(a: any): a is RequestPrintProps {
  return (
    a.hasOwnProperty('_requestType') && a._requestType === ContactRequestType.RequestForPrint
    && a.hasOwnProperty('solutionName') && typeof a.solutionName === 'string'
    && a.hasOwnProperty('notes') && typeof a.notes === 'string'
    && a.hasOwnProperty('projectName') && typeof a.projectName === 'string'
    && a.hasOwnProperty('comments') && typeof a.comments === 'string'
    && a.hasOwnProperty('findItState') && typeof a.findItState === 'object'
  );
}

export type RequestQuoteProps = {
  _requestType: ContactRequestType.RequestForQuote;
  solutionName: string;
  notes: string;
  projectName: string;
  comments: string;
  annualVolume: number;
  findItState: any;
  joinItState?: any;
};

export function isRequestQuoteProps(a: any): a is RequestQuoteProps {
  return (
    a.hasOwnProperty('_requestType') && a._requestType === ContactRequestType.RequestForQuote
    && a.hasOwnProperty('solutionName') && typeof a.solutionName === 'string'
    && a.hasOwnProperty('notes') && typeof a.notes === 'string'
    && a.hasOwnProperty('projectName') && typeof a.projectName === 'string'
    && a.hasOwnProperty('comments') && typeof a.comments === 'string'
    && a.hasOwnProperty('annualVolume') && typeof a.annualVolume === 'number'
    && a.hasOwnProperty('findItState') && typeof a.findItState === 'object'
  );
}

export type RequestSampleProps = {
  _requestType: ContactRequestType.RequestForSample;
  solutionName: string;
  notes: string;
  projectName: string;
  comments: string;
  quantity: number;
  findItState: any;
  joinItState?: any;
};

export function isRequestSampleProps(a: any): a is RequestSampleProps {
  return (
    a.hasOwnProperty('_requestType') && a._requestType === ContactRequestType.RequestForSample
    && a.hasOwnProperty('solutionName') && typeof a.solutionName === 'string'
    && a.hasOwnProperty('notes') && typeof a.notes === 'string'
    && a.hasOwnProperty('projectName') && typeof a.projectName === 'string'
    && a.hasOwnProperty('comments') && typeof a.comments === 'string'
    && a.hasOwnProperty('quantity') && typeof a.quantity === 'number'
    && a.hasOwnProperty('findItState') && typeof a.findItState === 'object'
  );
}

export type TalkToExpertProps = {
  _requestType: ContactRequestType.TalkToExpert;
  solutionName: string;
  notes: string;
  projectName: string;
  comments: string;
  findItState: any;
  joinItState?: any;
};

export function isTalkToExpertProps(a: any): a is TalkToExpertProps {
  return (
    a.hasOwnProperty('_requestType') && a._requestType === ContactRequestType.TalkToExpert
    && a.hasOwnProperty('solutionName') && typeof a.solutionName === 'string'
    && a.hasOwnProperty('notes') && typeof a.notes === 'string'
    && a.hasOwnProperty('projectName') && typeof a.projectName === 'string'
    && a.hasOwnProperty('comments') && typeof a.comments === 'string'
    && a.hasOwnProperty('findItState') && typeof a.findItState === 'object'
  );
}
