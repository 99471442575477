import { Diameter, DimensionedQuantity, isDiameter, isDimensionedQuantity, SelectedRivetNutParameters, SelectedRivetParameters, SelectedThreadedParameters } from '../FindIt/findItTypes';
import { SelectedPartParameters } from "./FastenerRecommender";
import Select, { ReactSelectOption, ReactSelectOptions } from '../utilities/FixRequiredSelect';
import { OptionsType, OptionTypeBase } from 'react-select';
import { useEffect, useState } from 'react';
import { ReactSelectOptionWithTooltip } from '../utilities/ReactSelectOptionWithTooltip';
import {
  Tooltip as BootstrapTooltip,
  OverlayTrigger
} from 'react-bootstrap';

import styles from "../../styles/_findIt.module.scss"



type FieldNames =
  | keyof SelectedThreadedParameters
  | keyof SelectedRivetParameters
  | keyof SelectedRivetNutParameters

type FieldSelectorMultipleChoiceProps = {
  selectionOptions: ReactSelectOptions<any, string>;
  required: boolean;
  fieldName: FieldNames;
  fieldTitle: string;
  parentField?: {
    fieldName: FieldNames,
    fieldTitle: string
  }
  icon?: {
    src: string,
    altText: string,
    position: 'left' | 'right' | 'center'
  }
  tooltipText?: string,
  // wrapperClassName?: string;
  childClassName: string;
  // placeholder?: string,
  // isDisabled?: boolean,
  selectedPartParameters: SelectedPartParameters;
  setSelectedPartParameters: (newPartParameters: SelectedPartParameters) => void;
};

export function FieldSelectorMultipleChoice(props: FieldSelectorMultipleChoiceProps) {

  const [selection, setSelection] = useState<OptionsType<OptionTypeBase> | null>(null)

  const handleSelectionChange = (newSelection: OptionTypeBase | OptionsType<OptionTypeBase> | null) => {
    if (!(newSelection === null || Array.isArray(newSelection))) {
      throw new Error("newSelection is not array:" + JSON.stringify(newSelection, null, 2))
    }
    console.log("newSelection", newSelection)
    setSelection(newSelection);
    const newSelectedValue = (newSelection === null)
      ? undefined
      : newSelection.reduce((newSelectedValueObj, currentValue: ReactSelectOption<string, string>) => {
        return {
          ...newSelectedValueObj,
          [currentValue.value]: true
        }
      }, {})
    const newSelectedPartParameters: SelectedPartParameters = {
      ...props.selectedPartParameters,
      // fastenerName: props.selectedPartParameters.fastenerName,
      [props.fieldName]: newSelectedValue
    };
    props.setSelectedPartParameters(newSelectedPartParameters);
  };

  const parentFieldName = props.parentField?.fieldName




  useEffect(() => {
    // @ts-ignore
    const currentSelection: any = props.selectedPartParameters[props.fieldName];
    const allowedSelections = props.selectionOptions.map(option => option.value)

    let selectionIsValid: boolean
    if (currentSelection === undefined) {
      selectionIsValid = false;
    } else if (typeof currentSelection === 'string') {
      selectionIsValid = (allowedSelections.indexOf(currentSelection) !== -1);
    } else if (isDimensionedQuantity(currentSelection)) {
      const allowedSelectionValues = (allowedSelections as DimensionedQuantity<any>[])
        .map(selection => selection.value);
      selectionIsValid = (allowedSelectionValues.indexOf(currentSelection.value) !== -1)
    } else if (isDiameter(currentSelection)) {
      const allowedSelectionValues = (allowedSelections as Diameter<any>[])
        .map(selection => selection.distance.value);
      selectionIsValid = (allowedSelectionValues.indexOf(currentSelection.distance.value) !== -1)
    } else {
      throw `Unrecognized selection type: ${typeof currentSelection} ${JSON.stringify(currentSelection)}`
    }

    if (!selectionIsValid) {
      handleSelectionChange(null);
    }
  },
    parentFieldName
      // @ts-ignore
      ? [props.selectedPartParameters[parentFieldName]]
      : []
  )

  // This should make the preloaded diameter selection visible, but it isn't working for some reason
  // // @ts-ignore
  // const currentSelection: any = props.selectedPartParameters[props.fieldName];
  // // const defaultValue = props.fastenerNameOptions.find((currentOption) => (currentOption.value === currentSelection))
  // // const defaultValue = null
  // const defaultValue = (currentSelection && isDiameter(currentSelection))
  //   ? props.selectionOptions.find((testOption) => testOption.value.distance.value == currentSelection.distance.value)
  //   : null
  // if (props.fieldName === 'diameter') {
  //   console.log("currentSelection", currentSelection)
  //   console.log("defaultValue", defaultValue)
  // }

  let placeholder: string | undefined = undefined;
  let isDisabled: boolean | undefined = undefined;
  if (
    props.parentField?.fieldName
    // @ts-ignore
    && (props.selectedPartParameters[props.parentField.fieldName] === undefined)
  ) {
    placeholder = `Pick ${props.parentField.fieldTitle} first`;
    isDisabled = true;
  }

  // const wrapperClassNameBase = `${props.wrapperClassName}`
  const wrapperClassName = (props.icon === undefined)
    ? ''
    : (props.icon.position === 'left')
      ? " findingIt-field-container-with-icon findingIt-field-container-with-icon-left"
      : " findingIt-field-container-with-icon findingIt-field-container-with-icon-right"

  const content = <div className={wrapperClassName}>
    {props.icon &&
      // We can't find fieldIconLeft; does it even exist?
        // <img src={props.icon.src} alt={props.icon.altText} className={`${styles.fieldIcon} ${styles.fieldIconLeft}`} />
        <img src={props.icon.src} alt={props.icon.altText} className={styles.fieldIcon} />
    }
    <div className={styles.findingItFieldContent}>
      <h2 className={`${styles.smallH2} ${styles.blueH2}`}>{props.fieldTitle}</h2>
      <div className={props.childClassName}>
        <Select
          options={props.selectionOptions}
          // @ts-ignore This works fine. Typescript finds no errors when in same file as custom Option definition
          components={{ Option: ReactSelectOptionWithTooltip }}
          onChange={handleSelectionChange}
          value={selection}
          isMulti={true}
          required={props.required}
          isClearable={true}
          placeholder={placeholder}
          isDisabled={isDisabled}
        // defaultValue={defaultValue}
        />
      </div>
    </div>
  </div>

  if (props.tooltipText === undefined) {
    return content
  }

  return <>
    <OverlayTrigger
      placement={"top-start"}
      delay={{ show: 0, hide: 200 }}
      overlay={<BootstrapTooltip id="button-tooltip">
        {props.tooltipText}
      </BootstrapTooltip>}
    >
      {content}
    </OverlayTrigger>
  </>;
}
