import { UnitSystem } from '../FindIt/findItTypes';
import { Form } from 'react-bootstrap';
import Switch from 'react-switch';
import styles from "../../styles/_torqueCalculator.module.scss"

type TorqueUnitSystemToggleProps = {
  currentUnitSystem: UnitSystem;
  setUnitSystem: (newUnitSystem: UnitSystem) => void;
  disableToggle?: true
};
export function TorqueUnitSystemToggle(props: TorqueUnitSystemToggleProps) {
  const handleToggleCurrentUnitSystem = () => {
    const newUnitSystem = (props.currentUnitSystem === UnitSystem.Imperial)
      ? UnitSystem.Metric
      : UnitSystem.Imperial;
    props.setUnitSystem(newUnitSystem);
  };

  return (
    <>
      <h2 className={styles.torqueUnitHeader}>Unit System</h2>
      <Form.Text className={styles.torqueUnitImperial}>Imperial</Form.Text>
      <Switch
        className={styles.torqueUnitSwitch}
        disabled={props.disableToggle}
        checked={props.currentUnitSystem === UnitSystem.Metric}
        onChange={handleToggleCurrentUnitSystem}
        checkedIcon={false}
        uncheckedIcon={false}
        onColor={'#327C9B'}
        offColor={'#327C9B'} />
      <Form.Text className={styles.torqueUnitMetric}>Metric</Form.Text>
   </>
  );
}
