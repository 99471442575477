import {  useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop(){
    const {pathname} = useLocation();

    useEffect(() => {
        //If scroll to top runs into issues it may be because it's running before DOM is loaded//
        const scrollToTop = document.querySelector('#root')
        scrollToTop?.scrollTo(0,0)
    }, [pathname]);

    return null
}