/*type fastener =
| Foo GenericThreaded
| Bar (GenericThreaded & {…})
| Baz (Generic Rivet)


GenericThreaded = Lengith & Diameter

function renderDiameter(d: Diameter): JSX.Eleemnt {
  return <input name=min>
}

.machine-screw-inputs .diameter {
  // Location data for machine screw diameter fields
}
*/
import { SelectedPartParameters } from "../FastenerRecommender/FastenerRecommender"
import { ReactSelectOptions } from "../utilities/FixRequiredSelect"

export enum UnitSystem {
  Imperial = 'imperial',
  Metric = 'metric'
}

export enum UnitsDistance {
  Millimeter = "mm",
  Inch = "in",
}

export enum UnitsPitch {
  ThreadsPerInch = "threads/in",
  MillimetersPerThread = "mm/thread"
}

export enum UnitsForce {
  PSI = "psi",
  Lbf = "Lbf",
  Newton = "N",
  Kilonewton = "kN"
}

export enum UnitsTorque {
  NewtonMeter = "N m",
  MegaPascal = "MPa", // Is this wrong? This seems wrong.
  FootLbF = "ft Lbf",
  Lbf = "Lbf",
  PSI = "psi",
  FootPoundForce = "ft⋅lbf"
}

export enum UnitsTensileStrength {
  MegaPascal = "MPa",
  PSI = "psi",
}

export enum UnitsPressure {
  PSI = "psi",
  MegaPascal = "MPa"
}

export enum UnitsCorrosionResistance {
  HoursToRedRust = 'hrs to rust'
}

export enum FastenerTypes {
  threaded = 'threaded',
  rivet = 'rivet',
  rivetNut = 'rivet nut'
}

export enum FastenerNames {
  // --- Threaded ---
  Bolt = "Bolt",
  TFormingScrewPlastic = "Thread Forming Screw – Plastic",
  TFormingScrewMetal = "Thread Forming Screw – Metal",
  MachineScrew = "Machine Screw",
  // --- Rivets ---
  OpenEnd = "Open End Rivet",                    // Per Tim, this is a type of nail rivet
  ClosedEnd = "Closed End Rivet",                // Per Tim, this is a type of nail rivet
  Crimped = "Crimped Rivet",                     // Per Tim, this is a type of nail rivet
  Rolled = "Rolled Rivet",                       // Per Tim, this is a type of nail rivet
  Bulbex = "Bulbex Rivet",
  NSKTR = "Non-Structural Klamp-Tite Rivet",
  MonoboltInterlock = "Monobolt/Interlock",
  Bulbing = "Bulbing Rivet",                     // Per Tim, this is a type of nail rivet
  StructuralKTR = "Structural Klamp-Tite Rivet",
  Solid = "Solid Rivet",
  SemiTubular = "Semi-Tubular Rivet",
  Tubular = "Tubular Rivet",
  SelfPierce = "Self-Pierce Rivet",
  // --- Rivet Nuts ---
  OpenEndRivetNut = "Open End Rivet Nut",
  ClosedEndRivetNut = "Closed End Rivet Nut",
}

export enum ThreadedFastenerNames {
  Bolt = FastenerNames.Bolt,
  TFormingScrewPlastic = FastenerNames.TFormingScrewPlastic,
  TFormingScrewMetal = FastenerNames.TFormingScrewMetal,
  MachineScrew = FastenerNames.MachineScrew
}

export enum RivetFastenerNames {
  OpenEnd = FastenerNames.OpenEnd,
  ClosedEnd = FastenerNames.ClosedEnd,
  Crimped = FastenerNames.Crimped,
  Rolled = FastenerNames.Rolled,
  Bulbex = FastenerNames.Bulbex,
  NSKTR = FastenerNames.NSKTR,
  MonoboltInterlock = FastenerNames.MonoboltInterlock,
  Bulbing = FastenerNames.Bulbing,
  StructuralKTR = FastenerNames.StructuralKTR,
  Solid = FastenerNames.Solid,
  SemiTubular = FastenerNames.SemiTubular,
  Tubular = FastenerNames.Tubular,
  SelfPierce = FastenerNames.SelfPierce,
}

export enum RivetNutFastenerNames {
  OpenEndRivetNut = FastenerNames.OpenEndRivetNut,
  ClosedEndRivetNut = FastenerNames.ClosedEndRivetNut,
}

export enum MaterialNames {
  Aluminum = "Aluminum",
  Brass = "Brass",
  Monel = "Monel",
  Nylon = "Nylon",
  Stainless_Steel = "Stainless Steel",
  Steel__Grade_5_ = "Steel (Grade 5)",
  Steel__Grade_8_ = "Steel (Grade 8)",
  Steel__Class_8_8_ = "Steel (Class 8.8)",
  Steel__Class_10_9_ = "Steel (Class 10.9)",
  Titanium = "Titanium",
  Steel__Ungraded_ = "Steel (Ungraded)",
  Copper = "Copper",
  Steel_Med_Carbon = "Steel Med Carbon",
  Steel__37MnB2_ = "Steel (37MnB2)"
}

export enum FinishNames {
  Anodized = "Anodized",
  Armor_Coat = "Armor Coat",
  Chemical_Black_Oxide = "Chemical Black Oxide",
  Cadmium = "Cadmium",
  Chrome_Plated = "Chrome Plated",
  Thermal_Black_Oxide = "Thermal Black Oxide",
  Geomet = "Geomet",
  Zinc_Galvanized = "Zinc Galvanized",
  Hot_Dipped_Galvanized = "Hot Dipped Galvanized",
  NL_19_SM_ = "NL-19(SM)",
  Plain = "Plain",
  Passivated = "Passivated",
  Zinc___Clear_Trivalent = "Zinc & Clear Trivalent",
  Zinc___Yellow_Trivalent = "Zinc & Yellow Trivalent",
  Zinc_Nickel = "Zinc Nickel",
  Magni_565 = "Magni 565"
}

type FinishSaltSprayRating = Record<
  FinishNames,
  | undefined
  | { saltSprayRating: DimensionedQuantity<UnitsCorrosionResistance> | DimensionedQuantityRange<UnitsCorrosionResistance> }
>

export const threadedFinishSaltSprayRatings: FinishSaltSprayRating = {
  [FinishNames.Anodized]: undefined,
  [FinishNames.Armor_Coat]: undefined,
  [FinishNames.Chemical_Black_Oxide]: {
    saltSprayRating: {
      minValue: 24,
      maxValue: 96,
      unit: UnitsCorrosionResistance.HoursToRedRust
    }
  },
  [FinishNames.Cadmium]: {
    saltSprayRating: {
      value: 100,
      unit: UnitsCorrosionResistance.HoursToRedRust
    }
  },
  [FinishNames.Chrome_Plated]: {
    saltSprayRating: {
      minValue: 50,
      maxValue: 150,
      unit: UnitsCorrosionResistance.HoursToRedRust
    }
  },
  [FinishNames.Thermal_Black_Oxide]: {
    saltSprayRating: {
      minValue: 24,
      maxValue: 96,
      unit: UnitsCorrosionResistance.HoursToRedRust
    }
  },
  [FinishNames.Geomet]: {
    saltSprayRating: {
      minValue: 500,
      maxValue: 1000,
      unit: UnitsCorrosionResistance.HoursToRedRust
    }
  },
  [FinishNames.Zinc_Galvanized]: {
    saltSprayRating: {
      value: 168,
      unit: UnitsCorrosionResistance.HoursToRedRust
    }
  },
  [FinishNames.Hot_Dipped_Galvanized]: {
    saltSprayRating: {
      value: 205,
      unit: UnitsCorrosionResistance.HoursToRedRust
    }
  },
  [FinishNames.NL_19_SM_]: undefined,
  [FinishNames.Plain]: undefined,
  [FinishNames.Passivated]: {
    saltSprayRating: {
      value: 120,
      unit: UnitsCorrosionResistance.HoursToRedRust
    }
  },
  [FinishNames.Zinc___Clear_Trivalent]: {
    saltSprayRating: {
      value: 96,
      unit: UnitsCorrosionResistance.HoursToRedRust
    }
  },
  [FinishNames.Zinc___Yellow_Trivalent]: {
    saltSprayRating: {
      value: 96,
      unit: UnitsCorrosionResistance.HoursToRedRust
    }
  },
  [FinishNames.Zinc_Nickel]: {
    saltSprayRating: {
      value: 1000,
      unit: UnitsCorrosionResistance.HoursToRedRust
    }
  },
  [FinishNames.Magni_565]: {
    saltSprayRating: {
      minValue: 720,
      maxValue: 1000,
      unit: UnitsCorrosionResistance.HoursToRedRust
    }
  },
}

export enum ThreadedHeadTypes {
  Socket_Head_Cap = "Socket Head Cap",
  Button_Head = "Button Head",
  Flanged_Button_Head = "Flanged Button Head",
  Flat_Countersunk_Head__82_ = "Flat Countersunk Head (82)",
  Flat_Countersunk_Head__90_ = "Flat Countersunk Head (90)",
  Pan_Head = "Pan Head",
  Truss_Head = "Truss Head",
  Hex_Head = "Hex Head",
  Hex_Washer_Head = "Hex Washer Head",
  Hex_Flange_Head = "Hex Flange Head",
  Oval_Head = "Oval Head",
}

export enum ThreadedDriveTypes {
  Hex_External = "Hex External",
  Hex_Internal = "Hex Internal",
  Torx = "Torx",
  Torx_Plus = "Torx Plus",
  Phillips = "Phillips",
  Square = "Square",
}

export const driveTypebyHeadList = {
  [ThreadedHeadTypes.Socket_Head_Cap]: {
    [ThreadedDriveTypes.Hex_Internal]: true,
    [ThreadedDriveTypes.Torx]: true,
    [ThreadedDriveTypes.Torx_Plus]: true,
    [ThreadedDriveTypes.Square]: true
  },
  [ThreadedHeadTypes.Button_Head]: {
    [ThreadedDriveTypes.Hex_Internal]: true,
    [ThreadedDriveTypes.Torx]: true,
    [ThreadedDriveTypes.Torx_Plus]: true,
    [ThreadedDriveTypes.Phillips]: true,
    [ThreadedDriveTypes.Square]: true
  },
  [ThreadedHeadTypes.Flanged_Button_Head]: {
    [ThreadedDriveTypes.Hex_Internal]: true,
    [ThreadedDriveTypes.Torx]: true,
    [ThreadedDriveTypes.Torx_Plus]: true,
    [ThreadedDriveTypes.Phillips]: true,
    [ThreadedDriveTypes.Square]: true
  },
  [ThreadedHeadTypes.Flat_Countersunk_Head__82_]: {
    [ThreadedDriveTypes.Hex_Internal]: true,
    [ThreadedDriveTypes.Torx]: true,
    [ThreadedDriveTypes.Torx_Plus]: true,
    [ThreadedDriveTypes.Phillips]: true,
    [ThreadedDriveTypes.Square]: true
  },
  [ThreadedHeadTypes.Flat_Countersunk_Head__90_]: {
    [ThreadedDriveTypes.Hex_Internal]: true,
    [ThreadedDriveTypes.Torx]: true,
    [ThreadedDriveTypes.Torx_Plus]: true,
    [ThreadedDriveTypes.Phillips]: true,
    [ThreadedDriveTypes.Square]: true
  },
  [ThreadedHeadTypes.Pan_Head]: {
    [ThreadedDriveTypes.Hex_Internal]: true,
    [ThreadedDriveTypes.Torx]: true,
    [ThreadedDriveTypes.Torx_Plus]: true,
    [ThreadedDriveTypes.Phillips]: true,
    [ThreadedDriveTypes.Square]: true
  },
  [ThreadedHeadTypes.Truss_Head]: {
    [ThreadedDriveTypes.Hex_Internal]: true,
    [ThreadedDriveTypes.Torx]: true,
    [ThreadedDriveTypes.Torx_Plus]: true,
    [ThreadedDriveTypes.Phillips]: true,
    [ThreadedDriveTypes.Square]: true
  },
  [ThreadedHeadTypes.Hex_Head]: {
    [ThreadedDriveTypes.Hex_External]: true,
    [ThreadedDriveTypes.Hex_Internal]: true,
    [ThreadedDriveTypes.Torx]: true,
    [ThreadedDriveTypes.Torx_Plus]: true,
    [ThreadedDriveTypes.Phillips]: true,
    [ThreadedDriveTypes.Square]: true
  },
  [ThreadedHeadTypes.Hex_Washer_Head]: {
    [ThreadedDriveTypes.Hex_External]: true,
    [ThreadedDriveTypes.Hex_Internal]: true,
    [ThreadedDriveTypes.Torx]: true,
    [ThreadedDriveTypes.Torx_Plus]: true,
    [ThreadedDriveTypes.Phillips]: true,
    [ThreadedDriveTypes.Square]: true
  },
  [ThreadedHeadTypes.Hex_Flange_Head]: {
    [ThreadedDriveTypes.Hex_External]: true,
    [ThreadedDriveTypes.Hex_Internal]: true,
    [ThreadedDriveTypes.Torx]: true,
    [ThreadedDriveTypes.Torx_Plus]: true,
    [ThreadedDriveTypes.Phillips]: true,
    [ThreadedDriveTypes.Square]: true
  },
  [ThreadedHeadTypes.Oval_Head]: {
    [ThreadedDriveTypes.Hex_Internal]: true,
    [ThreadedDriveTypes.Torx]: true,
    [ThreadedDriveTypes.Torx_Plus]: true,
    [ThreadedDriveTypes.Phillips]: true,
    [ThreadedDriveTypes.Square]: true
  }
}


export const finishesByMaterialList: {
  [key in MaterialNames]: {
    [key in FinishNames]?: boolean
  }
} = {
  [MaterialNames.Steel_Med_Carbon]: {},
  [MaterialNames.Steel__37MnB2_]: {},
  [MaterialNames.Aluminum]: {
    [FinishNames.Anodized]: true,
    [FinishNames.Plain]: true
  },
  [MaterialNames.Brass]: {
    [FinishNames.Plain]: true
  },
  [MaterialNames.Monel]: {
    [FinishNames.Plain]: true
  },
  [MaterialNames.Nylon]: {
    [FinishNames.Plain]: true
  },
  [MaterialNames.Stainless_Steel]: {
    [FinishNames.Chemical_Black_Oxide]: true,
    [FinishNames.Cadmium]: true,
    [FinishNames.Hot_Dipped_Galvanized]: true,
    [FinishNames.Plain]: true,
    [FinishNames.Passivated]: true,
    [FinishNames.Zinc_Nickel]: true
  },
  [MaterialNames.Steel__Grade_5_]: {
    [FinishNames.Armor_Coat]: true,
    [FinishNames.Chemical_Black_Oxide]: true,
    [FinishNames.Cadmium]: true,
    [FinishNames.Chrome_Plated]: true,
    [FinishNames.Thermal_Black_Oxide]: true,
    [FinishNames.Geomet]: true,
    [FinishNames.Zinc_Galvanized]: true,
    [FinishNames.Hot_Dipped_Galvanized]: true,
    [FinishNames.Plain]: true,
    [FinishNames.Zinc___Clear_Trivalent]: true,
    [FinishNames.Zinc___Yellow_Trivalent]: true,
    [FinishNames.Zinc_Nickel]: true
  },
  [MaterialNames.Steel__Grade_8_]: {
    [FinishNames.Armor_Coat]: true,
    [FinishNames.Chemical_Black_Oxide]: true,
    [FinishNames.Cadmium]: true,
    [FinishNames.Chrome_Plated]: true,
    [FinishNames.Thermal_Black_Oxide]: true,
    [FinishNames.Geomet]: true,
    [FinishNames.Zinc_Galvanized]: true,
    [FinishNames.Hot_Dipped_Galvanized]: true,
    [FinishNames.Plain]: true,
    [FinishNames.Zinc___Clear_Trivalent]: true,
    [FinishNames.Zinc___Yellow_Trivalent]: true,
    [FinishNames.Zinc_Nickel]: true
  },
  [MaterialNames.Steel__Class_8_8_]: {
    [FinishNames.Armor_Coat]: true,
    [FinishNames.Chemical_Black_Oxide]: true,
    [FinishNames.Cadmium]: true,
    [FinishNames.Chrome_Plated]: true,
    [FinishNames.Thermal_Black_Oxide]: true,
    [FinishNames.Geomet]: true,
    [FinishNames.Zinc_Galvanized]: true,
    [FinishNames.Hot_Dipped_Galvanized]: true,
    [FinishNames.Plain]: true,
    [FinishNames.Zinc___Clear_Trivalent]: true,
    [FinishNames.Zinc___Yellow_Trivalent]: true,
    [FinishNames.Zinc_Nickel]: true
  },
  [MaterialNames.Steel__Class_10_9_]: {
    [FinishNames.Armor_Coat]: true,
    [FinishNames.Chemical_Black_Oxide]: true,
    [FinishNames.Cadmium]: true,
    [FinishNames.Chrome_Plated]: true,
    [FinishNames.Thermal_Black_Oxide]: true,
    [FinishNames.Geomet]: true,
    [FinishNames.Zinc_Galvanized]: true,
    [FinishNames.Hot_Dipped_Galvanized]: true,
    [FinishNames.Plain]: true,
    [FinishNames.Zinc___Clear_Trivalent]: true,
    [FinishNames.Zinc___Yellow_Trivalent]: true,
    [FinishNames.Zinc_Nickel]: true
  },
  [MaterialNames.Titanium]: {
    [FinishNames.Anodized]: true,
    [FinishNames.Plain]: true
  },
  [MaterialNames.Steel__Ungraded_]: {
    [FinishNames.Armor_Coat]: true,
    [FinishNames.Chemical_Black_Oxide]: true,
    [FinishNames.Cadmium]: true,
    [FinishNames.Chrome_Plated]: true,
    [FinishNames.Thermal_Black_Oxide]: true,
    [FinishNames.Geomet]: true,
    [FinishNames.Zinc_Galvanized]: true,
    [FinishNames.Hot_Dipped_Galvanized]: true,
    [FinishNames.Plain]: true,
    [FinishNames.Zinc___Clear_Trivalent]: true,
    [FinishNames.Zinc___Yellow_Trivalent]: true,
    [FinishNames.Zinc_Nickel]: true
  },
  [MaterialNames.Copper]: {
    [FinishNames.Armor_Coat]: false,
    [FinishNames.Chemical_Black_Oxide]: false,
    [FinishNames.Cadmium]: false,
    [FinishNames.Chrome_Plated]: false,
    [FinishNames.Thermal_Black_Oxide]: false,
    [FinishNames.Geomet]: false,
    [FinishNames.Zinc_Galvanized]: false,
    [FinishNames.Hot_Dipped_Galvanized]: false,
    [FinishNames.Plain]: false,
    [FinishNames.Zinc___Clear_Trivalent]: false,
    [FinishNames.Zinc___Yellow_Trivalent]: false,
    [FinishNames.Zinc_Nickel]: false,
  }
}

export const finishesByMaterialRivetNutsList: {
  [key in MaterialNames]: {
    [key in FinishNames]?: boolean
  }
} = {
  [MaterialNames.Steel__Class_10_9_]: {},
  [MaterialNames.Steel__Class_8_8_]: {},
  [MaterialNames.Steel__Grade_5_]: {},
  [MaterialNames.Steel__Grade_8_]: {},
  [MaterialNames.Copper]: {},
  "Aluminum": {
    "Anodized": true,
    "Plain": true,
    "Passivated": true
  },
  "Brass": {
    "Plain": true
  },
  "Monel": {
    "Plain": true,
    "Passivated": true
  },
  "Nylon": {
    "Plain": true
  },
  "Stainless Steel": {
    "Chemical Black Oxide": true,
    "Cadmium": true,
    "Hot Dipped Galvanized": true,
    "Plain": true,
    "Passivated": true,
    "Zinc Nickel": true
  },
  "Steel Med Carbon": {
    "Armor Coat": true,
    "Chemical Black Oxide": true,
    "Cadmium": true,
    "Chrome Plated": true,
    "Thermal Black Oxide": true,
    "Geomet": true,
    "Zinc Galvanized": true,
    "Hot Dipped Galvanized": true,
    "Plain": true,
    "Zinc & Clear Trivalent": true,
    "Zinc & Yellow Trivalent": true,
    "Zinc Nickel": true,
    "Magni 565": true
  },
  "Steel (37MnB2)": {
    "Armor Coat": true,
    "Chemical Black Oxide": true,
    "Cadmium": true,
    "Chrome Plated": true,
    "Thermal Black Oxide": true,
    "Geomet": true,
    "Zinc Galvanized": true,
    "Hot Dipped Galvanized": true,
    "Plain": true,
    "Zinc & Clear Trivalent": true,
    "Zinc & Yellow Trivalent": true,
    "Zinc Nickel": true,
    "Magni 565": true
  },
  "Titanium": {
    "Anodized": true,
    "Plain": true
  },
  "Steel (Ungraded)": {
    "Armor Coat": true,
    "Chemical Black Oxide": true,
    "Cadmium": true,
    "Chrome Plated": true,
    "Thermal Black Oxide": true,
    "Geomet": true,
    "Zinc Galvanized": true,
    "Hot Dipped Galvanized": true,
    "Plain": true,
    "Zinc & Clear Trivalent": true,
    "Zinc & Yellow Trivalent": true,
    "Zinc Nickel": true
  }
}

export enum RivetHeadStyles {
  Dome = "Dome",
  LoPro = "Lo-Pro",
  Countersunk90Deg = "Countersunk 90 deg",
  Countersunk120Deg = "Countersunk 120 deg",
  LargeFlange = "Large Flange",
  Button = "Button",
  Brazier = "Brazier"
}

export enum RivetNutHeadStyles {
  LargeFlange = "Large Flange",
  SmallFlange = "Small Flange",
  TrimHead = "Trim Head",
  CountersunkHead = "Countersunk Head",
  FlatHead = "Flat Head",
  LowProfile = "Low Profile",
}

export enum RivetNutMiscFeatures {
  Sealing = "Sealing",
  EndType = "End Type",
  RivFloat = "Riv-Float",
  WedgeHead = "Wedge Head",
}

export enum RivetNutBodyTypes {
  Round = "Round",
  RoundKnurled = "Round, knurled",
  Slotted = "Slotted",
  HalfHex = "Half hex",
  FullHex = "Full hex",
}

export type Distance<U> = DimensionedQuantity<U>

export type Fastener =
  | ThreadedFastener
  | RivetFastener
  | RivetNutFastener

// type ThreadedFastener = {
//   _fastenerType: FastenerTypes.threaded,
//   name: FastenerNames,
//   parameters: {
//     imperialDiameters: {
//       [key: number]:  ThreadedParameters<UnitsDistance.Inch, UnitsPitch.ThreadsPerInch, UnitsForce.PSI>
//     },
//     metricDiameters: {
//       [key: number]:  ThreadedParameters<UnitsDistance.Millimeter, UnitsPitch.MillimetersPerThread, UnitsForce.Newton>
//     }
//   }
// }

type GenericFastener<FastenerType, ImperialFastenerParameters, MetricFastenerParameters> = {
  fastenerType: FastenerType,
  name: FastenerNames,
  parameters: {
    imperialDiameters: {
      [key: number | string]: ImperialFastenerParameters
    },
    metricDiameters: {
      [key: number | string]: MetricFastenerParameters
    }
  }
}

export type RivetNutFastener = GenericFastener<
  FastenerTypes.rivetNut,
  RivetNutParameters<UnitsDistance.Inch, UnitsPitch.ThreadsPerInch, UnitsForce.PSI, UnitsTorque.FootPoundForce>,
  RivetNutParameters<UnitsDistance.Millimeter, UnitsPitch.MillimetersPerThread, UnitsForce.Newton, UnitsTorque.NewtonMeter>
>

export function isRivetNutFastener(fastener: Fastener): fastener is RivetNutFastener {
  return (fastener.fastenerType === FastenerTypes.rivetNut)
}

export type SelectedRivetNutParameters = {
  fastenerName: FastenerNames,
  diameter?: Diameter<UnitsDistance>,
  pitch?: DimensionedQuantity<UnitsPitch>,
  gripRange?: DimensionedQuantityRange<UnitsDistance>,
  installedLength?: DimensionedQuantity<UnitsDistance>,
  uninstalledLength?: DimensionedQuantity<UnitsDistance>,
  swagingForce?: DimensionedQuantity<UnitsForce>,
  headStyle?: RivetNutHeadStyles,
  miscFeatures?: {
    [Parameter in RivetNutMiscFeatures]?: true
  },
  bodyType?: RivetNutBodyTypes,
  material?: MaterialNames,
  finish?: FinishNames,
  tensileStrength?: DimensionedQuantity<UnitsForce> | DimensionedQuantityRange<UnitsForce>,
  pullOutStrength?: DimensionedQuantity<UnitsForce> | DimensionedQuantityRange<UnitsForce>,
  shearStrength?: DimensionedQuantity<UnitsForce> | DimensionedQuantityRange<UnitsForce>,
  maxTorqueUnsupported?: DimensionedQuantity<UnitsForce> | DimensionedQuantityRange<UnitsForce>,
  maxTorqueSupported?: DimensionedQuantity<UnitsForce> | DimensionedQuantityRange<UnitsForce>,
}

export const isSelectedRivetNutParameters = (params: SelectedPartParameters): params is SelectedRivetNutParameters => {
  return (
    params.fastenerName !== undefined
    && Object.values(RivetNutFastenerNames).includes(params.fastenerName)
  )
}

export type RivetNutMaterial<UForce, UTorque> = {
  tensileStrength?: Strength<UForce>,
  pullOutStrength?: Strength<UForce>,
  shearStrength?: Strength<UForce>,
  maxTorqueUnsupported?: Strength<UTorque>,
  maxTorqueSupported?: Strength<UTorque>,
}

export type RivetNutParameters<UnitsDistance, UnitsPitch, UnitsForce, UnitsTorque> = {
  diameter: Diameter<UnitsDistance>,
  pitch: DimensionedQuantity<UnitsPitch>,
  gripRange: DimensionedQuantityRange<UnitsDistance>,
  length: {
    uninstalled: DimensionedQuantity<UnitsDistance>,
    installed: DimensionedQuantity<UnitsDistance>,
  },
  swagingForce: DimensionedQuantity<UnitsForce>,
  compatibleHeadStyles: {
    [Parameter in RivetNutHeadStyles]?: true
  },
  compatibleMiscFeatures: {
    [Parameter in RivetNutMiscFeatures]?: true
  },
  compatibleBodyTypes: {
    [Parameter in RivetNutBodyTypes]?: true
  },
  materials: {
    [Parameter in MaterialNames]?: RivetNutMaterial<UnitsForce, UnitsTorque>
  }
}

export type ThreadedFastener = GenericFastener<
  FastenerTypes.threaded,
  ThreadedParameters<UnitsDistance.Inch, UnitsPitch.ThreadsPerInch, UnitsForce.PSI>,
  ThreadedParameters<UnitsDistance.Millimeter, UnitsPitch.MillimetersPerThread, UnitsForce.Newton>
>

export function isThreadedFastener(fastener: Fastener): fastener is ThreadedFastener {
  return (fastener.fastenerType === FastenerTypes.threaded)
}

type ThreadedParameters<D, P, F> = {
  diameter: Diameter<D>,
  lengths: ThreadedLengthRange<D>[],
  pitches: PitchSet<P>
  materials: {
    [Parameter in MaterialNames]?: GenericThreadedMaterial<F>
  },
  compatibleHeadTypes: {
    [Parameter in ThreadedHeadTypes]?: true
  }
}

export type SelectedThreadedParameters = {
  fastenerName: FastenerNames,
  diameter?: Diameter<UnitsDistance>,
  length?: ThreadedLength<UnitsDistance>,
  pitch?: Pitch<UnitsPitch>,
  material?: MaterialNames,
  finish?: FinishNames,
  tensileStrength?: Strength<UnitsForce>,
  shearStrength?: Strength<UnitsForce>,
  headType?: ThreadedHeadTypes,
  driveType?: ThreadedDriveTypes,
}

export const isSelectedThreadedParameters = (params: SelectedPartParameters): params is SelectedThreadedParameters => {
  return (
    params.fastenerName !== undefined
    && Object.values(ThreadedFastenerNames).includes(params.fastenerName)
  )
}

export type GenericThreadedMaterial<U> =
  | StandardThreadedMaterial<U>
  | PinAndCollarMaterial<U>

type StandardThreadedMaterial<U> = {
  coarsePitch?: Strengths<U>,
  finePitch?: Strengths<U>
}

export const isStandardThreadedMaterial = (material: any): material is StandardThreadedMaterial<UnitsForce> => {
  return (
    (material.hasOwnProperty('coarsePitch') && isStrengths(material.coarsePitch))
    || (material.hasOwnProperty('finePitch') && isStrengths(material.finePitch))
  )
}

export enum PitchType {
  coarse = "coarse",
  fine = "fine"
}

type StandardPitchSet<U> = {
  coarse?: GenericPitch<U, PitchType.coarse>,
  fine?: GenericPitch<U, PitchType.fine>
}

export type DimensionedQuantity<U> = {
  unit: U,
  value: number
}

export const isDimensionedQuantity = (d: any): d is DimensionedQuantity<any> => {
  return (
    d !== undefined
    && d.hasOwnProperty('value')
    && typeof d.value === 'number'
    && d.hasOwnProperty('unit')
  )
}

export type DimensionedQuantityRange<U> = {
  unit: U,
  minValue: number,
  maxValue: number
}

export const isDimensionedQuantityRange = (d: any): d is DimensionedQuantityRange<any> => {
  return (
    d !== undefined
    && d.hasOwnProperty('minValue')
    && typeof d.minValue === 'number'
    && d.hasOwnProperty('maxValue')
    && typeof d.maxValue === 'number'
    && d.hasOwnProperty('unit')
  )
}

export type GenericPitch<U, P> =
  & DimensionedQuantity<U>  // Value is positive rational number
  & { pitchType: P }

export const isGenericPitch = (p: any): p is GenericPitch<UnitsPitch, PitchType> => {
  return (
    p.hasOwnProperty('pitchType')
    && isDimensionedQuantity(p)
  )
}

type PinAndCollarPitch =
  | { _4Groove: true }
  | { _6Groove: true }
  | { multiGroove: true }

type Pitch<U> =
  | GenericPitch<U, PitchType>
// | PinAndCollarPitch

export type Strength<U> = DimensionedQuantity<U> // Value is positive rational number

export type StrengthRange<U> = DimensionedQuantityRange<U>

export type Strengths<U> = {
  tensile?: Strength<U>,
  shear?: Strength<U>
}

export const isStrengths = (s: any): s is Strengths<UnitsForce> => {
  return (
    (s.hasOwnProperty('tensile') && isDimensionedQuantity(s.tensile))
    || (s.hasOwnProperty('shear') && isDimensionedQuantity(s.shear))
  )
}

type PinAndCollarMaterial<U> = {
  _4Groove?: Strengths<U>,
  _6Groove?: Strengths<U>,
  multiGroove?: Strengths<U>,
}

type PinAndCollarPitchSet = {
  _4Groove?: true,
  _6Groove?: true,
  multiGroove?: true,
}

export function isPinAndCollarPitchSet(pitchSet: PitchSet<PitchType> | StandardPitchSet<UnitsPitch>)
  : pitchSet is PinAndCollarPitchSet {
  return (
    pitchSet.hasOwnProperty('_4Groove')
    || pitchSet.hasOwnProperty('_6Groove')
    || pitchSet.hasOwnProperty('multiGroove')
  )
}

type PitchSet<U> =
  | StandardPitchSet<U>
  | PinAndCollarPitchSet

export type Diameter<T> = {
  distance: Distance<T>,
  text: string,
}

export const isDiameter = (d: any): d is Diameter<any> => {
  return (
    d.hasOwnProperty('distance')
    && isDimensionedQuantity(d.distance)
    && d.hasOwnProperty('text')
    && typeof d.text === 'string'
  )
}

export type IncrementRange<T> = {
  min: T,
  max: T,
  increment: T
}

export const isIncrementRange = (r: any): r is IncrementRange<any> => {
  return (
    typeof r === 'object'
    && r.hasOwnProperty('min')
    && isDimensionedQuantity(r.min)
    && r.hasOwnProperty('max')
    && isDimensionedQuantity(r.max)
    && r.hasOwnProperty('increment')
    && isDimensionedQuantity(r.increment)
  )
}

export type ThreadedLengthRange<T> = IncrementRange<Distance<T>>

export type ThreadedLength<D> = DimensionedQuantity<D>

export type RivetFastener = GenericFastener<
  FastenerTypes.rivet,
  RivetParameters<UnitsDistance.Inch, UnitsForce.PSI>,
  RivetParameters<UnitsDistance.Millimeter, UnitsForce.Newton>
>

export function isRivetFastener(fastener: Fastener): fastener is RivetFastener {
  return (fastener.fastenerType === FastenerTypes.rivet)
}

export type RivetMaterial<U> = {
  tensileStrength?: Strength<U>,
  stemMaterials: {
    [Parameter in MaterialNames]?: {
      shearStrength?: Strength<U>
    }
  }
}

export type RivetGripRange<T> = IncrementRange<Distance<T>>

export type RivetGrip<T> = Distance<T>

type RivetParameters<D, F> = {
  diameter: Diameter<D>
  gripRanges: RivetGripRange<D>[],
  bodyMaterials: {
    [Parameter in MaterialNames]?: RivetMaterial<F>
  },
  headStyles: {
    [Parameter in RivetHeadStyles]?: true
  }
}

export type SelectedRivetParameters = {
  fastenerName: FastenerNames,
  diameter?: Diameter<UnitsDistance>,
  gripRange?: RivetGripRange<UnitsDistance>,
  headType?: RivetHeadStyles,
  bodyMaterial?: MaterialNames,
  bodyFinish?: FinishNames,
  stemMaterial?: MaterialNames,
  tensileStrength?: Strength<UnitsForce>,
  shearStrength?: Strength<UnitsForce>,
}

export const isSelectedRivetParameters = (params: SelectedPartParameters): params is SelectedRivetParameters => {
  return (
    params.fastenerName !== undefined
    && Object.values(RivetFastenerNames).includes(params.fastenerName)
  )
}


export type PossibleThreadedSelectionOptions = {
  type: "threaded",
  fastenerName: ReactSelectOptions<FastenerNames, FastenerNames>,
  diameter: ReactSelectOptions<Diameter<UnitsDistance>, string>,
  length: ReactSelectOptions<ThreadedLength<UnitsDistance>, string>,
  pitch: ReactSelectOptions<GenericPitch<UnitsPitch, PitchType>, string>,
  material: ReactSelectOptions<MaterialNames, string>,
  finish: ReactSelectOptions<string, string>,
  tensileStrength: ReactSelectOptions<(Strength<UnitsForce> | StrengthRange<UnitsForce>), string>,
  shearStrength: ReactSelectOptions<(Strength<UnitsForce> | StrengthRange<UnitsForce>), string>,
  headType: ReactSelectOptions<ThreadedHeadTypes, string>,
  driveType: ReactSelectOptions<string, string>
}

export const isPossibleThreadedSelectionOptions = (o: PossibleFastenerSelectionOptions): o is PossibleThreadedSelectionOptions => {
  return (o.type === 'threaded')
}

export type PossibleRivetSelectionOptions = {
  type: "rivet",
  fastenerName: ReactSelectOptions<FastenerNames, FastenerNames>,
  diameter: ReactSelectOptions<Diameter<UnitsDistance>, string>,
  gripRange: ReactSelectOptions<RivetGrip<UnitsDistance>, string>,
  headStyle: ReactSelectOptions<RivetHeadStyles, string>,
  bodyMaterial: ReactSelectOptions<MaterialNames, string>,
  bodyFinish: ReactSelectOptions<string, string>,
  stemMaterial: ReactSelectOptions<MaterialNames, string>,
  tensileStrength: ReactSelectOptions<(Strength<UnitsForce> | StrengthRange<UnitsForce>), string>,
  shearStrength: ReactSelectOptions<(Strength<UnitsForce> | StrengthRange<UnitsForce>), string>,
}

export const isPossibleRivetSelectionOptions = (o: PossibleFastenerSelectionOptions): o is PossibleRivetSelectionOptions => {
  return (o.type === 'rivet')
}

export type PossibleRivetNutSelectionOptions = {
  type: "rivetNut",
  fastenerName: ReactSelectOptions<FastenerNames, FastenerNames>,
  diameterAndPitch: ReactSelectOptions<
    {
      diameter: Diameter<UnitsDistance>,
      pitch: DimensionedQuantity<UnitsPitch>
    },
    string
  >,
  // diameter: ReactSelectOptions<Diameter<UnitsDistance>, string>,
  // pitch: ReactSelectOptions<DimensionedQuantity<UnitsPitch>, string>,
  gripRangeAndLengths: ReactSelectOptions<
    {
      gripRange: DimensionedQuantityRange<UnitsDistance>,
      installedLength: DimensionedQuantity<UnitsDistance>,
      uninstalledLength: DimensionedQuantity<UnitsDistance>
    },
    string
  >,
  // gripRange: ReactSelectOptions<DimensionedQuantityRange<UnitsDistance>, string>,
  // installedLength: ReactSelectOptions<DimensionedQuantity<UnitsDistance>, string>,
  // uninstalledLength: ReactSelectOptions<DimensionedQuantity<UnitsDistance>, string>,
  swagingForce: ReactSelectOptions<DimensionedQuantity<UnitsForce>, string>,
  headStyle: ReactSelectOptions<RivetNutHeadStyles, string>,
  miscFeatures: ReactSelectOptions<RivetNutMiscFeatures, string>,
  bodyType: ReactSelectOptions<RivetNutBodyTypes, string>,
  material: ReactSelectOptions<MaterialNames, string>,
  finish: ReactSelectOptions<FinishNames, string>,
  tensileStrength: ReactSelectOptions<
    | DimensionedQuantity<UnitsForce>
    | DimensionedQuantityRange<UnitsForce>
    ,
    string
  >,
  pullOutStrength: ReactSelectOptions<
    | DimensionedQuantity<UnitsForce>
    | DimensionedQuantityRange<UnitsForce>
    ,
    string
  >,
  shearStrength: ReactSelectOptions<
    | DimensionedQuantity<UnitsForce>
    | DimensionedQuantityRange<UnitsForce>
    ,
    string
  >,
  maxTorqueUnsupported: ReactSelectOptions<
    | DimensionedQuantity<UnitsTorque>
    | DimensionedQuantityRange<UnitsTorque>
    ,
    string
  >,
  maxTorqueSupported: ReactSelectOptions<
    | DimensionedQuantity<UnitsTorque>
    | DimensionedQuantityRange<UnitsTorque>
    ,
    string
  >,
}

export const isPossibleRivetNutSelectionOptions = (o: PossibleFastenerSelectionOptions): o is PossibleRivetNutSelectionOptions => {
  return (o.type === 'rivetNut')
}

export type PossibleFastenerSelectionOptions =
  | (Partial<PossibleThreadedSelectionOptions> & Pick<PossibleThreadedSelectionOptions, 'type' | 'fastenerName'>)
  | (Partial<PossibleRivetSelectionOptions> & Pick<PossibleRivetSelectionOptions, 'type' | 'fastenerName'>)
  | (Partial<PossibleRivetNutSelectionOptions> & Pick<PossibleRivetNutSelectionOptions, 'type' | 'fastenerName'>)
  | {
    type: undefined,
    fastenerName: ReactSelectOptions<string, FastenerNames>
  }

/*
  FastenerRecommender
    Will be parent component of both JoiningIt and FindingIt.
    When the user navigates to JoiningIt or FindingIt routes, load FastenerRecommender with the appropriate child active
    In the future, may display popout sidebar which would show a summary and allow easy navigation to different parts of JoiningIt/FindingIt
    Will store the user input state of both JoiningIt and FindingIt
      State example: {
        unitSystem: Imperial | Metric,
        jointParameters: {
          blindHole: true,
          mustBeServiceable: true,
          materialsAreWeldable: false,
          ...
        },
        selectedPartParameters: {
          fastenerType: FastenerNames.Bolt,
          selecteddiameter: ...,
          headStyle: ...,
          ...
        }
      }

  JoiningItData
    Will store the rules describing which joint parameters are compatible with which fastener types

  FindingItData
    Will store the rules describing the possible dimensions and other parameters for each fastener type

  FindingIt
    Choose fastener type (set in state inherited from FastenerRecommender parent)
    Choose unit system (set in state inherited from FastenerRecommender parent)
    Based on selected fastener type, display child with appropriate inputs and illustration, passing down state update functions
      FindingItInputs
    switch {
            GenericThreaded. Args: illustration & input field location styles
        [This handles ~8 threaded types with same inputs]
      PinAndCollar(props: { fastenerName: FastenerTypes.PinAndCollar, ... })
      GenericRivet
      ThreadedInsert
    }
    Child: Display solution preview
*/