import styles from "./styles/_button.module.scss"

type ButtonProps = {
  textColor?: Pick<React.CSSProperties, 'color'>
  backgroundColor?: Pick<React.CSSProperties, 'background'>
  border?: Pick<React.CSSProperties, 'border'>
  size?: 'sm' | 'med' | 'lg'
} & React.ButtonHTMLAttributes<HTMLButtonElement>

export function Button({ textColor, backgroundColor, border, size, children, ...props }: ButtonProps, ) {
  const style: React.CSSProperties = {
    color:  textColor?.color,
    background: backgroundColor?.background,
    border: border?.border
  }

  const sizeClassName = (size === 'sm')
      ? styles.sm
      : (size === 'med')
        ? styles.med
        : ""

  const className = `${styles.ffButton} ${props.className} ${sizeClassName}`
  

  // console.log("className", className)
  return <>
    <button
      {...props}
      className={className}
      style={style}
    >
      {children}
    </button>
  </>
}