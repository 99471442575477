import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent, StripeCardElementOptions } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import generalStyles from "../styles/style.module.scss"

const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
  style: {
    base: {
      'color': '#32325d',
      'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
      'fontSmoothing': 'antialiased',
      'fontSize': '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
  hidePostalCode: false
};

export interface InputError {
  type: 'validation_error';
  code: string;
  message: string;
};

export type CardInputProps = {
  setCardError?: React.Dispatch<React.SetStateAction<InputError | undefined>>
  setCardIsCompleted?: React.Dispatch<React.SetStateAction<boolean>>
  showIsEmptyError?: boolean
}

export default function CardInput(props: CardInputProps) {
  const [inputError, setInputError] = useState<InputError | undefined>();
  const [isEmpty, setIsEmpty] = useState<boolean>(true);

  useEffect(() => {
    props.setCardIsCompleted && props.setCardIsCompleted(false)
  }, [])


  const handleChange = (event: StripeCardElementChangeEvent) => {
    setInputError(event.error);
    setIsEmpty(event.empty);
    if (props.setCardError) {
      props.setCardError(event.error);
    }
    if (props.setCardIsCompleted) {
      props.setCardIsCompleted(!event.empty && !event.error)
    }
  }

  return (<div className={generalStyles.stripeCardElementWrapper}>
    <CardElement
      options={CARD_ELEMENT_OPTIONS}
      onChange={handleChange}
    />
    {inputError !== undefined &&
      <span id="card-errors" role="alert">{inputError.message}</span>
    }
    {props.showIsEmptyError && isEmpty &&
      <span id="card-errors" role="alert">Please enter your credit card details</span>
    }
  </div>);
}