import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Button } from "../../Button";
import { termsAndConditionsText } from "../TermsandConditions";
import { useNavigate } from 'react-router-dom'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { StripeCardElement } from "@stripe/stripe-js";
import CardInput, { InputError } from "../PaymentCardElement";
import { Plan, Price } from "../planTypes";
import { ChoosePlanModal } from "./Account";
import styles from "../../styles/_accountCreation.module.scss"


import {
  UserInfoSchema,
  UserInfoSchemaType,
  UserBillingAddressUsaFormType,
  UserBillingAddressUsaFormSchema,
  UserBillingAddressNonUsaFormType,
  UserBillingAddressNonUsaFormSchema,
  CountryEnum,
  StateEnum,
  UserNonBillingFormType,
  UserTermsAcceptanceSchema
} from "./UserInfoSchema";

import { useAuth0 } from "@auth0/auth0-react";
import env from '../../env';
import Loading from "../Loading";
import { useForm, SubmitHandler } from 'react-hook-form'
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from 'zod';

const createStripeSubscription = async (
  userData: UserInfoSchemaType,
  paymentMethodId: string,
  price: Price,
  getAccessTokenSilently: () => Promise<string>
) => {
  const accessToken = await getAccessTokenSilently();

  const createStripeSubscriptionApiUrl = `${env.REACT_APP_CF_WORKERS_URL}/createSubscription`;

  const customerCreationResp = await fetch(createStripeSubscriptionApiUrl, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userData: userData,
      paymentMethodId: paymentMethodId,
      priceId: price.priceId,
    }),
  })
    .then(async (response) => {
      return await response.json();
    })
    .then((result) => {
      console.log('subscription creation result', result);
      return result;
    })
    .catch((error) => {
      console.error(error);
    });

  return customerCreationResp;
};

const createTrialSubscription = async (
  userData: UserInfoSchemaType,
  getAccessTokenSilently: () => Promise<string>
) => {
  const accessToken = await getAccessTokenSilently();

  const createTrialSubscriptionApiUrl = `${env.REACT_APP_CF_WORKERS_URL}/createTrialSubscription`;

  const customerCreationResp = await fetch(createTrialSubscriptionApiUrl, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userData: userData,
    }),
  })
    .then(async (response) => {
      return await response.json();
    })
    .then((result) => {
      console.log('subscription creation result', result);
      return result;
    })
    .catch((error) => {
      console.error(error);
    });

  return customerCreationResp;
};



const AccountCreationSuccess = ({ combinedUserInputData, collectStripePaymentInfo, planObj }: {
  combinedUserInputData: Partial<UserInfoSchemaType>,
  collectStripePaymentInfo: boolean,
  planObj?: Plan
}) => {
  const [cardError, setCardError] = useState<InputError | undefined>();
  const [cardIsCompleted, setCardIsCompleted] = useState<boolean>(false);
  const [showCardIsEmptyError, setShowCardIsEmptyError] = useState<boolean>(false);
  const [requestStatus, setRequestStatus] = useState<'preactive' | 'loading' | 'success' | 'error'>('preactive');

  const usaFields = useForm<UserBillingAddressUsaFormType>({
    resolver: zodResolver(UserBillingAddressUsaFormSchema),
    mode: 'onTouched'
  });

  const nonUsaFields = useForm<UserBillingAddressNonUsaFormType>({
    resolver: zodResolver(UserBillingAddressNonUsaFormSchema),
    mode: 'onTouched'
  });

  const nonBillingFields = useForm<UserNonBillingFormType>({
    resolver: zodResolver(UserTermsAcceptanceSchema),
    mode: 'onTouched'
  });

  useEffect(() => {
    usaFields.setValue('country', CountryEnum.Values["United States of America"])
    nonUsaFields.setValue('country', CountryEnum.Values["United States of America"])
  }, [])

  const fieldsToShow: "nonBilling" | "usaBilling" | "nonUsaBilling" = (collectStripePaymentInfo)
    ? (usaFields.getValues().country == "United States of America")
      ? "usaBilling"
      : "nonUsaBilling"
    : "nonBilling"
  // const showUsaFields = (usaFields.getValues().country == "United States of America")

  const setAcceptanceDate = () => {
    nonBillingFields.setValue('termsOfUseAcceptance', new Date)
    usaFields.setValue('termsOfUseAcceptance', new Date)
    nonUsaFields.setValue('termsOfUseAcceptance', new Date)
  }

  // const plan = localStorage.getItem('signUpPlan');
  // const planObj: Plan | null = (plan) ? JSON.parse(plan) : null;
  const selectedPrice = planObj?.quarterlyPrice

  const navigate = useNavigate();

  const { getAccessTokenSilently, user } = useAuth0();

  const stripe = useStripe();
  const elements = useElements();

  let cardElement: StripeCardElement | null;

  const submitDataWithoutStripeInfo = async (data: UserInfoSchemaType) => {
    //todo: add terms of use acceptance date to data posted to backend?
    try {
      setRequestStatus('loading');

      const stripeCustomer = await createTrialSubscription(
        data,
        getAccessTokenSilently
      );
      if (!stripeCustomer) {
        //todo: add user-facing error handling
        console.log('Error creating Stripe customer');
        setRequestStatus('error');
        return;
      }
      // todo: handle success
      await getAccessTokenSilently();
      navigate('/')
    } catch (e) {
      console.error("Error while submitting:", e)
    }
  }

  const submitDataWithStripeInfo = async (data: UserInfoSchemaType) => {
    //todo: add terms of use acceptance date to data posted to backend?
    try {
      if (!selectedPrice) { throw "No selectedPriceId found. Has a plan been chosen?" }
      if (!stripe) { throw "Couldn't initialize stripe hook" }
      if (!elements) { throw "Couldn't initialize elements hook" }

      cardElement = elements.getElement(CardElement);
      if (!cardElement) { throw "Couldn't instantiate cardElement" }

      setRequestStatus('loading');

      await stripe
        .createPaymentMethod({
          type: 'card',
          card: cardElement,
        })
        .then(async (result) => {
          if (result.error) {
            console.error(result);
            //todo: figure out user-facing error handling
          } else {
            // console.log(result.paymentMethod.id, selectedPrice);
            const stripeCustomer = await createStripeSubscription(
              data,
              result.paymentMethod.id,
              selectedPrice,
              getAccessTokenSilently
            );
            if (!stripeCustomer) {
              //todo: add user-facing error handling
              console.log('Error creating Stripe customer');
              setRequestStatus('error');
              return;
            }
          }
        });
      // todo: handle success
      await getAccessTokenSilently();
      navigate('/')
    } catch (e) {
      console.error("Error while submitting:", e)
    }
  }

  const onSubmit:
    SubmitHandler<
      | UserBillingAddressNonUsaFormType
      | UserBillingAddressUsaFormType
      | UserNonBillingFormType
    > =
    (data) => {
      console.log("submission data", JSON.stringify(data, null, 2))
      setShowCardIsEmptyError(true);
      if (collectStripePaymentInfo && (!cardIsCompleted || cardError)) {
        console.error("Credit card data is not valid")
        return;
      }
      try {
        const newCombinedUserInputData = {
          ...combinedUserInputData,
          ...data
        }
        const completeUserData = UserInfoSchema.parse(newCombinedUserInputData)
        console.log("completeUserData", completeUserData)
        if (collectStripePaymentInfo) {
          submitDataWithStripeInfo(completeUserData);
        } else {
          submitDataWithoutStripeInfo(completeUserData);
        }
      } catch (e) {
        console.error("Error parsing newCombinedUserInputData :", e)
      }
    };

  const usaBillingAddressFields = <>
    <div className={styles.fieldContainer}>
      <label>Street Address<span className={styles.requiredAsterisk}>*</span></label>
      <input
        type='text'
        {...usaFields.register('streetAddress')}
        disabled={usaFields.formState.isSubmitting}
      />
      {usaFields.formState.errors.streetAddress && <>
        <p className={styles.accountCreationFormFieldErrorMessage}>{usaFields.formState.errors.streetAddress.message}</p>
      </>}
    </div>
    <div className={styles.accountCreationCityStateZipContainer}>
      <div className={styles.fieldContainer}>
        <label>City<span className={styles.requiredAsterisk}>*</span></label>
        <input
          type='text'
          {...usaFields.register('city')}
          disabled={usaFields.formState.isSubmitting}
        />
        {usaFields.formState.errors.city && <>
          <p className={styles.accountCreationFormFieldErrorMessage}>{usaFields.formState.errors.city?.message}</p>
        </>}
      </div>
      <div className={styles.fieldContainer}>
        <label>State<span className={styles.requiredAsterisk}>*</span></label>
        <select
          {...usaFields.register('state')}
          disabled={usaFields.formState.isSubmitting}
        >
          <option>Select...</option>
          {StateEnum.options.map(stateName => (<option>{stateName}</option>))}
        </select>
        {usaFields.formState.errors.state && <>
          <p className={styles.accountCreationFormFieldErrorMessage}>{usaFields.formState.errors.state.message}</p>
        </>}
      </div>
      <div className={styles.fieldContainer}>
        <label>Zip Code<span className={styles.requiredAsterisk}>*</span></label>
        <input
          type='text'
          {...usaFields.register('postalCode')}
          disabled={usaFields.formState.isSubmitting}
        />
        {usaFields.formState.errors.postalCode && <>
          <p className={styles.accountCreationFormFieldErrorMessage}>{usaFields.formState.errors.postalCode.message}</p>
        </>}
      </div>
    </div>
  </>

  const nonUsaBillingAddressFields = <>
    <div className={styles.fieldContainer}>
      <label>Address<span className={styles.requiredAsterisk}>*</span></label>
      <textarea
        rows={2}
        {...nonUsaFields.register('streetAddress')}
        disabled={nonUsaFields.formState.isSubmitting}
      />
      {nonUsaFields.formState.errors.streetAddress && <>
        <p className={styles.accountCreationFormFieldErrorMessage}>{nonUsaFields.formState.errors.streetAddress.message}</p>
      </>}
    </div>
    <div className={styles.fieldContainer}>
      <label>Postal Code</label>
      <input
        type='text'
        {...nonUsaFields.register('postalCode')}
        disabled={nonUsaFields.formState.isSubmitting}
      />
      {nonUsaFields.formState.errors.postalCode && <>
        <p className={styles.accountCreationFormFieldErrorMessage}>{nonUsaFields.formState.errors.postalCode.message}</p>
      </>}
    </div>
  </>

  console.log("NOnBillingFIelds:", nonBillingFields.formState.isValid, nonBillingFields.formState.errors.termsOfUseAcceptance, nonBillingFields.getValues().termsOfUseAcceptance)

  return (
    <div>
      {(!selectedPrice && collectStripePaymentInfo) && <ChoosePlanModal />}
      <form
        className={styles.accountCreationForm}
        onSubmit={(e) => {
          e.preventDefault();
          console.log("Received submit request")
          switch (fieldsToShow) {
            case "nonBilling":
              console.log("Submit request field type:", "nonBilling")
              nonBillingFields.handleSubmit(onSubmit)(e);
              break;
            case "nonUsaBilling":
              console.log("Submit request field type:", "nonUsaBilling")
              nonUsaFields.handleSubmit(onSubmit)(e);
              break;
            case "usaBilling":
              console.log("Submit request field type:", "usaBilling")
              usaFields.handleSubmit(onSubmit)(e);
              break;
            default:
              console.log("Submit request field type:", "unrecognized");
              throw new Error(`Unrecognized field selection: ${fieldsToShow}`)
          }
        }}
      >
        <input
          className={styles.hiddenEmailField}
          type='email'
          defaultValue={combinedUserInputData.email}
        />
        <br />
        <h3>
          In order to use this site, you must read and accept the following terms
          of use:
        </h3>
        <br />
        <ReactMarkdown className={styles.termsAndConditionsScrollContainer}>{termsAndConditionsText}</ReactMarkdown>
        <br />
        <div className={`${styles.fieldContainer} ${styles.checkboxFieldContainer}`}>
          <input
            type='checkbox'
            onChange={setAcceptanceDate}
          />
          <label><span className={styles.requiredAsterisk}>*</span> By checking this box, I affirm that I have read and agree to the above terms of use</label>
          {(nonUsaFields.formState.errors.termsOfUseAcceptance || usaFields.formState.errors.termsOfUseAcceptance || nonBillingFields.formState.errors.termsOfUseAcceptance) && <>
            <p className={styles.accountCreationFormFieldErrorMessage}>
              {
                nonUsaFields.formState.errors.termsOfUseAcceptance?.message
                || usaFields.formState.errors.termsOfUseAcceptance?.message
                || nonBillingFields.formState.errors.termsOfUseAcceptance?.message
              }
            </p>
          </>}
        </div>
        <br />
        {collectStripePaymentInfo && <>
          <h4>Payment Information:</h4>
          <br />
          <CardInput
            showIsEmptyError={showCardIsEmptyError}
            setCardIsCompleted={setCardIsCompleted}
            setCardError={setCardError}
          />
          <br />
          <h4>Billing Address:</h4>
          <div className={styles.fieldContainer}>
            <label>Country<span className={styles.requiredAsterisk}>*</span></label>
            <select
              {...nonUsaFields.register('country')}
              {...usaFields.register('country')}
              disabled={nonUsaFields.formState.isSubmitting}
            >
              {CountryEnum.options.map((countryName) => (
                <option>{countryName}</option>
              ))}
            </select>
            {nonUsaFields.formState.errors.country && <>
              <p className={styles.accountCreationFormFieldErrorMessage}>{nonUsaFields.formState.errors.country.message}</p>
            </>}
          </div>
          {(usaFields.getValues().country !== "United States of America")
            ? nonUsaBillingAddressFields
            : usaBillingAddressFields
          }
        </>}
        {requestStatus == 'loading' &&
          <Loading />
        }
        {(requestStatus == 'preactive' || requestStatus == 'error') &&
          <Button
            type='submit'
            className={styles.accountCreationSubmitButton}
            disabled={cardError != undefined}
          >
            Sign Up
          </Button>
        }
      </form>
    </div>
  );

}


export default AccountCreationSuccess
