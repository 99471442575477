import { FESBranded } from "../globalComponents/FESBrandedFeatures";
import torqueDiagram1 from "../../images/Torque_Graphic_Only.svg";
import torqueDiagram4 from "../../images/Torque_Graphic_Only.svg";
import Select, { ReactSelectOption } from "../utilities/FixRequiredSelect";
import { ReactSelectOptionWithTooltip } from "../utilities/ReactSelectOptionWithTooltip";
import { Button } from "../../Button";
import { useState, useRef, useEffect, } from "react";
import { TorqueUnitSystemToggle } from "../TorqueCalculator/TorqueUnitsSystemToggle";
import { UnitsPressure, UnitSystem } from "../FindIt/findItTypes";
import { diameterPitchOptions } from "./DiameterPitchOptions";

import {
  UnitsDistance,
  UnitsPitch,
  UnitsTorque,
  UnitsTensileStrength,
  DimensionedQuantity,
  UnitsForce
} from "../FindIt/findItTypes";

import { FindingItTooltipContent } from "../FastenerRecommender/FindingIt";
import { FfTooltip } from "../globalComponents/FfTooltip";
import { PositionType } from "@atlaskit/tooltip";
import infoIcon from "../../images/info-hover-01.svg";

import { calculateTorque, CalculateTorqueProps, CalculatedTorqueValues } from "./calculateTorque";
import { z, ZodType } from "zod";
import {
  useForm,
  SubmitHandler,
  UseFormRegister,
  FieldErrorsImpl,
  DeepRequired,
  Path,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { 
  MM_PER_INCH,
  MEGAPASCALS_PER_PSI,
  FOOT_LB_F_PER_NEWTON_METER,
  POUND_FORCE_PER_KILONEWTON
} from "../utilities/constants";

import styles from "../../styles/_torqueCalculator.module.scss"
import utilityStyles from "../../styles/_utilityStyles.module.scss"
import generalStyles from "../../styles/style.module.scss"
import findingItStyles from "../../styles/_findIt.module.scss"

export function numericString<T>(
  schema: ZodType<T>
): z.ZodEffects<z.ZodType<T, z.ZodTypeDef, T>, T, T> {
  return z.preprocess((a) => {
    if (typeof a === "string" && a !== "") {
      return parseFloat(a);
    } else if (typeof a === "number") {
      return a;
    } else {
      return undefined;
    }
  }, schema);
}

export function percentage<T>(
  schema: ZodType<T>
): z.ZodEffects<z.ZodType<T, z.ZodTypeDef, T>, T, T> {
  return z.preprocess((a) => {
    if (typeof a === "number") {
      const newValue = a / 100;
      // console.log(newValue);
      return newValue;
    } else {
      // console.log("Error!");
      return undefined;
    }
  }, schema);
}

const TorqueCalculatorInputsFormSchema = z
  .object({
    //todo: figure out why range validation isn't working right
    targetTorqueOverYieldPercentage: numericString(
      percentage(z.number().gt(0).lte(100))
    ), // Percentage, range 0 - 100, excluding 0 but including 100
    nominalDiameter: numericString(z.number().gt(0).lte(90)), // value in mm. Equivalent to Mx, where x is value
    threadPitch: numericString(z.number().gt(0)), // value in mm
    regionOfContactUnderNutOrBoltHeadOuterDiameter: numericString(
      z.number().gt(0).lte(200)
    ),
    regionOfContactUnderNutOrBoltHeadInnerDiameter: numericString(
      z.number().gt(0).lte(200)
    ),
    engagementLength: numericString(z.number().gt(0).lte(1000)),
    reducedShankDiameter: numericString(z.number().gt(0).optional()),
    tensileStrengthRangeMin: numericString(z.number().gt(0).lte(1_000_000)), // Units in MPa == N/mm^2
    tensileStrengthRangeMax: numericString(z.number().gt(0).lte(1_000_000)), // Units in MPa == N/mm^2
    yieldOverTensileStrengthRatioRangeMin: numericString(
      z.number().gt(0).lte(1)
    ), // unitless
    yieldOverTensileStrengthRatioRangeMax: numericString(
      z.number().gt(0).lte(1)
    ), // unitless
    threadFrictionCoefficientRangeMin: numericString(z.number().gt(0).lte(1)), // unitless
    threadFrictionCoefficientRangeMax: numericString(z.number().gt(0).lte(1)), // unitless
    headFrictionCoefficientRangeMin: numericString(z.number().gt(0).lte(1)), // unitless
    headFrictionCoefficientRangeMax: numericString(z.number().gt(0).lte(1)), // unitless
  })
  .refine(
    (data) => {
      return (
        data.regionOfContactUnderNutOrBoltHeadInnerDiameter === undefined ||
        data.regionOfContactUnderNutOrBoltHeadOuterDiameter === undefined ||
        data.regionOfContactUnderNutOrBoltHeadInnerDiameter <
          data.regionOfContactUnderNutOrBoltHeadOuterDiameter
      );
    },
    {
      message: "Inner diameter must be smaller than outer diameter",
      path: ["regionOfContactUnderNutOrBoltHeadInnerDiameter"],
    }
  )
  .refine(
    (data) => {
      return (
        data.tensileStrengthRangeMin === undefined ||
        data.tensileStrengthRangeMax === undefined ||
        data.tensileStrengthRangeMin <= data.tensileStrengthRangeMax
      );
    },
    {
      message:
        "Minimum tensile strength must be smaller than maximum tensile strength",
      path: ["tensileStrengthRangeMin"],
    }
  )
  .refine(
    (data) => {
      return (
        data.yieldOverTensileStrengthRatioRangeMin === undefined ||
        data.yieldOverTensileStrengthRatioRangeMax === undefined ||
        data.yieldOverTensileStrengthRatioRangeMin <=
          data.yieldOverTensileStrengthRatioRangeMax
      );
    },
    {
      message:
        "Minimum yield over tensile strength ratio must be smaller than maximum yield over tensile strength ratio",
      path: ["yieldOverTensileStrengthRatioRangeMin"],
    }
  )
  .refine(
    (data) => {
      return (
        data.threadFrictionCoefficientRangeMin === undefined ||
        data.threadFrictionCoefficientRangeMax === undefined ||
        data.threadFrictionCoefficientRangeMin <=
          data.threadFrictionCoefficientRangeMax
      );
    },
    {
      message:
        "Minimum thread friction coefficient must be smaller than maximum thread friction coefficient",
      path: ["threadFrictionCoefficientRangeMin"],
    }
  )
  .refine(
    (data) => {
      return (
        data.headFrictionCoefficientRangeMin === undefined ||
        data.headFrictionCoefficientRangeMax === undefined ||
        data.headFrictionCoefficientRangeMin <=
          data.headFrictionCoefficientRangeMax
      );
    },
    {
      message:
        "Minimum head friction coefficient must be smaller than maximum head friction coefficient",
      path: ["headFrictionCoefficientRangeMin"],
    }
  )
  .refine(
    (data) => {
      return (
        data.nominalDiameter === undefined ||
        data.reducedShankDiameter === undefined ||
        data.reducedShankDiameter < data.nominalDiameter
      );
    },
    {
      message:
        "Reduced shank diameter must be smaller than nominal diameter",
      path: ["reducedShankDiameter"],
    }
  );


type ValidatedInputProps<InputsFieldsType> = {
  placeHolder?: string;
  fieldName: Path<InputsFieldsType>;
  className?: string;
  isSubmitting: boolean;
  errors: FieldErrorsImpl<DeepRequired<InputsFieldsType>>;
  // @ts-ignore
  register: UseFormRegister<InputsFieldsType>;
  unitString?: string;
  unit?: string;
};

function ValidatedInput<InputsFieldsType>({
  placeHolder,
  fieldName,
  className,
  isSubmitting,
  errors,
  register,
  unitString,
  unit,
}: ValidatedInputProps<InputsFieldsType>) {
  // Todo: figure out how to catch invalid fieldnames without using ts-ignore like a n00b
  
  // @ts-ignore
  const inputError = errors[fieldName]
    ? {
      className: `${styles.torqueCalculatorFieldErrorMessage}`,
      text: errors[fieldName]?.message
    }
    : {
      className: `${styles.torqueCalculatorFieldErrorMessage} ${utilityStyles.hidden}`,
      text: "no error"
    }

  const inputClassName = ""
    + (unitString !== undefined ? " with-units" : "")
    // @ts-ignore
    + (errors[fieldName] ? " input-error" : "");

  return (
    <div className={className} placeholder={placeHolder}>
      {unit !== undefined && (
        <> {<span className={styles.inputLabel}>{unit}{" "}</span>}</>
      )}
      <input
        className={inputClassName}
        placeholder={placeHolder}
        type="text"
        {...register(fieldName)}
        disabled={isSubmitting}
      />
      {unitString !== undefined && (
        <>
          {<span className={styles.torqueCalculatorUnitString}>{unitString}</span>}
        </>
      )}
      <p className={inputError.className}>
        {inputError.text}
      </p>
    </div>
  );
}


function inchesOrMillimetersDistanceToMillimeters(currentUnitSystem: UnitSystem, distance: number) {
  if (currentUnitSystem === UnitSystem.Metric) {
    return distance;
  }

  return distance * MM_PER_INCH
}

function torqueCalculatorInputsToCalculateTorqueProps(
  data: TorqueCalculatorInputsFormType,
  currentUnitSystem: UnitSystem
): { 
  metric: CalculateTorqueProps<
    UnitsDistance.Millimeter, 
    UnitsPitch.MillimetersPerThread, 
    UnitsTensileStrength.MegaPascal
  >,
  imperial?: CalculateTorqueProps<
    UnitsDistance.Inch, 
    UnitsPitch.ThreadsPerInch, 
    UnitsTensileStrength.PSI
  >
} {
  const metric: CalculateTorqueProps<
    UnitsDistance.Millimeter, 
    UnitsPitch.MillimetersPerThread, 
    UnitsTensileStrength.MegaPascal
  > = {
    targetTorqueOverYieldRatio: data.targetTorqueOverYieldPercentage, // Is this a raw number ratio, or a percentage?
    nominalDiameter: {
      value: inchesOrMillimetersDistanceToMillimeters(currentUnitSystem, data.nominalDiameter),
      unit: UnitsDistance.Millimeter,
    },
    threadPitch: {
      value:
        currentUnitSystem === UnitSystem.Metric
          ? data.threadPitch
          : MM_PER_INCH / data.threadPitch, // convert threads per inch to mm per thread
      unit: UnitsPitch.MillimetersPerThread,
    },
    regionOfContactUnderNutOrBoltHead: {
      outerDiameter: {
        value: inchesOrMillimetersDistanceToMillimeters(currentUnitSystem, data.regionOfContactUnderNutOrBoltHeadOuterDiameter),
        unit: UnitsDistance.Millimeter,
      },
      innerDiameter: {
        value: inchesOrMillimetersDistanceToMillimeters(currentUnitSystem, data.regionOfContactUnderNutOrBoltHeadInnerDiameter),
        unit: UnitsDistance.Millimeter,
      },
    },
    enagementLength: {
      value: inchesOrMillimetersDistanceToMillimeters(currentUnitSystem, data.engagementLength),
      unit: UnitsDistance.Millimeter,
    },
    reducedShankDiameter: data.reducedShankDiameter
      ? {
          value: inchesOrMillimetersDistanceToMillimeters(currentUnitSystem, data.reducedShankDiameter),
          unit: UnitsDistance.Millimeter,
        }
      : undefined,
    tensileStrengthRange: {
      unit: UnitsTensileStrength.MegaPascal,
      minValue:
        currentUnitSystem === UnitSystem.Metric
          ? data.tensileStrengthRangeMin
          : data.tensileStrengthRangeMin * MEGAPASCALS_PER_PSI, // convert imperial to metric
      maxValue:
        currentUnitSystem === UnitSystem.Metric
          ? data.tensileStrengthRangeMax
          : data.tensileStrengthRangeMax * MEGAPASCALS_PER_PSI,
    },
    yieldOverTensileStrengthRatioRange: {
      minValue: data.yieldOverTensileStrengthRatioRangeMin,
      maxValue: data.yieldOverTensileStrengthRatioRangeMax,
      unit: 1,
    },
    threadFrictionCoefficientRange: {
      minValue: data.threadFrictionCoefficientRangeMin,
      maxValue: data.threadFrictionCoefficientRangeMax,
      unit: 1,
    },
    headFrictionCoefficientRange: {
      minValue: data.headFrictionCoefficientRangeMin,
      maxValue: data.headFrictionCoefficientRangeMax,
      unit: 1,
    },
  };

  if (currentUnitSystem === UnitSystem.Metric) {
    return { metric: metric }
  }
  
  // otherwise, unit system is imperial:
  return {
    metric: metric,
    imperial: {
      targetTorqueOverYieldRatio: data.targetTorqueOverYieldPercentage, // Is this a raw number ratio, or a percentage?
      nominalDiameter: {
        value: data.nominalDiameter,
        unit: UnitsDistance.Inch,
      },
      threadPitch: {
        value: data.threadPitch,
        unit: UnitsPitch.ThreadsPerInch,
      },
      regionOfContactUnderNutOrBoltHead: {
        outerDiameter: {
          value: data.regionOfContactUnderNutOrBoltHeadOuterDiameter,
          unit: UnitsDistance.Inch,
        },
        innerDiameter: {
          value: data.regionOfContactUnderNutOrBoltHeadInnerDiameter,
          unit: UnitsDistance.Inch,
        },
      },
      enagementLength: {
        value: data.engagementLength,
        unit: UnitsDistance.Inch,
      },
      reducedShankDiameter: data.reducedShankDiameter
        ? {
            value: data.reducedShankDiameter,
            unit: UnitsDistance.Inch,
          }
        : undefined,
      tensileStrengthRange: {
        unit: UnitsTensileStrength.PSI,
        minValue: data.tensileStrengthRangeMin,
        maxValue: data.tensileStrengthRangeMax,
      },
      yieldOverTensileStrengthRatioRange: {
        minValue: data.yieldOverTensileStrengthRatioRangeMin,
        maxValue: data.yieldOverTensileStrengthRatioRangeMax,
        unit: 1,
      },
      threadFrictionCoefficientRange: {
        minValue: data.threadFrictionCoefficientRangeMin,
        maxValue: data.threadFrictionCoefficientRangeMax,
        unit: 1,
      },
      headFrictionCoefficientRange: {
        minValue: data.headFrictionCoefficientRangeMin,
        maxValue: data.headFrictionCoefficientRangeMax,
        unit: 1,
      },
    }
  }
}

type TorqueCalculatorInputFieldProps<InputsFieldsType> = {
  wrapperClassName: string;
  headerClassName: string;
  headerText: string;
  placeHolder?: string;
  unitString?: string;
  tooltip?: {
    content: React.ReactNode;
    position?: PositionType;
  };
} & ValidatedInputProps<InputsFieldsType>;

function TorqueCalculatorInputField<InputsFieldsType>({
  wrapperClassName,
  headerClassName,
  headerText,
  placeHolder,
  unitString,
  tooltip,
  fieldName,
  className: inputClassName,
  errors,
  isSubmitting,
  register,
}: TorqueCalculatorInputFieldProps<InputsFieldsType>) {
  const header =
    tooltip === undefined ? (
      <>
        <h2 className={styles.torqueHeader}>{headerClassName}{headerText}</h2>
      </>
    ) : (
      <>
        <h2 className={styles.torqueHeader}>
          {headerText}{" "}
          <FfTooltip
            tooltipContent={tooltip.content}
            tooltipDecorator="icon-pro-tip"
            position={tooltip?.position ? tooltip?.position : "right"}
          >
            <img
              alt="info"
              src={infoIcon}
              className={`${generalStyles.infoIcon} ${findingItStyles.findingItFieldHeaderIcon}`}
            />
          </FfTooltip>
        </h2>
      </>
    );

  return (
    <>
      <div className={wrapperClassName}>
        {header}
        <ValidatedInput<InputsFieldsType>
          placeHolder={placeHolder}
          fieldName={fieldName}
          className={inputClassName}
          errors={errors}
          isSubmitting={isSubmitting}
          register={register}
          unitString= {unitString}
        />
      </div>
    </>
  );
}

type TorqueCalculatorInputsFormType = z.infer<
  typeof TorqueCalculatorInputsFormSchema
>;

type TorqueCalculatorInputsProps = {
  calculatedValues?: CalculatedTorqueValues;
  setCalculatedValues: React.Dispatch<
    React.SetStateAction<undefined | CalculatedTorqueValues>
  >;
  calculateTorqueInputs?: CalculateTorquePropsMetricWithOptionalImperial;
  setCalculateTorqueInputs: React.Dispatch<
    React.SetStateAction<undefined | CalculateTorquePropsMetricWithOptionalImperial>
  >;
  unitSystem: UnitSystem;
  setUnitSystem: React.Dispatch<React.SetStateAction<UnitSystem>>;
};

const TorqueCalculatorInputs = ({
  calculatedValues,
  setCalculatedValues,
  calculateTorqueInputs,
  setCalculateTorqueInputs,
  unitSystem,
  setUnitSystem,
}: TorqueCalculatorInputsProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    resetField,
    formState: { errors, isSubmitting },
  } = useForm<TorqueCalculatorInputsFormType>({
    resolver: zodResolver(TorqueCalculatorInputsFormSchema),
    mode: "onTouched",
  });

  // console.log("errors", errors);

  const onSubmit: SubmitHandler<TorqueCalculatorInputsFormType> = async (
    data,
    e
  ) => {
    // console.log("Data submitted:", data);
    e?.preventDefault();
    const CalculateTorquePropsMetricWithOptionalImperial = torqueCalculatorInputsToCalculateTorqueProps(
      data,
      unitSystem
    );
    setCalculateTorqueInputs(CalculateTorquePropsMetricWithOptionalImperial);
    const calculatedValuesMetric = calculateTorque(CalculateTorquePropsMetricWithOptionalImperial.metric);

    const calculatedValuesMetricOrImperial = (unitSystem === UnitSystem.Metric)
      ? calculatedValuesMetric
      : torqueCalculatorResultsToImperial(calculatedValuesMetric);

    setCalculatedValues(calculatedValuesMetricOrImperial);
  };

  return (
    <>
      <div className={`${generalStyles.featureHeader} ${styles.torquingItHeader}`}>
        <FESBranded
          tagClass={generalStyles.fesTag}
          name="TorquingIt"
          wrapperClass={generalStyles.fesBrandedHeader}
          tradeMarkClass="fes-branded-trademark"
        />
      </div>
      <div className={styles.torqueCardWrapper}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.torqueText}>We offer a generic torque calculator for our public users. Still, for SaaS clients, we suggest putting a user-friendly face to your proven bolt and torque calculations with pro tips and prefilled answers based on JoiningIt and FindingIt.</div>
          <br></br>
          <div className={styles.torqueText}>We do this so more D.R.E.s can fill out the calculator vs. the 5 out of 2000 designers from your team who can fill out your complicated spreadsheet calculator’s.</div>
          <div className={styles.torqueCard}>
            <img
              src={torqueDiagram1}
              alt="screw diagram"
              className={styles.torqueImage}
            />
            <TorqueCalculatorInputField
              wrapperClassName={`${styles.targetTorqueWrapper} ${styles.torqueFieldWrapper}`}
              headerClassName={styles.targetTorqueHeader}
              headerText="Target Torque as % of Yield"
              fieldName={"targetTorqueOverYieldPercentage"}
              className={styles.selectTargetTorque}
              errors={errors}
              isSubmitting={isSubmitting}
              register={register}
              tooltip={{
                content: (
                  <FindingItTooltipContent
                    title="Target Torque as % of Yield"
                    text="Depends on the scatter of the friction coefficients, tool accuracy and tightening method.The lower the scatter, the higher strength utilization you can have."
                    // imgSrc={torqueDiagram1}
                  />
                ),
              }}
            />
            <div className={styles.unitsTorqueWrapper}>
                <TorqueUnitSystemToggle
                  currentUnitSystem={unitSystem}
                  setUnitSystem={setUnitSystem}
                  // disableToggle
                />
            </div>
            <div className={`${styles.tensileStrengthWrapper} ${styles.torqueRangedFieldWrapper} ${styles.torqueFieldWrapper}`}>
              <div className={`${styles.torqueRangedFieldHeader} ${styles.torqueHeader}`}>
                Tensile Strength Range
              </div>
              <ValidatedInput
                // placeHolder="minimum"
                fieldName={"tensileStrengthRangeMin"}
                className={styles.torqueRangedFieldContentRow1}
                errors={errors}
                isSubmitting={isSubmitting}
                register={register}
                unitString={
                  unitSystem === UnitSystem.Metric
                    ? UnitsTorque.MegaPascal
                    : UnitsForce.PSI
                }
                unit="min"
              />
              <ValidatedInput
                // placeHolder="maximum"
                fieldName={"tensileStrengthRangeMax"}
                className={styles.torqueRangedFieldContentRow2}
                errors={errors}
                isSubmitting={isSubmitting}
                register={register}
                unitString={
                  unitSystem === UnitSystem.Metric
                    ? UnitsTorque.MegaPascal
                    : UnitsForce.PSI
                }
                unit="max"
              />
            </div>
            <div className={`${styles.yieldRatioWrapper} ${styles.torqueRangedFieldWrapper} ${styles.torqueFieldWrapper}`}>
              <div className={`${styles.torqueRangedFieldHeader} ${styles.torqueHeader}`}>
                Yield / Tensile Strength Ratio
              </div>
              <ValidatedInput
                // placeHolder="minimum"
                fieldName={"yieldOverTensileStrengthRatioRangeMin"}
                className={styles.torqueRangedFieldContentRow1}
                errors={errors}
                isSubmitting={isSubmitting}
                register={register}
                unit="min"
              />
              <ValidatedInput
                // placeHolder="maximum"
                fieldName={"yieldOverTensileStrengthRatioRangeMax"}
                className={styles.torqueRangedFieldContentRow2}
                errors={errors}
                isSubmitting={isSubmitting}
                register={register}
                unit="max"
              />
            </div>

            {/* --------------------------------------------------------- */}

            <div className={`${styles.torqueNominalWrapper} ${styles.torqueFieldWrapper}`}>
              <div className={styles.torqueHeader}>
                Diameter and Pitch
                {" "}
                <FfTooltip
                  tooltipContent={<p>For same diameter, fine threads will usually provide higher fatigue strength due to a higher cross section area on the threads.</p>}
                  tooltipDecorator="icon-pro-tip"
                  position={"right"}
                >
                  <img
                    alt="info"
                    src={infoIcon}
                    className={`${utilityStyles.infoIcon} ${findingItStyles.findingItFieldHeaderIcon}`}
                  />
                </FfTooltip>
              </div>
                <Select
                  // className={styles.diameterPitchSelect}
                  options={diameterPitchOptions[unitSystem]}
                  // @ts-ignore This works fine. Typescript finds no errors when in same file as custom Option definition
                  components={{ Option: ReactSelectOptionWithTooltip }}
                  // @ts-ignore
                  onChange={(
                    newValue: ReactSelectOption<
                      { diameter: number; pitch: number },
                      string
                    > | null
                  ) => {
                    // console.log("newValue:", newValue);
                    if (!newValue || !newValue.value) {
                      resetField("nominalDiameter");
                      resetField("threadPitch");
                    } else {
                      setValue("nominalDiameter", newValue.value.diameter);
                      setValue("threadPitch", newValue.value.pitch);
                    }
                  }}
                  required={true}
                  isClearable={true}
                />
              <TorqueCalculatorInputField
                wrapperClassName=" "
                headerClassName={`${styles.shankDiameterHeader} ${styles.torqueHeader}`}
                headerText="Reduced Shank Diameter"
                placeHolder="Optional"
                tooltip={{
                  content: (
                    <FindingItTooltipContent
                      title="Reduced Shank Diameter"
                      text="Reduce shank is often used to promote a more controlled elongation of the fastener, in special when tightened into yield. That avoids thread deformations that could lead to higher loss in clamp-load due to relaxation."
                      // imgSrc={torqueDiagram1}
                    />
                  ),
                }}
                unitString={
                    unitSystem === UnitSystem.Metric
                      ? UnitsDistance.Millimeter
                      : UnitsDistance.Inch
                  }
                fieldName={"reducedShankDiameter"}
                className={styles.selectShankDiameter}
                errors={errors}
                isSubmitting={isSubmitting}
                register={register}
              />
            </div>

            {/* --------------------------------------------------------- */}
            
            {/* <TorqueCalculatorInputField
              wrapperClassName="torque-shank-wrapper torque-field-wrapper"
              headerClassName="shank-diameter-header torque-header"
              headerText="Reduced Shank Diameter"
              placeHolder="Optional"
              tooltip={{
                content: (
                  <FindingItTooltipContent
                    title="Reduced Shank Diameter"
                    text="Determinded by whether the bolt or stud has any diameter reduction in the shank. Especially if the diameter is smaller than the root diameter of the fastener."
                    // imgSrc={torqueDiagram1}
                  />
                ),
              }}
               unitString={
                  unitSystem === UnitSystem.Metric
                    ? UnitsDistance.Millimeter
                    : UnitsDistance.Inch
                }
              fieldName={"reducedShankDiameter"}
              className={"select-shank-diameter"}
              errors={errors}
              isSubmitting={isSubmitting}
              register={register}
            /> */}
            <TorqueCalculatorInputField
              wrapperClassName={`${styles.torqueEngagementWrapper} ${styles.torqueFieldWrapper}`}
              headerClassName={`${styles.engagementHeader} ${styles.torqueHeader}`}
              headerText="Thread Engagement Length"
              tooltip={{
                content: (
                  <FindingItTooltipContent
                    title="Thread Engegement Length"
                    text="Recommended to be at least the same as fastener diameter. If lees, it can affect the max load the threads can handle. For alluminium threads agains steel threads, the minimum recommended engagement is 1.5X."
                    // imgSrc={torqueDiagram1}
                  />
                ),
              }}
               unitString={
                  unitSystem === UnitSystem.Metric
                    ? UnitsDistance.Millimeter
                    : UnitsDistance.Inch
                }
              fieldName={"engagementLength"}
              className={styles.selectEngagement}
              errors={errors}
              isSubmitting={isSubmitting}
              register={register}
            />
            <div className={`${styles.torqueThreadFrictionWrapper} ${styles.torqueRangedFieldWrapper} ${styles.torqueFieldWrapper}`}>
              <div className={`${styles.torqueRangedFieldHeader} ${styles.torqueHeader}`}>
                Thread Friction Coefficient (K)
              </div>
              <ValidatedInput
                // placeHolder="minimum"
                fieldName={"threadFrictionCoefficientRangeMin"}
                className={styles.torqueRangedFieldContentRow1}
                errors={errors}
                isSubmitting={isSubmitting}
                register={register}
                unit="min"
              />
              <ValidatedInput
                // placeHolder="maximum"
                fieldName={"threadFrictionCoefficientRangeMax"}
                className={styles.torqueRangedFieldContentRow2}
                errors={errors}
                isSubmitting={isSubmitting}
                register={register}
                unit="max"
              />
            </div>
              <TorqueCalculatorInputField
              wrapperClassName={`${styles.torqueOuterDiameterWrapper} ${styles.torqueFieldWrapper}`}
              headerClassName={`${styles.outerDiameterHeader} ${styles.torqueHeader}`}
              headerText="Outer Diameter of Head or Nut"
              tooltip={{
                content: (
                  <FindingItTooltipContent
                    title="Outer Diameter of Head or Nut"
                    text="External contact diameter between bolt or nut (element that rotates and counter part"
                    // imgSrc={torqueDiagram1}
                  />
                ),
              }}
              unitString={
                  unitSystem === UnitSystem.Metric
                    ? UnitsDistance.Millimeter
                    : UnitsDistance.Inch
                }
              fieldName={"regionOfContactUnderNutOrBoltHeadOuterDiameter"}
              className={styles.selectOuterDiameter}
              errors={errors}
              isSubmitting={isSubmitting}
              register={register}
            />
            <TorqueCalculatorInputField
              wrapperClassName={`${styles.torqueInnerDiameterWrapper} ${styles.torqueFieldWrapper}`}
              headerClassName={`${styles.innerDiameterHeader} ${styles.torqueHeader}`}
              headerText="Hole Diameter Under Head or Nut"
              tooltip={{
                content: (
                  <FindingItTooltipContent
                    title="Hole Diameter Under Head or Nut"
                    text="Diameter of hole underneath spinning element (bolt or nut)"
                    // imgSrc={torqueDiagram1}
                  />
                ),
              }}
               unitString={
                  unitSystem === UnitSystem.Metric
                    ? UnitsDistance.Millimeter
                    : UnitsDistance.Inch
                }
              fieldName={"regionOfContactUnderNutOrBoltHeadInnerDiameter"}
              className={styles.selectInnerDiameter}
              errors={errors}
              isSubmitting={isSubmitting}
              register={register}
            />
            <div className={`${styles.torqueHeadFrictionWrapper} ${styles.torqueFieldWrapper}`}>
              <div className={`${styles.torqueRangedFieldHeader} ${styles.torqueHeader}`}>
                Head Friction Coefficient (K)
              </div>
              {/* <div className={styles.torqueMin}>min</div> */}
              <ValidatedInput
                // placeHolder="minimum"
                fieldName={"headFrictionCoefficientRangeMin"}
                className={styles.torqueRangedFieldContentRow1}
                errors={errors}
                isSubmitting={isSubmitting}
                register={register}
                unit="min"
              />
              {/* <div className={styles.torqueMax}>max</div> */}
              <ValidatedInput
                // placeHolder="maximum"
                fieldName={"headFrictionCoefficientRangeMax"}
                className={styles.torqueRangedFieldContentRow2}
                errors={errors}
                isSubmitting={isSubmitting}
                register={register}
                unit="max"
              />
            </div>
          </div>
          {!calculatedValues && (
            <div className={styles.torqueSubmitWrapper}>
              <Button className={styles.torqueSubmit} type="submit">
                Submit
              </Button>
            </div>
          )}
        </form>
      </div>
    </>
  );
};


function kilonewtonsToPoundForce(quantity: DimensionedQuantity<UnitsForce.Kilonewton>): DimensionedQuantity<UnitsForce.Lbf> {
  return {
    unit: UnitsForce.Lbf,
    value: quantity.value * POUND_FORCE_PER_KILONEWTON
    /*
      x kN * ( a LbF / b kN )
    */
  }
}

function newtonMetersToFootPoundForce(quantity: DimensionedQuantity<UnitsTorque.NewtonMeter>): DimensionedQuantity<UnitsTorque.FootLbF> {
  return {
    unit: UnitsTorque.FootLbF,
    value: quantity.value * FOOT_LB_F_PER_NEWTON_METER
  }
}

function megaPascalToPSI(quantity: DimensionedQuantity<UnitsPressure.MegaPascal>): DimensionedQuantity<UnitsPressure.PSI> {
  return {
    unit: UnitsPressure.PSI,
    value: quantity.value / MEGAPASCALS_PER_PSI
  }
}

function torqueCalculatorResultsToImperial(calculatedValues: ReturnType< typeof calculateTorque >): CalculatedTorqueValues {
  if (calculatedValues.targetTorque.unit !== UnitsTorque.NewtonMeter) {
    throw new Error("Input units are imperial, but should be metric");
  }
  const result = {
    targetTorque: newtonMetersToFootPoundForce(calculatedValues.targetTorque),
    minClampLoadOnYield: kilonewtonsToPoundForce(calculatedValues.minClampLoadOnYield),
    minTorqueOnYield: newtonMetersToFootPoundForce(calculatedValues.minTorqueOnYield),
    yieldFactorRange: calculatedValues.yieldFactorRange,
    minUltimateClampLoad: kilonewtonsToPoundForce(calculatedValues.minUltimateClampLoad) ,
    minUltimateTorque: newtonMetersToFootPoundForce(calculatedValues.minUltimateTorque),
    threadEngagementRatio: calculatedValues.threadEngagementRatio,
    nutFactorVariabilities: calculatedValues.nutFactorVariabilities,
    maxContactSurfacePressure: megaPascalToPSI(calculatedValues.maxContactSurfacePressure),
    clampLoads: {
      min: kilonewtonsToPoundForce(calculatedValues.clampLoads.min),
      max: kilonewtonsToPoundForce(calculatedValues.clampLoads.max),
      dispersion: calculatedValues.clampLoads.dispersion
    }
  }
  console.log(
    "torqueCalculatorResultsToImperial",
    "metric values (inputs)",
    calculatedValues,
    "imperial values (outputs)",
    result
  )
  return result;
}

type TorqueCalculatorResultProps = {
  calculatedValues: CalculatedTorqueValues;
  calculateTorqueInputs: CalculateTorquePropsMetricWithOptionalImperial;
  unitSystem: UnitSystem;
};

function TorqueCalculatorResult({
  calculatedValues,
  calculateTorqueInputs,
  unitSystem,
}: TorqueCalculatorResultProps) {
  const resultsHeaderRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    resultsHeaderRef.current?.scrollIntoView();
  }, []);

  const selectedCalculateTorqueInputs = (calculateTorqueInputs.imperial === undefined)
    ? calculateTorqueInputs.metric
    : calculateTorqueInputs.imperial

  return (
    <div className={styles.torqueCalculatorResultsWrapper}>
      <div
        ref={resultsHeaderRef}
        className={styles.torqueCalculatorResultsContainer}
      >
        <h2 className={findingItStyles.findingItSummaryHeader}>
          <span className={generalStyles.tealH2}>Torque Calculation Results:</span>
        </h2>
        <div>
          <dl>
            <dt>
              Target Torque at{" "}
              {selectedCalculateTorqueInputs.targetTorqueOverYieldRatio * 100}% of Yield
            </dt>
            <dd>{`${calculatedValues.targetTorque.value.toFixed(2)} ${
              calculatedValues.targetTorque.unit
            }`}</dd>
            <dt>Lowest Clamp-Load on Yield</dt>
            <dd>{`${calculatedValues.minClampLoadOnYield.value.toFixed(2)} ${
              calculatedValues.minClampLoadOnYield.unit
            }`}</dd>
            <dt>Lowest Torque on Yield</dt>
            <dd>{`${calculatedValues.minTorqueOnYield.value.toFixed(2)} ${
              calculatedValues.minTorqueOnYield.unit
            }`}</dd>
            <dt>Lowest Ultimate Clamp-Load</dt>
            <dd>{`${calculatedValues.minUltimateClampLoad.value.toFixed(2)} ${
              calculatedValues.minUltimateClampLoad.unit
            }`}</dd>
            <dt>Lowest Ultimate Torque</dt>
            <dd>{`${calculatedValues.minUltimateTorque.value.toFixed(2)} ${
              calculatedValues.minUltimateTorque.unit
            }`}</dd>
            <dt>Clamp-Load Range</dt>
            <dd>
              {calculatedValues.clampLoads.min.value.toFixed(2)} -{" "}
              {calculatedValues.clampLoads.max.value.toFixed(2)}{" "}
              {calculatedValues.clampLoads.max.unit}
            </dd>
            <dt>Clamp-Load Dispersion</dt>
            <dd>{calculatedValues.clampLoads.dispersion.toFixed(0)} %</dd>
            <dt>Max contact surface pressure</dt>
            <dd>
              {calculatedValues.maxContactSurfacePressure.value.toFixed(2)}{" "}
              {calculatedValues.maxContactSurfacePressure.unit}
            </dd>
            <dt>Thread Engagement Ratio</dt>
            <dd>{calculatedValues.threadEngagementRatio.toFixed(0)} %</dd>
          </dl>
        </div>
        <img
          src={torqueDiagram4}
          alt="screw diagram"
          className={styles.torqueImage2}
        />
      </div>
      <div className={styles.torqueCalculatorInputSummaryContainer}>
        <dl>
          <dt>Target Torque</dt>{" "}
          {/* Should this be Target Torque or Target Torque over Yield Ratio? */}
          <dd>{`${
            selectedCalculateTorqueInputs.targetTorqueOverYieldRatio * 100
          } %`}</dd>
          <dt>Tensile Strength</dt>
          <dd>
            {`${selectedCalculateTorqueInputs.tensileStrengthRange.minValue.toFixed(
              2
            )}  ${selectedCalculateTorqueInputs.tensileStrengthRange.unit}`}{" "}
            -
            {` ${selectedCalculateTorqueInputs.tensileStrengthRange.maxValue.toFixed(
              2
            )}  ${selectedCalculateTorqueInputs.tensileStrengthRange.unit}`}
          </dd>
          <dt>Yield / Tensile Strength Ratio</dt>
          <dd>
            {`${selectedCalculateTorqueInputs.yieldOverTensileStrengthRatioRange.minValue.toFixed(
              3
            )} `}{" "}
            -
            {` ${selectedCalculateTorqueInputs.yieldOverTensileStrengthRatioRange.maxValue.toFixed(
              3
            )}  `}
          </dd>
          <dt>Nominal Diameter</dt>
          <dd>{`${selectedCalculateTorqueInputs.nominalDiameter.value.toFixed(2)} ${
            selectedCalculateTorqueInputs.nominalDiameter.unit
          }`}</dd>
          <dt>Reduced Shank Diameter</dt>
          <dd>{`${selectedCalculateTorqueInputs.reducedShankDiameter?.value.toFixed(2)} ${selectedCalculateTorqueInputs.reducedShankDiameter?.unit}`}</dd>
          <dt>Pitch</dt>
          <dd>{`${selectedCalculateTorqueInputs.threadPitch.value.toFixed(2)} ${
            selectedCalculateTorqueInputs.threadPitch.unit
          }`}</dd>
          <dt>Thread Engagement Length</dt>
          <dd>{`${selectedCalculateTorqueInputs.enagementLength.value.toFixed(2)} ${
            selectedCalculateTorqueInputs.enagementLength.unit
          }`}</dd>
          <dt>Thread Friction Coefficient (K)</dt>
          <dd>
            {`${selectedCalculateTorqueInputs.threadFrictionCoefficientRange.minValue.toFixed(
              2
            )} `}{" "}
            -
            {` ${selectedCalculateTorqueInputs.threadFrictionCoefficientRange.maxValue.toFixed(
              2
            )} `}
          </dd>
          <dt>Head or Nut Outer Diameter</dt>
          <dd>{`${selectedCalculateTorqueInputs.regionOfContactUnderNutOrBoltHead.outerDiameter.value.toFixed(
            2
          )} ${
            selectedCalculateTorqueInputs.regionOfContactUnderNutOrBoltHead
              .outerDiameter.unit
          }`}</dd>
          <dt>Hole Diameter Under Head or Nut</dt>
          <dd>{`${selectedCalculateTorqueInputs.regionOfContactUnderNutOrBoltHead.innerDiameter.value.toFixed(
            2
          )} ${
            selectedCalculateTorqueInputs.regionOfContactUnderNutOrBoltHead
              .innerDiameter.unit
          }`}</dd>
          <dt>Head Friction Coefficient (K)</dt>
          <dd>
            {`${selectedCalculateTorqueInputs.headFrictionCoefficientRange.minValue.toFixed(
              2
            )} `}{" "}
            -
            {` ${selectedCalculateTorqueInputs.headFrictionCoefficientRange.maxValue.toFixed(
              2
            )}`}
          </dd>
        </dl>
        <img
          src={torqueDiagram4}
          alt="screw diagram"
          className={styles.torqueImage3}
        />
      </div>
    </div>
  );
}

type CalculateTorquePropsMetricWithOptionalImperial = {
  metric: CalculateTorqueProps<
    UnitsDistance.Millimeter, 
    UnitsPitch.MillimetersPerThread, 
    UnitsTensileStrength.MegaPascal
  >,
  imperial?: CalculateTorqueProps<
  UnitsDistance.Inch, 
  UnitsPitch.ThreadsPerInch, 
  UnitsTensileStrength.PSI
>
}

function TorqueCalculator() {
  const [calculatedValues, setCalculatedValues] = useState<
    undefined | CalculatedTorqueValues
  >();
  const [calculateTorqueInputs, setCalculateTorqueInputs] = useState<
    undefined | CalculateTorquePropsMetricWithOptionalImperial
  >();
  const [unitSystem, setUnitSystem] = useState<UnitSystem>(UnitSystem.Metric);

  // This preload stuff will be useful when we're passing in preloads from other components. Implement later.
  // const location = useLocation()
  // let preloadState: TorqueCalculatorPreloads | undefined = undefined
  // if (location && location.state && location.state.preload) {
  //   preloadState = location.state
  // }
  // console.log("preloadState", JSON.stringify(preloadState, null, 2))

  return (
    <>
      <div className={styles.torqueWrapper}>
        <TorqueCalculatorInputs
          setCalculatedValues={setCalculatedValues}
          calculatedValues={calculatedValues}
          calculateTorqueInputs={calculateTorqueInputs}
          setCalculateTorqueInputs={setCalculateTorqueInputs}
          unitSystem={unitSystem}
          setUnitSystem={setUnitSystem}
        />
        {calculatedValues && calculateTorqueInputs && (
          <TorqueCalculatorResult
            calculatedValues={calculatedValues}
            calculateTorqueInputs={calculateTorqueInputs}
            unitSystem={unitSystem}
          />
        )}
      </div>
    </>
  );
}

export default TorqueCalculator;

// function ScrollIntoView({ htmlElementRef }: {htmlElementRef: MutableRefObject<HTMLDivElement | null>}) {
//   // Height of header (and/or anything else necessitating offset)
//   const scrollOffsetPx = 100;

//   // const elementPosition = htmlElementRef?.getBoundingClientRect().top;
//   // if (elementPosition === undefined) {
//   //   return;
//   // }
//   // const offsetPosition = elementPosition + scrollOffsetPx;
//   useEffect(() => {
//     const elementPosition = htmlElementRef?.current?.getBoundingClientRect().top;
//     if (elementPosition === undefined) {
//       console.log("Element position undefined")
//       console.log("html element ref:", htmlElementRef)
//       console.log("element ref.current", htmlElementRef.current)
//       return;
//     }
//     const offsetPosition = elementPosition + scrollOffsetPx;
//     const documentBody = document?.querySelector("body");
//     console.log("document", document)
//     console.log("documentBody:", documentBody)
//     console.log("offset position", offsetPosition)
//     documentBody?.scrollTo(0, offsetPosition);
//   }, [htmlElementRef])
//   return <div style={{display: 'none'}}>test</div>
// }