import { UnitSystem } from "../FindIt/findItTypes";

export const diameterPitchOptions: Record<
  UnitSystem,
  Array<{
    label: string,
    value: {
      diameter: number,
      pitch: number,
    },
  }>
> = {
  [UnitSystem.Imperial]: [
    {
      label: `1/4"-20`,
      value: {
        diameter: 0.25,
        pitch: 20,
      },
    },
  ],
  [UnitSystem.Metric]: [
    {
      label: `M1 x 0.2`,
      value: {
        diameter: 1,
        pitch: 0.2,
      },
    },
    {
      label: `M1 x 0.25`,
      value: {
        diameter: 1,
        pitch: 0.25,
      },
    },
    {
      label: `M1.2 x 0.2`,
      value: {
        diameter: 1.2,
        pitch: 0.2,
      },
    },
    {
      label: `M1.2 x 0.25`,
      value: {
        diameter: 1.2,
        pitch: 0.25,
      },
    },
    {
      label: `M1.4 x 0.2`,
      value: {
        diameter: 1.4,
        pitch: 0.2,
      },
    },
    {
      label: `M1.4 x 0.3`,
      value: {
        diameter: 1.4,
        pitch: 0.3,
      },
    },
    {
      label: `M1.6 x 0.2`,
      value: {
        diameter: 1.6,
        pitch: 0.2,
      },
    },
    {
      label: `M1.6 x 0.35`,
      value: {
        diameter: 1.6,
        pitch: 0.35,
      },
    },
    {
      label: `M1.7 x 0.35`,
      value: {
        diameter: 1.7,
        pitch: 0.35,
      },
    },
    {
      label: `M1.8 x 0.2`,
      value: {
        diameter: 1.8,
        pitch: 0.2,
      },
    },
    {
      label: `M1.8 x 0.35`,
      value: {
        diameter: 1.8,
        pitch: 0.35,
      },
    },
    {
      label: `M2 x 0.25`,
      value: {
        diameter: 2,
        pitch: 0.25,
      },
    },
    {
      label: `M2 x 0.4`,
      value: {
        diameter: 2,
        pitch: 0.4,
      },
    },
    {
      label: `M2.2 x 0.25`,
      value: {
        diameter: 2.2,
        pitch: 0.25,
      },
    },
    {
      label: `M2.2 x 0.45`,
      value: {
        diameter: 2.2,
        pitch: 0.45,
      },
    },
    {
      label: `M2.3 x 0.4`,
      value: {
        diameter: 2.3,
        pitch: 0.4,
      },
    },
    {
      label: `M2.5 x 0.35`,
      value: {
        diameter: 2.5,
        pitch: 0.35,
      },
    },
    {
      label: `M2.5 x 0.45`,
      value: {
        diameter: 2.5,
        pitch: 0.45,
      },
    },
    {
      label: `M2.6 x 0.45`,
      value: {
        diameter: 2.6,
        pitch: 0.45,
      },
    },
    {
      label: `M3 x 0.35`,
      value: {
        diameter: 3,
        pitch: 0.35,
      },
    },
    {
      label: `M3 x 0.5`,
      value: {
        diameter: 3,
        pitch: 0.5,
      },
    },
    {
      label: `M3.5 x 0.35`,
      value: {
        diameter: 3.5,
        pitch: 0.35,
      },
    },
    {
      label: `M3.5 x 0.6`,
      value: {
        diameter: 3.5,
        pitch: 0.6,
      },
    },
    {
      label: `M4 x 0.5`,
      value: {
        diameter: 4,
        pitch: 0.5,
      },
    },
    {
      label: `M4 x 0.7`,
      value: {
        diameter: 4,
        pitch: 0.7,
      },
    },
    {
      label: `M5 x 0.5`,
      value: {
        diameter: 5,
        pitch: 0.5,
      },
    },
    {
      label: `M5 x 0.8`,
      value: {
        diameter: 5,
        pitch: 0.8,
      },
    },
    {
      label: `M6 x 0.75`,
      value: {
        diameter: 6,
        pitch: 0.75,
      },
    },
    {
      label: `M6 x 1`,
      value: {
        diameter: 6,
        pitch: 1,
      },
    },
    {
      label: `M7 x 0.75`,
      value: {
        diameter: 7,
        pitch: 0.75,
      },
    },
    {
      label: `M7 x 1`,
      value: {
        diameter: 7,
        pitch: 1,
      },
    },
    {
      label: `M8 x 0.75`,
      value: {
        diameter: 8,
        pitch: 0.75,
      },
    },
    {
      label: `M8 x 1`,
      value: {
        diameter: 8,
        pitch: 1,
      },
    },
    {
      label: `M8 x 1.25`,
      value: {
        diameter: 8,
        pitch: 1.25,
      },
    },
    {
      label: `M9 x 0.75`,
      value: {
        diameter: 9,
        pitch: 0.75,
      },
    },
    {
      label: `M9 x 1`,
      value: {
        diameter: 9,
        pitch: 1,
      },
    },
    {
      label: `M9 x 1.25`,
      value: {
        diameter: 9,
        pitch: 1.25,
      },
    },
    {
      label: `M10 x 0.75`,
      value: {
        diameter: 10,
        pitch: 0.75,
      },
    },
    {
      label: `M10 x 1`,
      value: {
        diameter: 10,
        pitch: 1,
      },
    },
    {
      label: `M10 x 1.25`,
      value: {
        diameter: 10,
        pitch: 1.25,
      },
    },
    {
      label: `M10 x 1.5`,
      value: {
        diameter: 10,
        pitch: 1.5,
      },
    },
    {
      label: `M11 x 0.75`,
      value: {
        diameter: 11,
        pitch: 0.75,
      },
    },
    {
      label: `M11 x 1`,
      value: {
        diameter: 11,
        pitch: 1,
      },
    },
    {
      label: `M11 x 1.5`,
      value: {
        diameter: 11,
        pitch: 1.5,
      },
    },
    {
      label: `M12 x 1`,
      value: {
        diameter: 12,
        pitch: 1,
      },
    },
    {
      label: `M12 x 1.25`,
      value: {
        diameter: 12,
        pitch: 1.25,
      },
    },
    {
      label: `M12 x 1.5`,
      value: {
        diameter: 12,
        pitch: 1.5,
      },
    },
    {
      label: `M12 x 1.75`,
      value: {
        diameter: 12,
        pitch: 1.75,
      },
    },
    {
      label: `M14 x 1`,
      value: {
        diameter: 14,
        pitch: 1,
      },
    },
    {
      label: `M14 x 1.25`,
      value: {
        diameter: 14,
        pitch: 1.25,
      },
    },
    {
      label: `M14 x 1.5`,
      value: {
        diameter: 14,
        pitch: 1.5,
      },
    },
    {
      label: `M14 x 2`,
      value: {
        diameter: 14,
        pitch: 2,
      },
    },
    {
      label: `M16 x 1`,
      value: {
        diameter: 16,
        pitch: 1,
      },
    },
    {
      label: `M16 x 1.5`,
      value: {
        diameter: 16,
        pitch: 1.5,
      },
    },
    {
      label: `M16 x 2`,
      value: {
        diameter: 16,
        pitch: 2,
      },
    },
    {
      label: `M18 x 1`,
      value: {
        diameter: 18,
        pitch: 1,
      },
    },
    {
      label: `M18 x 1.5`,
      value: {
        diameter: 18,
        pitch: 1.5,
      },
    },
    {
      label: `M18 x 2`,
      value: {
        diameter: 18,
        pitch: 2,
      },
    },
    {
      label: `M18 x 2.5`,
      value: {
        diameter: 18,
        pitch: 2.5,
      },
    },
    {
      label: `M20 x 1`,
      value: {
        diameter: 20,
        pitch: 1,
      },
    },
    {
      label: `M20 x 1.5`,
      value: {
        diameter: 20,
        pitch: 1.5,
      },
    },
    {
      label: `M20 x 2`,
      value: {
        diameter: 20,
        pitch: 2,
      },
    },
    {
      label: `M20 x 2.5`,
      value: {
        diameter: 20,
        pitch: 2.5,
      },
    },
    {
      label: `M22 x 1`,
      value: {
        diameter: 22,
        pitch: 1,
      },
    },
    {
      label: `M22 x 1.5`,
      value: {
        diameter: 22,
        pitch: 1.5,
      },
    },
    {
      label: `M22 x 2`,
      value: {
        diameter: 22,
        pitch: 2,
      },
    },
    {
      label: `M22 x 2.5`,
      value: {
        diameter: 22,
        pitch: 2.5,
      },
    },
    {
      label: `M24 x 1`,
      value: {
        diameter: 24,
        pitch: 1,
      },
    },
    {
      label: `M24 x 1.5`,
      value: {
        diameter: 24,
        pitch: 1.5,
      },
    },
    {
      label: `M24 x 2`,
      value: {
        diameter: 24,
        pitch: 2,
      },
    },
    {
      label: `M24 x 3`,
      value: {
        diameter: 24,
        pitch: 3,
      },
    },
    {
      label: `M27 x 1`,
      value: {
        diameter: 27,
        pitch: 1,
      },
    },
    {
      label: `M27 x 1.5`,
      value: {
        diameter: 27,
        pitch: 1.5,
      },
    },
    {
      label: `M27 x 2`,
      value: {
        diameter: 27,
        pitch: 2,
      },
    },
    {
      label: `M27 x 3`,
      value: {
        diameter: 27,
        pitch: 3,
      },
    },
    {
      label: `M30 x 1.5`,
      value: {
        diameter: 30,
        pitch: 1.5,
      },
    },
    {
      label: `M30 x 2`,
      value: {
        diameter: 30,
        pitch: 2,
      },
    },
    {
      label: `M30 x 3`,
      value: {
        diameter: 30,
        pitch: 3,
      },
    },
    {
      label: `M30 x 3.5`,
      value: {
        diameter: 30,
        pitch: 3.5,
      },
    },
    {
      label: `M33 x 1.5`,
      value: {
        diameter: 33,
        pitch: 1.5,
      },
    },
    {
      label: `M33 x 2`,
      value: {
        diameter: 33,
        pitch: 2,
      },
    },
    {
      label: `M33 x 3`,
      value: {
        diameter: 33,
        pitch: 3,
      },
    },
    {
      label: `M33 x 3.5`,
      value: {
        diameter: 33,
        pitch: 3.5,
      },
    },
    {
      label: `M36 x 1.5`,
      value: {
        diameter: 36,
        pitch: 1.5,
      },
    },
    {
      label: `M36 x 2`,
      value: {
        diameter: 36,
        pitch: 2,
      },
    },
    {
      label: `M36 x 3`,
      value: {
        diameter: 36,
        pitch: 3,
      },
    },
    {
      label: `M36 x 4`,
      value: {
        diameter: 36,
        pitch: 4,
      },
    },
    {
      label: `M39 x 1.5`,
      value: {
        diameter: 39,
        pitch: 1.5,
      },
    },
    {
      label: `M39 x 2`,
      value: {
        diameter: 39,
        pitch: 2,
      },
    },
    {
      label: `M39 x 3`,
      value: {
        diameter: 39,
        pitch: 3,
      },
    },
    {
      label: `M39 x 4`,
      value: {
        diameter: 39,
        pitch: 4,
      },
    },
    {
      label: `M42 x 2`,
      value: {
        diameter: 42,
        pitch: 2,
      },
    },
    {
      label: `M42 x 3`,
      value: {
        diameter: 42,
        pitch: 3,
      },
    },
    {
      label: `M42 x 4`,
      value: {
        diameter: 42,
        pitch: 4,
      },
    },
    {
      label: `M42 x 4.5`,
      value: {
        diameter: 42,
        pitch: 4.5,
      },
    },
    {
      label: `M45 x 2`,
      value: {
        diameter: 45,
        pitch: 2,
      },
    },
    {
      label: `M45 x 3`,
      value: {
        diameter: 45,
        pitch: 3,
      },
    },
    {
      label: `M45 x 4`,
      value: {
        diameter: 45,
        pitch: 4,
      },
    },
    {
      label: `M45 x 4.5`,
      value: {
        diameter: 45,
        pitch: 4.5,
      },
    },
    {
      label: `M48 x 2`,
      value: {
        diameter: 48,
        pitch: 2,
      },
    },
    {
      label: `M48 x 3`,
      value: {
        diameter: 48,
        pitch: 3,
      },
    },
    {
      label: `M48 x 4`,
      value: {
        diameter: 48,
        pitch: 4,
      },
    },
    {
      label: `M48 x 5`,
      value: {
        diameter: 48,
        pitch: 5,
      },
    },
    {
      label: `M52 x 2`,
      value: {
        diameter: 52,
        pitch: 2,
      },
    },
    {
      label: `M52 x 3`,
      value: {
        diameter: 52,
        pitch: 3,
      },
    },
    {
      label: `M52 x 4`,
      value: {
        diameter: 52,
        pitch: 4,
      },
    },
    {
      label: `M52 x 5`,
      value: {
        diameter: 52,
        pitch: 5,
      },
    },
    {
      label: `M56 x 2`,
      value: {
        diameter: 56,
        pitch: 2,
      },
    },
    {
      label: `M56 x 3`,
      value: {
        diameter: 56,
        pitch: 3,
      },
    },
    {
      label: `M56 x 4`,
      value: {
        diameter: 56,
        pitch: 4,
      },
    },
    {
      label: `M56 x 5.5`,
      value: {
        diameter: 56,
        pitch: 5.5,
      },
    },
    {
      label: `M60 x 2`,
      value: {
        diameter: 60,
        pitch: 2,
      },
    },
    {
      label: `M60 x 3`,
      value: {
        diameter: 60,
        pitch: 3,
      },
    },
    {
      label: `M60 x 4`,
      value: {
        diameter: 60,
        pitch: 4,
      },
    },
    {
      label: `M60 x 5.5`,
      value: {
        diameter: 60,
        pitch: 5.5,
      },
    },
    {
      label: `M64 x 2`,
      value: {
        diameter: 64,
        pitch: 2,
      },
    },
    {
      label: `M64 x 3`,
      value: {
        diameter: 64,
        pitch: 3,
      },
    },
    {
      label: `M64 x 4`,
      value: {
        diameter: 64,
        pitch: 4,
      },
    },
    {
      label: `M64 x 6`,
      value: {
        diameter: 64,
        pitch: 6,
      },
    },
    {
      label: `M68 x 2`,
      value: {
        diameter: 68,
        pitch: 2,
      },
    },
    {
      label: `M68 x 3`,
      value: {
        diameter: 68,
        pitch: 3,
      },
    },
    {
      label: `M68 x 4`,
      value: {
        diameter: 68,
        pitch: 4,
      },
    },
    {
      label: `M68 x 6`,
      value: {
        diameter: 68,
        pitch: 6,
      },
    },
    {
      label: `M72 x 2`,
      value: {
        diameter: 72,
        pitch: 2,
      },
    },
    {
      label: `M72 x 3`,
      value: {
        diameter: 72,
        pitch: 3,
      },
    },
    {
      label: `M72 x 4`,
      value: {
        diameter: 72,
        pitch: 4,
      },
    },
    {
      label: `M72 x 6`,
      value: {
        diameter: 72,
        pitch: 6,
      },
    },
    {
      label: `M80 x 2`,
      value: {
        diameter: 80,
        pitch: 2,
      },
    },
    {
      label: `M80 x 3`,
      value: {
        diameter: 80,
        pitch: 3,
      },
    },
    {
      label: `M80 x 4`,
      value: {
        diameter: 80,
        pitch: 4,
      },
    },
    {
      label: `M80 x 6`,
      value: {
        diameter: 80,
        pitch: 6,
      },
    },
    {
      label: `M90 x 2`,
      value: {
        diameter: 90,
        pitch: 2,
      },
    },
    {
      label: `M90 x 3`,
      value: {
        diameter: 90,
        pitch: 3,
      },
    },
    {
      label: `M90 x 4`,
      value: {
        diameter: 90,
        pitch: 4,
      },
    },
    {
      label: `M90 x 6`,
      value: {
        diameter: 90,
        pitch: 6,
      },
    },
    {
      label: `M100 x 2`,
      value: {
        diameter: 100,
        pitch: 2,
      },
    },
    {
      label: `M100 x 3`,
      value: {
        diameter: 100,
        pitch: 3,
      },
    },
    {
      label: `M100 x 4`,
      value: {
        diameter: 100,
        pitch: 4,
      },
    },
    {
      label: `M100 x 6`,
      value: {
        diameter: 100,
        pitch: 6,
      },
    },
  ]
}
