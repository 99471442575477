import { SelectedRivetParameters, SelectedThreadedParameters } from '../FindIt/findItTypes';
import { SelectedPartParameters } from "./FastenerRecommender";
import Select, { ReactSelectOptions } from '../utilities/FixRequiredSelect';
import { BasicFieldSelector } from './BasicFieldSelector';
import styles from "../../styles/_findIt.module.scss"


type FieldNames =
  | keyof SelectedThreadedParameters
  | keyof SelectedRivetParameters

type MaterialAndFinishFieldSelectorProps = {
  material: {
    selectionOptions: ReactSelectOptions<any, string>;
    required: boolean;
    fieldName: FieldNames;
    fieldTitle: string;
    parentField?: {
      fieldName: FieldNames,
      fieldTitle: string
    }
  },
  finish: {
    selectionOptions: ReactSelectOptions<any, string>;
    required: boolean;
    fieldName: FieldNames;
    fieldTitle: string;
    parentField?: {
      fieldName: FieldNames,
      fieldTitle: string
    }
  }
  fieldGroupTitle: string,
  // wrapperClassName: string;
  childClassName: string;
  // placeholder?: string,
  // isDisabled?: boolean,
  selectedPartParameters: SelectedPartParameters;
  setSelectedPartParameters: (newPartParameters: SelectedPartParameters) => void;
};

export function MaterialAndFinishFieldSelector(props: MaterialAndFinishFieldSelectorProps) {

  return <>
    <h2 className={`${styles.smallH2} ${styles.blueH2}`}>{props.fieldGroupTitle}</h2>
    <div className={props.childClassName}>
      <BasicFieldSelector
        {...props.material}
        selectedPartParameters={props.selectedPartParameters}
        setSelectedPartParameters={props.setSelectedPartParameters}
      />
    </div>
    <div className={props.childClassName}>
      <BasicFieldSelector
        {...props.finish}
        selectedPartParameters={props.selectedPartParameters}
        setSelectedPartParameters={props.setSelectedPartParameters}
      />
    </div>
  </>;
}
