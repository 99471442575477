type FESBrandedProps = {
  name: string;
  wrapperClass?: string;
  tagClass?: string;
  tradeMarkClass?: string
};

export function FESBranded(props: FESBrandedProps) {
  return (
    <div className={props.wrapperClass}>
      <abbr title="Finding Engineered Solutions" className={props.tagClass}>FES</abbr>
      {props.name}
      <span className={props.tradeMarkClass}>™</span>
    </div>
  );
}
