import { GetUpcomingLivestormEventsResponseSchema } from "./LivestormApiResponseSchema"
import { useEffect, useState } from "react";
import env from "../env";
import generalStyles from "../styles/style.module.scss"

export function LivestormEventList() {
  const height = 155
  const [showEvents, setShowEvents] = useState<boolean>(false);

  useEffect(() => {
    const checkForScheduledEvents = async () => {
      try {
        const getUpcomingLivestormEventsUrl = `${env.REACT_APP_CF_WORKERS_URL}/getUpcomingLivestormEvents`;
        const resp = await fetch(getUpcomingLivestormEventsUrl, {
          mode: 'cors',
        });
        const responseBody = await resp.json();
        // console.log("response body", responseBody)
        const { upcomingLivestormEvents } = GetUpcomingLivestormEventsResponseSchema.parse(responseBody);
        const newShowLivestormEvents = (upcomingLivestormEvents.length > 0);
        setShowEvents(newShowLivestormEvents);
      } catch (e) {
        console.error(JSON.stringify(e));

        setShowEvents(false);
      }
    }
    checkForScheduledEvents();
  }, []);

  return <>
    <iframe
      style={showEvents ? {} : { display: 'none' }}
      className={generalStyles.webinar}
      width="100%"
      height={height}
      src="https://app.livestorm.co/finding-fasteners/upcoming?limit=3"
      title="Heather Bostick events | Livestorm"
    ></iframe>
    {!showEvents &&
      <div style={{ width: '100%', height: height }} />
    }
  </>
}