import { FastenerNames, Fastener, FastenerTypes, UnitsDistance, UnitsPitch, PitchType, UnitsForce, UnitsTorque } from "./findItTypes";

export const fastenerList: Record<FastenerNames, Fastener> = {
  [FastenerNames.Bolt]: {
    "fastenerType": FastenerTypes.threaded,
    "name": FastenerNames.Bolt,
    "parameters": {
      "imperialDiameters": {
        "1": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 1
            },
            "text": "1\""
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 2
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 8
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 8,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 12,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 72700
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 39664
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 79600
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 44960
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 90900
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 49579
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 99400
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 56200
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true
          }
        },
        "0.06": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.06
            },
            "text": "#0"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.001
              }
            }
          ],
          "pitches": {
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 80,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 181
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 108
                }
              }
            },
            "Steel (Grade 8)": {
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 270
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 135
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Oval Head": true
          }
        },
        "0.073": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.073
            },
            "text": "#1"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.001
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 64,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 72,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 316
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 157
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 334
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 171
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 395
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 196
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 417
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 213
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Oval Head": true
          }
        },
        "0.086": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.086
            },
            "text": "#2"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.5
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.001
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 56,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 64,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 444
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 223
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 473
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 244
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 555
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 279
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 591
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 305
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Oval Head": true
          }
        },
        "0.099": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.099
            },
            "text": "#3"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.5
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.001
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 48,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 56,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 584
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 292
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 628
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 325
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 731
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 366
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 785
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 406
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.112": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.112
            },
            "text": "#4"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 2.5
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 40,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 48,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 725
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 357
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 793
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 408
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 906
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 447
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 992
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 510
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.125": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.125
            },
            "text": "#5"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 2.5
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 40,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 44,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 955
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 484
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 996
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 515
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 1194
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 605
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 1245
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 644
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.138": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.138
            },
            "text": "#6"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 3
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 32,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 40,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 1091
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 536
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 1218
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 629
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 1364
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 670
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 1523
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 787
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.164": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.164
            },
            "text": "#8"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 3.5
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 32,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 36,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 1680
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 861
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 1769
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 925
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 2100
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 1076
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 2211
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 1156
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.19": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.19
            },
            "text": "#10"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 3.5
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 24,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 32,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 2100
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 1043
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 2400
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 1262
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 2625
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 1304
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 3000
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 1577
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.25": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.25
            },
            "text": "1/4\""
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.375
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 8
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 20,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 28,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 3800
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 1935
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 4350
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 2343
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 4750
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 2419
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 5450
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 2929
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.3125": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.3125
            },
            "text": "5/16\""
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.375
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 8
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 18,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 24,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 6300
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 3265
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 6950
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 3774
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 7850
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 4081
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 8700
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 4717
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.375": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.375
            },
            "text": "3/8\""
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.5
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 8
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 16,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 24,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 9300
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 4880
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 10500
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 5821
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 11600
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 6099
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 13200
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 7276
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.4375": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.4375
            },
            "text": "7/16\""
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.5
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 8
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 14,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 20,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 12800
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 6717
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 14200
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 7846
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 15900
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 8396
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 17800
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 9808
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.5": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.5
            },
            "text": "1/2\""
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.5
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 8
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 13,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 20,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 17000
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 9048
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 19200
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 10700
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 21300
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 11310
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 24000
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 13376
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.625": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.625
            },
            "text": "5/8\""
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 1
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 8
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 11,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 18,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 27100
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 14525
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 30700
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 17279
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 33900
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 18157
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 38400
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 21599
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.75": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.75
            },
            "text": "3/4\""
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 1.5
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 8
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 10,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 16,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 40100
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 21737
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 44800
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 25290
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 50100
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 27172
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 56000
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 31613
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.875": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.875
            },
            "text": "7/8\""
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 1.5
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 8
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 9,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 14,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 55400
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 30181
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 61100
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 34594
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 69300
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 37726
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 76400
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 43242
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true
          }
        }
      },
      "metricDiameters": {
        "2": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2
            },
            "text": "M2 "
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 2.5
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 20
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.4,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.25,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 1.656
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 1.428
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 1.829
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 2.1528
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 1.789
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 2.292
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "3": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3
            },
            "text": "M3 "
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 4
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 30
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.5,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.35,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 4.02
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 3.6
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 4.222
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 5.23
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 4.511
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 5.291
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4
            },
            "text": "M4 "
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 5
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 40
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.7,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.05,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 7.02
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 6.224
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 7.316
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 9.13
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 7.799
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 9.167
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "5": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 5
            },
            "text": "M5 "
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 6
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 50
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.8,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.5,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 11.35
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 10.223
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 12.335
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 14.8
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 12.3163
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 15.456
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "6": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 6
            },
            "text": "M6 "
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 8
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 60
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 2
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.75,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 16.1
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 14.4
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 16.46
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 20.9
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 18.043
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 20.625
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "8": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 8
            },
            "text": "M8 "
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 10
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 80
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 2
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.25,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 29.2
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 26.493
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 31.36
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 29.263
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 38.1
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 33.196
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 40.8
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 36.667
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "10": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 10
            },
            "text": "M10 "
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 13
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 90
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 2
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.5,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.25,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 46.4
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 42.243
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 51.6
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 45.723
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 60.3
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 52.932
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 67.1
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 57.292
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "12": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 12
            },
            "text": "M12 "
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 16
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 90
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 3
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.75,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.25,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 67.4
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 61.652
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 70.5
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 70.169
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 87.7
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 77.251
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 91.6
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 87.922
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true
          }
        },
        "16": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 16
            },
            "text": "M16 "
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 20
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 90
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 3
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 2,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.5,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 125
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 117.052
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 134
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 128.683
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 163
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 146.667
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 174
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 161.241
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true
          }
        },
        "18": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 18
            },
            "text": "M18 "
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 22
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 100
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 3
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 2.5,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.5,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 159
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 141.825
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 179
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 167.926
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 200
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 177.708
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 225
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 210.413
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true
          }
        },
        "20": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 20
            },
            "text": "M20 "
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 24
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 100
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 3
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 2.5,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.5,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 203
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 182.893
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 226
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 212.384
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 255
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 229.167
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 283
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 266.12
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true
          }
        },
        "2.5": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2.5
            },
            "text": "M2.5 "
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 25
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.45,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.35,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 2.712
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 2.391
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 2.726
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 3.5256
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 2.995
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 3.416
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        }
      }
    }
  },
  [FastenerNames.MachineScrew]: {
    "fastenerType": FastenerTypes.threaded,
    "name": FastenerNames.MachineScrew,
    "parameters": {
      "imperialDiameters": {
        "1": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 1
            },
            "text": "1\""
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 2
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 8
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 8,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 12,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 72700
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 39664
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 79600
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 44960
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 90900
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 49579
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 99400
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 56200
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true
          }
        },
        "0.06": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.06
            },
            "text": "#0"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.001
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 80,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 80,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 181
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 108
                }
              }
            },
            "Steel (Grade 8)": {
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 270
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 135
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Oval Head": true
          }
        },
        "0.073": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.073
            },
            "text": "#1"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.25
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.001
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 64,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 72,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 316
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 157
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 334
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 171
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 395
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 196
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 417
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 213
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Oval Head": true
          }
        },
        "0.086": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.086
            },
            "text": "#2"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.5
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.001
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 56,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 64,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 444
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 223
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 473
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 244
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 555
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 279
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 591
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 305
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Oval Head": true
          }
        },
        "0.099": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.099
            },
            "text": "#3"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.1875
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.5
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.001
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 48,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 56,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 584
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 292
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 628
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 325
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 731
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 366
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 785
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 406
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Oval Head": true
          }
        },
        "0.112": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.112
            },
            "text": "#4"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.1875
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.5
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 40,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 48,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 725
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 357
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 793
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 408
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 906
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 447
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 992
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 510
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Oval Head": true
          }
        },
        "0.125": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.125
            },
            "text": "#5"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.5
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 40,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 44,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 955
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 484
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 996
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 515
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 1194
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 605
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 1245
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 644
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Oval Head": true
          }
        },
        "0.138": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.138
            },
            "text": "#6"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 3
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 32,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 40,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 1091
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 536
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 1218
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 629
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 1364
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 670
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 1523
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 787
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Oval Head": true
          }
        },
        "0.164": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.164
            },
            "text": "#8"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 32,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 36,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 1680
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 861
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 1769
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 925
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 2100
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 1076
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 2211
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 1156
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Oval Head": true
          }
        },
        "0.19": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.19
            },
            "text": "#10"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 24,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 32,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 2100
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 1043
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 2400
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 1262
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 2625
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 1304
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 3000
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 1577
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Oval Head": true
          }
        },
        "0.25": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.25
            },
            "text": "1/4\""
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 20,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 28,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 3800
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 1935
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 4350
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 2343
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 4750
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 2419
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 5450
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 2929
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.3125": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.3125
            },
            "text": "5/16\""
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.375
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 5
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 18,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 24,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 6300
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 3265
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 6950
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 3774
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 7850
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 4081
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 8700
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 4717
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.375": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.375
            },
            "text": "3/8\""
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.5
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 5
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 16,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 24,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 9300
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 4880
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 10500
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 5821
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 11600
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 6099
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 13200
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 7276
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.4375": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.4375
            },
            "text": "7/16\""
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.5
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 5
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 14,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 20,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 12800
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 6717
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 14200
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 7846
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 15900
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 8396
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 17800
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 9808
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.5": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.5
            },
            "text": "1/2\""
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.5
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 8
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 13,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 20,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 17000
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 9048
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 19200
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 10700
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 21300
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 11310
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 24000
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 13376
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.625": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.625
            },
            "text": "5/8\""
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 1.25
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 6
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 11,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 18,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 27100
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 14525
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 30700
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 17279
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 33900
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 18157
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 38400
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 21599
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.75": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.75
            },
            "text": "3/4\""
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 1.5
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 6
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 10,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 16,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 40100
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 21737
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 44800
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 25290
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 50100
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 27172
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 56000
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 31613
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.875": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.875
            },
            "text": "7/8\""
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 2
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 8
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 9,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 14,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Grade 5)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 55400
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 30181
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 61100
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 34594
                }
              }
            },
            "Steel (Grade 8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 69300
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 37726
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 76400
                },
                "shear": {
                  "unit": UnitsForce.PSI,
                  "value": 43242
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true
          }
        }
      },
      "metricDiameters": {
        "2": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2
            },
            "text": "M2"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 20
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.04,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.025,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 1.656
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 1.428
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 1.829
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 2.1528
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 1.789
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 2.292
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "3": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3
            },
            "text": "M3"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 4
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 70
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 2
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.5,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.35,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 4.02
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 3.6
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 4.222
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 5.23
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 4.511
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 5.291
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4
            },
            "text": "M4"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 5
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 110
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 2
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.7,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.5,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 7.02
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 6.224
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 7.316
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 9.13
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 7.799
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 9.167
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "5": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 5
            },
            "text": "M5"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 6
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 150
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 2
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.8,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.5,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 11.35
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 10.223
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 12.335
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 14.8
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 12.3163
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 15.456
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "6": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 6
            },
            "text": "M6"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 6
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 200
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 2
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.75,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 16.1
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 14.4
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 16.46
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 20.9
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 18.043
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 20.625
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "8": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 8
            },
            "text": "M8"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 8
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 240
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 5
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.25,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 29.2
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 26.493
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 31.36
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 29.263
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 38.1
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 33.196
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 40.8
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 36.667
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "10": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 10
            },
            "text": "M10"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 10
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 300
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 5
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.5,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.25,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 46.4
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 42.243
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 51.6
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 45.723
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 60.3
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 52.932
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 67.1
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 57.292
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "12": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 12
            },
            "text": "M12"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 12
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 300
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 5
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.75,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.25,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 67.4
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 61.652
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 70.5
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 70.169
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 87.7
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 77.251
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 91.6
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 87.922
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Flange Head": true
          }
        },
        "16": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 16
            },
            "text": "M16"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 20
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 300
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 5
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 2,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.5,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 125
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 117.052
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 134
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 128.683
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 163
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 146.667
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 174
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 161.241
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Truss Head": true,
            "Hex Flange Head": true
          }
        },
        "20": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 20
            },
            "text": "M20"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 25
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 400
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 5
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 2.5,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.5,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 203
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 182.893
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 226
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 212.384
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 255
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 229.167
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 283
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 266.12
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Hex Flange Head": true
          }
        },
        "24": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 24
            },
            "text": "M24"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 40
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 300
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 5
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 3,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 2,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 293
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 263.366
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 319
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 298.535
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 367
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 330.001
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 399
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 374.068
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Hex Flange Head": true
          }
        },
        "1.6": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 1.6
            },
            "text": "M1.6"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 16
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.35,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.2,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 1.016
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 0.855
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 1.3208
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true
          }
        },
        "2.5": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2.5
            },
            "text": "M2.5"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 30
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.45,
              "pitchType": PitchType.coarse
            },
            "fine": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.35,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Class 8.8)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 2.712
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 2.391
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 2.726
                }
              }
            },
            "Steel (Class 10.9)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 3.5256
                },
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 2.995
                }
              },
              "finePitch": {
                "shear": {
                  "unit": UnitsForce.Newton,
                  "value": 3.416
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        }
      }
    }
  },
  [FastenerNames.TFormingScrewPlastic]: {
    "fastenerType": FastenerTypes.threaded,
    "name": FastenerNames.TFormingScrewPlastic,
    "parameters": {
      "imperialDiameters": {
        "0.046": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.046
            },
            "text": "#OO"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.375
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 51,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {}
          },
          "compatibleHeadTypes": {
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Oval Head": true
          }
        },
        "0.062": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.062
            },
            "text": "#O"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.75
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 42,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 2
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Oval Head": true
          }
        },
        "0.0765": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.0765
            },
            "text": "#1"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.75
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 32,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 3.5
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Oval Head": true
          }
        },
        "0.086": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.086
            },
            "text": "#2"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 28,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 6
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.103": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.103
            },
            "text": "#3"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 24,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 10
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.12": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.12
            },
            "text": "#4"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 20,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 14
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.14": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.14
            },
            "text": "#6"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.75
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 19,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 24
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.157": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.157
            },
            "text": "#7"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.75
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 18,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 40
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.176": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.176
            },
            "text": "#8"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 2
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 16,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 52
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.19": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.19
            },
            "text": "#9"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 2
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 15,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 58
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.205": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.205
            },
            "text": "#10"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.375
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 2
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 14,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Aluminum": {},
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 65
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.223": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.223
            },
            "text": "#12"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.375
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 2
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 14,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Aluminum": {},
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 115
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.265": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.265
            },
            "text": "1/4\""
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.375
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 2
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 10,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Aluminum": {},
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 176
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.33": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.33
            },
            "text": "5/16\""
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.5
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 2
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 9,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Aluminum": {},
            "Stainless Steel": {},
            "Steel (Ungraded)": {}
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        }
      },
      "metricDiameters": {
        "1": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 1
            },
            "text": "D10"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 10
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.44,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 0.04
                }
              }
            },
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 0.05
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Pan Head": true,
            "Truss Head": true
          }
        },
        "2": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2
            },
            "text": "D20"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 4
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 20
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.78,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 0.3
                }
              }
            },
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 0.42
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Pan Head": true,
            "Truss Head": true
          }
        },
        "3": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3
            },
            "text": "D30"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 6
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 30
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.12,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 1.1
                }
              }
            },
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 1.5
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4
            },
            "text": "D40"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 8
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 40
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.46,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 2.7
                }
              }
            },
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 3.7
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "5": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 5
            },
            "text": "D50"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 10
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 50
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.8,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Aluminum": {},
            "Stainless Steel": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 5.2
                }
              }
            },
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 7.1
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "6": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 6
            },
            "text": "D60"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 12
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 60
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 2.14,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Aluminum": {},
            "Stainless Steel": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 9.3
                }
              }
            },
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 12.8
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "7": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 7
            },
            "text": "D70"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 14
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 70
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 2.48,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Aluminum": {},
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 20
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "8": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 8
            },
            "text": "D80"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 20
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 80
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 2.82,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Aluminum": {},
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 30
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "9": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 9
            },
            "text": "D90"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 25
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 90
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 3.16,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Aluminum": {},
            "Stainless Steel": {},
            "Steel (Ungraded)": {}
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "10": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 10
            },
            "text": "D100"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 25
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 100
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 3.5,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Aluminum": {},
            "Stainless Steel": {},
            "Steel (Ungraded)": {}
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "1.2": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 1.2
            },
            "text": "D12"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 12
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.51,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 0.07
                }
              }
            },
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 0.1
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Pan Head": true,
            "Truss Head": true
          }
        },
        "1.4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 1.4
            },
            "text": "D14"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 14
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.57,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 0.11
                }
              }
            },
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 0.15
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Pan Head": true,
            "Truss Head": true
          }
        },
        "1.6": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 1.6
            },
            "text": "D16"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.5
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 16
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.64,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 0.17
                }
              }
            },
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 0.23
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Pan Head": true,
            "Truss Head": true
          }
        },
        "1.8": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 1.8
            },
            "text": "D18"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.5
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 18
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.71,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 0.23
                }
              }
            },
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 0.31
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Pan Head": true,
            "Truss Head": true
          }
        },
        "2.2": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2.2
            },
            "text": "D22"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 4.5
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 22
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.85,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 0.41
                }
              }
            },
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 0.55
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "2.5": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2.5
            },
            "text": "D25"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 5
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 25
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.95,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 0.67
                }
              }
            },
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 0.9
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "3.5": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3.5
            },
            "text": "D35"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 7
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.29,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 1.8
                }
              }
            },
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 2.5
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "4.5": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4.5
            },
            "text": "D45"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 9
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 45
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.63,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 3.8
                }
              }
            },
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 5.2
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        }
      }
    }
  },
  [FastenerNames.TFormingScrewMetal]: {
    "fastenerType": FastenerTypes.threaded,
    "name": FastenerNames.TFormingScrewMetal,
    "parameters": {
      "imperialDiameters": {
        "0.059": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.059
            },
            "text": "IR0"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.5
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 80,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 2
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Oval Head": true
          }
        },
        "0.084": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.084
            },
            "text": "IR2"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.75
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 56,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 6
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.097": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.097
            },
            "text": "IR3"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.1875
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.75
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 48,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 10
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.109": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.109
            },
            "text": "IR4"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 40,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 14
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.122": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.122
            },
            "text": "IR5"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 40,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 22
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.135": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.135
            },
            "text": "IR6"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 32,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 25
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.161": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.161
            },
            "text": "IR8"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.375
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 32,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 48
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.186": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.186
            },
            "text": "IR10"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.5
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 2
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 24,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {}
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.187": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.187
            },
            "text": "IR10"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.375
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 2
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 32,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 65
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 81
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.212": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.212
            },
            "text": "IR12"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.5
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 2
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 24,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 160
                }
              },
              "finePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 200
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.245": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.245
            },
            "text": "IR1/4"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.5
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 2
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 20,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 200
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.246": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.246
            },
            "text": "IR1/4"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.5
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 2
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 28,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.PSI,
                  "value": 620
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.307": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.307
            },
            "text": "IR5/16"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.5
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 3
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 18,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {}
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.368": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.368
            },
            "text": "IR3/8"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.5
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 16,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {}
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.43": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.43
            },
            "text": "IR7/16"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.75
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 5
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 14,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {}
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.433": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.433
            },
            "text": "IR7/16"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.5
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 5
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "fine": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 20,
              "pitchType": PitchType.fine
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {}
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.492": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.492
            },
            "text": "IR1/2"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.75
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 5
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 13,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {}
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.553": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.553
            },
            "text": "IR9/16"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.75
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 5
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 12,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {}
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "0.615": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.615
            },
            "text": "IR5/8"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 1
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 5
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.ThreadsPerInch,
              "value": 11,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {}
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (82)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        }
      },
      "metricDiameters": {
        "0.9495": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 0.9495
            },
            "text": "MR1.0"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 6
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.25,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 0.06
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Pan Head": true,
            "Truss Head": true
          }
        },
        "1.1495": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 1.1495
            },
            "text": "MR1.2"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 6
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.25,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 0.12
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Pan Head": true,
            "Truss Head": true
          }
        },
        "1.346": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 1.346
            },
            "text": "MR1.4"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 6
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.3,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 0.19
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Pan Head": true,
            "Truss Head": true
          }
        },
        "1.535": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 1.535
            },
            "text": "MR1.6"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 8
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.35,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 0.28
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Pan Head": true,
            "Truss Head": true
          }
        },
        "1.735": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 1.735
            },
            "text": "MR1.8"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 10
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.35,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 0.44
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,


            "Pan Head": true,
            "Truss Head": true
          }
        },
        "1.925": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 1.925
            },
            "text": "MR2.0"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 10
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.4,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 0.6
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Pan Head": true,
            "Truss Head": true
          }
        },
        "2.115": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2.115
            },
            "text": "MR2.2"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 10
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.45,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {}
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "2.425": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2.425
            },
            "text": "MR2.5"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 10
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.45,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 1.2
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "2.92": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2.92
            },
            "text": "MR3.0"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 6
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 16
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.5,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 2.2
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "3.405": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3.405
            },
            "text": "MR3.5"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 8
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 16
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.6,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 3.35
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "3.89": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3.89
            },
            "text": "MR4.0"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 8
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 25
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.7,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 5.2
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "4.385": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4.385
            },
            "text": "MR4.5"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 8
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 25
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.75,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 7.5
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "4.875": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4.875
            },
            "text": "MR5.0"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 8
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 25
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 0.8,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 10.5
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "5.855": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 5.855
            },
            "text": "MR6.0"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 10
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 50
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 17.7
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "6.855": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 6.855
            },
            "text": "MR7.0"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 10
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 50
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {}
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "7.81": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 7.81
            },
            "text": "MR8.0"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 16
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 75
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.25,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {
              "coarsePitch": {
                "tensile": {
                  "unit": UnitsForce.Newton,
                  "value": 41
                }
              }
            }
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "8.81": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 8.81
            },
            "text": "MR9.0"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 16
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 75
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.25,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {}
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "9.77": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 9.77
            },
            "text": "MR10"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 20
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 130
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.5,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {}
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true,
            "Oval Head": true
          }
        },
        "11.74": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 11.74
            },
            "text": "MR12"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 20
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 130
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 1.75,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {}
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Button Head": true,
            "Flanged Button Head": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true
          }
        },
        "13.7": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 13.7
            },
            "text": "MR14"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 25
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 130
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 2,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {}
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true
          }
        },
        "15.7": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 15.7
            },
            "text": "MR16"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 25
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 130
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 2,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {}
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true
          }
        },
        "17.62": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 17.62
            },
            "text": "MR18"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 25
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 130
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 2.5,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {}
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true
          }
        },
        "19.62": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 19.62
            },
            "text": "MR20"
          },
          "lengths": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 25
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 130
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "pitches": {
            "coarse": {
              "unit": UnitsPitch.MillimetersPerThread,
              "value": 2.5,
              "pitchType": PitchType.coarse
            }
          },
          "materials": {
            "Stainless Steel": {},
            "Steel (Ungraded)": {}
          },
          "compatibleHeadTypes": {
            "Socket Head Cap": true,
            "Flat Countersunk Head (90)": true,
            "Pan Head": true,
            "Truss Head": true,
            "Hex Head": true,
            "Hex Washer Head": true,
            "Hex Flange Head": true
          }
        }
      }
    }
  },
  [FastenerNames.OpenEnd]: {
    "fastenerType": FastenerTypes.rivet,
    "name": FastenerNames.OpenEnd,
    "parameters": {
      "imperialDiameters": {
        "0.0938": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.0938
            },
            "text": "3/32\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.0312
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.5
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.062
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 135
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 85
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 125
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 205
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 150
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "0.125": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.125
            },
            "text": "1/8\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.062
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.75
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.062
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 235
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 155
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 210
                  }
                }
              }
            },
            "Copper": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 300
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 215
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 700
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 550
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 550
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 425
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 295
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "0.156": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.156
            },
            "text": "5/32\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.75
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.062
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 350
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 225
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 340
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1130
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 900
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 900
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 570
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 410
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "0.187": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.187
            },
            "text": "3/16\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.062
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 500
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 315
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 445
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1375
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 1000
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 1000
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 815
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 590
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "0.25": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.25
            },
            "text": "1/4\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.062
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 750
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 600
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 890
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1505
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 1245
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "0.312": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.312
            },
            "text": "5/16\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.062
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {
                "Aluminum": {},
                "Steel (Ungraded)": {}
              }
            },
            "Brass": {
              "stemMaterials": {
                "Brass": {},
                "Steel (Ungraded)": {}
              }
            },
            "Nylon": {
              "stemMaterials": {
                "Nylon": {}
              }
            },
            "Titanium": {
              "stemMaterials": {
                "Titanium": {}
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "0.375": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.375
            },
            "text": "3/8\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.062
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {
                "Aluminum": {},
                "Steel (Ungraded)": {}
              }
            },
            "Brass": {
              "stemMaterials": {
                "Brass": {},
                "Steel (Ungraded)": {}
              }
            },
            "Nylon": {
              "stemMaterials": {
                "Nylon": {}
              }
            },
            "Titanium": {
              "stemMaterials": {
                "Titanium": {}
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "0.438": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.438
            },
            "text": "7/16\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {
                "Aluminum": {},
                "Steel (Ungraded)": {}
              }
            },
            "Brass": {
              "stemMaterials": {
                "Brass": {},
                "Steel (Ungraded)": {}
              }
            },
            "Nylon": {
              "stemMaterials": {
                "Nylon": {}
              }
            },
            "Titanium": {
              "stemMaterials": {
                "Titanium": {}
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        }
      },
      "metricDiameters": {
        "4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4
            },
            "text": "4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 15.9
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 1556
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 1000
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 1512
                  }
                }
              }
            },
            "Brass": {
              "stemMaterials": {
                "Brass": {},
                "Steel (Ungraded)": {}
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 5027
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 4003
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 4005
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 2535
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 1824
                  }
                }
              }
            },
            "Titanium": {
              "stemMaterials": {
                "Titanium": {}
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "2.4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2.4
            },
            "text": "2.4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.8
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 9.5
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 600
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 378
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 378
                  }
                }
              }
            },
            "Brass": {
              "stemMaterials": {
                "Brass": {},
                "Steel (Ungraded)": {}
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 912
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 667
                  }
                }
              }
            },
            "Titanium": {
              "stemMaterials": {
                "Titanium": {}
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "3.2": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3.2
            },
            "text": "3.2 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.8
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 15.9
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 1045
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 689
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 934
                  }
                }
              }
            },
            "Brass": {
              "stemMaterials": {
                "Brass": {},
                "Steel (Ungraded)": {}
              }
            },
            "Copper": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 1335
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 957
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 3114
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 2446
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 2448
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 1891
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 1312
                  }
                }
              }
            },
            "Titanium": {
              "stemMaterials": {
                "Titanium": {}
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "4.8": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4.8
            },
            "text": "4.8 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 25.4
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 2224
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 1401
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 1979
                  }
                }
              }
            },
            "Brass": {
              "stemMaterials": {
                "Brass": {},
                "Steel (Ungraded)": {}
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 6116
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 4448
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 4450
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 3625
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 2624
                  }
                }
              }
            },
            "Titanium": {
              "stemMaterials": {
                "Titanium": {}
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "6.4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 6.4
            },
            "text": "6.4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 25.4
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 3336
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 2669
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 3958
                  }
                }
              }
            },
            "Brass": {
              "stemMaterials": {
                "Brass": {},
                "Steel (Ungraded)": {}
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 6694
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 5538
                  }
                }
              }
            },
            "Titanium": {
              "stemMaterials": {
                "Titanium": {}
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        }
      }
    }
  },
  [FastenerNames.ClosedEnd]: {
    "fastenerType": FastenerTypes.rivet,
    "name": FastenerNames.ClosedEnd,
    "parameters": {
      "imperialDiameters": {
        "0.125": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.125
            },
            "text": "1/8\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.032
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.5
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.062
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 150
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 105
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 305
                  }
                }
              }
            },
            "Copper": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 335
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 270
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 499
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 472
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 157
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 180
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true
          }
        },
        "0.156": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.156
            },
            "text": "5/32\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.063
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.312
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.062
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 240
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 155
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 430
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 899
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 742
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true
          }
        },
        "0.187": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.187
            },
            "text": "3/16\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.063
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.5
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.062
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 310
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 220
                  }
                },
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 575
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 575
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 990
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 966
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true
          }
        },
        "0.25": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.25
            },
            "text": "1/4\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.126
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.375
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.126
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1100
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 900
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true
          }
        }
      },
      "metricDiameters": {
        "4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4
            },
            "text": "4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.5
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 8
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.5
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 1068
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 689
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 1913
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 4000
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 3300
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true
          }
        },
        "3.2": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3.2
            },
            "text": "3.2 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 12
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.5
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 667
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 467
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 1357
                  }
                }
              }
            },
            "Copper": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 1490
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 1201
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 2220
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 2100
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 698
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 800
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true
          }
        },
        "4.8": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4.8
            },
            "text": "4.8 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.5
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 12
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.5
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 1379
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 979
                  }
                },
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 2558
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 4337
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 4404
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 4297
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true
          }
        },
        "6.4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 6.4
            },
            "text": "6.4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 10
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 3
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 4893
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 4003
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true
          }
        }
      }
    }
  },
  [FastenerNames.Crimped]: {
    "fastenerType": FastenerTypes.rivet,
    "name": FastenerNames.Crimped,
    "parameters": {
      "imperialDiameters": {
        "0.118": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.118
            },
            "text": "59/500\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.031
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.172
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.136
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 220
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 157
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 157
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "0.126": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.126
            },
            "text": "1/8\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.031
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.375
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.136
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 230
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 165
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 165
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 445
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 364
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 264
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 202
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "0.156": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.156
            },
            "text": "5/32\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.02
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.5
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.136
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 375
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 255
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 255
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 728
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 546
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 405
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 344
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "0.187": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.187
            },
            "text": "3/16\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.062
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.781
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.187
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 525
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 345
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 345
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1012
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 931
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 647
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 585
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "0.25": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.25
            },
            "text": "1/4\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.06
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.325
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.187
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 560
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 700
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 700
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 800
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 700
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        }
      },
      "metricDiameters": {
        "3": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3
            },
            "text": "3 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.031
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.172
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.136
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 980
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 700
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 700
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4
            },
            "text": "4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.02
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.5
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.136
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 1670
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 1130
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 1130
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 3240
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 2430
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 1800
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 1530
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "3.2": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3.2
            },
            "text": "3.2 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.031
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.375
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.136
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 1020
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 730
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 730
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 1980
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 1620
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 1170
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 900
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "4.8": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4.8
            },
            "text": "4.8 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.062
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.781
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.187
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 2330
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 1530
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 1530
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 4500
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 4140
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 2880
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 2600
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "6.4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 6.4
            },
            "text": "6.4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.06
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.325
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.187
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 2500
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 3100
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 3100
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 3560
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 3110
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        }
      }
    }
  },
  [FastenerNames.Rolled]: {
    "fastenerType": FastenerTypes.rivet,
    "name": FastenerNames.Rolled,
    "parameters": {
      "imperialDiameters": {
        "0.126": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.126
            },
            "text": "1/8\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.039
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.312
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 85
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 65
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 150
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 450
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 719
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 360
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 270
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "0.156": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.156
            },
            "text": "5/32\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.047
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.312
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.062
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 145
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 105
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 250
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 899
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 1169
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 540
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 370
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "0.187": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.187
            },
            "text": "3/16\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.063
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.75
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 205
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 145
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 350
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1124
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 1236
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 719
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 607
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "0.25": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.25
            },
            "text": "1/4\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.08
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.35
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 560
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 460
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1799
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 1799
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 800
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 700
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        }
      },
      "metricDiameters": {
        "4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4
            },
            "text": "4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 6.4
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 1601
              },
              "stemMaterials": {
                "Aluminum": {},
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 1112
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 4000
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 5200
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 2400
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 1650
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "3.2": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3.2
            },
            "text": "3.2 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.8
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 12.7
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 2
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 978
              },
              "stemMaterials": {
                "Aluminum": {},
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 667
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 2000
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 3200
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 1600
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 1200
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "4.8": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4.8
            },
            "text": "4.8 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 25.4
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 2246
              },
              "stemMaterials": {
                "Aluminum": {},
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 1556
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 1556
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 5000
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 5500
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 3200
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 2700
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "6.4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 6.4
            },
            "text": "6.4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 8.9
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 7
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 2491
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 2046
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 8000
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 14300
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 3560
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 3110
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        }
      }
    }
  },
  [FastenerNames.Bulbex]: {
    "fastenerType": FastenerTypes.rivet,
    "name": FastenerNames.Bulbex,
    "parameters": {
      "imperialDiameters": {
        "0.126": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.126
            },
            "text": "1/8\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 112
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 90
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Large Flange": true
          }
        },
        "0.156": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.156
            },
            "text": "5/32\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.472
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.15
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 225
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 135
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Large Flange": true
          }
        },
        "0.187": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.187
            },
            "text": "3/16\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.472
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.187
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 240
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 175
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Large Flange": true
          }
        }
      },
      "metricDiameters": {
        "4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4
            },
            "text": "4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 12
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 3
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 1000
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 600
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Large Flange": true
          }
        },
        "3.2": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3.2
            },
            "text": "3.2 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 0
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 6.4
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 6.4
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 500
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 400
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Large Flange": true
          }
        },
        "4.8": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4.8
            },
            "text": "4.8 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 12
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 4
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 1070
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 1070
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Large Flange": true
          }
        }
      }
    }
  },
  [FastenerNames.NSKTR]: {
    "fastenerType": FastenerTypes.rivet,
    "name": FastenerNames.NSKTR,
    "parameters": {
      "imperialDiameters": {
        "0.187": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.187
            },
            "text": "3/16\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.05
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.562
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.187
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 450
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 700
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true
          }
        },
        "0.25": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.25
            },
            "text": "1/4\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.06
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.375
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.187
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 700
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 1250
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true
          }
        }
      },
      "metricDiameters": {
        "4.8": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4.8
            },
            "text": "4.8 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.27
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 19.05
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 2000
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 3110
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true
          }
        },
        "6.4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 6.4
            },
            "text": "6.4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.52
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 9.53
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 3110
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 5160
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true
          }
        }
      }
    }
  },
  [FastenerNames.StructuralKTR]: {
    "fastenerType": FastenerTypes.rivet,
    "name": FastenerNames.StructuralKTR,
    "parameters": {
      "imperialDiameters": {
        "0.25": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.25
            },
            "text": "1/4\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.06
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.375
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.2
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 700
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 1250
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 1250
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Countersunk 120 deg": true
          }
        }
      },
      "metricDiameters": {
        "4.8": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4.8
            },
            "text": "4.8 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.3
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 19
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 5
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 2000
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 3110
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 3110
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Countersunk 120 deg": true,
            "Large Flange": true
          }
        },
        "6.4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 6.4
            },
            "text": "6.4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.5
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 9.5
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 5
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 3110
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 5560
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 5560
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Countersunk 120 deg": true
          }
        }
      }
    }
  },
  [FastenerNames.MonoboltInterlock]: {
    "fastenerType": FastenerTypes.rivet,
    "name": FastenerNames.MonoboltInterlock,
    "parameters": {
      "imperialDiameters": {
        "0.187": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.187
            },
            "text": "3/16\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.064
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.437
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 500
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 675
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1150
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 1450
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1150
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 1450
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 1450
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 90 deg": true,
            "Large Flange": true
          }
        },
        "0.25": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.25
            },
            "text": "1/4\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.08
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.625
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 950
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 1350
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2350
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 2650
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2350
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 2650
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 2650
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 90 deg": true
          }
        },
        "0.375": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.375
            },
            "text": "3/8\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.1
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.625
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.25
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2092
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 2840
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 4361
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 5863
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 3709
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 5940
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 5940
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 90 deg": true
          }
        }
      },
      "metricDiameters": {
        "10": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 10
            },
            "text": "10 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 2.5
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 16
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 6.4
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 9300
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 12630
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 19400
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 26080
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 19400
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 26080
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 26370
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 90 deg": true
          }
        },
        "4.8": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4.8
            },
            "text": "4.8 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 8
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 6.4
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 2220
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 3000
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 5100
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 6440
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 5100
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 6440
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 6440
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 90 deg": true,
            "Large Flange": true
          }
        },
        "6.4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 6.4
            },
            "text": "6.4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 16
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 6.4
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 4220
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 6000
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 10450
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 11780
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 10450
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 11780
                  }
                },
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 11780
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 90 deg": true
          }
        }
      }
    }
  },
  [FastenerNames.Bulbing]: {
    "fastenerType": FastenerTypes.rivet,
    "name": FastenerNames.Bulbing,
    "parameters": {
      "imperialDiameters": {
        "0.125": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.125
            },
            "text": "1/8\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.039
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.276
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.078
              }
            }
          ],
          "bodyMaterials": {
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 450
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 719
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 292
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 562
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Countersunk 90 deg": true
          }
        },
        "0.156": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.156
            },
            "text": "5/32\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.039
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.276
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.078
              }
            }
          ],
          "bodyMaterials": {
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 899
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 1169
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 629
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 740
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Countersunk 90 deg": true
          }
        },
        "0.187": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.187
            },
            "text": "3/16\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.059
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.394
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.078
              }
            }
          ],
          "bodyMaterials": {
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1124
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 1236
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 854
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 1258
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Countersunk 90 deg": true
          }
        },
        "0.236": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.236
            },
            "text": "15/64\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.059
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.472
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.078
              }
            }
          ],
          "bodyMaterials": {
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1213
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 1910
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true
          }
        },
        "0.25": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.25
            },
            "text": "1/4\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.059
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.354
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 78
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 600
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 1574
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1799
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 3215
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1528
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.PSI,
                    "value": 2495
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true
          }
        }
      },
      "metricDiameters": {
        "4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4
            },
            "text": "4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 9
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 2
              }
            }
          ],
          "bodyMaterials": {
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 4000
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 5200
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 2500
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 3300
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Countersunk 90 deg": true
          }
        },
        "6": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 6
            },
            "text": "6 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.5
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 12
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 2
              }
            }
          ],
          "bodyMaterials": {
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 5400
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 8500
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true
          }
        },
        "3.2": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3.2
            },
            "text": "3.2 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 7
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 2
              }
            }
          ],
          "bodyMaterials": {
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 2000
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 3200
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 1300
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 2500
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Countersunk 90 deg": true
          }
        },
        "4.8": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4.8
            },
            "text": "4.8 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.5
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 8.5
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 2
              }
            }
          ],
          "bodyMaterials": {
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 5000
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 5500
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 3800
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 5600
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true,
            "Countersunk 90 deg": true
          }
        },
        "6.4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 6.4
            },
            "text": "6.4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.5
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 9
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 2
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 2670
              },
              "stemMaterials": {
                "Aluminum": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 7000
                  }
                }
              }
            },
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 8000
              },
              "stemMaterials": {
                "Stainless Steel": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 14300
                  }
                }
              }
            },
            "Steel (Ungraded)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 6800
              },
              "stemMaterials": {
                "Steel (Ungraded)": {
                  "shearStrength": {
                    "unit": UnitsForce.Newton,
                    "value": 11100
                  }
                }
              }
            }
          },
          "headStyles": {
            "Dome": true
          }
        }
      }
    }
  },
  [FastenerNames.Solid]: {
    "fastenerType": FastenerTypes.rivet,
    "name": FastenerNames.Solid,
    "parameters": {
      "imperialDiameters": {
        "0.0625": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.0625
            },
            "text": "1/16\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.375
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.088": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.088
            },
            "text": "88/1000\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.375
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.0938": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.0938
            },
            "text": "3/32\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.375
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.104": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.104
            },
            "text": "13/125\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.375
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.11": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.11
            },
            "text": "7/64\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.375
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.119": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.119
            },
            "text": "119/1000\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.375
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.125": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.125
            },
            "text": "1/8\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.375
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.129": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.129
            },
            "text": "129/1000\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.375
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.143": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.143
            },
            "text": "143/1000\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.375
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.156": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.156
            },
            "text": "5/32\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.375
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.164": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.164
            },
            "text": "41/250\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.375
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.174": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.174
            },
            "text": "87/500\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.375
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.1875": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.1875
            },
            "text": "3/16\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.375
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.201": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.201
            },
            "text": "201/1000\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.375
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.222": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.222
            },
            "text": "111/500\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.375
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.25": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.25
            },
            "text": "1/4\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.375
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.3125": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.3125
            },
            "text": "5/16\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.375
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.375": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.375
            },
            "text": "3/8\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.375
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.0625
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        }
      },
      "metricDiameters": {
        "4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4
            },
            "text": "4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "1.6": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 1.6
            },
            "text": "1.6 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "2.2": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2.2
            },
            "text": "2.2 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "2.4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2.4
            },
            "text": "2.4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "2.6": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2.6
            },
            "text": "2.6 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "2.8": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2.8
            },
            "text": "2.8 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "3.16": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3.16
            },
            "text": "3.16 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "3.44": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3.44
            },
            "text": "3.44 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "3.72": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3.72
            },
            "text": "3.72 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "4.28": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4.28
            },
            "text": "4.28 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "4.56": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4.56
            },
            "text": "4.56 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "4.84": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4.84
            },
            "text": "4.84 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "5.12": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 5.12
            },
            "text": "5.12 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "5.4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 5.4
            },
            "text": "5.4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "5.68": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 5.68
            },
            "text": "5.68 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "5.96": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 5.96
            },
            "text": "5.96 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "6.24": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 6.24
            },
            "text": "6.24 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "6.52": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 6.52
            },
            "text": "6.52 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        }
      }
    }
  },
  [FastenerNames.SemiTubular]: {
    "fastenerType": FastenerTypes.rivet,
    "name": FastenerNames.SemiTubular,
    "parameters": {
      "imperialDiameters": {
        "0.0625": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.0625
            },
            "text": "1/16\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.088": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.088
            },
            "text": "11/125\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.0938": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.0938
            },
            "text": "3/32\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.104": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.104
            },
            "text": "13/125\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.11": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.11
            },
            "text": "7/64\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.119": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.119
            },
            "text": "119/1000\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.125": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.125
            },
            "text": "1/8\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.129": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.129
            },
            "text": "129/1000\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.143": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.143
            },
            "text": "143/1000\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.156": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.156
            },
            "text": "5/32\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.164": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.164
            },
            "text": "41/250\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.174": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.174
            },
            "text": "87/500\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.1875": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.1875
            },
            "text": "3/16\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.201": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.201
            },
            "text": "201/1000\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.222": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.222
            },
            "text": "111/500\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.25": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.25
            },
            "text": "1/4\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.3125": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.3125
            },
            "text": "5/16\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.375": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.375
            },
            "text": "3/8\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        }
      },
      "metricDiameters": {
        "4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4
            },
            "text": "4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 100
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "1.6": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 1.6
            },
            "text": "1.6 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 100
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "2.2": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2.2
            },
            "text": "2.2 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 100
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "2.4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2.4
            },
            "text": "2.4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 100
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "2.6": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2.6
            },
            "text": "2.6 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 100
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "2.8": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2.8
            },
            "text": "2.8 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 100
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "3.16": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3.16
            },
            "text": "3.16 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 100
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "3.44": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3.44
            },
            "text": "3.44 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 100
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "3.72": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3.72
            },
            "text": "3.72 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 100
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "4.28": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4.28
            },
            "text": "4.28 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 100
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "4.56": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4.56
            },
            "text": "4.56 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 100
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "4.84": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4.84
            },
            "text": "4.84 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 100
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "5.12": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 5.12
            },
            "text": "5.12 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 100
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "5.4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 5.4
            },
            "text": "5.4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 100
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "5.68": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 5.68
            },
            "text": "5.68 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 100
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "5.96": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 5.96
            },
            "text": "5.96 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 100
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "6.24": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 6.24
            },
            "text": "6.24 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 100
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "6.52": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 6.52
            },
            "text": "6.52 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 100
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        }
      }
    }
  },
  [FastenerNames.Tubular]: {
    "fastenerType": FastenerTypes.rivet,
    "name": FastenerNames.Tubular,
    "parameters": {
      "imperialDiameters": {
        "0.0625": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.0625
            },
            "text": "1/16\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.088": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.088
            },
            "text": "11/125\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.0938": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.0938
            },
            "text": "3/32\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.104": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.104
            },
            "text": "13/125\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.11": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.11
            },
            "text": "7/64\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.119": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.119
            },
            "text": "119/1000\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.125": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.125
            },
            "text": "1/8\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.129": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.129
            },
            "text": "129/1000\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.143": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.143
            },
            "text": "143/1000\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.156": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.156
            },
            "text": "5/32\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.164": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.164
            },
            "text": "41/250\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.174": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.174
            },
            "text": "87/500\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.1875": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.1875
            },
            "text": "3/16\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.201": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.201
            },
            "text": "201/1000\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.222": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.222
            },
            "text": "111/500\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.25": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.25
            },
            "text": "1/4\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.3125": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.3125
            },
            "text": "5/16\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "0.375": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.375
            },
            "text": "3/8\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.125
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.04
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        }
      },
      "metricDiameters": {
        "4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4
            },
            "text": "4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "1.6": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 1.6
            },
            "text": "1.6 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "2.2": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2.2
            },
            "text": "2.2 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "2.4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2.4
            },
            "text": "2.4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "2.6": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2.6
            },
            "text": "2.6 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "2.8": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2.8
            },
            "text": "2.8 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "3.16": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3.16
            },
            "text": "3.16 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "3.44": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3.44
            },
            "text": "3.44 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "3.72": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3.72
            },
            "text": "3.72 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "4.28": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4.28
            },
            "text": "4.28 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "4.56": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4.56
            },
            "text": "4.56 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "4.84": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4.84
            },
            "text": "4.84 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "5.12": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 5.12
            },
            "text": "5.12 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "5.4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 5.4
            },
            "text": "5.4 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "5.68": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 5.68
            },
            "text": "5.68 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "5.96": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 5.96
            },
            "text": "5.96 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "6.24": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 6.24
            },
            "text": "6.24 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "6.52": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 6.52
            },
            "text": "6.52 mm"
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 35
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Brass": {
              "stemMaterials": {}
            },
            "Copper": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            },
            "Titanium": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        }
      }
    }
  },
  [FastenerNames.SelfPierce]: {
    "fastenerType": FastenerTypes.rivet,
    "name": FastenerNames.SelfPierce,
    "parameters": {
      "imperialDiameters": {
        "2": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 2
            },
            "text": "2\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.3
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 0.8
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.3
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true
          }
        },
        "3": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 3
            },
            "text": "3\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 0.4
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 1.6
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.4
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true
          }
        },
        "4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 4
            },
            "text": "4\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 1.3
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 3.2
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.5
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true
          }
        },
        "5": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 5
            },
            "text": "5\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 2
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 4.3
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.7
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true
          }
        },
        "3.6": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 3.6
            },
            "text": "3 3/5\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Inch,
                "value": 1
              },
              "max": {
                "unit": UnitsDistance.Inch,
                "value": 2.6
              },
              "increment": {
                "unit": UnitsDistance.Inch,
                "value": 0.5
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Countersunk 120 deg": true
          }
        }
      },
      "metricDiameters": {
        "2": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 2
            },
            "text": "2\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.3
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.8
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.3
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "3": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3
            },
            "text": "3\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.4
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.6
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.4
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "4": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4
            },
            "text": "4\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 1.3
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 3.2
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.5
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "5": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 5
            },
            "text": "5\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 2
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 4.3
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.7
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        },
        "3.6": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3.6
            },
            "text": "3 3/5\""
          },
          "gripRanges": [
            {
              "min": {
                "unit": UnitsDistance.Millimeter,
                "value": 1
              },
              "max": {
                "unit": UnitsDistance.Millimeter,
                "value": 2.6
              },
              "increment": {
                "unit": UnitsDistance.Millimeter,
                "value": 0.5
              }
            }
          ],
          "bodyMaterials": {
            "Aluminum": {
              "stemMaterials": {}
            },
            "Stainless Steel": {
              "stemMaterials": {}
            },
            "Steel (Ungraded)": {
              "stemMaterials": {}
            }
          },
          "headStyles": {
            "Dome": true,
            "Lo-Pro": true,
            "Button": true,
            "Countersunk 90 deg": true,
            "Countersunk 120 deg": true,
            "Large Flange": true,
            "Brazier": true
          }
        }
      }
    }
  },
  "Open End Rivet Nut": {
    "fastenerType": FastenerTypes.rivetNut,
    "name": FastenerNames.OpenEndRivetNut,
    "parameters": {
      "imperialDiameters": {
        "0.098-40": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.098
            },
            "text": "4-40"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 40
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.02,
            "maxValue": 0.25
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 0.452
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 0.256
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 900
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {},
            "Steel Med Carbon": {},
            "Steel (37MnB2)": {},
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.128-32": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.128
            },
            "text": "6-32"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 32
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.02,
            "maxValue": 0.25
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 0.452
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 0.256
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 900
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {},
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 944.2
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 404.6
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 0.8
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.1
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1843.4
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 517
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 0.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 944.2
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 404.6
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 0.8
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.1
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1227.5
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 526
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.1
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.4
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.156-32": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.156
            },
            "text": "8-32"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 32
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.03,
            "maxValue": 0.25
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 0.512
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 0.315
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 1125
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3
              }
            },
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1236.4
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 651.9
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.6
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.8
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2068.2
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 854.2
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.4
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 4.1
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1236.4
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 651.9
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.6
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.8
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1607.3
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 847.5
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 2.1
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 4.9
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.188-24": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.188
            },
            "text": "10-24"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 24
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.03,
            "maxValue": 0.188
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 0.63
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 0.433
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 1800
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.6
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.9
              }
            },
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1798.4
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 944.2
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 2.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5.8
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2697.6
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1236.4
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.9
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 8.3
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1798.4
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 944.2
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 2.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5.8
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2337.9
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1227.5
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 7.6
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.188-32": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.188
            },
            "text": "10-32"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 32
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.03,
            "maxValue": 0.188
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 0.63
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 433
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 1800
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.6
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.9
              }
            },
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1798.4
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 944.2
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 2.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5.8
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2697.6
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1236.4
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.9
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 8.3
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1798.4
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 944.2
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 2.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5.8
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2337.9
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1227.5
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 7.6
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.25-20": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.25
            },
            "text": "1/4-20"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 20
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.05,
            "maxValue": 0.375
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 0.807
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 0.61
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 2700
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 8
              }
            },
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2427.8
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1461.2
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 9.2
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 4113.8
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1910.8
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 2.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 12.5
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2427.8
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1461.2
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 9.2
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 3156.1
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1899.6
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 4.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 11.9
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.25-28": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.25
            },
            "text": "1/4-28"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 28
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.05,
            "maxValue": 0.375
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 0.807
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 0.61
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 2700
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 8
              }
            },
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2427.8
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1461.2
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 9.2
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 4113.8
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1910.8
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 2.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 12.5
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2427.8
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1461.2
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 9.2
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 3156.1
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1899.6
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 4.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 11.9
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.312-18": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.312
            },
            "text": "5/16-18"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 18
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.05,
            "maxValue": 0.5
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 0.906
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 0.709
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 3375
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5.8
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 17
              }
            },
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2810
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1775.9
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 23.6
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 5440.2
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2315.4
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 2.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 16.7
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2810
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1775.9
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 23.6
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 3653
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2308.7
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 6.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 30.7
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.312-24": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.312
            },
            "text": "5/16-24"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 24
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.05,
            "maxValue": 0.5
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 0.906
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 0.709
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 3375
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5.8
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 17
              }
            },
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2810
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1775.9
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 23.6
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 5440.2
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2315.4
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 2.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 16.7
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2810
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1775.9
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 23.6
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 3653
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2308.7
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 6.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 30.7
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.375-16": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.375
            },
            "text": "3/8-16"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 16
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.05,
            "maxValue": 0.5
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 0.984
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 0.787
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 4950
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {},
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 3889
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2405.4
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 12.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 33.2
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 7620.7
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 3147.2
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.1
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 24.9
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 3889
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2405.4
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 12.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 33.2
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 5055.7
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 3127
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 16
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 43.2
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.375-24": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.375
            },
            "text": "3/8-24"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 24
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.05,
            "maxValue": 0.5
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 0.984
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 0.787
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 4950
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {},
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 3889
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2405.4
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 12.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 33.2
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 7620.7
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 3147.2
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.1
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 24.9
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 3889
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2405.4
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 12.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 33.2
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 5055.7
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 3127
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 16
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 43.2
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.5-13": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.5
            },
            "text": "1/2-13"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 13
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.05,
            "maxValue": 0.5
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 1.102
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 0.906
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 6750
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {},
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 4788.2
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2765
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 15.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 44.3
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 8092.8
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 3596.8
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 26.6
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 4788.2
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2765
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 15.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 44.3
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 6224.7
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 3594.5
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 20.4
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 57.6
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.5-20": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.5
            },
            "text": "1/2-20"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 20
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.05,
            "maxValue": 0.5
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 1.102
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 0.906
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 6750
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {},
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 4788.2
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2765
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 15.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 44.3
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 8092.8
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 3596.8
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 26.6
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 4788.2
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2765
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 15.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 44.3
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 6224.7
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 3594.5
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 20.4
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 57.6
              }
            },
            "Titanium": {}
          }
        }
      },
      "metricDiameters": {
        "3-0.5": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3
            },
            "text": "M3"
          },
          "pitch": {
            "unit": UnitsPitch.MillimetersPerThread,
            "value": 0.5
          },
          "gripRange": {
            "unit": UnitsDistance.Millimeter,
            "minValue": 0.5,
            "maxValue": 3
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Millimeter,
              "value": 11.5
            },
            "installed": {
              "unit": UnitsDistance.Millimeter,
              "value": 6.5
            }
          },
          "swagingForce": {
            "unit": UnitsForce.Newton,
            "value": 4000
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {},
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 4200
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 1800
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 1.1
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 1.5
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 4200
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 1800
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 1.1
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 1.5
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 4200
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 1800
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 1.1
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 1.5
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 5460
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 2300
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 1.4
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 2
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "4-0.7": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4
            },
            "text": "M4"
          },
          "pitch": {
            "unit": UnitsPitch.MillimetersPerThread,
            "value": 0.7
          },
          "gripRange": {
            "unit": UnitsDistance.Millimeter,
            "minValue": 0.5,
            "maxValue": 4
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Millimeter,
              "value": 13
            },
            "installed": {
              "unit": UnitsDistance.Millimeter,
              "value": 8
            }
          },
          "swagingForce": {
            "unit": UnitsForce.Newton,
            "value": 5000
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 4700
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 1900
              },
              "shearStrength": {
                "unit": UnitsForce.Newton,
                "value": 1900
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 1.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 4
              }
            },
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 5500
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 2900
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 2.2
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 5.1
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 5500
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 2900
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 2.2
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 5.1
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 5500
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 2900
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 2.2
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 5.1
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 7150
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 3800
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 2.9
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 6.6
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "5-0.8": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 5
            },
            "text": "M5"
          },
          "pitch": {
            "unit": UnitsPitch.MillimetersPerThread,
            "value": 0.8
          },
          "gripRange": {
            "unit": UnitsDistance.Millimeter,
            "minValue": 0.5,
            "maxValue": 6
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Millimeter,
              "value": 16
            },
            "installed": {
              "unit": UnitsDistance.Millimeter,
              "value": 11
            }
          },
          "swagingForce": {
            "unit": UnitsForce.Newton,
            "value": 8000
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 6300
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 2600
              },
              "shearStrength": {
                "unit": UnitsForce.Newton,
                "value": 2600
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 2.2
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 5.3
              }
            },
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 8000
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 4200
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 3.4
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 7.9
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 8000
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 4200
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 3.4
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 7.9
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 8000
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 4200
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 3.4
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 7.9
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 10400
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 5500
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 4.4
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 10.3
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "6-1": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 6
            },
            "text": "M6"
          },
          "pitch": {
            "unit": UnitsPitch.MillimetersPerThread,
            "value": 1
          },
          "gripRange": {
            "unit": UnitsDistance.Millimeter,
            "minValue": 0.5,
            "maxValue": 6
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Millimeter,
              "value": 20.5
            },
            "installed": {
              "unit": UnitsDistance.Millimeter,
              "value": 15.5
            }
          },
          "swagingForce": {
            "unit": UnitsForce.Newton,
            "value": 12000
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 10100
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 3400
              },
              "shearStrength": {
                "unit": UnitsForce.Newton,
                "value": 3400
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 4.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 10.8
              }
            },
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 10800
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 6500
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 4.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 12.4
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 10800
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 6500
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 4.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 12.4
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 10800
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 6500
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 4.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 12.4
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 14000
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 8500
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 6.1
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 16.1
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "8-1.25": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 8
            },
            "text": "M8"
          },
          "pitch": {
            "unit": UnitsPitch.MillimetersPerThread,
            "value": 1.25
          },
          "gripRange": {
            "unit": UnitsDistance.Millimeter,
            "minValue": 0.5,
            "maxValue": 6
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Millimeter,
              "value": 23
            },
            "installed": {
              "unit": UnitsDistance.Millimeter,
              "value": 18
            }
          },
          "swagingForce": {
            "unit": UnitsForce.Newton,
            "value": 15000
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 11700
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 3700
              },
              "shearStrength": {
                "unit": UnitsForce.Newton,
                "value": 4700
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 7.8
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 23
              }
            },
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 12500
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 7900
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 6.8
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 32
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 12500
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 7900
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 6.8
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 32
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 12500
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 7900
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 6.8
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 32
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 16250
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 10300
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 8.8
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 41.6
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "10-1.5": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 10
            },
            "text": "M10"
          },
          "pitch": {
            "unit": UnitsPitch.MillimetersPerThread,
            "value": 1.5
          },
          "gripRange": {
            "unit": UnitsDistance.Millimeter,
            "minValue": 0.5,
            "maxValue": 6
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Millimeter,
              "value": 25
            },
            "installed": {
              "unit": UnitsDistance.Millimeter,
              "value": 20
            }
          },
          "swagingForce": {
            "unit": UnitsForce.Newton,
            "value": 22000
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {},
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 17300
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 10700
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 16.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 45
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 17300
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 10700
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 16.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 45
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 17300
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 10700
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 16.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 45
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 22500
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 14000
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 21.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 58.5
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "12-1.75": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 12
            },
            "text": "M12"
          },
          "pitch": {
            "unit": UnitsPitch.MillimetersPerThread,
            "value": 1.75
          },
          "gripRange": {
            "unit": UnitsDistance.Millimeter,
            "minValue": 0.5,
            "maxValue": 6
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Millimeter,
              "value": 28
            },
            "installed": {
              "unit": UnitsDistance.Millimeter,
              "value": 23
            }
          },
          "swagingForce": {
            "unit": UnitsForce.Newton,
            "value": 30000
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {},
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 21300
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 12300
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 21.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 60
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 21300
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 12300
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 21.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 60
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 21300
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 12300
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 21.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 60
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 27700
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 16000
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 27.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 78
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        }
      }
    }
  },
  "Closed End Rivet Nut": {
    "fastenerType": FastenerTypes.rivetNut,
    "name": FastenerNames.ClosedEndRivetNut,
    "parameters": {
      "imperialDiameters": {
        "0.098-40": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.098
            },
            "text": "4-40"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 40
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.02,
            "maxValue": 0.25
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 0.649
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 0.453
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 900
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {},
            "Brass": {},
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {},
            "Steel Med Carbon": {},
            "Steel (37MnB2)": {},
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.128-32": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.128
            },
            "text": "6-32"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 32
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.02,
            "maxValue": 0.25
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 0.649
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 0.453
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 900
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {},
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 944.2
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 404.6
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 0.8
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.1
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1843.4
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 517
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 0.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 944.2
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 404.6
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 0.8
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.1
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1227.5
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 526
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.1
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.4
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.156-32": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.156
            },
            "text": "8-32"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 32
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.03,
            "maxValue": 0.25
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 0.709
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 0.512
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 1125
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3
              }
            },
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1236.4
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 651.9
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.6
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.8
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2068.2
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 854.2
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.4
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 4.1
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1236.4
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 651.9
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.6
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.8
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1607.3
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 847.5
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 2.1
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 4.9
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.188-24": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.188
            },
            "text": "2022-10-24T07:00:00.000Z"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 24
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.03,
            "maxValue": 0.188
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 0.827
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 0.63
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 1800
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.6
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.9
              }
            },
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1798.4
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 944.2
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 2.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5.8
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2697.6
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1236.4
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.9
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 8.3
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1798.4
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 944.2
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 2.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5.8
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2337.9
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1227.5
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 7.6
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.188-32": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.188
            },
            "text": "10-32"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 32
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.03,
            "maxValue": 0.188
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 0.827
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 0.63
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 1800
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.6
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.9
              }
            },
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1798.4
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 944.2
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 2.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5.8
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2697.6
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1236.4
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 1.9
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 8.3
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 1798.4
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 944.2
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 2.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5.8
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2337.9
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1227.5
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 7.6
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.25-20": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.25
            },
            "text": "1/4-20"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 20
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.05,
            "maxValue": 0.375
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 1.004
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 0.807
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 2700
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 8
              }
            },
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2427.8
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1461.2
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 9.2
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 4113.8
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1910.8
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 2.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 12.5
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2427.8
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1461.2
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 9.2
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 3156.1
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1899.6
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 4.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 11.9
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.25-28": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.25
            },
            "text": "1/4-28"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 28
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.05,
            "maxValue": 0.375
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 1.004
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 0.807
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 2700
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 8
              }
            },
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2427.8
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1461.2
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 9.2
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 4113.8
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1910.8
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 2.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 12.5
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2427.8
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1461.2
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 9.2
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 3156.1
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1899.6
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 4.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 11.9
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.312-18": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.312
            },
            "text": "5/16-18"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 18
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.05,
            "maxValue": 0.5
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 1.103
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 0.906
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 3375
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5.8
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 17
              }
            },
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2810
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1775.9
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 23.6
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 5440.2
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2315.4
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 2.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 16.7
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2810
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1775.9
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 23.6
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 3653
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2308.7
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 6.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 30.7
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.312-24": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.312
            },
            "text": "5/16-24"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 24
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.05,
            "maxValue": 0.5
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 1.103
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 0.906
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 3375
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5.8
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 17
              }
            },
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2810
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1775.9
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 23.6
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 5440.2
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2315.4
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 2.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 16.7
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 2810
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 1775.9
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 23.6
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 3653
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2308.7
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 6.5
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 30.7
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.375-16": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.375
            },
            "text": "3/8-16"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 16
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.05,
            "maxValue": 0.5
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 1.181
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 0.984
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 4950
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {},
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 3889
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2405.4
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 12.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 33.2
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 7620.7
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 3147.2
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.1
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 24.9
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 3889
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2405.4
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 12.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 33.2
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 5055.7
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 3127
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 16
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 43.2
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.375-24": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.375
            },
            "text": "3/8-24"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 24
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.05,
            "maxValue": 0.5
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 1.181
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 0.984
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 4959
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {},
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 3889
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2405.4
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 12.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 33.2
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 7620.7
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 3147.2
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 3.1
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 24.9
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 3889
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2405.4
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 12.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 33.2
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 5055.7
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 3127
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 16
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 43.2
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.5-13": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.5
            },
            "text": "1/2-13"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 13
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.05,
            "maxValue": 0.5
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 1.299
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 1.103
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 6750
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {},
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 4788.2
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2765
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 15.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 44.3
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 8092.8
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 3596.8
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 26.6
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 4788.2
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2765
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 15.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 44.3
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 6224.7
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 3594.5
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 20.4
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 57.6
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "0.5-20": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Inch,
              "value": 0.5
            },
            "text": "1/2-20"
          },
          "pitch": {
            "unit": UnitsPitch.ThreadsPerInch,
            "value": 20
          },
          "gripRange": {
            "unit": UnitsDistance.Inch,
            "minValue": 0.05,
            "maxValue": 0.5
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Inch,
              "value": 1.299
            },
            "installed": {
              "unit": UnitsDistance.Inch,
              "value": 1.103
            }
          },
          "swagingForce": {
            "unit": UnitsForce.PSI,
            "value": 6750
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {},
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 4788.2
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2765
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 15.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 44.3
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 8092.8
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 3596.8
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 5.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 26.6
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 4788.2
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 2765
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 15.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 44.3
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.PSI,
                "value": 6224.7
              },
              "pullOutStrength": {
                "unit": UnitsForce.PSI,
                "value": 3594.5
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 20.4
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.FootPoundForce,
                "value": 57.6
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        }
      },
      "metricDiameters": {
        "3-0.5": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 3
            },
            "text": "M3"
          },
          "pitch": {
            "unit": UnitsPitch.MillimetersPerThread,
            "value": 0.5
          },
          "gripRange": {
            "unit": UnitsDistance.Millimeter,
            "minValue": 0.5,
            "maxValue": 3
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Millimeter,
              "value": 16.5
            },
            "installed": {
              "unit": UnitsDistance.Millimeter,
              "value": 11.5
            }
          },
          "swagingForce": {
            "unit": UnitsForce.Newton,
            "value": 4000
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {},
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 4200
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 1800
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 1.1
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 1.5
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 8200
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 2300
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 0.4
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 4
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 4200
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 1800
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 1.1
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 1.5
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 5460
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 2300
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 1.4
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 2
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "4-0.7": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 4
            },
            "text": "M4"
          },
          "pitch": {
            "unit": UnitsPitch.MillimetersPerThread,
            "value": 0.7
          },
          "gripRange": {
            "unit": UnitsDistance.Millimeter,
            "minValue": 0.5,
            "maxValue": 4
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Millimeter,
              "value": 18
            },
            "installed": {
              "unit": UnitsDistance.Millimeter,
              "value": 13
            }
          },
          "swagingForce": {
            "unit": UnitsForce.Newton,
            "value": 5000
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 4700
              },
              "shearStrength": {
                "unit": UnitsForce.Newton,
                "value": 1900
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 1.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 4
              }
            },
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 5500
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 2900
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 2.2
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 5.1
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 9200
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 3800
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 1.9
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 5.6
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 5500
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 2900
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 2.2
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 5.1
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 7150
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 3800
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 2.9
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 6.6
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "5-0.8": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 5
            },
            "text": "M5"
          },
          "pitch": {
            "unit": UnitsPitch.MillimetersPerThread,
            "value": 0.8
          },
          "gripRange": {
            "unit": UnitsDistance.Millimeter,
            "minValue": 0.5,
            "maxValue": 6
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Millimeter,
              "value": 21
            },
            "installed": {
              "unit": UnitsDistance.Millimeter,
              "value": 16
            }
          },
          "swagingForce": {
            "unit": UnitsForce.Newton,
            "value": 8000
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 6300
              },
              "shearStrength": {
                "unit": UnitsForce.Newton,
                "value": 2600
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 2.2
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 5.3
              }
            },
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 8000
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 4200
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 3.4
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 7.9
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 12000
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 5500
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 2.6
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 11.3
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 8000
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 4200
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 3.4
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 7.9
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 10400
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 5500
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 4.4
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 10.3
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "6-1": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 6
            },
            "text": "M6"
          },
          "pitch": {
            "unit": UnitsPitch.MillimetersPerThread,
            "value": 1
          },
          "gripRange": {
            "unit": UnitsDistance.Millimeter,
            "minValue": 0.5,
            "maxValue": 6
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Millimeter,
              "value": 25.5
            },
            "installed": {
              "unit": UnitsDistance.Millimeter,
              "value": 20.5
            }
          },
          "swagingForce": {
            "unit": UnitsForce.Newton,
            "value": 12000
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 10100
              },
              "shearStrength": {
                "unit": UnitsForce.Newton,
                "value": 3400
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 4.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 10.8
              }
            },
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 10800
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 6500
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 4.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 12.4
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 18300
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 8500
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 3.4
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 16.9
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 10800
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 6500
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 4.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 12.4
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 14000
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 8500
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 6.1
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 16.1
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "8-1.25": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 8
            },
            "text": "M8"
          },
          "pitch": {
            "unit": UnitsPitch.MillimetersPerThread,
            "value": 1.25
          },
          "gripRange": {
            "unit": UnitsDistance.Millimeter,
            "minValue": 0.5,
            "maxValue": 6
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Millimeter,
              "value": 28
            },
            "installed": {
              "unit": UnitsDistance.Millimeter,
              "value": 23
            }
          },
          "swagingForce": {
            "unit": UnitsForce.Newton,
            "value": 15000
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 11700
              },
              "shearStrength": {
                "unit": UnitsForce.Newton,
                "value": 4700
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 7.8
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 23
              }
            },
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 12500
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 7900
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 6.8
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 32
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 24200
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 10300
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 3.6
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 22.6
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 12500
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 7900
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 6.8
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 32
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 16250
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 10300
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 8.8
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 41.6
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "10-1.5": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 10
            },
            "text": "M10"
          },
          "pitch": {
            "unit": UnitsPitch.MillimetersPerThread,
            "value": 1.5
          },
          "gripRange": {
            "unit": UnitsDistance.Millimeter,
            "minValue": 0.5,
            "maxValue": 6
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Millimeter,
              "value": 30
            },
            "installed": {
              "unit": UnitsDistance.Millimeter,
              "value": 25
            }
          },
          "swagingForce": {
            "unit": UnitsForce.Newton,
            "value": 22000
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {},
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 17300
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 10700
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 16.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 45
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 33900
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 14000
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 4.2
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 33.8
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 17300
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 10700
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 16.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 45
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 22500
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 14000
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 21.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 58.5
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        },
        "12-1.75": {
          "diameter": {
            "distance": {
              "unit": UnitsDistance.Millimeter,
              "value": 12
            },
            "text": "M12"
          },
          "pitch": {
            "unit": UnitsPitch.MillimetersPerThread,
            "value": 1.75
          },
          "gripRange": {
            "unit": UnitsDistance.Millimeter,
            "minValue": 0.5,
            "maxValue": 6
          },
          "length": {
            "uninstalled": {
              "unit": UnitsDistance.Millimeter,
              "value": 33
            },
            "installed": {
              "unit": UnitsDistance.Millimeter,
              "value": 28
            }
          },
          "swagingForce": {
            "unit": UnitsForce.Newton,
            "value": 30000
          },
          "compatibleHeadStyles": {
            "Large Flange": true,
            "Small Flange": true,
            "Trim Head": true,
            "Countersunk Head": true,
            "Flat Head": true,
            "Low Profile": true
          },
          "compatibleMiscFeatures": {
            "Sealing": true,
            "Riv-Float": true,
            "Wedge Head": true
          },
          "compatibleBodyTypes": {
            "Round": true,
            "Round, knurled": true,
            "Slotted": true,
            "Half hex": true,
            "Full hex": true
          },
          "materials": {
            "Aluminum": {},
            "Brass": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 21300
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 12300
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 21.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 60
              }
            },
            "Monel": {},
            "Nylon": {},
            "Stainless Steel": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 36000
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 16000
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 7.2
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 36.1
              }
            },
            "Steel Med Carbon": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 21300
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 12300
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 21.3
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 60
              }
            },
            "Steel (37MnB2)": {
              "tensileStrength": {
                "unit": UnitsForce.Newton,
                "value": 27700
              },
              "pullOutStrength": {
                "unit": UnitsForce.Newton,
                "value": 16000
              },
              "maxTorqueUnsupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 27.7
              },
              "maxTorqueSupported": {
                "unit": UnitsTorque.NewtonMeter,
                "value": 78
              }
            },
            "Titanium": {},
            "Steel (Ungraded)": {}
          }
        }
      }
    }
  }
};
