import { useAuth0 } from "@auth0/auth0-react";
import { Card } from "react-bootstrap";
import { plans } from '../ChoosePlan'
import { CompanyInfo } from "./SignupRivian";
import generalStyles from "../../styles/style.module.scss"

const unknownCompanyInfo: CompanyInfo = {
  industry: null,
  industryFocus: null,
  companyName: null,
  companyAddress: null,
  companyWebsite: null,
}

export function SignupTrial() {

  const { loginWithRedirect } = useAuth0();

  const advancedPlan = plans[plans.length - 1];
  localStorage.setItem('signUpPlan', JSON.stringify(advancedPlan));
  localStorage.setItem('signUpCompany', JSON.stringify(unknownCompanyInfo));

  loginWithRedirect({
    screen_hint: "signup",
    redirectUri: process.env.REACT_APP_AUTH0_SIGNUP_CALLBACK,
    appState: {
      plan: advancedPlan
    }
  })

  return <div className={generalStyles.baseContainer}>
    <div className={generalStyles.verify}>
      <Card.Body>
        Redirecting to trial account creation page...
      </Card.Body>
    </div>
  </div>
}