import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import SignupButton from "./auth/SignupButton";
import env from "../env";
import { FormEvent, FormEventHandler, useState } from "react";
import { submitAdvancedPlanContactRequest } from "./ContactUs";
import { LoadingIndicator } from "react-select/src/components/indicators";
import Loading from "./Loading";

enum paymentTier {
  free,
  basic,
  standard,
  premium,
  professional
}

type price = {
  cost: number,
  priceId: string
}

export type plan = {
  name: "Student" | "Standard" | "Premium" | "Advanced",
  paymentTier: paymentTier,
  // monthlyPrice?: price,
  // yearlyPrice?: price,
  quarterlyPrice: price,
  features: string[]
}

export const plans: plan[] = [
  // {
  //   name: "Free",
  //   paymentTier: paymentTier.basic,
  //   monthlyPrice: 0,
  //   yearlyPrice: null,
  //   features: [
  //    "FES FindingIt Fastener Calculator",
  //     "Virtual Search and Print Access",
  //     "Qualified Sourcing Options for Specified Joints"

  //     // todo @Crystal: add other features
  //   ]
  // },
  {
    name: "Student",
    paymentTier: paymentTier.basic,
    // monthlyPrice: {
    //   cost: 19.99,
    //   priceId: (env.NODE_ENV === 'development')
    //     ? 'price_1KYGtAEvX1LaA23CiwnsW4ZB'  // development
    //     : 'price_1KYG9gEvX1LaA23CNQ9SFw6v'  // production
    // },
    // yearlyPrice: {
    //   cost: 200,
    //   priceId: env.NODE_ENV === 'development'
    //     ? 'price_1KYGtWEvX1LaA23CMIorHgi3 '  // development
    //     : 'price_1KYGA9EvX1LaA23CHbZ3x7fK'  // production
    // },
    quarterlyPrice: {
      cost: 29.97,
      priceId: (env.NODE_ENV === 'development')
          ? 'price_1KZ4H0EvX1LaA23CSfWP5rF8'  // development
          : 'price_1KZ4I4EvX1LaA23C5afpo72t'  // production
    },
    features: [
      "FES FindingIt Fastener Calculator",
      "Virtual Search and Print Access",
      "Qualified Sourcing Options for Specified Joints",
      "FES JoiningIt Joint Qualifier",
      "Fastener Training - FES LearningIt",
      "1 hour phone support/year",
      "Virtual Search and Print Access",
      "101’s and Joint Qualification Training",
      "Sample and testing partner assistance"
      // todo @Crystal: add other features
    ]
  },
  {
    name: "Standard",
    paymentTier: paymentTier.standard,
    // monthlyPrice: {
    //   cost: 49.99,
    //   priceId: (env.NODE_ENV === 'development')
    //     ? 'price_1Ii7qCEvX1LaA23CW0TqnKUw'  // development
    //     : 'price_1Jfss5EvX1LaA23CLiYderlT'  // production
    // },
    // yearlyPrice: {
    //   cost: 500,
    //   priceId: (env.NODE_ENV === 'development')
    //     ? 'price_1Ii7qCEvX1LaA23Cy0fmHPoO'  // development
    //     : 'price_1Jfss5EvX1LaA23CJ1walvRo'  // production
    // },
    quarterlyPrice: {
      cost: 149.97,
      priceId: (env.NODE_ENV === 'development')
          ? 'price_1KZ4NCEvX1LaA23CwQhUK8Io'  // development
          : 'price_1KZ4JAEvX1LaA23CF0BcWiG8'  // production
    },
    features: [
      "All Basic Plan features",
      "5 hours phone support/year (total)",
      "Live expert review",
      "Advanced FES JoiningIt",
      "FES LearningIt Library of learning courses",
      "Secondary process support",
      "PDH (Personal Development Hours)"
      // todo @Crystal: add other features
    ]
  },
  {
    name: "Premium",
    paymentTier: paymentTier.premium,
    // monthlyPrice: {
    //   cost: 99.99,
    //   priceId: (env.NODE_ENV === 'development')
    //     ? 'price_1IZUbAEvX1LaA23CzeRZf2OV'  // development
    //     : 'price_1JfsqyEvX1LaA23CI4rsRkUy'  // production
    // },
    // yearlyPrice: {
    //   cost: 1000,
    //   priceId: (env.NODE_ENV === 'development')
    //     ? 'price_1IZUbAEvX1LaA23CmBtOvprd'  // development
    //     : 'price_1JfsqyEvX1LaA23COVMDGqGm'  // production
    // },
    quarterlyPrice: {
      cost: 299.97,
      priceId: (env.NODE_ENV === 'development')
          ? 'price_1KZ4MOEvX1LaA23CCXHSJTGY'  // development
          : 'price_1KZ4KHEvX1LaA23CFrA7OQUb'  // production
    },
    features: [
      "All Basic and Standard Plan features",
      "10 hours phone support/year (total)",
      "Custom Fastener Sourcing, Product Development, and Prototype Support",
      "Assembly & Installation Tooling Support",
      "3rd Party Automation Assistance or Referral",
      "Online Courses with joining experts to grow in your role or train new hires"
      // todo @Crystal: add other features
    ]
  },
  {
    name: "Advanced",
    paymentTier: paymentTier.professional,
    // monthlyPrice: null,
    // yearlyPrice: null,
    features: [
      "Joint Qualification & Verification",
      "Is superlatively professional"
      // todo @Crystal: add other features
    ]
  } as plan,
];

type AdvancedPlanContactModalProps = {
  showModal: boolean
  handleClose: () => void
}

function AdvancedPlanContactModal(props: AdvancedPlanContactModalProps) {
  const [requestStatus, setRequestStatus] = useState<'unsent' | 'pending' | 'successful' | 'error'>('unsent');

  const [userEmail, setUserEmail] = useState<string | undefined>(undefined);
  const [teamMemberCount, setTeamMemberCount] = useState<number | undefined>(undefined);
  const [comments, setComments] = useState<string | undefined>(undefined);

  const formDisabled: boolean = (requestStatus === 'pending' || requestStatus === 'successful')

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (userEmail !== undefined && teamMemberCount !== undefined) {
      const requestPayload = {
        email: userEmail,
        teamMemberCount: teamMemberCount,
        comments: comments ? comments : ""
      }
      setRequestStatus('pending');
      const result = await submitAdvancedPlanContactRequest(requestPayload);
      setRequestStatus(result ? 'successful' : 'error');
    }
  }

  return (
    <Modal
      show={props.showModal}
      scrollable
      onHide={props.handleClose}
    >
      <Modal.Header closeButton>Contact Us</Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit} >
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            required
            disabled={formDisabled}
            value={userEmail}
            onChange={ e => setUserEmail(e.target.value) }
          />
          <br />
          <Form.Label>Number of Team Members</Form.Label>
          <Form.Control
            type='number'
            min={1}
            step={1}
            required
            disabled={formDisabled}
            value={teamMemberCount}
            onChange={ e => setTeamMemberCount(Math.round(Number(e.target.value))) }
          />
          <br />
          <Form.Label>Comments</Form.Label>
          <Form.Control
            as='textarea'
            disabled={formDisabled}
            value={comments}
            onChange={ e => setComments(e.target.value) }
          />
          <br />
          { (formDisabled === false) &&
            <Button type='submit'>Submit</Button>
          }
          { (requestStatus === 'pending') &&
            <Loading />
          }
          { (requestStatus === 'successful') && <>
            Your request has been submitted. Our team will get back to you as soon as possible.
            <br />
            <Button 
              onClick={props.handleClose}
            >
              Close
            </Button>
          </>}
        </Form>
      </Modal.Body>
    </Modal>
  )
}

function DisplayPlan(props: {plan: plan, handleShowAdvancedPlanContactModal: () => void }) {
  const monthlyCost = Math.round(props.plan.quarterlyPrice?.cost / 3 * 10000) / 10000

  if(props.plan.name === "Advanced") {
    return (
      <>
      <div className="plan advancedPlan">
        <div className="whiteGhost">
          <div className="blueBubbleTag overlap">{props.plan.name} Plan</div> {/*@Evan - I'd like to be able to pull out a classname property from this object, how do?*/}
          {/* todo @Evan @Crystal @Kai: handle unique cases for Free and Pro plans */}
          <div className="advancedPricingContainer">
            <div className="advancedPricingInfo">
             <div className="advancedPricingInfoSpecialText">Up to</div><div>50</div><div className="advancedPricingInfoSpecialText">users</div>
            </div>
          </div>
          <div className="advancedPricingContact">Contact us for pricing</div>
            <div className="advancedFeatures">
              <span style={{ fontSize: "1rem", fontWeight: "bold"}}>All features of the Basic, Standard and Premium plans, at a bulk rate for your organization.</span>
              <br></br><br></br><p>This is for any large team looking for a group plan or a new product or program team deep in design needing a lot more than our standard offer.</p>
            </div>
            <Button
              onClick={props.handleShowAdvancedPlanContactModal}
            >Contact Us</Button>
          </div>
      </div>
      </>
    )
  } else {
    return (
      <div className="plan">
        <div className="whiteGhost">
          <div className="blueBubbleTag overlap">{props.plan.name} Plan</div> {/*@Evan - I'd like to be able to pull out a classname property from this object, how do?*/}
          {/* todo @Evan @Crystal @Kai: handle unique cases for Free and Pro plans */}
          <div className="pricingInfo">
              <div><div className="priceValue">${monthlyCost}<span style={{ fontSize: ".9rem" }}>/mo</span></div></div>
          </div>
          <div className="free-trial-notice">
            14-day free trial
          </div>
          <div className="features">
            <ul>
              {
                props.plan.features.map((feature, i) => {
                  return <li key={i}>{feature}</li>
                })
              }
            </ul>
          </div>
          {/* todo: allow users to choose payment frequency */}
            {/* <SignupButton signupPlan={props.plan} /> */}
        </div>
      </div>
    )
  }
}

export default function ChoosePlan() {
  const [showAdvancedPlanContactModal, setShowAdvancedPlanContactModal] = useState<boolean>(false);
  const handleModalShow = () => setShowAdvancedPlanContactModal(true);
  const handleModalClose = () => setShowAdvancedPlanContactModal(false);

  return (<>
    <AdvancedPlanContactModal
      showModal={showAdvancedPlanContactModal}
      handleClose={handleModalClose}
    />
    <div className="plans">
      <div className="choosePlanGrid">
        {
          plans.map((plan, i) => {
            return <DisplayPlan key={i} plan={plan} handleShowAdvancedPlanContactModal={handleModalShow} />
          })
        }
      </div>
    </div>
  </>)
}