const env = {
  REACT_APP_STRIPE_PUB_KEY: process.env.REACT_APP_STRIPE_PUB_KEY as string,
  REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN as string,
  REACT_APP_AUTH0_CLIENTID: process.env.REACT_APP_AUTH0_CLIENTID as string,
  REACT_APP_AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE as string,
  REACT_APP_AUTH0_SIGNUP_CALLBACK: process.env.REACT_APP_AUTH0_SIGNUP_CALLBACK as string,
  REACT_APP_CF_WORKERS_URL: process.env.REACT_APP_CF_WORKERS_URL as string,
  REACT_APP_GRAPHQL_URL: process.env.REACT_APP_GRAPHQL_URL as string,
  REACT_APP_HASURA_SECRET_KEY: process.env.REACT_APP_HASURA_SECRET_KEY as string,
  REACT_APP_ROOT_URL: process.env.REACT_APP_ROOT_URL as string,
  NODE_ENV: process.env.NODE_ENV as string
};

for (const envVar in env) {
  //@ts-expect-error
  if (env[envVar] === undefined) {
    throw new Error(`Missing env variable: ${envVar}`)
    // console.error(`Missing frontend env variable: ${envVar}`)
  }
}

// console.log("node env:", env.NODE_ENV)

export default env;