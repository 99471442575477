import { DimensionedQuantity, IncrementRange, isDimensionedQuantity,  SelectedRivetParameters, SelectedThreadedParameters } from '../FindIt/findItTypes';
import { SelectedPartParameters } from "./FastenerRecommender";
import Select, { ReactSelectOption, ReactSelectOptions } from '../utilities/FixRequiredSelect';
import { BasicFieldSelector } from './BasicFieldSelector';
import { PositionType } from "@atlaskit/tooltip";
import { FfTooltip } from '../globalComponents/FfTooltip';
import infoIcon from "../../images/info-hover-01.svg"
import styles from "../../styles/_findIt.module.scss"
import utilityStyles from "../../styles/_utilityStyles.module.scss"

type FieldNames =
  | keyof SelectedThreadedParameters
  | keyof SelectedRivetParameters

type RangeFieldSelectorProps = {
  selectionOptions: ReactSelectOptions<any, string>;
  defaultValue?: {
    min?: ReactSelectOption<any, string>,
    max?: ReactSelectOption<any, string>
  },
  required: boolean;
  fieldName: FieldNames;
  fieldTitle: string;
  parentField?: {
    fieldName: FieldNames,
    fieldTitle: string
  }
  icon?: {
    src: string,
    altText: string,
    position: 'left' | 'right' | 'center'
  }
  tooltip?: {
    content: React.ReactNode,
    position?: PositionType,
  }
  // wrapperClassName: string;
  childClassName: string;
  // placeholder?: string,
  // isDisabled?: boolean,
  highlight?: {
    setFeatureAsHighlighted?: () => void,
    setFeatureAsNotHighlighted?: () => void
    setRivetFeatureAsHighlighted?: () => void,
    setRivetFeatureAsNotHighlighted?: () => void
  },
  selectedPartParameters: SelectedPartParameters;
  setSelectedPartParameters: (newPartParameters: SelectedPartParameters) => void;
};

export function RangeFieldSelector(props: RangeFieldSelectorProps) {

  // console.log("rangeFieldSelector defaultValue", props.defaultValue)

  // @ts-ignore
  const selectedRange: undefined | Partial<IncrementRange<DimensionedQuantity<any>>> = props.selectedPartParameters[props.fieldName]

  // console.log("Selected Range", selectedRange)

  const selectedMin: DimensionedQuantity<any> | undefined = (selectedRange !== undefined && selectedRange.min !== undefined)
    ? selectedRange.min
    : undefined;

  const selectedMax: DimensionedQuantity<any> | undefined = (selectedRange !== undefined && selectedRange.max !== undefined)
    ? selectedRange.max
    : undefined;

  const minOptions = (selectedMax === undefined)
    ? props.selectionOptions
    : props.selectionOptions.filter((option) => {
      if (!isDimensionedQuantity(option.value)) {
        throw "Non-dimensioned quanitity value in Range Field Selector"
      }
      if (option.value.unit !== selectedMax.unit) {
        throw "Unit mismatch"
      }
      return (option.value.value <= selectedMax.value)
    })

  const maxOptions = (selectedMin === undefined)
    ? props.selectionOptions
    : props.selectionOptions.filter((option) => {
      if (!isDimensionedQuantity(option.value)) {
        throw "Non-dimensioned quanitity value in Range Field Selector"
      }
      if (option.value.unit !== selectedMin.unit) {
        throw "Unit mismatch"
      }
      return (option.value.value >= selectedMin.value)
    })

  const setValue = (fieldName: 'min' | 'max', inputSelectedPartParams: SelectedPartParameters) => {
    // @ts-ignore
    const newValue: DimensionedQuantity<any> | undefined = inputSelectedPartParams[props.fieldName];
    const newRange: Partial<IncrementRange<DimensionedQuantity<any>>> = {
      max: selectedMax,
      min: selectedMin,
      increment: newValue?.unit,
      [fieldName]: newValue
    }
    const newPartParams = {
      ...inputSelectedPartParams,
      [props.fieldName]: newRange
    }
    props.setSelectedPartParameters(newPartParams);
  }

  const setMin = (inputSelectedPartParams: SelectedPartParameters) => setValue('min', inputSelectedPartParams);
  const setMax = (inputSelectedPartParams: SelectedPartParameters) => setValue('max', inputSelectedPartParams);

  const wrapperClassName = (props.icon === undefined)
    ? ''
    : (props.icon.position === 'left')
      ? " findingIt-field-container-with-icon findingIt-field-container-with-icon-left"
      : " findingIt-field-container-with-icon findingIt-field-container-with-icon-right"

  const header = !props.tooltip
    ? <h2 className={`${styles.smallH2} ${styles.blueH2}`}>{props.fieldTitle}</h2>
    : <>

      <h2 className={`${styles.smallH2} ${styles.blueH2}`}>
        {props.fieldTitle}
        {" "}
        <FfTooltip
          tooltipContent={props.tooltip.content}
          tooltipDecorator='icon-pro-tip'
          position={props.tooltip?.position ? props.tooltip?.position : "right"}
        >
          <img src={infoIcon} className={`${utilityStyles.infoIcon} ${styles.findingItFieldHeaderIcon}`} />
        </FfTooltip>
      </h2>

    </>

  const content = <>
    <div
      className={props.childClassName}
      onMouseOver={props.highlight?.setFeatureAsHighlighted}
      onMouseLeave={props.highlight?.setFeatureAsNotHighlighted}
      onBlur={props.highlight?.setFeatureAsNotHighlighted}
    >
      {props.icon &&
        // Couldn't find styles.fieldIconLeft; does it even exist?
        // <img src={props.icon.src} alt={props.icon.altText} className={`${styles.FieldIcon} ${styles.fieldIconLeft}`} />
        <img src={props.icon.src} alt={props.icon.altText} className={styles.fieldIcon} />
      }
      <div className={`${styles.smallH2} ${styles.blueH2} ${styles.gridColumnContent}`} > {header}
      </div>
      <div className={styles.findItRangeFieldLabel}>Min</div>
      <BasicFieldSelector
        // <FieldSelector
        {...props}
        selectionOptions={minOptions}
        defaultValue={props.defaultValue?.min}
        setSelectedPartParameters={setMin}
        onFocus={props.highlight?.setFeatureAsHighlighted}
        onBlur={props.highlight?.setFeatureAsNotHighlighted}
      />
      <div className={styles.findItRangeFieldLabel}>Max</div>
      <BasicFieldSelector
        // <FieldSelector
        {...props}
        selectionOptions={maxOptions}
        setSelectedPartParameters={setMax}
        onFocus={props.highlight?.setFeatureAsHighlighted}
        onBlur={props.highlight?.setFeatureAsNotHighlighted}
        defaultValue={props.defaultValue?.max}
      />
    </div>
    
  </>

  if (!props.tooltip) {
    return content
  }

  return <>
  {content}
  </>;
}