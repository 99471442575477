import {
  Diameter,
  DimensionedQuantity,
  DimensionedQuantityRange,
  driveTypebyHeadList,
  Fastener,
  FastenerNames,
  finishesByMaterialList,
  finishesByMaterialRivetNutsList,
  FinishNames,
  GenericPitch,
  GenericThreadedMaterial,
  isDiameter,
  isDimensionedQuantityRange,
  isGenericPitch,
  isPinAndCollarPitchSet,
  isRivetFastener,
  isRivetNutFastener,
  isSelectedRivetNutParameters,
  isSelectedRivetParameters,
  isSelectedThreadedParameters,
  isStandardThreadedMaterial,
  isThreadedFastener,
  MaterialNames,
  PitchType,
  PossibleFastenerSelectionOptions,
  PossibleRivetNutSelectionOptions,
  PossibleRivetSelectionOptions,
  PossibleThreadedSelectionOptions,
  RivetFastener,
  RivetGrip,
  RivetGripRange,
  RivetHeadStyles,
  RivetMaterial,
  RivetNutBodyTypes,
  RivetNutFastener,
  RivetNutHeadStyles,
  RivetNutMaterial,
  RivetNutMiscFeatures,
  RivetNutParameters,
  SelectedRivetNutParameters,
  SelectedRivetParameters,
  SelectedThreadedParameters,
  Strength,
  StrengthRange,
  Strengths,
  ThreadedDriveTypes,
  ThreadedFastener,
  threadedFinishSaltSprayRatings,
  ThreadedHeadTypes,
  ThreadedLength,
  ThreadedLengthRange,
  UnitsDistance,
  UnitsForce,
  UnitsPitch,
  UnitsTorque,
  UnitSystem
} from '../FindIt/findItTypes';

import { fastenerList } from "../FindIt/fastenerList";
import { ReactSelectOptions } from '../utilities/FixRequiredSelect';
import { SelectedPartParameters } from "./FastenerRecommender";

import styles from "../../styles/_findIt.module.scss"
import utilityStyles from "../../styles/_utilityStyles.module.scss"

const defaultStrengthOptions: Record<
  UnitSystem,
  ReactSelectOptions<
    StrengthRange<UnitsForce>,
    string
  >
> = {
  [UnitSystem.Imperial]: [
    {
      value: {
        unit: UnitsForce.PSI,
        minValue: 0,
        maxValue: 100
      },
      label: `0 – 100 ${UnitsForce.PSI}`
    },
    {
      value: {
        unit: UnitsForce.PSI,
        minValue: 100,
        maxValue: 200
      },
      label: `100 – 200 ${UnitsForce.PSI}`
    },
    {
      value: {
        unit: UnitsForce.PSI,
        minValue: 200,
        maxValue: 300
      },
      label: `200 – 300 ${UnitsForce.PSI}`
    },
    {
      value: {
        unit: UnitsForce.PSI,
        minValue: 300,
        maxValue: 400
      },
      label: `300 – 400 ${UnitsForce.PSI}`
    },
    {
      value: {
        unit: UnitsForce.PSI,
        minValue: 400,
        maxValue: 500
      },
      label: `400 – 500 ${UnitsForce.PSI}`
    },
    {
      value: {
        unit: UnitsForce.PSI,
        minValue: 500,
        maxValue: 600
      },
      label: `500 – 600 ${UnitsForce.PSI}`
    },
    {
      value: {
        unit: UnitsForce.PSI,
        minValue: 600,
        maxValue: 700
      },
      label: `600 – 700 ${UnitsForce.PSI}`
    },
    {
      value: {
        unit: UnitsForce.PSI,
        minValue: 700,
        maxValue: 800
      },
      label: `700 – 800 ${UnitsForce.PSI}`
    },
    {
      value: {
        unit: UnitsForce.PSI,
        minValue: 800,
        maxValue: 900
      },
      label: `800 – 900 ${UnitsForce.PSI}`
    },
    {
      value: {
        unit: UnitsForce.PSI,
        minValue: 900,
        maxValue: 1000
      },
      label: `900 – 1000 ${UnitsForce.PSI}`
    },
    {
      value: {
        unit: UnitsForce.PSI,
        minValue: 1000,
        maxValue: 2000
      },
      label: `1000 – 2000 ${UnitsForce.PSI}`
    },
    {
      value: {
        unit: UnitsForce.PSI,
        minValue: 2000,
        maxValue: 3000
      },
      label: `2000 – 3000 ${UnitsForce.PSI}`
    },
    {
      value: {
        unit: UnitsForce.PSI,
        minValue: 3000,
        maxValue: 4000
      },
      label: `3000 – 4000 ${UnitsForce.PSI}`
    },
    {
      value: {
        unit: UnitsForce.PSI,
        minValue: 4000,
        maxValue: 5000
      },
      label: `4000 – 5000 ${UnitsForce.PSI}`
    },
    {
      value: {
        unit: UnitsForce.PSI,
        minValue: 5000,
        maxValue: 6000
      },
      label: `5000 – 6000 ${UnitsForce.PSI}`
    },
    {
      value: {
        unit: UnitsForce.PSI,
        minValue: 6000,
        maxValue: 7000
      },
      label: `6000 – 7000 ${UnitsForce.PSI}`
    },
    {
      value: {
        unit: UnitsForce.PSI,
        minValue: 7000,
        maxValue: 8000
      },
      label: `7000 – 8000 ${UnitsForce.PSI}`
    },
    {
      value: {
        unit: UnitsForce.PSI,
        minValue: 8000,
        maxValue: 9000
      },
      label: `8000 – 9000 ${UnitsForce.PSI}`
    },
    {
      value: {
        unit: UnitsForce.PSI,
        minValue: 9000,
        maxValue: 10000
      },
      label: `9000 – 10000 ${UnitsForce.PSI}`
    },
    {
      value: {
        unit: UnitsForce.PSI,
        minValue: 10000,
        maxValue: 1000000
      },
      label: `10,000+ ${UnitsForce.PSI}`
    },
  ],
  [UnitSystem.Metric]: [
    {
      value: {
        unit: UnitsForce.Newton,
        minValue: 0,
        maxValue: 500
      },
      label: `0 – 500 ${UnitsForce.Newton}`
    },
    {
      value: {
        unit: UnitsForce.Newton,
        minValue: 500,
        maxValue: 1000
      },
      label: `500 – 1000 ${UnitsForce.Newton}`
    },
    {
      value: {
        unit: UnitsForce.Newton,
        minValue: 1000,
        maxValue: 1500
      },
      label: `1000 – 1500 ${UnitsForce.Newton}`
    },
    {
      value: {
        unit: UnitsForce.Newton,
        minValue: 1500,
        maxValue: 2000
      },
      label: `1500 – 2000 ${UnitsForce.Newton}`
    },
    {
      value: {
        unit: UnitsForce.Newton,
        minValue: 2000,
        maxValue: 2500
      },
      label: `2000 – 2500 ${UnitsForce.Newton}`
    },
    {
      value: {
        unit: UnitsForce.Newton,
        minValue: 2500,
        maxValue: 3000
      },
      label: `2500 – 3000 ${UnitsForce.Newton}`
    },
    {
      value: {
        unit: UnitsForce.Newton,
        minValue: 3000,
        maxValue: 3500
      },
      label: `3000 – 3500 ${UnitsForce.Newton}`
    },
    {
      value: {
        unit: UnitsForce.Newton,
        minValue: 3500,
        maxValue: 4000
      },
      label: `3500 – 4000 ${UnitsForce.Newton}`
    },
    {
      value: {
        unit: UnitsForce.Newton,
        minValue: 4000,
        maxValue: 4500
      },
      label: `4000 – 4500 ${UnitsForce.Newton}`
    },
    {
      value: {
        unit: UnitsForce.Newton,
        minValue: 4500,
        maxValue: 5000
      },
      label: `4500 – 5000 ${UnitsForce.Newton}`
    },
    {
      value: {
        unit: UnitsForce.Newton,
        minValue: 5000,
        maxValue: 10000
      },
      label: `5000 – 10000 ${UnitsForce.Newton}`
    },
    {
      value: {
        unit: UnitsForce.Newton,
        minValue: 10000,
        maxValue: 15000
      },
      label: `10000 – 15000 ${UnitsForce.Newton}`
    },
    {
      value: {
        unit: UnitsForce.Newton,
        minValue: 15000,
        maxValue: 20000
      },
      label: `15000 – 20000 ${UnitsForce.Newton}`
    },
    {
      value: {
        unit: UnitsForce.Newton,
        minValue: 20000,
        maxValue: 25000
      },
      label: `20000 – 25000 ${UnitsForce.Newton}`
    },
    {
      value: {
        unit: UnitsForce.Newton,
        minValue: 25000,
        maxValue: 30000
      },
      label: `25000 – 30000 ${UnitsForce.Newton}`
    },
    {
      value: {
        unit: UnitsForce.Newton,
        minValue: 30000,
        maxValue: 35000
      },
      label: `30000 – 35000 ${UnitsForce.Newton}`
    },
    {
      value: {
        unit: UnitsForce.Newton,
        minValue: 35000,
        maxValue: 40000
      },
      label: `35000 – 40000 ${UnitsForce.Newton}`
    },
    {
      value: {
        unit: UnitsForce.Newton,
        minValue: 40000,
        maxValue: 45000
      },
      label: `40000 – 45000 ${UnitsForce.Newton}`
    },
    {
      value: {
        unit: UnitsForce.Newton,
        minValue: 45000,
        maxValue: 50000
      },
      label: `45000 – 50000 ${UnitsForce.Newton}`
    },
    {
      value: {
        unit: UnitsForce.Newton,
        minValue: 50000,
        maxValue: 5000000
      },
      label: `50000+ ${UnitsForce.Newton}`
    },
  ]
}

const defaultTorqueStrengthOptions: Record<
  UnitSystem,
  ReactSelectOptions<
    StrengthRange<UnitsTorque>,
    string
  >
> = {
  [UnitSystem.Imperial]: [
    {
      value: {
        unit: UnitsTorque.FootPoundForce,
        minValue: 0,
        maxValue: 100
      },
      label: `0 – 100 ${UnitsTorque.FootPoundForce}`
    },
    {
      value: {
        unit: UnitsTorque.FootPoundForce,
        minValue: 100,
        maxValue: 200
      },
      label: `100 – 200 ${UnitsTorque.FootPoundForce}`
    },
    {
      value: {
        unit: UnitsTorque.FootPoundForce,
        minValue: 200,
        maxValue: 300
      },
      label: `200 – 300 ${UnitsTorque.FootPoundForce}`
    },
    {
      value: {
        unit: UnitsTorque.FootPoundForce,
        minValue: 300,
        maxValue: 400
      },
      label: `300 – 400 ${UnitsTorque.FootPoundForce}`
    },
    {
      value: {
        unit: UnitsTorque.FootPoundForce,
        minValue: 400,
        maxValue: 500
      },
      label: `400 – 500 ${UnitsTorque.FootPoundForce}`
    },
    {
      value: {
        unit: UnitsTorque.FootPoundForce,
        minValue: 500,
        maxValue: 600
      },
      label: `500 – 600 ${UnitsTorque.FootPoundForce}`
    },
    {
      value: {
        unit: UnitsTorque.FootPoundForce,
        minValue: 600,
        maxValue: 700
      },
      label: `600 – 700 ${UnitsTorque.FootPoundForce}`
    },
    {
      value: {
        unit: UnitsTorque.FootPoundForce,
        minValue: 700,
        maxValue: 800
      },
      label: `700 – 800 ${UnitsTorque.FootPoundForce}`
    },
    {
      value: {
        unit: UnitsTorque.FootPoundForce,
        minValue: 800,
        maxValue: 900
      },
      label: `800 – 900 ${UnitsTorque.FootPoundForce}`
    },
    {
      value: {
        unit: UnitsTorque.FootPoundForce,
        minValue: 900,
        maxValue: 1000
      },
      label: `900 – 1000 ${UnitsTorque.FootPoundForce}`
    },
    {
      value: {
        unit: UnitsTorque.FootPoundForce,
        minValue: 1000,
        maxValue: 2000
      },
      label: `1000 – 2000 ${UnitsTorque.FootPoundForce}`
    },
    {
      value: {
        unit: UnitsTorque.FootPoundForce,
        minValue: 2000,
        maxValue: 3000
      },
      label: `2000 – 3000 ${UnitsTorque.FootPoundForce}`
    },
    {
      value: {
        unit: UnitsTorque.FootPoundForce,
        minValue: 3000,
        maxValue: 4000
      },
      label: `3000 – 4000 ${UnitsTorque.FootPoundForce}`
    },
    {
      value: {
        unit: UnitsTorque.FootPoundForce,
        minValue: 4000,
        maxValue: 5000
      },
      label: `4000 – 5000 ${UnitsTorque.FootPoundForce}`
    },
    {
      value: {
        unit: UnitsTorque.FootPoundForce,
        minValue: 5000,
        maxValue: 6000
      },
      label: `5000 – 6000 ${UnitsTorque.FootPoundForce}`
    },
    {
      value: {
        unit: UnitsTorque.FootPoundForce,
        minValue: 6000,
        maxValue: 7000
      },
      label: `6000 – 7000 ${UnitsTorque.FootPoundForce}`
    },
    {
      value: {
        unit: UnitsTorque.FootPoundForce,
        minValue: 7000,
        maxValue: 8000
      },
      label: `7000 – 8000 ${UnitsTorque.FootPoundForce}`
    },
    {
      value: {
        unit: UnitsTorque.FootPoundForce,
        minValue: 8000,
        maxValue: 9000
      },
      label: `8000 – 9000 ${UnitsTorque.FootPoundForce}`
    },
    {
      value: {
        unit: UnitsTorque.FootPoundForce,
        minValue: 9000,
        maxValue: 10000
      },
      label: `9000 – 10000 ${UnitsTorque.FootPoundForce}`
    },
    {
      value: {
        unit: UnitsTorque.FootPoundForce,
        minValue: 10000,
        maxValue: 1000000
      },
      label: `10,000+ ${UnitsTorque.FootPoundForce}`
    },
  ],
  [UnitSystem.Metric]: [
    {
      value: {
        unit: UnitsTorque.NewtonMeter,
        minValue: 0,
        maxValue: 500
      },
      label: `0 – 500 ${UnitsTorque.NewtonMeter}`
    },
    {
      value: {
        unit: UnitsTorque.NewtonMeter,
        minValue: 500,
        maxValue: 1000
      },
      label: `500 – 1000 ${UnitsTorque.NewtonMeter}`
    },
    {
      value: {
        unit: UnitsTorque.NewtonMeter,
        minValue: 1000,
        maxValue: 1500
      },
      label: `1000 – 1500 ${UnitsTorque.NewtonMeter}`
    },
    {
      value: {
        unit: UnitsTorque.NewtonMeter,
        minValue: 1500,
        maxValue: 2000
      },
      label: `1500 – 2000 ${UnitsTorque.NewtonMeter}`
    },
    {
      value: {
        unit: UnitsTorque.NewtonMeter,
        minValue: 2000,
        maxValue: 2500
      },
      label: `2000 – 2500 ${UnitsTorque.NewtonMeter}`
    },
    {
      value: {
        unit: UnitsTorque.NewtonMeter,
        minValue: 2500,
        maxValue: 3000
      },
      label: `2500 – 3000 ${UnitsTorque.NewtonMeter}`
    },
    {
      value: {
        unit: UnitsTorque.NewtonMeter,
        minValue: 3000,
        maxValue: 3500
      },
      label: `3000 – 3500 ${UnitsTorque.NewtonMeter}`
    },
    {
      value: {
        unit: UnitsTorque.NewtonMeter,
        minValue: 3500,
        maxValue: 4000
      },
      label: `3500 – 4000 ${UnitsTorque.NewtonMeter}`
    },
    {
      value: {
        unit: UnitsTorque.NewtonMeter,
        minValue: 4000,
        maxValue: 4500
      },
      label: `4000 – 4500 ${UnitsTorque.NewtonMeter}`
    },
    {
      value: {
        unit: UnitsTorque.NewtonMeter,
        minValue: 4500,
        maxValue: 5000
      },
      label: `4500 – 5000 ${UnitsTorque.NewtonMeter}`
    },
    {
      value: {
        unit: UnitsTorque.NewtonMeter,
        minValue: 5000,
        maxValue: 10000
      },
      label: `5000 – 10000 ${UnitsTorque.NewtonMeter}`
    },
    {
      value: {
        unit: UnitsTorque.NewtonMeter,
        minValue: 10000,
        maxValue: 15000
      },
      label: `10000 – 15000 ${UnitsTorque.NewtonMeter}`
    },
    {
      value: {
        unit: UnitsTorque.NewtonMeter,
        minValue: 15000,
        maxValue: 20000
      },
      label: `15000 – 20000 ${UnitsTorque.NewtonMeter}`
    },
    {
      value: {
        unit: UnitsTorque.NewtonMeter,
        minValue: 20000,
        maxValue: 25000
      },
      label: `20000 – 25000 ${UnitsTorque.NewtonMeter}`
    },
    {
      value: {
        unit: UnitsTorque.NewtonMeter,
        minValue: 25000,
        maxValue: 30000
      },
      label: `25000 – 30000 ${UnitsTorque.NewtonMeter}`
    },
    {
      value: {
        unit: UnitsTorque.NewtonMeter,
        minValue: 30000,
        maxValue: 35000
      },
      label: `30000 – 35000 ${UnitsTorque.NewtonMeter}`
    },
    {
      value: {
        unit: UnitsTorque.NewtonMeter,
        minValue: 35000,
        maxValue: 40000
      },
      label: `35000 – 40000 ${UnitsTorque.NewtonMeter}`
    },
    {
      value: {
        unit: UnitsTorque.NewtonMeter,
        minValue: 40000,
        maxValue: 45000
      },
      label: `40000 – 45000 ${UnitsTorque.NewtonMeter}`
    },
    {
      value: {
        unit: UnitsTorque.NewtonMeter,
        minValue: 45000,
        maxValue: 50000
      },
      label: `45000 – 50000 ${UnitsTorque.NewtonMeter}`
    },
    {
      value: {
        unit: UnitsTorque.NewtonMeter,
        minValue: 50000,
        maxValue: 5000000
      },
      label: `50000+ ${UnitsTorque.NewtonMeter}`
    },
  ]
}

// function getGenericThreadedDiameterOptions(
//   selectedParameters: SelectedThreadedParameters,
//   currentFastener: ThreadedFastener,
//   currentUnitSystem: UnitSystem
// ): ReactSelectOptions<Diameter<UnitsDistance>, string> {
//   // Todo: determine allowed diameters based on other selected parameters
//   const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
//     ? currentFastener.parameters.imperialDiameters
//     : currentFastener.parameters.metricDiameters;

//   const selectionObjs: ReactSelectOptions<Diameter<UnitsDistance>, string> = Object.entries(diameterObj).map(([key, parametersObj]) => {
//     if (!isDiameter(parametersObj.diameter)) {
//       throw "diameter is not diameter"
//     }
//     return {
//       label: `${parametersObj.diameter.text} - ${parametersObj.diameter.distance.value} ${parametersObj.diameter.distance.unit}`,
//       value: parametersObj.diameter
//     }
//   }).sort((a, b) => {
//     if (!isDiameter(a.value) || !isDiameter(b.value)) {
//       throw "diameter is not diameter"
//     }
//     return a.value.distance.value - b.value.distance.value
//   })

//   return selectionObjs
// }

function getGenericThreadedLengthOptions(
  selectedParameters: SelectedThreadedParameters,
  currentFastener: ThreadedFastener,
  currentUnitSystem: UnitSystem
): ReactSelectOptions<ThreadedLength<UnitsDistance>, string> {
  // Todo: determine allowed lengths based on other selected parameters
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectedDiameter = selectedParameters.diameter?.distance.value;

  // If a diameter is selected, get only params for that diameter; otherwise get all params
  const diameterObjList = (selectedDiameter !== undefined)
    ? { [selectedDiameter]: diameterObj[selectedDiameter] }
    : diameterObj

  // console.log("diameter obj list:", diameterObjList)

  // Get list of possible lengths for all diameters
  const allLengths: ThreadedLength<UnitsDistance>[] = Object.entries(diameterObjList).reduce(
    (lengthsForAllDiameters: ThreadedLength<UnitsDistance>[], [key, parametersObj]) => {
      // console.log("parameters obj", parametersObj)
      // Get list of possible lengths for current diameter
      // @ts-expect-error We should only ever have one type of ThreadedLengthRange at a time
      const currentDiameterLengths: ThreadedLength<UnitsDistance>[] = parametersObj.lengths.reduce(
        (lengths: ThreadedLength<UnitsDistance>[], lengthObj: ThreadedLengthRange<UnitsDistance>) => {
          const currentLengths: ThreadedLength<UnitsDistance>[] = [];
          for (let newLengthValue = lengthObj.min.value; newLengthValue <= lengthObj.max.value; newLengthValue += lengthObj.increment.value) {
            const newLength: ThreadedLength<UnitsDistance> = {
              unit: lengthObj.min.unit,
              value: Math.round(newLengthValue * 10000) / 10000
            }
            currentLengths.push(newLength);
          }
          return [...lengths, ...currentLengths]
        }, [])
      return [...lengthsForAllDiameters, ...currentDiameterLengths]
    }, [])

  const sortedLengths = allLengths.sort((a, b) => a.value - b.value)
  const uniqueSortedLengths: ThreadedLength<UnitsDistance>[] = []
  sortedLengths.forEach((length) => {
    // Get index of matching length obj, or -1 if not found
    const matchingLengthIndex = uniqueSortedLengths
      .findIndex(storedLength => (length.value === storedLength.value))
    if (matchingLengthIndex === -1) {
      uniqueSortedLengths.push(length)
    }
  })

  const result = uniqueSortedLengths.map((length) => {
    return {
      label: `${length.value} ${length.unit}`,
      value: length
    }
  })

  return result
}

function getGenericThreadPitchOptions(
  selectedParameters: SelectedThreadedParameters,
  currentFastener: ThreadedFastener,
  currentUnitSystem: UnitSystem
): ReactSelectOptions<GenericPitch<UnitsPitch, PitchType>, string> {
  // Todo: determine allowed lengths based on other selected parameters
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectedDiameter = selectedParameters.diameter?.distance.value;

  // If a diameter is selected, get only params for that diameter; otherwise get all params
  const diameterObjList = (selectedDiameter !== undefined)
    ? { [selectedDiameter]: diameterObj[selectedDiameter] }
    : diameterObj

  // console.log("diameter obj list:", diameterObjList)

  const compatiblePitchesObj: { [n: string]: GenericPitch<UnitsPitch, PitchType> } =
    Object.entries(diameterObjList).reduce((accPitches, [key, currentDiameterObj]) => {
      const currentPitch = currentDiameterObj.pitches
      if (isPinAndCollarPitchSet(currentPitch)) {
        throw "getGenericThreadPitchOptions: unhandled PinAndCollarPitch"
      }
      const newPitchObj = {
        [`${currentPitch.coarse?.value}-coarse`]: currentPitch.coarse,
        [`${currentPitch.fine?.value}-fine`]: currentPitch.fine,
      }
      return {
        ...accPitches,
        ...newPitchObj
      }
    }, {})

  const result: ReactSelectOptions<GenericPitch<UnitsPitch, PitchType>, string> = Object.entries(compatiblePitchesObj)
    .filter(function ([key, value]) { return value !== undefined })  // Shouldn't need this, but just in case
    .map(
      ([key, pitch]) => {
        return {
          label: `${pitch.value} ${pitch.unit} (${pitch.pitchType})`,
          value: pitch
        }
      }
    ).sort((a, b) => a.value.value - b.value.value)

  return result
}

function getGenericThreadedHeadTypeOptions(
  selectedParameters: SelectedThreadedParameters,
  currentFastener: ThreadedFastener,
  currentUnitSystem: UnitSystem
): ReactSelectOptions<ThreadedHeadTypes, string> {
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectedDiameter = selectedParameters.diameter?.distance.value;

  // If a diameter is selected, get only params for that diameter; otherwise get all params
  const diameterObjList = (selectedDiameter !== undefined)
    ? { [selectedDiameter]: diameterObj[selectedDiameter] }
    : diameterObj

  // Get object containing possible headtypes for all diameters
  const compatibleHeadTypes: { [Parameter in ThreadedHeadTypes]?: true } = Object.entries(diameterObjList).reduce(
    (accHeadTypes, [key, parametersObj]) => {
      return {
        ...accHeadTypes,
        ...parametersObj.compatibleHeadTypes
      }
    }, {}
  )

  // Get array of unique possible headtypes from object
  const result: ReactSelectOptions<ThreadedHeadTypes, string> = Object.entries(compatibleHeadTypes)
    .filter(function ([key, value]) { return value !== undefined })  // Shouldn't need this, but just in case
    .map(
      ([headStyleName]) => {
        return {
          label: headStyleName,
          value: headStyleName as ThreadedHeadTypes
        }
      }
    )

  return result
}

function getThreadedDriveTypeOptions(
  selectedParameters: SelectedThreadedParameters
): ReactSelectOptions<ThreadedDriveTypes, string> {

  const currentHeadType = selectedParameters.headType;

  if (!currentHeadType) {
    return []
  }

  const allowedDriveTypesObj = driveTypebyHeadList[currentHeadType];

  const result = Object.entries(allowedDriveTypesObj)
    .filter(function ([key, value]) { return value !== undefined })
    .map(([driveType]) => {
      return {
        label: driveType,
        value: driveType as ThreadedDriveTypes
      }
    })

  return result;
}

function getGenericThreadedMaterialOptions(
  selectedParameters: SelectedThreadedParameters,
  currentFastener: ThreadedFastener,
  currentUnitSystem: UnitSystem
): ReactSelectOptions<MaterialNames, string> {
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectedDiameter = selectedParameters.diameter?.distance.value;

  // If a diameter is selected, get only params for that diameter; otherwise get all params
  const diameterObjList = (selectedDiameter !== undefined)
    ? { [selectedDiameter]: diameterObj[selectedDiameter] }
    : diameterObj

  // Get object containing possible materials for all diameters
  const compatibleMaterials: { [Parameter in MaterialNames]?: GenericThreadedMaterial<UnitsForce> }
    = Object.entries(diameterObjList).reduce((accMaterials, [key, parametersObj]) => {
      return {
        ...accMaterials,
        ...parametersObj.materials
      }
    }, {})

  // Get array of unique possible headtypes from object
  const result: ReactSelectOptions<MaterialNames, string> = Object.entries(compatibleMaterials)
    .filter(function ([key, value]) { return value !== undefined })  // Shouldn't need this, but just in case
    .map(([materialName]) => {
      return {
        label: materialName,
        value: materialName as MaterialNames
      }
    })

  return result
}

function getGenericThreadedCombinedStrengthOptions(
  selectedParameters: SelectedThreadedParameters,
  currentFastener: ThreadedFastener,
  currentUnitSystem: UnitSystem
): Strengths<UnitsForce> {
  // Todo: determine allowed lengths based on other selected parameters
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectedDiameter = selectedParameters.diameter?.distance.value;
  const selectedMaterial = selectedParameters.material;
  const selectedPitch = selectedParameters.pitch;

  // Assume a specific diameter, material, and pitch have all been selected.
  if (selectedDiameter === undefined || selectedMaterial === undefined || selectedPitch === undefined) {
    throw "Undefined selected diameter, material, or pitch"
  }

  if (!isGenericPitch(selectedPitch)) {
    throw "Non-generic pitch type"
  }

  const selectedPitchType = selectedPitch.pitchType

  const material = diameterObj[selectedDiameter].materials[selectedMaterial]

  if (material === undefined) {
    throw "Undefined material"
  }
  if (!isStandardThreadedMaterial(material)) {
    return {}
    // throw `nonstandard material: ${JSON.stringify(material)}`
  }

  const strengths = (selectedPitchType === PitchType.coarse)
    ? material.coarsePitch
    : material.finePitch

  if (strengths === undefined) {
    throw "Undefined strengths"
  }

  console.log("Strengths:", strengths)

  return strengths
}

function getGenericThreadedTensileStrengthOptions(
  selectedParameters: SelectedThreadedParameters,
  currentFastener: ThreadedFastener,
  currentUnitSystem: UnitSystem
): ReactSelectOptions<
  Strength<UnitsForce> | StrengthRange<UnitsForce>,
  string
> {
  try {
    const { tensile } = getGenericThreadedCombinedStrengthOptions(selectedParameters, currentFastener, currentUnitSystem);

    if (!tensile) {
      return defaultStrengthOptions[currentUnitSystem]
    }

    return [{
      value: tensile,
      label: `${tensile.value} ${tensile.unit}`
    }]
  } catch (error) {
    console.error("Error in getGenericThreadedTensileStrengthOptions:", error)
    return []
  }
}

function getGenericThreadedShearStrengthOptions(
  selectedParameters: SelectedThreadedParameters,
  currentFastener: ThreadedFastener,
  currentUnitSystem: UnitSystem
): ReactSelectOptions<
  Strength<UnitsForce> | StrengthRange<UnitsForce>,
  string
> {
  try {
    const { shear } = getGenericThreadedCombinedStrengthOptions(selectedParameters, currentFastener, currentUnitSystem);

    if (!shear) {
      return defaultStrengthOptions[currentUnitSystem]
    }

    return [{
      value: shear,
      label: `${shear.value} ${shear.unit}`
    }]
  } catch (error) {
    return []
  }
}

function getThreadedFinishOptions(
  selectedParameters: SelectedThreadedParameters
): ReactSelectOptions<FinishNames, string> {

  const currentMaterial = selectedParameters.material;

  if (!currentMaterial) {
    return []
  }

  const allowedFinishesObj = finishesByMaterialList[currentMaterial];

  const result = Object.entries(allowedFinishesObj)
    .filter(function ([key, value]) { return value !== undefined })
    .map(([finish]) => {
      const namedFinish = finish as FinishNames;
      const saltSprayRating = threadedFinishSaltSprayRatings[namedFinish]?.saltSprayRating;
      if (!saltSprayRating) {
        return {
          label: namedFinish,
          value: namedFinish,
        };
      }
      let tooltipText: string;
      if (isDimensionedQuantityRange(saltSprayRating)) {
        tooltipText = `Salt spray rating: ${saltSprayRating.minValue} - ${saltSprayRating.maxValue} ${saltSprayRating.unit}`
      } else {
        tooltipText = `Salt spray rating: ${saltSprayRating.value} ${saltSprayRating.unit}`
      }
      return {
        label: namedFinish,
        value: namedFinish,
        tooltipText: tooltipText
      }
    })

  console.log("Possible finishes:", result)

  return result;
}



function getPossibleThreadedSelections(selectedParameters: SelectedThreadedParameters, currentUnitSystem: UnitSystem)
  : Partial<PossibleThreadedSelectionOptions> {
  const currentFastener = fastenerList[selectedParameters.fastenerName]
  if (!isThreadedFastener(currentFastener)) {
    console.error("Error in getPossibleThreadedSelections(): Current fastener is not threaded")
    return {}
  }
  // Return a different objboi for each threaded field
  return {
    type: 'threaded',
    diameter: getGenericFastenerDiameterOptions(selectedParameters, currentFastener, currentUnitSystem),
    length: getGenericThreadedLengthOptions(selectedParameters, currentFastener, currentUnitSystem),
    pitch: getGenericThreadPitchOptions(selectedParameters, currentFastener, currentUnitSystem),
    material: getGenericThreadedMaterialOptions(selectedParameters, currentFastener, currentUnitSystem),
    finish: getThreadedFinishOptions(selectedParameters),
    tensileStrength: getGenericThreadedTensileStrengthOptions(selectedParameters, currentFastener, currentUnitSystem),
    shearStrength: getGenericThreadedShearStrengthOptions(selectedParameters, currentFastener, currentUnitSystem),
    headType: getGenericThreadedHeadTypeOptions(selectedParameters, currentFastener, currentUnitSystem),
    driveType: getThreadedDriveTypeOptions(selectedParameters)
  }
}

// function getGenericRivetDiameterOptions(
//   selectedParameters: SelectedRivetParameters,
//   currentFastener: RivetFastener,
//   currentUnitSystem: UnitSystem
// ): ReactSelectOptions<Diameter<UnitsDistance>, string> {
//   // Todo: determine allowed diameters based on other selected parameters
//   const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
//     ? currentFastener.parameters.imperialDiameters
//     : currentFastener.parameters.metricDiameters;

//   const selectionObjs: ReactSelectOptions<Diameter<UnitsDistance>, string> = Object.entries(diameterObj).map(([key, parametersObj]) => {
//     if (!isDiameter(parametersObj.diameter)) {
//       throw "diameter is not diameter"
//     }
//     return {
//       label: parametersObj.diameter.text,
//       value: parametersObj.diameter
//     }
//   }).sort((a, b) => {
//     if (!isDiameter(a.value) || !isDiameter(b.value)) {
//       throw "diameter is not diameter"
//     }
//     return a.value.distance.value - b.value.distance.value
//   })

//   return selectionObjs
// }

function getGenericRivetHeadStyleOptions(
  selectedParameters: SelectedRivetParameters,
  currentFastener: RivetFastener,
  currentUnitSystem: UnitSystem
): ReactSelectOptions<RivetHeadStyles, string> {
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectedDiameter = selectedParameters.diameter?.distance.value;

  // If a diameter is selected, get only params for that diameter; otherwise get all params
  const diameterObjList = (selectedDiameter !== undefined)
    ? { [selectedDiameter]: diameterObj[selectedDiameter] }
    : diameterObj

  // Get object containing possible headtypes for all diameters
  const compatibleHeadTypes: { [Parameter in RivetHeadStyles]?: true } = Object.entries(diameterObjList).reduce(
    (accHeadTypes, [key, parametersObj]) => {
      return {
        ...accHeadTypes,
        ...parametersObj.headStyles
      }
    }, {}
  )

  // Get array of unique possible headtypes from object
  const result: ReactSelectOptions<RivetHeadStyles, string> = Object.entries(compatibleHeadTypes)
    .filter(function ([key, value]) { return value !== undefined })  // Shouldn't need this, but just in case
    .map(
      ([headStyleName]) => {
        return {
          label: headStyleName,
          value: headStyleName as RivetHeadStyles
        }
      }
    )

  return result
}

function getGenericRivetGripRangeOptions(
  selectedParameters: SelectedRivetParameters,
  currentFastener: RivetFastener,
  currentUnitSystem: UnitSystem
): ReactSelectOptions<RivetGrip<UnitsDistance>, string> {
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectedDiameter = selectedParameters.diameter?.distance.value;

  // If a diameter is selected, get only params for that diameter; otherwise get all params
  const diameterObjList = (selectedDiameter !== undefined)
    ? { [selectedDiameter]: diameterObj[selectedDiameter] }
    : diameterObj

  // Get object containing possible headtypes for all diameters
  const compatibleGrips: { [n: number]: RivetGrip<UnitsDistance> } = Object.entries(diameterObjList).reduce(
    (accGrips, [key, parametersObj]) => {
      // @ts-expect-error We should only ever have one type of gripRange at a time
      const gripsFromCurrentDiameter = parametersObj.gripRanges.reduce(
        (accGrips: [], currentGripRange: RivetGripRange<UnitsDistance>) => {
          let gripsFromCurrentRange = {}
          for (let i = currentGripRange.min.value; i <= currentGripRange.max.value; i += currentGripRange.increment.value) {
            gripsFromCurrentRange = {
              ...gripsFromCurrentRange,
              [i]: {
                value: i,
                unit: currentGripRange.min.unit
              }
            }
          }
          return {
            ...accGrips,
            ...gripsFromCurrentRange
          };
        }, {}
      )
      return {
        ...accGrips,
        ...gripsFromCurrentDiameter
      }
    }, {}
  )

  // Get array of unique possible headtypes from object
  const result: ReactSelectOptions<RivetGrip<UnitsDistance>, string> = Object.entries(compatibleGrips)
    .filter(function ([key, value]) { return value !== undefined })  // Shouldn't need this, but just in case
    .sort((a, b) => a[1].value - b[1].value)
    .map(
      ([gripValue, gripObj]) => {
        return {
          // label: `${gripObj.value} ${gripObj.unit}`,
          label: `${gripObj.value.toFixed(1)} ${gripObj.unit}`,
          value: gripObj
        }
      }
    )

  return result
}

function getRivetBodyMaterialOptions(
  selectedParameters: SelectedRivetParameters,
  currentFastener: RivetFastener,
  currentUnitSystem: UnitSystem
): ReactSelectOptions<MaterialNames, string> {
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectedDiameter = selectedParameters.diameter?.distance.value;

  // If a diameter is selected, get only params for that diameter; otherwise get all params
  const diameterObjList = (selectedDiameter !== undefined)
    ? { [selectedDiameter]: diameterObj[selectedDiameter] }
    : diameterObj

  // Get object containing possible materials for all diameters
  const compatibleMaterials: { [Parameter in MaterialNames]?: RivetMaterial<UnitsForce> }
    = Object.entries(diameterObjList).reduce((accMaterials, [key, parametersObj]) => {
      return {
        ...accMaterials,
        ...parametersObj.bodyMaterials
      }
    }, {})

  // Get array of unique possible body materials from object
  const result: ReactSelectOptions<MaterialNames, string> = Object.entries(compatibleMaterials)
    .filter(function ([key, value]) { return value !== undefined })  // Shouldn't need this, but just in case
    .map(([materialName]) => {
      return {
        label: materialName,
        value: materialName as MaterialNames
      }
    })

  return result
}

function getRivetBodyFinishOptions(
  selectedParameters: SelectedRivetParameters
): ReactSelectOptions<FinishNames, string> {

  const currentMaterial = selectedParameters.bodyMaterial;

  if (!currentMaterial) {
    return []
  }

  const allowedFinishesObj = finishesByMaterialList[currentMaterial];

  const result = Object.entries(allowedFinishesObj)
    .filter(function ([key, value]) { return value !== undefined })
    .map(([finish]) => {
      const namedFinish = finish as FinishNames;
      const saltSprayRating = threadedFinishSaltSprayRatings[namedFinish]?.saltSprayRating;
      if (!saltSprayRating) {
        return {
          label: namedFinish,
          value: namedFinish,
        };
      }
      let tooltipText: string;
      if (isDimensionedQuantityRange(saltSprayRating)) {
        tooltipText = `Salt spray rating: ${saltSprayRating.minValue} - ${saltSprayRating.maxValue} ${saltSprayRating.unit}`
      } else {
        tooltipText = `Salt spray rating: ${saltSprayRating.value} ${saltSprayRating.unit}`
      }
      return {
        label: namedFinish,
        value: namedFinish,
        tooltipText: tooltipText
      }
    })

  console.log("Possible finishes:", result)

  return result;
}

function getRivetStemMaterialOptions(
  selectedParameters: SelectedRivetParameters,
  currentFastener: RivetFastener,
  currentUnitSystem: UnitSystem
): ReactSelectOptions<MaterialNames, string> {
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectedDiameter = selectedParameters.diameter?.distance.value;
  const selectedBodyMaterial = selectedParameters.bodyMaterial;

  if (selectedDiameter === undefined || selectedBodyMaterial === undefined) {
    return [];
  }

  const bodyMaterialObj = diameterObj[selectedDiameter].bodyMaterials[selectedBodyMaterial];

  if (bodyMaterialObj === undefined) {
    return [];
  }

  const compatibleStemMaterials = Object.entries(bodyMaterialObj.stemMaterials)
    .filter(function ([key, value]) { return value !== undefined })  // Shouldn't need this, but just in case
    .map(([materialName, value]) => {
      return {
        label: materialName,
        value: materialName as MaterialNames
      }
    })

  return compatibleStemMaterials;
}

function getRivetTensileStrengthOptions(
  selectedParameters: SelectedRivetParameters,
  currentFastener: RivetFastener,
  currentUnitSystem: UnitSystem
): ReactSelectOptions<
  Strength<UnitsForce> | StrengthRange<UnitsForce>,
  string
> {
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectedDiameter = selectedParameters.diameter?.distance.value;
  const selectedBodyMaterial = selectedParameters.bodyMaterial;

  if (selectedDiameter === undefined || selectedBodyMaterial === undefined) {
    return [];
  }

  const bodyMaterialObj = diameterObj[selectedDiameter].bodyMaterials[selectedBodyMaterial];

  if (bodyMaterialObj === undefined) {
    return [];
  }

  const tensileStrength = bodyMaterialObj.tensileStrength

  if (tensileStrength === undefined) {
    return defaultStrengthOptions[currentUnitSystem]
  }

  return [{
    value: tensileStrength,
    label: `${tensileStrength.value} ${tensileStrength.unit}`
  }]
}

function getRivetShearStrengthOptions(
  selectedParameters: SelectedRivetParameters,
  currentFastener: RivetFastener,
  currentUnitSystem: UnitSystem
): ReactSelectOptions<
  Strength<UnitsForce> | StrengthRange<UnitsForce>,
  string
> {
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectedDiameter = selectedParameters.diameter?.distance.value;
  const selectedBodyMaterial = selectedParameters.bodyMaterial;
  const selectedStemMaterial = selectedParameters.stemMaterial;

  if (selectedDiameter === undefined
    || selectedBodyMaterial === undefined
    || selectedStemMaterial === undefined
  ) {
    return [];
  }

  const bodyMaterialObj = diameterObj[selectedDiameter].bodyMaterials[selectedBodyMaterial];

  if (bodyMaterialObj === undefined) {
    return [];
  }

  const stemMaterialObj = bodyMaterialObj.stemMaterials[selectedStemMaterial]

  if (stemMaterialObj === undefined || stemMaterialObj.shearStrength === undefined) {
    return defaultStrengthOptions[currentUnitSystem]
  }

  return [{
    value: stemMaterialObj.shearStrength,
    label: `${stemMaterialObj.shearStrength.value} ${stemMaterialObj.shearStrength.unit}`
  }]
}

function getPossibleRivetSelections(selectedParameters: SelectedRivetParameters, currentUnitSystem: UnitSystem)
  : Partial<PossibleRivetSelectionOptions> {
  const currentFastener = fastenerList[selectedParameters.fastenerName]
  if (!isRivetFastener(currentFastener)) {
    console.error("Error in getPossibleRivetSelections(): Current fastener is not rivet")
    return {}
  }
  // Return a different objboi for each rivet field
  return {
    type: 'rivet',
    diameter: getGenericFastenerDiameterOptions(selectedParameters, currentFastener, currentUnitSystem),
    gripRange: getGenericRivetGripRangeOptions(selectedParameters, currentFastener, currentUnitSystem),
    headStyle: getGenericRivetHeadStyleOptions(selectedParameters, currentFastener, currentUnitSystem),
    bodyMaterial: getRivetBodyMaterialOptions(selectedParameters, currentFastener, currentUnitSystem),
    bodyFinish: getRivetBodyFinishOptions(selectedParameters),
    stemMaterial: getRivetStemMaterialOptions(selectedParameters, currentFastener, currentUnitSystem),
    tensileStrength: getRivetTensileStrengthOptions(selectedParameters, currentFastener, currentUnitSystem),
    shearStrength: getRivetShearStrengthOptions(selectedParameters, currentFastener, currentUnitSystem)
  }
}

function getGenericFastenerDiameterOptions(
  selectedParameters: SelectedPartParameters,
  currentFastener: Fastener,
  currentUnitSystem: UnitSystem
): ReactSelectOptions<Diameter<UnitsDistance>, string> {
  // Todo: determine allowed diameters based on other selected parameters
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectionObjs: ReactSelectOptions<Diameter<UnitsDistance>, string> = Object.entries(diameterObj).map(([key, parametersObj]) => {
    if (!isDiameter(parametersObj.diameter)) {
      throw "diameter is not diameter"
    }
    return {
      label: parametersObj.diameter.text,
      value: parametersObj.diameter
    }
  }).sort((a, b) => {
    if (!isDiameter(a.value) || !isDiameter(b.value)) {
      throw "diameter is not diameter"
    }
    return a.value.distance.value - b.value.distance.value
  })

  return selectionObjs
}

function getRivetNutDiameterAndPitchOptions(
  selectedParameters: SelectedRivetNutParameters,
  currentFastener: RivetNutFastener,
  currentUnitSystem: UnitSystem
): PossibleRivetNutSelectionOptions['diameterAndPitch'] {
  // Todo: determine allowed lengths based on other selected parameters
  const diameterAndPitchObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const result: ReturnType<typeof getRivetNutDiameterAndPitchOptions> = Object.entries(diameterAndPitchObj)
    .map(([key, currentDiameterAndPitchObj]) => {
      const diameter: Diameter<UnitsDistance> = currentDiameterAndPitchObj.diameter;
      const pitch: DimensionedQuantity<UnitsPitch> = currentDiameterAndPitchObj.pitch;

      const unitSystem = (pitch.unit === UnitsPitch.MillimetersPerThread)
        ? UnitSystem.Metric
        : UnitSystem.Imperial;

      const label = (unitSystem === UnitSystem.Metric)
        ? `${diameter.text} x ${pitch.value} ${pitch.unit}`
        : diameter.text;

      return {
        label: label,
        value: {
          diameter: diameter,
          pitch: pitch
        }
      }
    })
    .sort((a, b) => a.value.diameter.distance.value - b.value.diameter.distance.value);

  return result
}

function getRivetNutPitchOptions(
  selectedParameters: SelectedRivetNutParameters,
  currentFastener: RivetNutFastener,
  currentUnitSystem: UnitSystem
): ReactSelectOptions<DimensionedQuantity<UnitsPitch>, string> {
  // Todo: determine allowed lengths based on other selected parameters
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectedDiameter = selectedParameters.diameter?.distance.value;

  // If a diameter is selected, get only params for that diameter; otherwise get all params
  const diameterObjList = (selectedDiameter !== undefined)
    ? { [selectedDiameter]: diameterObj[selectedDiameter] }
    : diameterObj

  // console.log("diameter obj list:", diameterObjList)

  const uniquePitches: {
    [n: number]: DimensionedQuantity<UnitsPitch>
  } = Object.entries(diameterObjList).reduce((partialUniquePitches, [key, currentDiameterObj]) => {
    const currentPitchObj: DimensionedQuantity<UnitsPitch> = currentDiameterObj.pitch;
    return {
      ...partialUniquePitches,
      [currentPitchObj.value]: currentPitchObj
    }
  }, {})

  const result: ReturnType<typeof getRivetNutPitchOptions> = Object.entries(uniquePitches)
    .map(([key, currentPitchObj]) => {
      return {
        label: `${currentPitchObj.value} ${currentPitchObj.unit}`,
        value: currentPitchObj
      }
    })
    .sort((a, b) => a.value.value - b.value.value);

  return result
}

function getSelectedRivetNutDiameterAndPitchObjKey(
  diameter: Diameter<UnitsDistance> | undefined,
  pitch: DimensionedQuantity<UnitsPitch> | undefined
) {
  if (diameter === undefined || pitch === undefined) {
    return undefined;
  }
  return `${diameter.distance.value}-${pitch.value}`;
}

function getRivetNutGripRangeAndLengthOptions(
  selectedParameters: SelectedRivetNutParameters,
  currentFastener: RivetNutFastener,
  currentUnitSystem: UnitSystem
): PossibleRivetNutSelectionOptions['gripRangeAndLengths'] {
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectedDiameterAndPitchKey = getSelectedRivetNutDiameterAndPitchObjKey(selectedParameters.diameter, selectedParameters.pitch);


  if (selectedDiameterAndPitchKey === undefined) {
    return []
  }
  const selectedDiameterObj = diameterObj[selectedDiameterAndPitchKey];

  const result: ReturnType<typeof getRivetNutGripRangeAndLengthOptions> = [
    {
      label:
        `Grip range: ${selectedDiameterObj.gripRange.minValue} - ${selectedDiameterObj.gripRange.maxValue} ${selectedDiameterObj.gripRange.unit};
Uninstalled length: ${selectedDiameterObj.length.uninstalled.value} ${selectedDiameterObj.length.uninstalled.unit};
Installed length: ${selectedDiameterObj.length.installed.value} ${selectedDiameterObj.length.installed.unit}`,
      value: {
        gripRange: selectedDiameterObj.gripRange,
        installedLength: selectedDiameterObj.length.installed,
        uninstalledLength: selectedDiameterObj.length.uninstalled
      }
    }
  ];

  // // If a diameter is selected, get only params for that diameter; otherwise get all params
  // const diameterObjList = (selectedDiameterAndPitchKey !== undefined)
  //   ? { [selectedDiameterAndPitchKey]: diameterObj[selectedDiameterAndPitchKey] }
  //   : diameterObj

  // // Get object containing possible grip ranges for all diameters
  // const compatibleGrips: { [n: string]: DimensionedQuantityRange<UnitsDistance> } = Object.entries(diameterObjList).reduce(
  //   (accGrips, [key, parametersObj]: [string, RivetNutParameters<UnitsDistance, UnitsPitch, UnitsForce>]) => {
  //     const currentGrip = parametersObj.gripRange;
  //     const result: { [n: string]: DimensionedQuantityRange<UnitsDistance> } = {
  //       ...accGrips,
  //       [`${currentGrip.minValue} - ${currentGrip.maxValue} ${currentGrip.unit}`]: currentGrip,
  //     };
  //     return result
  //   }, {}
  // )

  // // Get array of unique possible headtypes from object
  // const result: ReactSelectOptions<DimensionedQuantityRange<UnitsDistance>, string> = Object.entries(compatibleGrips)
  //   .filter(function ([key, value]) { return value !== undefined })  // Shouldn't need this, but just in case
  //   .sort((a, b) => a[1].minValue - b[1].minValue)
  //   .map(
  //     ([gripKey, gripObj]) => {
  //       return {
  //         // label: `${gripObj.value} ${gripObj.unit}`,
  //         label: gripKey,
  //         value: gripObj
  //       }
  //     }
  //   )

  return result;
}

function getRivetNutGripRangeOptions(
  selectedParameters: SelectedRivetNutParameters,
  currentFastener: RivetNutFastener,
  currentUnitSystem: UnitSystem
): ReactSelectOptions<DimensionedQuantityRange<UnitsDistance>, string> {
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectedDiameterAndPitchKey = getSelectedRivetNutDiameterAndPitchObjKey(selectedParameters.diameter, selectedParameters.pitch);

  // If a diameter is selected, get only params for that diameter; otherwise get all params
  const diameterObjList = (selectedDiameterAndPitchKey !== undefined)
    ? { [selectedDiameterAndPitchKey]: diameterObj[selectedDiameterAndPitchKey] }
    : diameterObj

  // Get object containing possible grip ranges for all diameters
  const compatibleGrips: { [n: string]: DimensionedQuantityRange<UnitsDistance> } = Object.entries(diameterObjList).reduce(
    (accGrips, [key, parametersObj]: [string, RivetNutParameters<UnitsDistance, UnitsPitch, UnitsForce, UnitsTorque>]) => {
      const currentGrip = parametersObj.gripRange;
      const result: { [n: string]: DimensionedQuantityRange<UnitsDistance> } = {
        ...accGrips,
        [`${currentGrip.minValue} - ${currentGrip.maxValue} ${currentGrip.unit}`]: currentGrip,
      };
      return result
    }, {}
  )

  // Get array of unique possible headtypes from object
  const result: ReactSelectOptions<DimensionedQuantityRange<UnitsDistance>, string> = Object.entries(compatibleGrips)
    .filter(function ([key, value]) { return value !== undefined })  // Shouldn't need this, but just in case
    .sort((a, b) => a[1].minValue - b[1].minValue)
    .map(
      ([gripKey, gripObj]) => {
        return {
          // label: `${gripObj.value} ${gripObj.unit}`,
          label: gripKey,
          value: gripObj
        }
      }
    )

  return result;
}

function getRivetNutInstalledLengthOptions(
  selectedParameters: SelectedRivetNutParameters,
  currentFastener: RivetNutFastener,
  currentUnitSystem: UnitSystem
): ReactSelectOptions<DimensionedQuantity<UnitsDistance>, string> {
  // Todo: determine allowed lengths based on other selected parameters
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectedDiameterAndPitchKey = getSelectedRivetNutDiameterAndPitchObjKey(selectedParameters.diameter, selectedParameters.pitch);

  // If a diameter is selected, get only params for that diameter; otherwise get all params
  const diameterObjList = (selectedDiameterAndPitchKey !== undefined)
    ? { [selectedDiameterAndPitchKey]: diameterObj[selectedDiameterAndPitchKey] }
    : diameterObj

  const uniqueInstalledLengths: { [n: number]: DimensionedQuantity<UnitsDistance> } = Object.entries(diameterObjList)
    .reduce((partialLengths, [key, currentDiameterObj]) => {
      const currentLengthObj = currentDiameterObj.length.installed;
      return {
        ...partialLengths,
        [currentLengthObj.value]: currentLengthObj
      }
    }, {})

  const result: ReturnType<typeof getRivetNutInstalledLengthOptions> = Object.entries(uniqueInstalledLengths)
    .map(([key, currentLengthObj]) => {
      return {
        label: `${currentLengthObj.value} ${currentLengthObj.unit}`,
        value: currentLengthObj
      }
    })

  return result;
}

function getRivetNutUninstalledLengthOptions(
  selectedParameters: SelectedRivetNutParameters,
  currentFastener: RivetNutFastener,
  currentUnitSystem: UnitSystem
): ReactSelectOptions<DimensionedQuantity<UnitsDistance>, string> {
  // Todo: determine allowed lengths based on other selected parameters
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectedDiameterAndPitchKey = getSelectedRivetNutDiameterAndPitchObjKey(selectedParameters.diameter, selectedParameters.pitch);

  // If a diameter is selected, get only params for that diameter; otherwise get all params
  const diameterObjList = (selectedDiameterAndPitchKey !== undefined)
    ? { [selectedDiameterAndPitchKey]: diameterObj[selectedDiameterAndPitchKey] }
    : diameterObj

  const uniqueUninstalledLengths: { [n: number]: DimensionedQuantity<UnitsDistance> } = Object.entries(diameterObjList)
    .reduce((partialLengths, [key, currentDiameterObj]) => {
      const currentLengthObj = currentDiameterObj.length.uninstalled;
      return {
        ...partialLengths,
        [currentLengthObj.value]: currentLengthObj
      }
    }, {})

  const result: ReturnType<typeof getRivetNutUninstalledLengthOptions> = Object.entries(uniqueUninstalledLengths)
    .map(([key, currentLengthObj]) => {
      return {
        label: `${currentLengthObj.value} ${currentLengthObj.unit}`,
        value: currentLengthObj
      }
    })

  return result;
}

function getRivetNutSwagingForceOptions(
  selectedParameters: SelectedRivetNutParameters,
  currentFastener: RivetNutFastener,
  currentUnitSystem: UnitSystem
): ReactSelectOptions<DimensionedQuantity<UnitsForce>, string> {
  // Todo: determine allowed lengths based on other selected parameters
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectedDiameterAndPitchKey = getSelectedRivetNutDiameterAndPitchObjKey(selectedParameters.diameter, selectedParameters.pitch);

  // If a diameter is selected, get only params for that diameter; otherwise get all params
  const diameterObjList = (selectedDiameterAndPitchKey !== undefined)
    ? { [selectedDiameterAndPitchKey]: diameterObj[selectedDiameterAndPitchKey] }
    : diameterObj

  const uniqueSwagingForces: { [n: number]: DimensionedQuantity<UnitsForce> } = Object.entries(diameterObjList)
    .reduce((partialLengths, [key, currentDiameterObj]) => {
      const currentSwagingForceObj: DimensionedQuantity<UnitsForce> = currentDiameterObj.swagingForce;
      return {
        ...partialLengths,
        [currentSwagingForceObj.value]: currentSwagingForceObj
      }
    }, {})

  const result: ReturnType<typeof getRivetNutSwagingForceOptions> = Object.entries(uniqueSwagingForces)
    .map(([key, currentSwagingForceObj]) => {
      return {
        label: `${currentSwagingForceObj.value} ${currentSwagingForceObj.unit}`,
        value: currentSwagingForceObj
      }
    })

  return result;
}

function getRivetNutHeadStyleOptions(
  selectedParameters: SelectedRivetNutParameters,
  currentFastener: RivetNutFastener,
  currentUnitSystem: UnitSystem
): ReactSelectOptions<RivetNutHeadStyles, string> {
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectedDiameterAndPitchKey = getSelectedRivetNutDiameterAndPitchObjKey(selectedParameters.diameter, selectedParameters.pitch);

  // If a diameter is selected, get only params for that diameter; otherwise get all params
  const diameterObjList = (selectedDiameterAndPitchKey !== undefined)
    ? { [selectedDiameterAndPitchKey]: diameterObj[selectedDiameterAndPitchKey] }
    : diameterObj

  // Get object containing possible headtypes for all diameters
  const compatibleHeadStyles: { [Parameter in RivetNutHeadStyles]?: true } = Object.entries(diameterObjList).reduce(
    (accHeadStyles, [key, parametersObj]) => {
      return {
        ...accHeadStyles,
        ...parametersObj.compatibleHeadStyles
      }
    }, {}
  )

  // Get array of unique possible headtypes from object
  const result: ReturnType<typeof getRivetNutHeadStyleOptions> = Object.entries(compatibleHeadStyles)
    .filter(function ([key, value]) { return value !== undefined })  // Shouldn't need this, but just in case
    .map(
      ([headStyleName]) => {
        return {
          label: headStyleName,
          value: headStyleName as RivetNutHeadStyles
        }
      }
    )

  return result;
}

function getRivetNutMiscFeatureOptions(
  selectedParameters: SelectedRivetNutParameters,
  currentFastener: RivetNutFastener,
  currentUnitSystem: UnitSystem
): ReactSelectOptions<RivetNutMiscFeatures, string> {
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectedDiameterAndPitchKey = getSelectedRivetNutDiameterAndPitchObjKey(selectedParameters.diameter, selectedParameters.pitch);

  // If a diameter is selected, get only params for that diameter; otherwise get all params
  const diameterObjList = (selectedDiameterAndPitchKey !== undefined)
    ? { [selectedDiameterAndPitchKey]: diameterObj[selectedDiameterAndPitchKey] }
    : diameterObj

  // Get object containing possible headtypes for all diameters
  const compatibleMiscFeatures: { [Parameter in RivetNutMiscFeatures]?: true } = Object.entries(diameterObjList).reduce(
    (accMiscFeatures, [key, parametersObj]) => {
      return {
        ...accMiscFeatures,
        ...parametersObj.compatibleMiscFeatures
      }
    }, {}
  )

  // Get array of unique possible headtypes from object
  const result: ReturnType<typeof getRivetNutMiscFeatureOptions> = Object.entries(compatibleMiscFeatures)
    .filter(function ([key, value]) { return value !== undefined })  // Shouldn't need this, but just in case
    .map(
      ([miscFeatureName]) => {
        return {
          label: miscFeatureName,
          value: miscFeatureName as RivetNutMiscFeatures
        }
      }
    )

  return result;
}

function getRivetNutBodyTypeOptions(
  selectedParameters: SelectedRivetNutParameters,
  currentFastener: RivetNutFastener,
  currentUnitSystem: UnitSystem
): ReactSelectOptions<RivetNutBodyTypes, string> {
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectedDiameterAndPitchKey = getSelectedRivetNutDiameterAndPitchObjKey(selectedParameters.diameter, selectedParameters.pitch);

  // If a diameter is selected, get only params for that diameter; otherwise get all params
  const diameterObjList = (selectedDiameterAndPitchKey !== undefined)
    ? { [selectedDiameterAndPitchKey]: diameterObj[selectedDiameterAndPitchKey] }
    : diameterObj

  // Get object containing possible headtypes for all diameters
  const compatibleBodyTypes: { [Parameter in RivetNutBodyTypes]?: true } = Object.entries(diameterObjList).reduce(
    (accBodyTypes, [key, parametersObj]) => {
      return {
        ...accBodyTypes,
        ...parametersObj.compatibleBodyTypes
      }
    }, {}
  )

  // Get array of unique possible headtypes from object
  const result: ReturnType<typeof getRivetNutBodyTypeOptions> = Object.entries(compatibleBodyTypes)
    .filter(function ([key, value]) { return value !== undefined })  // Shouldn't need this, but just in case
    .map(
      ([bodyTypeName]) => {
        return {
          label: bodyTypeName,
          value: bodyTypeName as RivetNutBodyTypes
        }
      }
    )

  return result;
}

function getRivetNutMaterialOptions(
  selectedParameters: SelectedRivetNutParameters,
  currentFastener: RivetNutFastener,
  currentUnitSystem: UnitSystem
): ReactSelectOptions<MaterialNames, string> {
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectedDiameterAndPitchKey = getSelectedRivetNutDiameterAndPitchObjKey(selectedParameters.diameter, selectedParameters.pitch);

  // If a diameter is selected, get only params for that diameter; otherwise get all params
  const diameterObjList = (selectedDiameterAndPitchKey !== undefined)
    ? { [selectedDiameterAndPitchKey]: diameterObj[selectedDiameterAndPitchKey] }
    : diameterObj

  // Get object containing possible materials for all diameters
  const compatibleMaterials: { [Parameter in MaterialNames]?: RivetNutMaterial<UnitsForce, UnitsTorque> }
    = Object.entries(diameterObjList).reduce((accMaterials, [key, parametersObj]) => {
      return {
        ...accMaterials,
        ...parametersObj.materials
      }
    }, {})

  // Get array of unique possible headtypes from object
  const result: ReactSelectOptions<MaterialNames, string> = Object.entries(compatibleMaterials)
    .filter(function ([key, value]) { return value !== undefined })  // Shouldn't need this, but just in case
    .map(([materialName]) => {
      return {
        label: materialName,
        value: materialName as MaterialNames
      }
    })

  return result
}

function getRivetNutFinishOptions(
  selectedParameters: SelectedRivetNutParameters,
  currentFastener: RivetNutFastener,
  currentUnitSystem: UnitSystem
): ReactSelectOptions<FinishNames, string> {
  const currentMaterial = selectedParameters.material;

  if (!currentMaterial) {
    return []
  }

  const allowedFinishesObj = finishesByMaterialRivetNutsList[currentMaterial];

  const result = Object.entries(allowedFinishesObj)
    .filter(function ([key, value]) { return value !== undefined })
    .map(([finish]) => {
      const namedFinish = finish as FinishNames;
      const saltSprayRating = threadedFinishSaltSprayRatings[namedFinish]?.saltSprayRating;
      if (!saltSprayRating) {
        return {
          label: namedFinish,
          value: namedFinish,
        };
      }
      let tooltipText: string;
      if (isDimensionedQuantityRange(saltSprayRating)) {
        tooltipText = `Salt spray rating: ${saltSprayRating.minValue} - ${saltSprayRating.maxValue} ${saltSprayRating.unit}`
      } else {
        tooltipText = `Salt spray rating: ${saltSprayRating.value} ${saltSprayRating.unit}`
      }
      return {
        label: namedFinish,
        value: namedFinish,
        tooltipText: tooltipText
      }
    })

  console.log("Possible finishes:", result)

  return result;
  // const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
  //   ? currentFastener.parameters.imperialDiameters
  //   : currentFastener.parameters.metricDiameters;

  // const selectedDiameterAndPitchKey = getSelectedRivetNutDiameterAndPitchObjKey(selectedParameters.diameter, selectedParameters.pitch);

  // // If a diameter is selected, get only params for that diameter; otherwise get all params
  // const diameterObjList = (selectedDiameterAndPitchKey !== undefined)
  //   ? { [selectedDiameterAndPitchKey]: diameterObj[selectedDiameterAndPitchKey] }
  //   : diameterObj

  // // Get object containing possible materials for all diameters
  // const compatibleMaterials: { [Parameter in MaterialNames]?: RivetNutMaterial<UnitsForce> }
  //   = Object.entries(diameterObjList).reduce((accMaterials, [key, parametersObj]) => {
  //     return {
  //       ...accMaterials,
  //       ...parametersObj.materials
  //     }
  //   }, {})

  // // Get array of unique possible headtypes from object
  // const result: ReactSelectOptions<MaterialNames, string> = Object.entries(compatibleMaterials)
  //   .filter(function ([key, value]) { return value !== undefined })  // Shouldn't need this, but just in case
  //   .map(([materialName]) => {
  //     return {
  //       label: materialName,
  //       value: materialName as MaterialNames
  //     }
  //   })

  // return result
}

function getSpecifiedRivetNutStrengthOptions(
  selectedParameters: SelectedRivetNutParameters,
  currentFastener: RivetNutFastener,
  currentUnitSystem: UnitSystem,
  strengthType: keyof RivetNutMaterial<UnitsForce, UnitsTorque>
): ReactSelectOptions<
  DimensionedQuantity<UnitsForce> | DimensionedQuantityRange<UnitsForce>,
  string
> {
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectedDiameterAndPitchKey = getSelectedRivetNutDiameterAndPitchObjKey(selectedParameters.diameter, selectedParameters.pitch);

  const selectedMaterial = selectedParameters.material;
  if (!selectedDiameterAndPitchKey || !selectedMaterial) {
    return [];
  }

  const currentMaterialStrengths = diameterObj[selectedDiameterAndPitchKey].materials[selectedMaterial]
  if (!currentMaterialStrengths) {
    return [];
  }

  const currentStrength = currentMaterialStrengths[strengthType];

  if (!currentStrength || (currentStrength.unit !== UnitsForce.Newton && currentStrength.unit !== UnitsForce.PSI)) {
    return defaultStrengthOptions[currentUnitSystem]
  }

  const result: ReturnType<typeof getSpecifiedRivetNutStrengthOptions> = [
    {
      label: `${currentStrength.value} ${currentStrength.unit}`,
      value: currentStrength
    }
  ]

  return result;
}

function getSpecifiedRivetNutTorqueStrengthOptions(
  selectedParameters: SelectedRivetNutParameters,
  currentFastener: RivetNutFastener,
  currentUnitSystem: UnitSystem,
  strengthType: keyof RivetNutMaterial<UnitsForce, UnitsTorque>
): ReactSelectOptions<
  DimensionedQuantity<UnitsTorque> | DimensionedQuantityRange<UnitsTorque>,
  string
> {
  const diameterObj = (currentUnitSystem === UnitSystem.Imperial)
    ? currentFastener.parameters.imperialDiameters
    : currentFastener.parameters.metricDiameters;

  const selectedDiameterAndPitchKey = getSelectedRivetNutDiameterAndPitchObjKey(selectedParameters.diameter, selectedParameters.pitch);

  const selectedMaterial = selectedParameters.material;
  if (!selectedDiameterAndPitchKey || !selectedMaterial) {
    return [];
  }

  const currentMaterialStrengths = diameterObj[selectedDiameterAndPitchKey].materials[selectedMaterial]
  if (!currentMaterialStrengths) {
    return [];
  }

  const currentStrength = currentMaterialStrengths[strengthType];

  if (!currentStrength || (currentStrength.unit !== UnitsTorque.NewtonMeter && currentStrength.unit !== UnitsTorque.FootPoundForce)) {
    return defaultTorqueStrengthOptions[currentUnitSystem]
  }

  const result: ReturnType<typeof getSpecifiedRivetNutTorqueStrengthOptions> = [
    {
      label: `${currentStrength.value} ${currentStrength.unit}`,
      value: currentStrength
    }
  ]

  return result;
}

function getPossibleRivetNutSelections(selectedParameters: SelectedRivetNutParameters, currentUnitSystem: UnitSystem)
  : Partial<PossibleRivetNutSelectionOptions> {
  const currentFastener = fastenerList[selectedParameters.fastenerName]
  if (!isRivetNutFastener(currentFastener)) {
    console.error("Error in getPossibleRivetNutSelections(): Current fastener is not rivet nut")
    return {}
  }
  // Return a different objboi for each rivet nut field
  return {
    type: 'rivetNut',
    diameterAndPitch: getRivetNutDiameterAndPitchOptions(selectedParameters, currentFastener, currentUnitSystem),
    gripRangeAndLengths: getRivetNutGripRangeAndLengthOptions(selectedParameters, currentFastener, currentUnitSystem),
    swagingForce: getRivetNutSwagingForceOptions(selectedParameters, currentFastener, currentUnitSystem),
    headStyle: getRivetNutHeadStyleOptions(selectedParameters, currentFastener, currentUnitSystem),
    miscFeatures: getRivetNutMiscFeatureOptions(selectedParameters, currentFastener, currentUnitSystem),
    bodyType: getRivetNutBodyTypeOptions(selectedParameters, currentFastener, currentUnitSystem),
    material: getRivetNutMaterialOptions(selectedParameters, currentFastener, currentUnitSystem),
    finish: getRivetNutFinishOptions(selectedParameters, currentFastener, currentUnitSystem),
    tensileStrength: getSpecifiedRivetNutStrengthOptions(
      selectedParameters,
      currentFastener,
      currentUnitSystem,
      "tensileStrength"
    ),
    pullOutStrength: getSpecifiedRivetNutStrengthOptions(
      selectedParameters,
      currentFastener,
      currentUnitSystem,
      "pullOutStrength"
    ),
    shearStrength: getSpecifiedRivetNutStrengthOptions(
      selectedParameters,
      currentFastener,
      currentUnitSystem,
      "shearStrength"
    ),
    maxTorqueUnsupported: getSpecifiedRivetNutTorqueStrengthOptions(
      selectedParameters,
      currentFastener,
      currentUnitSystem,
      "maxTorqueUnsupported"
    ),
    maxTorqueSupported: getSpecifiedRivetNutTorqueStrengthOptions(
      selectedParameters,
      currentFastener,
      currentUnitSystem,
      "maxTorqueSupported"
    ),
  }
}

export function getPossibleFastenerSelections(
  selectedPartParameters: SelectedPartParameters,
  currentUnitSystem: UnitSystem
): PossibleFastenerSelectionOptions {
  const fastenerName = Object.entries(FastenerNames).map(([fastenerEnumKey, fastenerName]) => {
    return {
      label: fastenerName,
      value: fastenerName,
    };
  });
  const defaultResult = {
    type: undefined,
    fastenerName: fastenerName
  }

  switch (selectedPartParameters.fastenerName) {
    case undefined:
      // No fastener has been selected
      return defaultResult

    case FastenerNames.Bolt:
    case FastenerNames.MachineScrew:
    case FastenerNames.TFormingScrewMetal:
    case FastenerNames.TFormingScrewPlastic:
      // Threaded fastener selected
      if (!isSelectedThreadedParameters(selectedPartParameters)) {
        throw new Error("getPossibleFastenerSelections: selected part parameter type mismatch");
      }
      return {
        ...defaultResult,
        ...getPossibleThreadedSelections(selectedPartParameters, currentUnitSystem)
      }

    case FastenerNames.OpenEnd:
    case FastenerNames.ClosedEnd:
    case FastenerNames.Crimped:
    case FastenerNames.Rolled:
    case FastenerNames.Bulbex:
    case FastenerNames.NSKTR:
    case FastenerNames.MonoboltInterlock:
    case FastenerNames.Bulbing:
    case FastenerNames.StructuralKTR:
    case FastenerNames.Solid:
    case FastenerNames.SemiTubular:
    case FastenerNames.Tubular:
    case FastenerNames.SelfPierce:
      // Rivet fastener selected
      if (!isSelectedRivetParameters(selectedPartParameters)) {
        throw new Error("getPossibleFastenerSelections: selected part parameter type mismatch");
      }
      return {
        ...defaultResult,
        ...getPossibleRivetSelections(selectedPartParameters, currentUnitSystem)
      }
    case FastenerNames.OpenEndRivetNut:
    case FastenerNames.ClosedEndRivetNut:
      // Rivet Nut fastener selected
      if (!isSelectedRivetNutParameters(selectedPartParameters)) {
        throw new Error("getPossibleFastenerSelections: selected part parameter type mismatch");
      }
      return {
        ...defaultResult,
        ...getPossibleRivetNutSelections(selectedPartParameters, currentUnitSystem)
      }
    default:
      console.error("selected fastener not recognized");
      return defaultResult
  }

}
