import {
  Routes,
  Route,
} from "react-router-dom";
import React, { Suspense } from "react";
import Footer from "./components/Footer";
import { useAuth0 } from "@auth0/auth0-react";
import ScrollToTop from "./components/ScrollToTop";
import './styles/style.scss';
import { Home2 } from './components/Home2';
import Loading from './components/Loading';
import ProtectedRoute from './auth/ProtectedRoute';
import TermsandConditions from './components/TermsandConditions';
import { SignupRivian } from "./components/Account/SignupRivian";
import { SignupZoox } from "./components/Account/SignupZoox";
import { SignupTrial } from "./components/Account/SignupTrial";
import PrivacyPolicy from './components/PrivacyPolicy';
import { SignupApple } from "./components/Account/SignupApple";
import "@fontsource/staatliches";
import "@fontsource/oswald";
import "@fontsource/happy-monkey"
import "@fontsource/over-the-rainbow"
import { BrandedSignup, canooCompanyInfo } from "./components/Account/BrandedSignup";
import TorqueCalculator from "./components/TorqueCalculator/TorqueCalculator";
import Topbar from "./components/Topbar";
import LoadingScreen from "./components/LoadingScreen";
import Account from "./components/Account/Account";
import Communizing from "./components/Communizing";
import { Partytown } from '@builder.io/partytown/react';
import { Helmet } from "react-helmet";


const JoinIt = React.lazy(() => import('./components/JoinIt/JoinIt'));
const LearnIt = React.lazy(() => import('./components/LearnIt/LearnIt'));
const LearningIt = React.lazy(() => import('./components/LearnIt/LearningIt'));
const FastenerRecommender = React.lazy(() => import('./components/FastenerRecommender/FastenerRecommender'));
const DesignIt2 = React.lazy(() => import('./components/DesignIt/DesignIt2 2'));
const ChoosePlan2 = React.lazy(() => import('./components/ChoosePlan2'));
const AboutUs = React.lazy(() => import('./components/AboutUs'));
const MegaCastingArticle = React.lazy(() => import('./components/MegaCastingArticle'));
const ArticlesLandingPage = React.lazy(() => import('./components/ArticlesLandingPage'));
const ImportancePlasticsArticle = React.lazy(() => import('./components/ImportancePlasticsArticle'));
const ContactForm = React.lazy(() => import('./components/ContactForm'));
const CancellationForm = React.lazy(() => import('./components/CancellationForm'));
const Jobs = React.lazy(() => import('./components/Jobs'));
const MissionStatement = React.lazy(() => import('./components/MissionStatement'));
const IntroCourses = React.lazy(() => import('./components/IntroCourses'));
const Courses = React.lazy(() => import('./components/Courses'));
const MiniCourses = React.lazy(() => import('./components/MiniCourses'));
const ProblemSolution = React.lazy(() => import('./components/ProblemSolution'));
const WelcomeJoinIt = React.lazy(() => import('./components/welcomeJoinIt'));
const WelcomeFindIt = React.lazy(() => import('./components/welcomeFindIt'));
const WelcomeLearnIt = React.lazy(() => import('./components/welcomeLearnIt'));
const WelcomeDesignIt = React.lazy(() => import('./components/welcomeDesignIt'));
const WelcomeTorquingIt = React.lazy(() => import('./components/TorquingIt'));
const Welcome = React.lazy(() => import('./components/Welcome'));
const ComingSoon = React.lazy(() => import('./components/ComingSoon'));
const FindingClips = React.lazy(() => import('./components/FindingClips'));
const BatterySupport = React.lazy(() => import('./components/BatterySupport'));
const SealsAndCoatings = React.lazy(() => import('./components/SealsAndCoatings'));
const DemoVideo = React.lazy(() => import('./components/DemoVideo'));


// import { useIntercom } from 'react-use-intercom'

export default function App() {
  // const { boot } = useIntercom();
  const { isLoading, isAuthenticated, error } = useAuth0();

  if (error) {
    return <div>Oops... authentication error: {error.message}</div>;
  }

  if (isLoading) {
    console.log("Loading Auth0...");
    return <Loading />;
  }
  console.log(`Auth0 loaded; isAuthenticated: ${isAuthenticated}`);

  return (<>
    <Helmet>
      <Partytown debug={true} forward={['dataLayer.push', "_hsq.push", "Intercom"]} />
    </Helmet>

    <Topbar />
    <ScrollToTop />
    {/* todo @Justin: make a nicer loading page, with good styling */}
    <Suspense fallback={LoadingScreen}>
      <Routes>
        <Route path="/" element={<Home2 />} />
        <Route path="/ChoosePlan" element={<ChoosePlan2 />} />
        <Route path="/LearnIt/*" element={<LearnIt />} />
        <Route path="/TermsandConditions" element={<TermsandConditions />} />
        <Route path="/Rivian" element={<SignupRivian />} />
        <Route path="/Apple" element={<SignupApple />} />
        <Route path="/Zoox" element={<SignupZoox />} />
        <Route path="/Canoo" element={<BrandedSignup companyInfo={canooCompanyInfo} />} />
        <Route path="/FreeTrial" element={<SignupTrial />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/MissionStatement" element={<MissionStatement />} />
        <Route path="/MegaCastingArticle" element={<MegaCastingArticle />} />
        <Route path="/ImportancePlasticsArticle" element={<ImportancePlasticsArticle />} />
        <Route path="/ArticlesLandingPage" element={<ArticlesLandingPage />} />
        <Route path="/ProblemSolution" element={<ProblemSolution />} />
        <Route path="/IntroCourses" element={<IntroCourses />} />
        <Route path="/MiniCourses" element={<MiniCourses />} />
        <Route path="/Courses" element={<Courses />} />
        <Route path="/Jobs" element={<Jobs />} />
        <Route path="/ContactUs" element={<ContactForm />} />
        <Route path="/CancellationForm" element={<CancellationForm />} />
        <Route path="/WelcomeJoinIt" element={<WelcomeJoinIt />} />
        <Route path="/WelcomeFindIt" element={<WelcomeFindIt />} />
        <Route path="/WelcomeLearnIt" element={<WelcomeLearnIt />} />
        <Route path="/WelcomeDesignIt" element={<WelcomeDesignIt />} />
        <Route path="/WelcomeTorquingIt" element={<WelcomeTorquingIt />} />
        <Route path="/Welcome" element={<Welcome />} />
        <Route path="/ComingSoon" element={<ComingSoon />} />
        <Route path="/TorqueCalculator" element={<TorqueCalculator />} />
        <Route path="/FindingClips" element={<FindingClips />} />
        <Route path="/BatterySupport" element={<BatterySupport />} />
        <Route path="/Communizing" element={<Communizing />} />
        <Route path="/SealsAndCoatings" element={<SealsAndCoatings />} />
        <Route path="/Demo" element={<DemoVideo />} />
        {/* Protected routes */}
        <Route path="/JoinIt" element={<ProtectedRoute component={JoinIt} />} />
        <Route path="/LearningIt" element={<ProtectedRoute component={LearningIt} />} />
        <Route path="/Account" element={<ProtectedRoute component={Account} />} />
        <Route path="/DesignIt" element={<ProtectedRoute component={DesignIt2} />} />
        <Route path="/Account" element={<ProtectedRoute component={Account} />} />
        <Route path="/FindingIt" element={<ProtectedRoute component={FastenerRecommender} />} />
        <Route path="/FindIt" element={<ProtectedRoute component={FastenerRecommender} />} />
      </Routes>
    </Suspense>
    <Footer />

  </>);
}