import { NavLink } from "react-router-dom";
import LinkedIn from "../images/linkedIn.jpg";
import styles from "../styles/_footer.module.scss"

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className={styles.footerContainer}>
      <div className={styles.copyright}>
        Copyright &copy; {currentYear} Finding Fasteners, Inc. All rights
        reserved.
      </div>
      <NavLink to="/ContactUs" className={styles.footerLink1}>
        Contact Us
      </NavLink>
      <NavLink to="/PrivacyPolicy" className={styles.footerLink2}>
        Privacy Policy
      </NavLink>
      <NavLink to="/TermsandConditions" className={styles.footerLink3}>
        Terms of Use
      </NavLink>
      <NavLink to="/CancellationForm" className={styles.footerLink4}>
        Cancellation Policy
      </NavLink>
      <a
        className={styles.linkedInFooter}
        href="https://www.linkedin.com/company/finding-engineered-solutions/"
        target="_blank"
      >
        <img
          className={styles.linkedInFooter}
          alt="maze logo"
          src={LinkedIn}
        />
      </a>
    </div>
  );
};

export default Footer;
