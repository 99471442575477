import Tooltip, { TooltipPrimitive } from '@atlaskit/tooltip';
import styled from '@emotion/styled';
import PropsWithChildren, {  components, OptionProps } from 'react-select';
import ReactMarkdown from "react-markdown";
import {  OptionTypeBase, GroupTypeBase } from 'react-select/src/types';
import { ReactSelectOption } from './FixRequiredSelect';

const InlineDialog = styled(TooltipPrimitive)`
  color: black;
  background-color: white;
  border: solid 4px #266783;
  padding: 0.25rem;
`;

export type ReactSelectOptionWithTooltipProps =
  & PropsWithChildren<OptionProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>>
  & { data: ReactSelectOption<any, any> }

export const ReactSelectOptionWithTooltip = (props: ReactSelectOptionWithTooltipProps) => {
  const optionData = props.data
  if (optionData.tooltipText !== undefined) {
    return (
      <Tooltip 
        content={<ReactMarkdown>{optionData.tooltipText}</ReactMarkdown>}
        component={InlineDialog}
      >
        {/* @ts-ignore This works fine. Typescript finds no errors when the same file as the Select component. */}
        <components.Option {...props} />
      </Tooltip>
    );
  } else {
    {/* @ts-ignore This works fine. Typescript finds no errors when the same file as the Select component. */}
    return <components.Option {...props} />
  }
};