import {
  Diameter,
  DimensionedQuantity,
  isDiameter,
  isDimensionedQuantity,
  SelectedRivetNutParameters,
  SelectedRivetParameters,
  SelectedThreadedParameters
} from '../FindIt/findItTypes';

import { SelectedPartParameters } from "./FastenerRecommender";
import Select, { ReactSelectOptions } from '../utilities/FixRequiredSelect';
import { OptionTypeBase } from 'react-select';
import { useEffect, useState } from 'react';
import { ReactSelectOptionWithTooltip } from '../utilities/ReactSelectOptionWithTooltip';
import { FfTooltip } from '../globalComponents/FfTooltip';
import { PositionType } from "@atlaskit/tooltip";
import infoIcon from "../../images/info-hover-01.svg"
import { ReactSelectOption } from '../utilities/FixRequiredSelect';

import {
  Tooltip as BootstrapTooltip,
  OverlayTrigger
} from 'react-bootstrap';

import styles from "../../styles/_findIt.module.scss"
import utilityStyles from "../../styles/_utilityStyles.module.scss"


type FieldNames =
  | keyof SelectedThreadedParameters
  | keyof SelectedRivetParameters
  | keyof SelectedRivetNutParameters

type FieldSelectorProps = {
  selectionOptions: ReactSelectOptions<any, string>;
  defaultValue?: ReactSelectOption<any, string>
  required: boolean;
  fieldName: FieldNames;
  fieldTitle: string;
  parentField?: {
    fieldName: FieldNames,
    fieldTitle: string
  }
  icon?: {
    src: string,
    altText: string,
    position: 'left' | 'right' | 'center'
  }
  tooltip?: {
    content: React.ReactNode,
    position?: PositionType,
  }
  // wrapperClassName?: string;
  childClassName: string;
  // placeholder?: string,
  // isDisabled?: boolean,
  highlight?: {
    setFeatureAsHighlighted?: () => void,
    setFeatureAsNotHighlighted?: () => void
  },
  selectedPartParameters: SelectedPartParameters;
  setSelectedPartParameters: (newPartParameters: SelectedPartParameters) => void;
};

export function FieldSelector(props: FieldSelectorProps) {

  // console.log("field selector default value", props.defaultValue)

  const [selection, setSelection] = useState<OptionTypeBase | null>(
    (props.defaultValue !== undefined) ? props.defaultValue : null
  )

  const handleSelectionChange = (newSelection: OptionTypeBase | null) => {
    setSelection(newSelection);
    const newSelectedPartParameters: SelectedPartParameters = {
      ...props.selectedPartParameters,
      // fastenerName: props.selectedPartParameters.fastenerName,
      [props.fieldName]: newSelection?.value
    };
    props.setSelectedPartParameters(newSelectedPartParameters);
  };

  const parentFieldName = props.parentField?.fieldName




  useEffect(() => {
    // @ts-ignore
    const currentSelection: any = props.selectedPartParameters[props.fieldName];
    const allowedSelections = props.selectionOptions.map(option => option.value)

    let selectionIsValid: boolean
    if (currentSelection === undefined) {
      selectionIsValid = false;
    } else if (typeof currentSelection === 'string') {
      selectionIsValid = (allowedSelections.indexOf(currentSelection) !== -1);
    } else if (isDimensionedQuantity(currentSelection)) {
      const allowedSelectionValues = (allowedSelections as DimensionedQuantity<any>[])
        .map(selection => selection.value);
      selectionIsValid = (allowedSelectionValues.indexOf(currentSelection.value) !== -1)
    } else if (isDiameter(currentSelection)) {
      const allowedSelectionValues = (allowedSelections as Diameter<any>[])
        .map(selection => selection.distance.value);
      selectionIsValid = (allowedSelectionValues.indexOf(currentSelection.distance.value) !== -1)
    } else {
      throw `Unrecognized selection type: ${typeof currentSelection} ${JSON.stringify(currentSelection)}`
    }

    if (!selectionIsValid) {
      handleSelectionChange(null);
    }
  },
    parentFieldName
      // @ts-ignore
      ? [props.selectedPartParameters[parentFieldName]]
      : []
  )

  // let placeholder: string | undefined = undefined;
  const placeholder = undefined;  // Removed placeholder text per Heather's request. Leaving this in case we want it back.
  let isDisabled: boolean | undefined = undefined;
  if (
    props.parentField?.fieldName
    // @ts-ignore
    && (props.selectedPartParameters[props.parentField.fieldName] === undefined)
  ) {
    // placeholder = `Pick ${props.parentField.fieldTitle} first`;
    isDisabled = true;
  }

  // const wrapperClassNameBase = `${props.wrapperClassName}`
  const wrapperClassName = (props.icon === undefined)
    ? ''
    : (props.icon.position === 'left')
      ? " findingIt-field-container-with-icon findingIt-field-container-with-icon-left"
      : " findingIt-field-container-with-icon findingIt-field-container-with-icon-right"

  const header = !props.tooltip
    ? <h2 className={`${styles.smallH2} ${styles.blueH2}`}>{props.fieldTitle}</h2>
    : <>

      <h2 className={`${styles.smallH2} ${styles.blueH2}`}>
        {props.fieldTitle}
        {" "}
        <FfTooltip
          tooltipContent={props.tooltip.content}
          tooltipDecorator={'icon-pro-tip'}
          position={props.tooltip?.position ? props.tooltip?.position : "right"}
        >
          <img src={infoIcon} className={`${utilityStyles.infoIcon} ${styles.findingItFieldHeaderIcon}`} />
        </FfTooltip>
      </h2>

    </>

  const content = <>
    <div
      className={wrapperClassName}
      onMouseEnter={props.highlight?.setFeatureAsHighlighted}
      onMouseLeave={props.highlight?.setFeatureAsNotHighlighted}
      onBlur={props.highlight?.setFeatureAsNotHighlighted}
    >
      {props.icon &&
        // We can't find fieldIconLeft; does it even exist?
        // <img src={props.icon.src} alt={props.icon.altText} className={`${styles.fieldIcon} ${styles.fieldIconLeft}`} />
        <img src={props.icon.src} alt={props.icon.altText} className={styles.fieldIcon} />
      }
      <div className={styles.findingItFieldContent}>
        {header}
        <div className={props.childClassName}>
          <Select
            options={props.selectionOptions}
            // @ts-ignore This works fine. Typescript finds no errors when in same file as custom Option definition
            components={{ Option: ReactSelectOptionWithTooltip }}
            onChange={handleSelectionChange}
            defaultValue={props.defaultValue ? props.defaultValue : selection}
            value={selection}
            required={props.required}
            isClearable={true}
            placeholder={placeholder}
            isDisabled={isDisabled}
            onFocus={props.highlight?.setFeatureAsHighlighted}
            onBlur={props.highlight?.setFeatureAsNotHighlighted}
          />
        </div>
      </div>
    </div>
  </>

  return content;
}